import React, { FC } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { dispatch } from '../../store';
import { addToList, removeFromList } from '../../store/securities.slice';
import { QuotesConnector } from '../../integrations/quotes.connector';
import { TMarketSecurity } from '../../types/api';

export const PinBox: FC<{ marketSecurity: TMarketSecurity, pinned: boolean }> = ({ marketSecurity, pinned }) => {
    const onChange = (e: CheckboxChangeEvent) => {
        const { tradeModeCode, ticker } = marketSecurity;
        let key: string | null = null;
        if (marketSecurity.tradeModeCode) {
            key = `${tradeModeCode}-${ticker}`
        }
        if (e.target.checked) {
            if (key) {
                QuotesConnector.watch(key);
            }
            dispatch(addToList(marketSecurity))
        } else {
            if (key) {
                QuotesConnector.unwatch(key);
            }
            dispatch(removeFromList(marketSecurity))
        }
    };
    return (
        <Checkbox onChange={onChange} defaultChecked={pinned} />
    )
}
