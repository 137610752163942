import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Divider, Input, InputNumber, Modal, notification, Row, Select } from "antd";
import { dispatch, TState } from "../../../store";
import { closeSecuritiesTransferOrder, getOrders } from "../../../store/orders.slice";
import { apiConnector } from "../../../integrations/api.connector";
import { NcaLayerConnector } from "../../../integrations/ncaLayer.connector";
import { NotificationsConnector } from "../../../integrations/notifications.connector";
import { EIntOrderType, getIntOrderTypeName } from "../InternalOrderDetails";
import { ModalDS } from "../../shared/modals/ModalDS";
import { countNum, parseNum } from "../../../services/formatter";
import { isCompany, isHeadOfCompany, shortName } from "../../../services/helpers";
import { buildXMLv2 } from "../../../services/xmlBuilder";
import { TMarketSecurity, TOwnSecurity, TPDFHeading } from "../../../types/api";
import {
    ECheckReqType,
    ENonTradeOrderType,
    EOrderKind,
    ESecuritiesTransferType,
    TSecuritiesTransferRequest,
    TSetOrderTemplate
} from "../../../types/orders";
import { NcaMessage } from "../../../types/nca";
import { optionStyles, timeForErrorNotification, timeForSuccessNotification, timeForWarningNotification } from "../../../constants";
import "./../editedForm.css";

export const InternalSecuritiesTransferForm = () => {
    const {
        auth: {
            cProfile,
            clientData,
            userName,
            iin
        },
        orders: {
            showSecuritiesTransferOrderModal,
        },
        money: {
            subAccountsLocal,
            subAccountsInt
        },
        securities: {
            portfolio,
            bondsLib, equitiesLib
        },
        info: {
            brokers
        }
    } = useSelector((state: TState) => state);
    const switchValue = useSelector((state: TState) => state.auth.switch);
    const marketSecs = [...bondsLib, ...equitiesLib];
    const [step, setStep] = useState<"form" | "sign" | "error">('form');
    const [sending, setSending] = useState<boolean>(false);
    const searchSec = (searchString: string): TOwnSecurity[] => {
        return portfolio
            .filter(sec => {
                return (sec.tradeCode && sec.tradeCode.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                    (sec.isin && sec.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
            });
    }
    const searchOtherSec = (searchString: string): TMarketSecurity[] => {
        return marketSecs
            .filter(sec => {
                return (sec.tradeCode && sec.tradeCode.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                    (sec.isin && sec.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
            });
    }

    // From security
    const [search, setSearch] = useState<string>();
    const [foundFromSecs, setFromSecs] = useState<TOwnSecurity[]>();
    const [fromSec, setFromSec] = useState<TOwnSecurity["isin"]>();
    const [fromAmount, setFromAmount] = useState<number>();
    const [fromAcc, setFromAcc] = useState<number>();
    const onFromSec = (isin: string) => {
        const founds = searchSec(isin);
        setFromSec(founds[0].isin);
        setFromAcc(founds[0].imContractId);
        // setFromSec(searchSec(isin).find(s => s.imContractId === parseInt(imContractIdStr)))
    }
    useEffect(() => {
        if (search) {
            const uniques = new Map<string, TOwnSecurity>();
            const founds = searchSec(search);
            founds.forEach(f => uniques.set(f.isin, f));
            const list = Array.from(uniques.values())
            setFromSecs(list);
            // setFromSec(list[0]?.isin)
        }
    }, [search]);
    // To account
    const [toAcc, setToAcc] = useState<number>();

    const [orderData, setOrderData] = useState<TSecuritiesTransferRequest>();
    const [xml, setXml] = useState<string>()

    const handleOk = () => {
        // setStep("form")
        dispatch(closeSecuritiesTransferOrder())
    }
    const handleCancel = () => {
        // setStep("form")
        dispatch(closeSecuritiesTransferOrder())
    }
    const formOrder = () => {
        const security = fromSec && searchSec(fromSec)[0];
        const securityInfo = fromSec && searchOtherSec(fromSec)[0];
        const FHS = brokers.find(b => b.ourBrokerBool === 1);
        if (security && fromAcc && toAcc && fromAmount && clientData && securityInfo && FHS) {
            const newOrder: TSecuritiesTransferRequest = {
                inContrID: toAcc,
                notCheckRem: 0,
                outContrID: fromAcc,
                qty: fromAmount,
                securityID: security.securityId,
                transferTypeID: ESecuritiesTransferType.internalTransfer,
                date: new Date().toISOString()
            }
            setOrderData(newOrder);
            let heading: TPDFHeading
            if (Array.isArray(clientData)) {
                heading = {
                    orderDate: new Date().toISOString(),
                    docIssueDate: cProfile?.docIssueDate || "",
                    docIssuer: cProfile?.docIssueAuthor || "",
                    docNumber: cProfile?.docNumber || "",
                    docSeries: "",
                    docType: cProfile?.docType || "",
                    subAccount: clientData[0]?.subAccount,
                    accountNumber: clientData[0]?.imcAccount,
                    clientName: clientData[0]?.cliName,
                    contractNumber: clientData[0]?.contractNumber,
                    iinbin: clientData[0]?.biin
                };
            }

            else {
                heading = {
                    orderDate: new Date().toISOString(),
                    docIssueDate: cProfile?.docIssueDate || "",
                    docIssuer: cProfile?.docIssueAuthor || "",
                    docNumber: cProfile?.docNumber || "",
                    docSeries: "",
                    docType: cProfile?.docType || "",
                    subAccount: clientData?.subAccount,
                    accountNumber: clientData?.imcAccount,
                    clientName: clientData?.cliName,
                    contractNumber: clientData?.contractNumber,
                    iinbin: clientData?.biin
                };
            }
            const extraData = {
                orderKind: "owner_unchanged",
                direction: "incoming"
            }
            const ownerInfo = {
                clientName: clientData.cliName,
                broker: FHS.brokerName,
                subAccount: [...subAccountsInt, ...subAccountsLocal].find(acc => acc.imContractId === fromAcc)!.subAccount,
                docType: cProfile?.docType || "",
                docNumber: cProfile?.docNumber || "",
                docSeries: "",
                docIssueDate: cProfile?.docIssueDate || "",
                docIssuer: cProfile?.docIssueAuthor || "",
            }
            const counterpartyInfo = {
                ...ownerInfo,
                subAccount: [...subAccountsInt, ...subAccountsLocal].find(acc => acc.imContractId === toAcc)!.subAccount
            }
            const { issuer, globalType, tradeCode, isin } = securityInfo;
            const printData = {
                securityInfo: {
                    issuer, globalType, tradeCode, isin
                },
                senderInfo: ownerInfo,
                receiverInfo: counterpartyInfo,
            }
            const xml = buildXMLv2("SecuritiesTransferInternal", {
                heading,
                orderData: { ...extraData, ...newOrder },
                printData
            })
            setXml(xml);
            setStep("sign");
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "Заполните все поля",
                placement: "top"
                // description: errorMessage
            })
            return
        }
    }
    const signOrder = async () => {
        if (!orderData) return;
        let method: string;
        let args: {};
        if (NcaLayerConnector.getBundleVersion()) {
            method = "signXmlV2"
            args = { xml: xml, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password }
        } else {
            method = "signXml"
            args = { xml: xml }
        }
        const message = new NcaMessage(method, args);

        if (certificate !== '' || password !== '') {
            try {
                const signedXml: string = await NcaLayerConnector.callNcaMethod(message);
                // const response = await apiConnector.putSecurityTransferOrderTest({
                const reqType = ECheckReqType.secTransferReq;
                const response = await apiConnector.checkRequest({orderData, signedXml, switchValue}, reqType);
                // const response = await apiConnector.putSecurityTransferOrder({
                //     orderData,
                //     signedXml,
                //     switchValue
                // })
                if (response && response.success) {
                    notification.success({
                        duration: timeForSuccessNotification,
                        message: "Приказ отправлен",
                    })
                    dispatch(getOrders());
                    dispatch(closeSecuritiesTransferOrder());
                } else {
                    notification.error({
                        duration: timeForErrorNotification,
                        message: `Приказ отклонен`,
                        description: <><p>{response.message}</p><p>{response.details}</p></>
                    })
                }
            } catch (e) {
                console.log(e);
                setSignError('error');
            } finally {
                setSending(false);
            }
        } else {
            notification.error({
                duration: timeForErrorNotification,
                message: "Ошибка!",
                description: "Нужно указать путь до ключа и ввести пароль"
            });
        }
    }
    const pendOrder = async () => {
        setSending(true);
        if (orderData && xml) {
            const security = searchSec(fromSec!)[0]
            const orderTemplate: TSetOrderTemplate = {
                orderKind: EOrderKind.nonTrade,
                orderType: ENonTradeOrderType.securityTransferInternal,
                orderData,
                orderXml: xml,
                author: userName || "н/д",
                authorIIN: iin || "н/д",
                extraData: {
                    security,
                    fromAccount: [...subAccountsInt, ...subAccountsLocal].find(acc => acc.imContractId === fromAcc)!.holdPlace,
                    toAccount: [...subAccountsInt, ...subAccountsLocal].find(acc => acc.imContractId === toAcc)!.holdPlace,
                }
            };
            try {
                await apiConnector.checkTemplate(orderTemplate);
                dispatch(closeSecuritiesTransferOrder());
                NotificationsConnector.notify();
            } catch (e) {
                console.log(e);
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: "Не удалось отправить приказ"
                });
            } finally {
                setSending(false);
            }
        }
    }

    const [password, setPassword] = useState<string>('');
    const [certificate, setCertificate] = useState<string>("");
    const [signError, setSignError] = useState<string | null>(null);

    return (
        <Modal
            title={getIntOrderTypeName(EIntOrderType.securitiesTransfer)}
            visible={showSecuritiesTransferOrderModal}
            onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={680}
            bodyStyle={{ paddingTop: 12 }}
            style={{ overflow: "hidden", minHeight: 500 }}
            centered
            className="edited-form"
            zIndex={5}
        >
            <Row className="of-row">
                <Col span={8}>Введите торговый код или ISIN ЦБ:</Col>
                <Col span={16}>
                    <Input
                        // status={valid.investDoc ? undefined : "error"}
                        value={search}
                        maxLength={20}
                        // showCount
                        size="small" className="of-input"
                        // placeholder="№__ от __.__.____"
                        onChange={e => setSearch(e.target.value.toUpperCase())}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    ЦБ:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={fromSec}
                        // defaultValue={foundFromSecs && foundFromSecs[0].isin}
                        onChange={v => onFromSec(v)}>
                        {
                            foundFromSecs && foundFromSecs.map((sec, i) => (
                                <Select.Option key={`${i}`} value={sec.isin}>{sec.isin} {sec.tradeCode}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Субсчёт списания:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={fromAcc}
                        // disabled={!(fromSec && searchSec(fromSec).length > 1)}
                        onChange={v => setFromAcc(v)}>
                        {
                            (fromSec ? searchSec(fromSec) : []).map((sec, i) => (
                                <Select.Option key={`${i}`} value={sec.imContractId}>{sec.holdPlace} - {sec.subAccount}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Количество:
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={fromAmount}
                        max={fromSec ? searchSec(fromSec)[0]?.remQuantity || 0 : 0}
                        onChange={p => setFromAmount(p || 1)}
                        size="small" className="of-input repo-amount-open"
                        formatter={v => countNum(v as number)}
                        parser={v => parseNum(v as string, 0)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Субсчёт зачисления:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={toAcc}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setToAcc(v)}>
                        {
                            [...subAccountsInt, ...subAccountsLocal].filter(acc => acc.imContractId !== fromAcc).map((acc, i) => (
                                <Select.Option style={optionStyles} key={`${i}`} value={acc.imContractId}>
                                    {shortName(acc)} - {acc.subAccount}
                                    {/* {acc.holdPlace} - {acc.subAccount} */}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Divider />
            <Row>
                {step === "form" && <Button onClick={formOrder} type="primary" block>Создать приказ</Button>}
                {step === "sign" &&
                    <>
                        {isCompany() && !isHeadOfCompany() && <Button loading={sending} disabled={sending} onClick={pendOrder} type="primary" block>Отправить на
                            согласование</Button>}
                    </>}
            </Row>
            <ModalDS
                signError={signError}
                step={step}
                password={password}
                certificate={certificate}
                setCertificate={setCertificate}
                setPassword={setPassword}
                signOrder={signOrder}
                isDisable={false}
                sending={sending}
                OT={false}
                isActual={undefined}
                loading={false}
                onConfirm={undefined}
                template={undefined}
                isSigners={false}
            />
        </Modal>
    )
}