import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import { ReloadOutlined } from "@ant-design/icons";
import Search from 'antd/lib/input/Search';
import { dispatch, TState } from '../../store';
import { getOrderDealsDetails } from "../../store/orders.slice";
import { EOrderStatus, TDealStatusRecord } from "../../types/orders";
import { TableEditor } from "../tableEditor/TableEditor";
import { getHalfPageTableHeight, rowClassCompact, updateTableView } from '../../services/helpers';
import { columnsDealsRepo } from "./orderDealsDetailsRepo.data";
import { columnsDealsSec } from "./orderDealsDetailsSec.data";

export const OrderDeals: FC<{ isRepo?: boolean }> = ({ isRepo }) => {
    const timerRef = useRef<number | undefined>();
    const [searchString, setSearchString] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    // const {deals, ordersError, dealsFetching} = useSelector((state: TState) => state.orders)
    const { dealsDetails, dealsFetching } = useSelector((state: TState) => state.orders)
    // const [showModal, setShowModal] = useState<boolean>(false)
    // const [dealsDetails, setDealsDetails] = useState<TDealStatusRecord[]>([])

    // const openModal = (deals: TDealStatusRecord[]): void => {
    //     setDealsDetails(deals)
    //     setShowModal(true)
    // }
    // const closeModal = () => {
    //     setShowModal(false)
    // }
    useEffect(() => {
        dispatch(getOrderDealsDetails())
    }, [])

    const dataSource = (): (TDealStatusRecord & { action: any })[] => {
        return dealsDetails
            .map(p => {
                // const canBeCanceled = !isLog && cancelableStatusIds.includes(p.orderStatusId);
                // const ticker: string = p.ticker || p.repoBasket || "н.д.";
                return {
                    ...p, action: <></>
                    // ticker: ticker,
                    // icon: p.rejectOrderId ? <CloseCircleOutlined style={{fontSize: '16px', marginTop: '2px'}} /> : p.dealKindId === DealKind.buy ? <BuyIcon color={"var(--fhs-black)"} /> : <SellIcon color={"var(--fhs-black)"} />,
                    // action: <>
                    //     {/*{isLog ? null : <Button title="Отменить заказ" onClick={()=>dispatch(showCancelOrder(p))} disabled={!canBeCanceled} size="small" type="primary" danger><CloseCircleOutlined style={{fontSize: '18px', marginTop: '2px'}} /></Button>}*/}
                    //     {/*<Button type="primary" size="small" title="Скачать заказ" href={p.file_path} target="_blank"><FileDoneOutlined style={{fontSize: '18px', marginTop: '2px'}} /></Button>*/}
                    //     {/*<Button onClick={()=>getDoc(p)} type="primary" size="small" title="Скачать заказ"><FileDoneOutlined style={{fontSize: '18px', marginTop: '2px'}} /></Button>*/}
                    //     <Button onClick={()=>openModal(p.deals)} type="default" size="small" title="Подробнее">Подробнее</Button>
                    // </>
                }
            }).filter(row => {
                const checkIsRepo = row.dealDirection === 'REPO BUY' || row.dealDirection === 'REPO SELL'
                const checkIsSimple = row.dealDirection === 'BUY' || row.dealDirection === 'SELL'

                if (!searchString || searchString.length < 1) {
                    return isRepo ? checkIsRepo : checkIsSimple
                } else {
                    return (row.dealDirection && row.dealDirection.toLowerCase().includes(searchString.toLowerCase()) && (isRepo ? checkIsRepo : checkIsSimple))
                }
            }).filter(row =>
                row.orderStatusId === EOrderStatus.onCalculation ||
                row.orderStatusId === EOrderStatus.completed
            )
    }

    const onSearch = (value: string) => {
        setSearchString(value);
    }
    const onSearchChange = (value: string) => {
        if (!loading) {
            setLoading(true);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            setSearchString(value);
            setLoading(false);
        }, 100)
    }
    const columns = isRepo ? columnsDealsRepo : columnsDealsSec;
    const lsKey = isRepo ? 'OrderDealsDetailsRepo' : 'OrderDealsDetailsSec';

    const [myColumns, setMyColumns] = useState(updateTableView(columns, lsKey))
    const updateMyColumns = () => {
        setMyColumns(updateTableView(columns, lsKey))
    }
    // const [myColumnsDeals, setMyColumnsDeals] = useState(updateTableView(columnsDeals, 'OrderDealsDetails'))
    // const updateMyColumnsDeals = () => {
    //     setMyColumnsDeals(updateTableView(columnsDeals, 'OrderDealsDetails'))
    // }
    return (
        <>
            {/*<Modal*/}
            {/*    width={'100%'}*/}
            {/*    title={"Детали сделок"}*/}
            {/*    visible={showModal}*/}
            {/*    onCancel={closeModal}*/}
            {/*    okButtonProps={{style: {display: 'none'}}}*/}
            {/*>*/}
            {/*    <TableEditor updateColumns={updateMyColumnsDeals} columns={columns} keyForLS={'OrderDealsDetails'}/>*/}
            {/*    <Table*/}
            {/*        size="small"*/}
            {/*        showSorterTooltip={false}*/}
            {/*        rowClassName={rowClassCompact}*/}
            {/*        rowKey={record => record.clientOrderId}*/}
            {/*        dataSource={dataSourceDeals()}*/}
            {/*        columns={myColumnsDeals}*/}
            {/*        scroll={{*/}
            {/*            x: "max-content",*/}
            {/*            y: getFullPageTableHeight()*/}
            {/*        }}*/}
            {/*        loading={dealsFetching || loading}*/}
            {/*        pagination={false}*/}
            {/*    />*/}
            {/*</Modal>*/}
            <Search onSearch={onSearch}
                onChange={e => onSearchChange(e.target.value)}
                allowClear={true}
                placeholder={"Поиск"}
                style={{
                    width: 250,
                    marginBottom: 10,
                    zIndex: 2,
                    marginRight: 8
                }}
            />
            <Button style={{ marginRight: 8 }} onClick={() => dispatch(getOrderDealsDetails())} type="default">
                <ReloadOutlined />
            </Button>
            <TableEditor updateColumns={updateMyColumns} columns={columns} keyForLS={lsKey} />
            <Table
                size="small"
                showSorterTooltip={false}
                rowClassName={rowClassCompact}
                rowKey={record => record.clientOrderId}
                dataSource={dataSource()}
                columns={myColumns}
                scroll={{
                    x: "max-content",
                    y: isRepo ? getHalfPageTableHeight() + 100 : getHalfPageTableHeight() - 20
                }}
                loading={dealsFetching || loading}
                pagination={false}
            />
        </>
    )
}
