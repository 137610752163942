import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Space, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { nanoid } from 'nanoid';
import { BuyBtn } from './buy.btn';
import { EqBondInfoBtn } from './eqBondInfo.btn';
import { PinBox } from './pinBox';
import { Quote } from "./Quote";
import { TableEditor } from "../tableEditor/TableEditor";
import { BondsSide } from '../tradeSideComponents/BondsSide';
import { TState } from '../../store';
import { getHalfPageTableHeight, rowClassCompact, updateTableView } from '../../services/helpers';
import { TMarketSecurity, TSecurityQuotes } from '../../types/api';
import { EQuoteType } from "../../types/quotes";
import { columns } from './bonds.data';
import { sideComponentWidth } from '../../constants';


export const Bonds = () => {
    const timerRef = useRef<number | undefined>();
    const { bondsList, bondsLib, bondsFetching, bondsSideDisplayed } = useSelector((state: TState) => state.securities)
    const [searchString, setSearchString] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const onSearch = (value: string) => {
        setSearchString(value);
    }
    const onSearchChange = (value: string) => {
        if (!loading) {
            setLoading(true);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            setSearchString(value);
            setLoading(false);
        }, 500)
    }
    let testQuote: TSecurityQuotes = {
        open: "-",
        last: "-",
        bid: "-",
        ask: "-"
    }
    const dataSource = () => {
        const myKeys: string[] = [];
        const myList = bondsList.map(eq => {
            const key = eq.tradeModeCode + "-" + eq.ticker;
            myKeys.push(key);
            let btn: boolean;
            btn = eq.localBool === 1 && eq.tradeModeCode !== null;
            // let btn: boolean = false;
            // if (eq.localBool === 1 && eq.tradeModeCode !== null) {
            //     btn = true
            // }
            // else {
            //     btn = false;
            // }
            return {
                ...eq,
                last: <Quote secKey={key} type={EQuoteType.last} bonds={true} />,
                open: <Quote secKey={key} type={EQuoteType.open} bonds={true} />,
                bid: <Quote secKey={key} type={EQuoteType.bid} bonds={true} />,
                ask: <Quote secKey={key} type={EQuoteType.ask} bonds={true} />,
                action: <Space size="small">
                    <BuyBtn marketSecurity={eq} />
                    <EqBondInfoBtn disabledBtn={btn} marketSecurity={eq} securityType='bonds' />
                </Space>,
                pin: <PinBox marketSecurity={eq} pinned={true} />
            }
        }).filter(row => {
            if (!searchString || searchString.length < 1) {
                return true
            } else {
                return (row.ticker && row.ticker.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
                    // (row.subAccount && row.subAccount.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                    || (row.isin && row.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
            }
        })
        let searchList: Array<TMarketSecurity & { action: any, pin: any } & TSecurityQuotes> = [];
        if (searchString && searchString.length > 0) {
            const filtered = bondsLib.filter(row => {
                const key = row.tradeModeCode + "-" + row.ticker;
                return !myKeys.includes(key)
                    && ((row.ticker && row.ticker.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
                        // (row.subAccount && row.subAccount.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                        || (row.isin && row.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1))
            })

            if (filtered.length > 0) {
                searchList = filtered.map(eq => {
                    let btn: boolean;
                    btn = eq.localBool === 1 && eq.tradeModeCode !== null;
                    // let btn: boolean = false;
                    // if (eq.localBool === 1 && eq.tradeModeCode !== null) {
                    //     btn = true
                    // }
                    // else {
                    //     btn = false;
                    // }
                    return {
                        ...eq,
                        ...testQuote,
                        action: <Space size="small">
                            <BuyBtn marketSecurity={eq} />
                            <EqBondInfoBtn disabledBtn={btn} marketSecurity={eq} securityType='bonds' />
                        </Space>,
                        pin: <PinBox marketSecurity={eq} pinned={false} />
                    }
                });
            }
            // if(filtered.length > 0){
            //     searchList = filtered.map(eq => ({
            //         ...eq,
            //         ...testQuote,
            //         action: <Space size="small">
            //                 <BuyBtn marketSecurity={eq}/>
            //                 <EqBondInfoBtn marketSecurity={eq} securityType='bonds' />
            //             </Space>,
            //         pin: <PinBox marketSecurity={eq} pinned={false}/>
            //     }));
            // }
        }
        return [...myList, ...searchList]
    }
    const [myColumns, setMyColumns] = useState(updateTableView(columns, 'Bonds'))
    const updateMyColumns = () => {
        setMyColumns(updateTableView(columns, 'Bonds'))
    }
    return (
        <>
            <Search
                onSearch={onSearch}
                onChange={e => onSearchChange(e.target.value)}
                allowClear={true}
                placeholder={"Поиск"}
                style={{
                    width: 250,
                    marginBottom: 10,
                    zIndex: 2,
                    marginRight: 8
                }}
            />
            <TableEditor updateColumns={updateMyColumns} columns={columns} keyForLS={'Bonds'} />
            <div style={{ display: "flex", flexDirection: "row", width: '100%', height: '100%' }}>
                <Table
                    size="small"
                    showSorterTooltip={false}
                    rowClassName={rowClassCompact}
                    rowKey={() => nanoid(8)}
                    columns={myColumns}
                    dataSource={dataSource()}
                    scroll={{
                        x: "max-content",
                        y: getHalfPageTableHeight()
                    }}
                    loading={bondsFetching || loading}
                    pagination={false}
                    // onChange={onChange}
                    style={{ width: bondsSideDisplayed ? `${100 - sideComponentWidth}%` : "100%", transition: "width .2s linear" }}
                />
                {bondsSideDisplayed ? <BondsSide /> : <></>}
            </div>
        </>
    )
}
