import React, { FC, useEffect, useState } from "react";
import { List, arrayMove } from 'react-movable';
import { Button, Modal, Row } from "antd";
import { ControlOutlined } from "@ant-design/icons";
import { TOrderArray, TTableEditor } from "../../types/tableEditor";
import { TableEditorItem } from "./TableEditorItem";

export const TableEditor: FC<TTableEditor> = ({ columns, keyForLS, updateColumns }) => {

    const [showTableEditor, setShowTableEditor] = useState<boolean>(false)
    const [order, setOrder] = useState<TOrderArray[]>([])

    const handleOk = () => {
        setShowTableEditor(false)
    }
    const handleCancel = () => {
        setShowTableEditor(false)
    }
    const openModal = () => {
        setShowTableEditor(true)
    }

    const checkBoxChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
        const copyState: TOrderArray[] = [...order];
        order[i].active = e.target.checked;
        setOrder(copyState);
    }

    const saveOrderOfTable = () => {
        localStorage.setItem(keyForLS, JSON.stringify(order))
        setShowTableEditor(false)
        updateColumns()
    }

    useEffect(() => {
        const ls = localStorage.getItem(keyForLS);
        if (ls && JSON.parse(ls).length === columns.length) {
            setOrder(JSON.parse(ls));
        } else {
            setOrder(columns.map((el: Object, i: number) => {
                return {
                    active: true,
                    index: i
                }
            }))
        }
    }, [])

    return (
        <>
            <Button
                onClick={openModal}
                type="primary"
                ghost
            >
                <ControlOutlined style={{ fontSize: 17 }} />
            </Button>
            <Modal
                title={"Редактирование таблицы"}
                visible={showTableEditor}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}
                width={620}
                bodyStyle={{ paddingTop: 12 }}
                style={{ overflow: "hidden", minHeight: 500 }}
                centered
            >
                <Row className="of-row">
                    <Button
                        onClick={saveOrderOfTable}
                        type="primary"
                        ghost
                        block
                    >
                        Сохранить
                    </Button>
                </Row>
                <List
                    values={order}
                    onChange={({ oldIndex, newIndex }) => {
                        setOrder(arrayMove(order, oldIndex, newIndex))
                    }}
                    renderList={({ children, props }) => <ul
                        {...props}
                        style={{ listStyle: 'none' }}
                    >
                        {children}
                    </ul>}
                    renderItem={({ value, props, index }) => <li
                        {...props}
                        style={{ ...props.style, listStyle: 'none', background: 'white', border: '1px solid lightgrey', boxSizing: 'border-box', padding: '0 15px', cursor: 'grab', userSelect: 'none', zIndex: '10000' }}
                    >
                        <TableEditorItem
                            title={columns[value.index].title ? columns[value.index].title : 'Действия (Колонка с кнопками)'}
                            change={(e: React.ChangeEvent<HTMLInputElement>) => checkBoxChangeHandler(e, index || 0)}
                            checked={value.active}
                        />
                    </li>}
                />
            </Modal>
        </>
    )
}
