import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, Radio, RadioChangeEvent, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { TState, dispatch } from "../../../store";
import { setCert } from "../../../store/auth.slice";
import { apiConnector } from "../../../integrations/api.connector";
import { NcaLayerConnector } from "../../../integrations/ncaLayer.connector";
import { TSignCertificate, TCertificatePath } from "../../../types/api";
import { TOrderTemplate } from "../../../types/orders";
import { canSignOrder, processCertificate } from "../../../services/helpers";
import { dict } from "../../../i18n/phrases";
import { CustomInput } from "./CustomInput";
import { CustomModal } from "./CustomModal";
import { timeForWarningNotification } from "../../../constants";

interface IModalDS {
    step: string | null;
    signOrder: any;
    sending: boolean;
    password: string;
    certificate: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    setCertificate: (value: string) => void;
    isDisable: boolean;
    OT: boolean,
    isActual: any,
    loading: boolean,
    onConfirm: any,
    template?: TOrderTemplate,
    setTemplate?: React.Dispatch<React.SetStateAction<TOrderTemplate | null | undefined>>;
    isSigners: boolean,
    signError: string | null
}

export const ModalDS = ({
    step,
    signOrder,
    sending,
    password,
    certificate,
    setPassword,
    setCertificate,
    isDisable,
    OT,
    isActual,
    loading,
    onConfirm,
    template,
    setTemplate,
    isSigners,
    signError
}: IModalDS) => {
    const { auth: { ncaLayer, signCertificates } } = useSelector((state: TState) => state);

    const [certificates, setCertificates] = useState<TSignCertificate[]>([]);
    const [oneCertificate, setOneCertificate] = useState<TSignCertificate | null>(null);
    const [openPasswordModal, setPasswordModal] = useState<boolean>(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [certLoad, setCertLoad] = useState<boolean>(false);
    const [openModal, setModal] = useState<boolean>(false);
    const [openLogin, setLogin] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
    const [path, setPath] = useState<string>('');

    useEffect(() => {
        setCertificates(signCertificates);
        if (signCertificates.length > 0) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [signCertificates]);

    const handleOkLogin = async () => {
        if (ncaLayer) {
            setLoadingBtn(true);
            if (isSigners) {
                await onConfirm({ ...template });
            } else {
                await signOrder();
            }
            setLoadingBtn(false);
            setModal(false)
            setLogin(false)
            setPasswordModal(false)
            if (setTemplate) {
                setTemplate(null)
            }
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "NCALayer не обнаружен или не запущен",
                placement: 'top'
            });
        }
    };

    const chooseKey = async () => {
        if (ncaLayer) {
            if (NcaLayerConnector.getBundleVersion()) {
                if (certificates.length === 0) {
                    try {
                        setCertLoad(true);
                        const certPath: TCertificatePath = await apiConnector.getCertificate();
                        if (certPath.body.path.includes('GOSTK') || certPath.body.path.includes('RSA')) {
                            setPasswordModal(true);
                            setPath(certPath.body.path);
                        } else {
                            notification.warn({
                                duration: timeForWarningNotification,
                                message: "Выбранный ключ не подходит для подписаний. Пожалуйста выберите другой ключ.",
                                placement: 'top'
                            });
                        }
                    } catch (error) {
                        notification.warn({
                            duration: timeForWarningNotification,
                            message: 'Ключ не выбран. Пожалуйста выберите ключ повторно.',
                            placement: 'top'
                        });
                        setCertLoad(false)
                    } finally {
                        setCertLoad(false);
                    }
                } else {
                    setModal(true);
                    setPath(certificates.length > 0 ? certificates[0].path : '');
                }
            } else {
                setLoadingBtn(true);
                if (isSigners) {
                    await onConfirm({ ...template });
                } else {
                    await signOrder();
                }
                setLoadingBtn(false);
            }
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "NCALayer не обнаружен или не запущен",
                placement: 'top'
            });
        }
    };

    const addKey = async () => {
        if (ncaLayer) {
            try {
                setCertLoad(true);
                const certPath: TCertificatePath = await apiConnector.getCertificate();
                if (certPath.body.path.includes('GOSTK') || certPath.body.path.includes('RSA')) {
                    setPasswordModal(true);
                    setPath(certPath.body.path);
                } else {
                    notification.warn({
                        duration: timeForWarningNotification,
                        message: "Выбранный ключ не подходит для подписаний. Пожалуйста выберите другой ключ.",
                        placement: 'top'
                    });
                }
            } catch (error) {
                notification.warn({
                    duration: timeForWarningNotification,
                    message: 'Ключ не выбран. Пожалуйста выберите ключ повторно.',
                    placement: 'top'
                });
                setCertLoad(false)
            } finally {
                setCertLoad(false);
            }
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "NCALayer не обнаружен или не запущен",
                placement: 'top'
            });
        }
    };

    const handlePassword = async () => { 
        if (path !== '' && password !== '') {
            try {
                const certInfo: TSignCertificate = await apiConnector.getCertificateInfo(path, password);
                if (!certificates.some(item => item.path === certInfo.path)) {
                    setCertificates(prevCertificates => [...prevCertificates, certInfo]);
                    dispatch(setCert({ certType: 'sign', type: 'add', cert: certInfo }))
                }
                setCertificate(certInfo.path);
                setOneCertificate(certInfo);
                setPasswordModal(false);
                setLogin(true);
            } catch (error) {
                notification.warn({
                    duration: timeForWarningNotification,
                    message: "Вы ввели неправильный пароль. Пожалуйста попробуйте еще раз.",
                    placement: 'top'
                });
                setPassword('');
            }
        }
    };

    const removeKeys = (allKey: boolean, key: string | null) => {
        if (allKey) {
            setPath('');
            setCertificates([]);
            setIsButtonDisabled(true);
            dispatch(setCert({ certType: 'sign', type: 'removeAll' }))
        } else {
            setCertificates(prevCertificates =>
                prevCertificates.filter(cert => cert.path !== key)
            );
            dispatch(setCert({ certType: 'sign', type: 'removeOne', path: key }));
            setPath(certificates[0].path || '');
        }
    };

    const radioHandler = (e: RadioChangeEvent) => {
        setPath(e.target.value);
    };

    useEffect(() => {
        signError !== null && notification.warn({
            duration: timeForWarningNotification,
            message: "Данный пользователь не найден в системе. Пожалуйста выберите другой ключ.",
            placement: 'top'
        });
    }, [signError]);

    const okHandle = () => {
        if (path !== '' && certificates.length > 0) {
            setPasswordModal(true);
        }
    };

    return (
        <>
            {
                OT ? <Button disabled={isActual} type="primary" loading={loading} onClick={() => chooseKey()}>Подписать</Button> : null
            }
            {step === "sign" && canSignOrder() && (
                <Button
                    loading={sending}
                    onClick={() => chooseKey()}
                    type="primary"
                    block
                    disabled={isDisable}
                    danger
                >
                    {dict.singAndSendOrder}
                </Button>
            )}
            <Modal
                footer={false}
                closable={false}
                title={
                    <div style={{ textAlign: 'center' }}>
                        Выберете ключ для подписаний. Файл должен начинаться с GOSTKNCA или RSA.
                    </div>
                }
                visible={certLoad}
            />
            <CustomModal
                isButtonDisabled={isButtonDisabled}
                isModal={openModal}
                onCancel={() => { setModal(false) }}
                onOk={okHandle}
                addKey={addKey}
                onRemove={() => { removeKeys(true, null) }}
                titleTxt={"Выберите ключ для подписаний"}
            >
                {
                    certificates.length > 0 ?
                        <Radio.Group
                            style={{ width: "100%", overflow: "hidden" }}
                            defaultValue={certificates.length > 0 ? certificates[0].path : ''}
                            value={path || (certificates.length > 0 ? certificates[0].path : '')}
                            onChange={(e) => {
                                radioHandler(e);
                                setCertificate(e.target.value);
                                setPassword("");
                            }}
                        >
                            {
                                certificates.map((cert: TSignCertificate, index: number) => (
                                    <div key={index} style={{ display: "flex", flexDirection: "row", alignItems: 'center', marginBottom: '15px' }}>
                                        {
                                            processCertificate(cert) !== null &&
                                            <>
                                                <Radio
                                                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', fontSize: '12px', width: '90%' }}
                                                    onClick={() => {
                                                        setPasswordModal(true);
                                                        setPath(cert.path);
                                                    }}
                                                    value={cert.path}
                                                >
                                                    {processCertificate(cert)}
                                                </Radio>
                                                <Button
                                                    danger
                                                    type="default"
                                                    size="small"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => { removeKeys(false, cert.path) }}
                                                />
                                            </>
                                        }
                                    </div>
                                ))
                            }
                        </Radio.Group>
                        :
                        <p style={{ textAlign: 'center', fontSize: '20px', margin: 0 }}>Пусто</p>
                }
            </CustomModal>
            <CustomInput
                value={password}
                onPressEnter={handlePassword}
                setPassword={setPassword}
                onCancel={() => {
                    setPasswordModal(false);
                    setLogin(false);
                    setPassword('');
                }}
                visible={openPasswordModal}
            />
            <Modal
                title='Подписать'
                visible={openLogin}
                onOk={handleOkLogin}
                onCancel={() => {
                    setLogin(false);
                    setPassword('');
                }}
                zIndex={300}
                style={{ marginTop: '20vh' }}
                confirmLoading={loadingBtn}
            >
                <p style={{ textAlign: 'center', fontSize: '18px' }}>
                    {loadingBtn ? 'Пожалуйста подождите.' : processCertificate(oneCertificate)}
                </p>
            </Modal>
        </>
    );
};
export { CustomInput, CustomModal, processCertificate };

