import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Divider, Input, InputNumber, Modal, notification, Row, Select } from "antd";
import { EIntOrderType, getIntOrderTypeName } from "../InternalOrderDetails";
import { ModalDS } from "../../shared/modals/ModalDS";
import { dispatch, TState } from "../../../store";
import { closeSecurityConversionOrder, getOrders } from "../../../store/orders.slice";
import { countNum, parseNum } from "../../../services/formatter";
import { TMarketSecurity } from "../../../types/api";
import { timeForSuccessNotification } from "../../../constants";
import "./../editedForm.css";

export const SecuritiesConversionForm = () => {
    const {
        orders: {
            showSecuritiesConversionOrderModal,
        },
        money: {
            accounts,
        },
        securities: {
            bondsLib, equitiesLib
        }
    } = useSelector((state: TState) => state);
    const [step, setStep] = useState<"form" | "sign" | "error">('form');
    const [sending, setSending] = useState<boolean>(false)
    const searchSec = (searchString: string): TMarketSecurity[] => {
        return [...bondsLib, ...equitiesLib].filter(sec => {
            return (sec.ticker && sec.ticker.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                (sec.isin && sec.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                (sec.tradeCode && sec.tradeCode.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
        });
    }
    // From security
    const [search, setSearch] = useState<string>();
    const [foundFromSecs, setFromSecs] = useState<TMarketSecurity[]>();
    const [fromSec, setFromSec] = useState<TMarketSecurity>();
    const [fromAmount, setFromAmount] = useState<number>()
    const [fromAcc, setFromAcc] = useState<string>();
    const onFromSec = (isin: string) => {
        setFromSec(searchSec(isin)[0])
    }
    useEffect(() => {
        if (search) {
            setFromSecs(searchSec(search))
        }
    }, [search]);
    // To security
    const [search2, setSearch2] = useState<string>();
    const [toSec, setToSec] = useState<TMarketSecurity>();
    const [toAvailSecs, setToAvailSecs] = useState<TMarketSecurity[]>();
    const [toAmount, setToAmount] = useState<number>()
    const [toAcc, setToAcc] = useState<string>();
    const onToSec = (isin: string) => {
        setToSec(searchSec(isin)[0])
    }
    useEffect(() => {
        if (search2) {
            setToAvailSecs(searchSec(search2))
        }
    }, [search2]);
    const handleOk = () => {
        // setStep("form")
        dispatch(closeSecurityConversionOrder())
    }
    const handleCancel = () => {
        // setStep("form")
        dispatch(closeSecurityConversionOrder())
    }
    // const [drawerVisible, setDrawerVisible] = useState(true);
    // const drawerChange = (data: TIntOrderDescription) => {
    //     const {orderNum, portfolioType, investDoc, orderDate, end, start} = data;
    //     const orderDescriptionString = [
    //         `Распоряжение №${orderNum} от ${orderDate.toLocaleDateString("ru-RU")}`,
    //         `Инвестиционное решение ${investDoc}`,
    //         `Портфель: ${portfolioType}`,
    //         `Срок действия: ${start.toLocaleDateString("ru-RU")} - ${end.toLocaleDateString("ru-RU")}`
    //     ]
    //     setIntOrderDescription(data);
    //     setOrderDescriptionStrings(orderDescriptionString);
    // }
    // const [intOrderDescription, setIntOrderDescription] = useState<TIntOrderDescription>()
    // const [orderDescriptionStrings, setOrderDescriptionStrings] = useState(["Укажите реквизиты распоряжения"])
    const formOrder = () => {
        setStep("sign");
    }
    const signOrder = () => {
        setSending(true);
        setTimeout(() => {
            setSending(false);
            dispatch(closeSecurityConversionOrder());
            dispatch(getOrders());
            notification.success({
                duration: timeForSuccessNotification,
                message: "Распоряжение отправлено!",
                placement: "top"
                // description: errorMessage
            })
        }, 1500)
    }

    const [password, setPassword] = useState<string>('');
    const [certificate, setCertificate] = useState<string>("");
    const [signError, setSignError] = useState<string | null>(null);

    return (
        <Modal
            title={getIntOrderTypeName(EIntOrderType.securitiesConversion)}
            visible={showSecuritiesConversionOrderModal}
            onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={620}
            bodyStyle={{ paddingTop: 12 }}
            style={{ overflow: "hidden", minHeight: 500 }}
            centered
            className="edited-form"
            zIndex={5}
        >
            <Row className="of-row">
                <Col span={8}>Введите торговый код или ISIN ЦБ:</Col>
                <Col span={16}>
                    <Input
                        // status={valid.investDoc ? undefined : "error"}
                        value={search}
                        maxLength={20}
                        // showCount
                        size="small" className="of-input"
                        // placeholder="№__ от __.__.____"
                        onChange={e => setSearch(e.target.value.toUpperCase())}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    ЦБ:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={fromSec && fromSec.isin}
                        // defaultValue={foundFromSecs && foundFromSecs[0].isin}
                        onChange={v => onFromSec(v)}>
                        {
                            foundFromSecs && foundFromSecs.map((sec, i) => (
                                <Select.Option key={`${i}`} value={sec.isin}>{sec.isin} {sec.ticker}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Субсчёт списания:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={fromAcc}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setFromAcc(v)}>
                        {
                            accounts.map((acc, i) => (
                                <Select.Option key={`${i}`} value={acc.subAccount}>
                                    {acc.holdPlace} - {acc.subAccount}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Количество:
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={fromAmount}
                        onChange={p => setFromAmount(p || 1)}
                        size="small" className="of-input repo-amount-open"
                        formatter={v => countNum(v as number)}
                        parser={v => parseNum(v as string, 0)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>Торговый код ЦБ(зачисление):</Col>
                <Col span={16}>
                    <Input
                        // status={valid.investDoc ? undefined : "error"}
                        value={search2}
                        maxLength={20}
                        // showCount
                        size="small" className="of-input"
                        // placeholder="№__ от __.__.____"
                        onChange={e => setSearch2(e.target.value.toUpperCase())}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    ЦБ (зачисление):
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={toSec && toSec.isin}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => onToSec(v)}>
                        {
                            toAvailSecs && toAvailSecs.map((sec, i) => (
                                <Select.Option key={`${i}`} value={sec.isin}>{sec.isin} {sec.ticker}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Субсчёт зачисления:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={toAcc}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setToAcc(v)}>
                        {
                            accounts.map((acc, i) => (
                                <Select.Option key={`${i}`} value={acc.subAccount}>{acc.holdPlace} - {acc.subAccount}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Количество зачисления:
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={toAmount || fromAmount}
                        onChange={p => setToAmount(p || 1)}
                        size="small" className="of-input repo-amount-open"
                        formatter={v => countNum(v as number)}
                        parser={v => parseNum(v as string, 0)}
                    />
                </Col>
            </Row>
            <Divider />
            <Row>
                {step === "form" && <Button onClick={formOrder} type="primary" block>Создать приказ</Button>}
            </Row>
            <ModalDS
                signError={signError}
                step={step}
                password={password}
                certificate={certificate}
                setCertificate={setCertificate}
                setPassword={setPassword}
                signOrder={signOrder}
                isDisable={false}
                sending={sending}
                OT={false}
                isActual={undefined}
                loading={false}
                onConfirm={undefined}
                template={undefined}
                isSigners={false}
            />
        </Modal>
    )
}