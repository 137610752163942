import React from 'react'
import { ColumnsType } from 'antd/lib/table';
import { countNum, finNum } from '../../services/formatter';
import { TOrderStatusFull } from '../../types/orders';
import { getOrderStatusName } from '../../services/statuses';
import { daySuffix, getRepoDaysByTradeModeCode } from "../../services/helpers";

export const columns: ColumnsType<TOrderStatusFull & { action: any, icon: any }> = [
    {
        title: "",
        dataIndex: "icon",
        fixed: "left",
        sorter: (a, b) => a.dealKindId - b.dealKindId
    },
    {
        title: "Инструмент",
        dataIndex: "ticker",
        fixed: "left",
        width: 110,
        render: (text, record) => {
            return record.orderType === 'REJECT' ? <span style={{ color: "red" }}>!{text}</span> : text
        }
    },
    {
        title: "Валюта",
        dataIndex: "currencyCode",
        fixed: "left",
        width: 80
    },
    {
        title: "Срок",
        dataIndex: "tradeModeCode",
        fixed: "left",
        width: 80,
        render: text => `${daySuffix(getRepoDaysByTradeModeCode(text))}`
    },
    // {
    //     title: "Счет",
    //     dataIndex: "subAccount",
    //     sorter: (a, b) => a.subAccount.localeCompare(b.subAccount)
    // },
    {
        title: "Дата заказа",
        dataIndex: "orderDate",
        width: 120,
        render: text => new Date(text).toLocaleDateString("ru-RU")
    },
    {
        title: "Номер заказа",
        dataIndex: "orderNumber",
        width: 180,
        render: (text, record) => {
            return text ? `№ ${text}` : `ID ${record.clientOrderId}`;
        }
    },
    {
        title: "Доходность",
        dataIndex: "orderPrice",
        align: "right",
        render: finNum,
        sorter: (a, b) => a.orderQuantity - b.orderQuantity
    },
    {
        title: "Сумма по сделкам",
        dataIndex: "dealPrice",
        align: "right",
        render: finNum,
        sorter: (a, b) => a.dealPrice - b.dealPrice
    },
    {
        title: "Сумма",
        dataIndex: "orderAmount",
        align: "right",
        render: finNum,
        sorter: (a, b) => a.orderQuantity - b.orderQuantity
    },
    {
        title: "Статус",
        dataIndex: "orderStatusId",
        align: "right",
        render: getOrderStatusName,
        sorter: (a, b) => a.orderStatus.localeCompare(b.orderStatus)
    },
    {
        title: "Дата статуса",
        align: "right",
        dataIndex: "orderStatusDate",
        render: text => new Date(text).toLocaleString("ru-RU"),
        sorter: (a, b) => new Date(a.orderStatusDate).getTime() - new Date(b.orderStatusDate).getTime()
    },
    {
        title: "",
        dataIndex: "action",
        fixed: "right",
        align: "right",
        className: "action-column multi"
    },
    // {
    //     title: "",
    //     dataIndex: "file",
    //     fixed: "right",
    //     className: "action-column"
    // }
]

export const newRepoColumns: ColumnsType<TOrderStatusFull & { action: any, icon: any }> = [
    {
        title: "",
        dataIndex: "icon",
        width: 10,
        fixed: "left",
        sorter: (a, b) => a.dealKindId - b.dealKindId
    },
    {
        title: <span style={{ fontSize: "10px" }}>Номер заказа</span>,
        dataIndex: "orderNumber",
        width: 120,
        align: "center",
        render: (text, record) => ({
            children: text ? `${text}` : `ID ${record.clientOrderId}`,
            props: {
                style: { fontSize: "10px" } // Установите значение, соответствующее желаемому размеру шрифта
            }
        })
    },
    {
        title: <span style={{ fontSize: "10px" }}>Тип заказа</span>,
        dataIndex: "orderTypeId",
        width: 120,
        align: "center",
        render: (text) => {
            if (text === 1) {
                return <span style={{ fontSize: "10px" }}>Лимитный</span>;
            } else if (text === 2) {
                return <span style={{ fontSize: "10px" }}>Рыночный</span>;
            }
            // Если значение не соответствует 1, 2 или 3, можно вернуть что-то другое или пустую строку.
            return "";
        },
    },
    {
        title: <span style={{ fontSize: "10px" }}>Вид сделки</span>,
        dataIndex: "dealDirection",
        width: 120,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "10px" }
            }
        })
    },
    {
        title: <span style={{ fontSize: "10px" }}>Тикер</span>,
        dataIndex: "ticker",
        width: 100,
        align: "center",
        render: (text, record) => {
            return record.orderType === 'REJECT' ? <span style={{ fontSize: "10px", color: "red" }}>!{text}</span> : <span style={{ fontSize: "10px" }}>{text}</span>
        },
    },
    {
        title: <span style={{ fontSize: "10px" }}>ISIN</span>,
        dataIndex: "isin",
        width: 120,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "10px" }
            }
        }),
    },
    {
        title: <span style={{ fontSize: "10px" }}>Валюта</span>,
        dataIndex: "currencyCode",
        width: 80,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "10px" }
            }
        })
    },
    {
        title: <span style={{ fontSize: "10px" }}>Статус</span>,
        dataIndex: "orderStatus",
        width: 120,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "10px" }
            }
        })
    },
    {
        title: <span style={{ fontSize: "10px" }}>Цена</span>,
        dataIndex: "orderPrice",
        width: 120,
        align: "right",
        render: (text) => ({
            children: finNum(text),
            props: {
                style: { fontSize: "10px" }
            }
        }),
    },
    {
        title: <span style={{ fontSize: "10px" }}>Кол-во</span>,
        dataIndex: "orderQuantity",
        width: 120,
        align: "right",
        render: (text) => ({
            children: countNum(text),
            props: {
                style: { fontSize: "10px" }
            }
        }),
    },
    {
        title: <span style={{ fontSize: "10px" }}>Сумма</span>,
        dataIndex: "orderAmount",
        width: 160,
        align: "right",
        render: (text) => ({
            children: finNum(text),
            props: {
                style: { fontSize: "10px" }
            }
        }),
    },


    {
        title: <span style={{ fontSize: "10px" }}>Цена по сделкам</span>,
        dataIndex: "dealPrice",
        width: 160,
        align: "right",
        render: (text) => ({
            children: finNum(text),
            props: {
                style: { fontSize: "10px" }
            }
        }),
    },
    {
        title: <span style={{ fontSize: "10px" }}>Кол-во по сделкам</span>,
        dataIndex: "dealQuantity",
        width: 160,
        align: "right",
        render: (text) => ({
            children: countNum(text),
            props: {
                style: { fontSize: "10px" }
            }
        }),
    },
    {
        title: <span style={{ fontSize: "10px" }}>Сумма по сделкам</span>,
        dataIndex: "dealAmount",
        width: 160,
        align: "right",
        render: (text, record) => ({
            children: finNum(record.dealPrice * record.dealQuantity),
            props: {
                style: { fontSize: "10px" }
            }
        }),
    },

    {
        title: <span style={{ fontSize: "10px" }}>Ордера</span>,
        dataIndex: "orderDate",
        width: 160,
        align: "center",
        render: (text) => ({
            children: new Date(text).toLocaleString("ru-RU", { dateStyle: 'short', timeStyle: 'short' }),
            props: {
                style: { fontSize: "10px" }
            }
        })
    },

    {
        title: <span style={{ fontSize: "10px" }}>Обн статуса</span>,
        dataIndex: "orderStatusDate",
        width: 160,
        align: "center",
        render: (text) => ({
            children: new Date(text).toLocaleString("ru-RU", { dateStyle: 'short', timeStyle: 'short' }),
            props: {
                style: { fontSize: "10px" }
            }
        }),
        // sorter: (a, b) => new Date(a.orderStatusDate).getTime() - new Date(b.orderStatusDate).getTime()
    },

    {
        title: <span style={{ fontSize: "10px" }}>Действует до</span>,
        dataIndex: "durationDate",
        width: 160,
        align: "center",
        render: (text) => ({
            children: new Date(text).toLocaleString("ru-RU", { dateStyle: 'short', timeStyle: 'short' }),
            props: {
                style: { fontSize: "10px" }
            }
        }),
        // sorter: (a, b) => new Date(a.durationDate).getTime() - new Date(b.durationDate).getTime()
    },

    {
        title: <span style={{ fontSize: "10px" }}>Тип цены</span>,
        dataIndex: "priceQuotation",
        width: 80,
        align: "center",
        render: (text) => {
            if (text === 1) {
                return <span style={{ fontSize: "10px" }}>Чистая цена</span>;
            } else if (text === 2) {
                return <span style={{ fontSize: "10px" }}>Цена</span>;
            } else if (text === 3) {
                return <span style={{ fontSize: "10px" }}>Доходность</span>;
            }
            return "";
        },
    },

    {
        title: <span style={{ fontSize: "10px" }}>Счет</span>,
        dataIndex: "subAccount",
        width: 120,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "10px" } // Установите значение, соответствующее желаемому размеру шрифта
            }
        }),
    },

    {
        title: <span style={{ fontSize: "10px" }}>Сlient Order ID</span>,
        dataIndex: "clientOrderId",
        width: 120,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "10px" } // Установите значение, соответствующее желаемому размеру шрифта
            }
        })
    },

    {
        title: "",
        dataIndex: "action",
        // width: 120,
        fixed: "right",
        align: "right",
        className: "action-column multi"
    },
]
