import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EOrderStatus, TDigitalOrderRecord, TOraNonTradeOrder } from "../types/orders";
import { apiConnector } from "../integrations/api.connector";
import { NotificationsConnector } from "../integrations/notifications.connector";

export const getNTOrders = createAsyncThunk(
    'ntOrders/getAll',
    async (data: URLSearchParams | undefined, { dispatch }): Promise<{ paperOrders: TOraNonTradeOrder[], digitalOrders: TDigitalOrderRecord[] }> => {
        try {
            const { success, payload } = await apiConnector.getNonTradeOrders(data);            
            const orders: any[] = payload.digitalOrders;
            const trackedOrders = orders.filter((o: TDigitalOrderRecord) =>
                o.orderSysData.orderStatusId === EOrderStatus.confirmed ||
                o.orderSysData.orderStatusId === EOrderStatus.processing ||
                o.orderSysData.orderStatusId === EOrderStatus.onCalculation ||
                o.orderSysData.orderStatusId === EOrderStatus.awaitingConfirmation ||
                o.orderSysData.orderStatusId === EOrderStatus.partlyCompleted ||
                (o.orderSysData.orderStatusId > 30 && o.orderSysData.orderStatusId < 44)
            );
            const completedOrders = orders.filter((o: TDigitalOrderRecord) =>
                o.orderSysData.orderStatusId === EOrderStatus.completed ||
                o.orderSysData.orderStatusId === EOrderStatus.rejected ||
                o.orderSysData.orderStatusId === EOrderStatus.notCompleted ||
                o.orderSysData.orderStatusId === EOrderStatus.canceledByClient ||
                o.orderSysData.orderStatusId === EOrderStatus.canceledBySystem ||
                o.orderSysData.orderStatusId > 44
            );
            dispatch(addIdsToWatch(trackedOrders));
            dispatch(addIdsToUnwatch(completedOrders))
            NotificationsConnector.subscribe();
            NotificationsConnector.unsubscribe();
            return payload;
        } catch (e: any) {
            console.log(e);
            throw new Error(e)
        }
    }
)

export const nonTradeOrdersSlice = createSlice({
    name: 'ntOrders',
    initialState: {
        digitalOrders: [] as TDigitalOrderRecord[],
        paperOrders: [] as TOraNonTradeOrder[],
        ntOrdersFetching: false,
        idsWatchListNTO: [] as number[],
        idsUnwatchListNTO: [] as number[]
    },
    reducers: {
        addIdsToWatch: (state, action) => {
            const ids: number[] = [];
            const trackedOrders = action.payload;
            trackedOrders.forEach((order: any) => {
                ids.push(order.orderId);
            });
            state.idsWatchListNTO = ids;
        },
        addIdsToUnwatch: (state, action) => {
            const ids: number[] = [];
            const completedOrders = action.payload;
            completedOrders.forEach((order: any) => {
                ids.push(order.orderId);
            });
            state.idsUnwatchListNTO = ids;
        },
        logOutNonTrade: (state) => {
            return {
                ...state,
                ntOrdersFetching: false,
                digitalOrders: [],
                paperOrders: [],
                idsUnwatchListNTO: [],
                idsWatchListNTO: [],
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(
                getNTOrders.pending, state => {
                    state.ntOrdersFetching = true
                }
            )
            .addCase(
                getNTOrders.rejected, state => {
                    state.ntOrdersFetching = false
                }
            )
            .addCase(
                getNTOrders.fulfilled, (state, action) => {
                    const { digitalOrders, paperOrders } = action.payload;
                    state.digitalOrders = digitalOrders;
                    state.paperOrders = paperOrders;
                    state.ntOrdersFetching = false
                }
            )
    }
})

export const {
    addIdsToWatch,
    addIdsToUnwatch,
    logOutNonTrade
} = nonTradeOrdersSlice.actions