import {TPDFHeading} from "../types/api";
import {ENonTradeOrderType} from "../types/orders";

export const buildXML = (data: { [key: string]: string | number | null }, rootName: string, heading: TPDFHeading): string => {
    const xmlDoc = document.implementation.createDocument(null,rootName);
    const header = xmlDoc.createElement("clientData");
    const order = xmlDoc.createElement("orderData");
    for (let key in heading){
        const newNode = xmlDoc.createElement(key);
        newNode.innerHTML = (heading as { [key: string]: string })[key];
        header.appendChild(newNode);
    }
    for (let key in data){
        const newNode = xmlDoc.createElement(key);
        newNode.innerHTML = data[key]?.toString() || "";
        order.appendChild(newNode);
    }
    xmlDoc.documentElement.appendChild(header);
    xmlDoc.documentElement.appendChild(order);
    return new XMLSerializer().serializeToString(xmlDoc.documentElement);
}
export type TEntry = { [key: string]: string | number | null | undefined }
export type TDataForXML = {
    heading: TPDFHeading
    orderData: TEntry
    printData?: { [key: string]: TEntry }
}

export const buildXMLv2 = (rootName: string, data: TDataForXML): string => {
    const {heading, orderData, printData} = data
    const xmlDoc = document.implementation.createDocument(null,rootName);
    const buildBlock = (elements: TEntry, parentName: string): HTMLElement => {
        const parent = xmlDoc.createElement(parentName);
        for(let key in elements){
            const newNode = xmlDoc.createElement(key);
            try {
                newNode.textContent = elements[key]?.toString() || "";
            } catch (e) {
                console.log(e);
                console.log('errored:',{key, elements, v: elements[key]})
            }
            parent.appendChild(newNode);
        }
        return parent
    }
    const header = buildBlock(heading, "clientData");
    const order = buildBlock(orderData, "orderData");
    xmlDoc.documentElement.appendChild(header);
    xmlDoc.documentElement.appendChild(order);
    if(printData){
        for (let key in printData){
            const printBlock = buildBlock(printData[key],key);
            order.appendChild(printBlock);
        }
    }
    return new XMLSerializer().serializeToString(xmlDoc.documentElement);
}
export const getOrderType = (xml: string):ENonTradeOrderType => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, "text/xml");
    const rootNodeName = xmlDoc.documentElement.nodeName;
    switch (rootNodeName){
        case "SecuritiesTransferInternal": return ENonTradeOrderType.securityTransferInternal;
        case "SecuritiesTransferExternal": return ENonTradeOrderType.securityTransferExternal;
        case "MoneyTransferInternal": return ENonTradeOrderType.moneyTransferInternal;
        case "MoneyTransferExternal": return ENonTradeOrderType.moneyTransferExternal;
        default: return ENonTradeOrderType.unknown
    }
}