import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, DatePicker, Divider, Input, InputNumber, Modal, Radio, Row, Select } from "antd";
import moment from "moment";
import { dispatch, TState } from "../../store";
import { closePledgeOrder } from "../../store/orders.slice";
import { TMarketSecurity } from "../../types/api";
import { countNum, finNum, formatPrice, parseNum, parsePrice } from "../../services/formatter";
import "./editedForm.css";

export const PledgeOrderForm = () => {
    const { securities: { equitiesLib, bondsLib }, orders: { showPledgeOrderModal } } = useSelector((state: TState) => state)
    const [step, setStep] = useState<"form" | "sign" | "error">('form');
    const [role, setRole] = useState<'pledger' | 'pledgee'>('pledger');
    const [type, setType] = useState<'rights' | 'encumbrance'>('rights');
    const [action, setAction] = useState<'start' | 'end'>('start');
    const [secData, setSecData] = useState<TMarketSecurity[]>([]);
    const [sec, setSec] = useState<TMarketSecurity>();
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(0);
    const [sum, setSum] = useState(0);
    const [reason, setReason] = useState<string>();
    const [endDay, setEndDay] = useState(moment());
    useEffect(() => {
        setSum(price * quantity)
    }, [price, quantity])
    const handleOk = () => {
        setStep("form")
        dispatch(closePledgeOrder())
    }
    const handleCancel = () => {
        setStep("form")
        dispatch(closePledgeOrder())
    }
    const getTitle = () => {
        return (
            <>
                <span className="deal-direction">
                    Приказ
                </span>
            </>
        )
    }
    const roleOptions = [
        { label: 'Залогодатель', value: 'pledger' },
        // {label: 'Рынок', value: OrderType.market, disabled: orderDirection === DealDirection.buy},
        { label: 'Залогодержатель', value: 'pledgee' }
    ]
    const typeOptions = [
        { label: 'Залог права', value: 'rights' },
        { label: 'Обременение', value: 'encumbrance' }
    ]
    const actionOptions = [
        { label: 'Возникновение', value: 'start' },
        { label: 'Прекращение', value: 'end' }
    ]
    const handleSearch = (searchString: string) => {
        const target = [...equitiesLib, ...bondsLib];
        let result: TMarketSecurity[] = target.filter(row => {
            return (row.ticker && row.ticker.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
                || (row.isin && row.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
        });
        setTimeout(() => {
            setSecData(result);
        }, 200)
    }
    const handleChange = (ticker: string) => {
        const sec = secData.find(s => s.ticker === ticker);
        setSec(sec);
    }
    return (
        <Modal
            title={getTitle()}
            visible={showPledgeOrderModal}
            onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={650}
            bodyStyle={{ paddingTop: 12 }}
            className="edited-form"
            zIndex={5}
        >
            <Row className="of-row compact">
                <Col span={8}>
                    Инициатор:
                </Col>
                <Col span={16}>
                    <Radio.Group
                        className="radio-wide deal-type"
                        options={roleOptions}
                        value={role}
                        optionType="button"
                        buttonStyle="solid"
                        size="small"
                        onChange={e => setRole(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8}>
                    Тип:
                </Col>
                <Col span={16}>
                    <Radio.Group
                        className="radio-wide deal-type"
                        options={typeOptions}
                        value={type}
                        optionType="button"
                        buttonStyle="solid"
                        size="small"
                        onChange={e => setType(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8}>
                    Действие:
                </Col>
                <Col span={16}>
                    <Radio.Group
                        className="radio-wide deal-type"
                        options={actionOptions}
                        value={action}
                        optionType="button"
                        buttonStyle="solid"
                        size="small"
                        onChange={e => setAction(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8}>Выберите ЦБ:</Col>
                <Col span={16}>
                    <Select className="of-select deal-account" size="small"
                        value={sec?.ticker}
                        placeholder="Введите ISIN или торговый код"
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onSearch={handleSearch}
                        notFoundContent={null}
                        onChange={handleChange}>
                        {
                            secData.map((sec, i) => (
                                <Select.Option className="of-option" key={`${i}`}
                                    value={sec.ticker}>{sec.ticker} {sec.isin}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8}>Вид ценной бумаги:</Col>
                <Col span={16}>
                    <span>{sec?.securityType}</span>
                </Col>
                <Col span={8}>Торговый код:</Col>
                <Col span={16}>
                    <span>{sec?.tradeCode}</span>
                </Col>
                <Col span={8}>ISIN:</Col>
                <Col span={16}>
                    <span>{sec?.isin}</span>
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8}>Количество ЦБ:</Col>
                <Col span={16}>
                    <InputNumber
                        value={quantity}
                        min={1}
                        onChange={(q) => setQuantity(q || 0)}
                        size="small" className="of-input deal-quantity"
                        formatter={v => countNum(v as number)}
                        parser={v => parseNum(v as string, 0)}
                    />
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8}>
                    Цена:
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={price}
                        onChange={p => setPrice(p || 0)}
                        size="small" className="of-input deal-price"
                        formatter={v => formatPrice(v!, 4)}
                        parser={v => parsePrice(v!)}
                    // parser={v => parseNum(v as string, 2)}
                    />
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8}>
                    Сумма сделки:
                </Col>
                <Col span={16}>
                    <InputNumber
                        size="small" className="of-input deal-amount"
                        value={sum}
                        formatter={v => finNum(v as number)}
                        parser={v => parseNum((v as string), 2)}
                        disabled
                    />
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8} title="Основание для совершения сделки">Основание:</Col>
                <Col span={16}>
                    <Input
                        value={reason}
                        placeholder="Основание для совершения сделки"
                        size="small" className="of-input"
                        onChange={e => setReason(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8} title="Дата истечения или условия снятия обременения/залога">
                    Дата истечения:
                </Col>
                <Col span={16}>
                    <DatePicker
                        size="small" className="of-input deal-day"
                        disabledDate={current => current && current < moment().subtract(1, 'day')}
                        defaultValue={moment()}
                        onChange={val => val && setEndDay(val)}
                    />
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8} title="Наименование/ФИО владельца субсчета ДЕПО, на котором обременяются ЦБ">Наименование/ФИО владельца:</Col>
                <Col span={16}>
                    <Input
                        value={reason}
                        placeholder="ТОО 'КО и Компания'"
                        size="small" className="of-input"
                        onChange={e => setReason(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8} title="Субсчет ДЕПО, на котором обременяются ЦБ">Субсчет ДЕПО:</Col>
                <Col span={16}>
                    <Input
                        value={reason}
                        size="small" className="of-input"
                        onChange={e => setReason(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8}>Голоса:</Col>
                <Col span={16}>
                    <Input
                        value={reason}
                        size="small" className="of-input"
                        onChange={e => setReason(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row compact">
                <Col span={8}>Получение вознаграждения:</Col>
                <Col span={16}>
                    <Input
                        value={reason}
                        size="small" className="of-input"
                        onChange={e => setReason(e.target.value)}
                    />
                </Col>
            </Row>
            <Divider />
            <Row>
                <Button type="primary" block danger>Подписать и отправить приказ</Button>
            </Row>
        </Modal>
    )
}