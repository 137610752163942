import React from 'react';
import { Layout, Tabs, Divider } from 'antd';
import { Portfolio } from '../portfolio/Portfolio';
import { Orders } from '../orders/Orders';
import { Equities } from '../market/Equities';
import { Bonds } from '../market/Bonds';
import { OrderDeals } from "../orders/OrderDeals";
import { NewOrderDeals } from '../orders/NewOrderDeals';
import { getHalfPageContainerHeight } from '../../services/helpers';

export const TradePage = () => {
    return (
        <>
            <Layout className="content-wrap" style={{
                height: getHalfPageContainerHeight(),
                // backgroundColor: 'red'
            }}>
                <Tabs type="card">
                    <Tabs.TabPane tab="Акции" key="equities" tabKey="equities" style={{ position: "relative" }}>
                        <Equities />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Облигации" key="bonds" tabKey="bonds">
                        <Bonds />
                    </Tabs.TabPane>
                    {/*<Tabs.TabPane tab="Мой список 1" key="custom-1" tabKey="custom-1" disabled />*/}
                </Tabs>
            </Layout>
            <Divider style={{ margin: "7px 0" }} />
            <Layout className="content-wrap" style={{
                height: getHalfPageContainerHeight(), width: '100%'
                // backgroundColor: 'green'
            }}>
                <Tabs type="card">
                    <Tabs.TabPane tab="Портфель" key="portfolio" tabKey="portfolio" style={{ position: "relative" }}>
                        <Portfolio />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Заказы" key="orders" tabKey="orders">
                        <Orders />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Сделки" key="deals" tabKey="deals">
                        <OrderDeals />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Журнал" key="log" tabKey="log">
                        <Orders isLog />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Сделки(new)" key="new-deals" tabKey="new-deals">
                        <NewOrderDeals />
                    </Tabs.TabPane>
                </Tabs>
            </Layout>
        </>
    )
}
