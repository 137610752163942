import React from 'react';
import { useSelector } from 'react-redux';
import { TState } from '../../store';
import { closeBondsSide } from '../../store/securities.slice';
import { SecuritySide } from './SecutirySide';

export const BondsSide = () => {
    const {
        securities: {
            bondsSideInfo,
            bondsSideDisplayed
        }
    } = useSelector((state: TState) => state);

    const depthType = "Bonds"

    return (
        <SecuritySide
            securityInfo={bondsSideInfo}
            displayCondition={bondsSideDisplayed}
            closeFunction={closeBondsSide}
            depthType={depthType}
        />
    )
};