import React from "react";
import { useSelector } from 'react-redux';
import { Form, Input, Button } from "antd";
import { dispatch, TState } from '../../store';
import { loginByText } from '../../store/auth.slice';

export const NameAndPass = () => {
    const { authError } = useSelector((state: TState) => state.auth)
    const onFinish = (values: any) => {
        dispatch(loginByText(values));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Логин"
                    name="login"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>

                {/*<Form.Item name="remember" valuePropName="unchecked" wrapperCol={{offset: 8, span: 16}}>*/}
                {/*    <Checkbox>Remember me</Checkbox>*/}
                {/*</Form.Item>*/}

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Войти
                    </Button>
                    {authError && <p className="err-message">Неверный логин или пароль</p>}
                </Form.Item>
            </Form>
        </>
    )
}
