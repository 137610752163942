import React, { FC, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { Button, Table } from "antd";
import Search from "antd/lib/input/Search";
import { CloseCircleOutlined, EditOutlined, FileDoneOutlined, ReloadOutlined } from "@ant-design/icons";
import { TState, dispatch } from "../../store";
import { getOrderDeals, getOrders, showCancelTradeOrder } from "../../store/orders.slice";
import { apiConnector } from "../../integrations/api.connector";
import { DealKind, EOrderStatus, EPDFOrderType, TOrderStatusFull } from "../../types/orders";
import { TableEditor } from "../tableEditor/TableEditor";
import { BuyIcon } from "../shared/icons/buy.icon";
import { SellIcon } from "../shared/icons/sell.icon";
import { getHalfPageTableHeight, rowClassCompact, updateTableView } from "../../services/helpers";
import { columns } from './orders.data';
import { newColumnsDealsSec } from "./orderDealsDetailsSec.data";
import "./NewOrderDeals.css";


export const NewOrderDeals: FC<{ isLog?: boolean }> = ({ isLog = false }) => {
    const timerRef = useRef<number | undefined>();
    const [searchString, setSearchString] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const { deals, dealsFetching, ordersFetching, trackedOrders} = useSelector((state: TState) => state.orders)
    const orders = trackedOrders;

    const cancelableStatusIds = [
        EOrderStatus.confirmed,
        EOrderStatus.processing,
        EOrderStatus.awaitingConfirmation,
        EOrderStatus.partlyCompleted,
        EOrderStatus.partlyOnCalculationAndProcessing,
        EOrderStatus.partlyCompletedAndProcessing
    ];

    const downloadTXT = (p: TOrderStatusFull) => {
        const fileContent = `${p.signature}`;
        const file = new File([fileContent], `${p.clientOrderId}.xml`, { type: 'application/xml' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${p.clientOrderId}.xml`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
    }

    const getDoc = async (o: TOrderStatusFull) => {
        const { clientOrderId, signature, rejectOrderId, onum } = o;
        const p = await apiConnector.getPDF({
            id: o.id,
            xmlData: signature!,
            orderId: o.rejectOrderId && o.orderId ? o.orderId.toString() : clientOrderId.toString(),
            orderDate: o.orderDate,
            orderNumber: (o.rejectOrderId && o.onum) ? o.onum : o.orderNumber || "internal",
            orderType: o.rejectOrderId ? EPDFOrderType.cancel : EPDFOrderType.equities,
            rejOrderNumber: o.rejONum || undefined
        });
        downloadPDF(p, rejectOrderId || clientOrderId);
    }

    const downloadPDF = (p: ArrayBuffer, clientOrderId: number) => {
        const file = new File([p], `${clientOrderId}.pdf`, { type: 'application/pdf' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${clientOrderId}.pdf`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
    }

    const onSearch = (value: string) => {
        setSearchString(value);
    }
    const onSearchChange = (value: string) => {
        if (!loading) {
            setLoading(true);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            setSearchString(value);
            setLoading(false);
        }, 100)
    }



    const [columnsForOrder, setColumnsForOrder] = useState(updateTableView(columns, 'Orders'))
    const updateMyColumns = () => {
        setColumnsForOrder(updateTableView(columns, 'Orders'))
    }






































    useEffect(() => {
        dispatch(getOrderDeals())
    }, [])

    const timerRefForOrderDeals = useRef<number | undefined>();
    const [searchStringForOrderDeals, setSearchStringForOrderDeals] = useState<string>();
    const [loadingForOrderDeals, setLoadingForOrderDeals] = useState<boolean>(false);

    const onSearchForOrderDeals = (value: string) => {
        setSearchStringForOrderDeals(value);
    }

    const onSearchChangeForOrderDeals = (value: string) => {
        if (!loadingForOrderDeals) {
            setLoadingForOrderDeals(true);
        }
        if (timerRefForOrderDeals.current) {
            clearTimeout(timerRefForOrderDeals.current);
        }
        timerRefForOrderDeals.current = window.setTimeout(() => {
            setSearchStringForOrderDeals(value);
            setLoadingForOrderDeals(false);
        }, 100)
    }

    const columnsDeals = newColumnsDealsSec;
    const lsKey = 'OrderDealsDetailsSec';

    const [columnsForOrderDeals, setColumnsForOrderDeals] = useState(updateTableView(columnsDeals, lsKey))
    const updateColumnsForOrderDeals = () => {
        setColumnsForOrderDeals(updateTableView(columnsDeals, lsKey))
    }
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const nestedTableRender = (record: TOrderStatusFull) => {
        const matchedObject = deals.find(deal => deal.orderId === record.clientOrderId);
        const matchedDeals = matchedObject ? matchedObject.deals : [];

        const nullPrimaryClientOrderId = matchedDeals.find((item) => item.primaryClientOrderId === null);
        const notNullPrimaryClientOrderId = matchedDeals.filter((item) => item.primaryClientOrderId !== null);
        const updatedDeals = [nullPrimaryClientOrderId, ...notNullPrimaryClientOrderId].map((item, index) => ({
            ...item,
            orderNumber: item?.orderNumber.split('/')[0],
            key: index
        }));

        const dataSourceForNestedTable = updatedDeals.map((item) => ({
            orderStatus: item.orderStatus, // Replace with the actual data property names
            orderPrice: item.orderPrice,
            orderQuantity: item.orderQuantity,
            orderAmount: item.orderAmount,
            dealPrice: item.dealPrice,
            dealQuantity: item.dealQuantity,
            dealAmount: item.dealAmount,
            orderDate: item.orderDate,
            orderStatusDate: item.orderStatusDate,
            durationDate: item.durationDate,
            priceQuotation: item.priceQuotation,
            subAccount: item.subAccount
        }));
        return <React.Fragment>
            <Table
                rowClassName={rowClassCompact}
                showSorterTooltip={false}
                columns={columnsForOrderDeals}
                dataSource={dataSourceForNestedTable}
                loading={dealsFetching || loadingForOrderDeals}
                pagination={false} />
        </React.Fragment>
    }

    const onExpand = (record: TOrderStatusFull) => {
        const recordKey = record.id;
        const isExpanded = expandedRowKeys.includes(recordKey);

        if (isExpanded) {
            setExpandedRowKeys(prevKeys => prevKeys.filter(key => key !== recordKey));
        } else {
            setExpandedRowKeys(prevKeys => [...prevKeys, recordKey]);
        }
    };

    const dataSourceForOrder = (): (TOrderStatusFull & { action: any, icon: any })[] => {
        return orders
            .map(p => {
                const canBeCanceled = !isLog && cancelableStatusIds.includes(p.orderStatusId);
                const ticker: string = p.ticker || p.repoBasket || "н.д.";
                return {
                    ...p,
                    ticker: ticker,
                    icon: p.rejectOrderId ? <CloseCircleOutlined style={{ fontSize: '16px', marginTop: '2px' }} /> : p.dealKindId === DealKind.buy ? <BuyIcon color={"var(--fhs-black)"} /> : <SellIcon color={"var(--fhs-black)"} />,
                    action: <>
                        {isLog ? null : <Button title="Отменить заказ" onClick={() => dispatch(showCancelTradeOrder(p))} disabled={!canBeCanceled} size="small" type="primary" danger><CloseCircleOutlined style={{ fontSize: '18px', marginTop: '2px' }} /></Button>}
                        <Button onClick={() => getDoc(p)} type="primary" size="small" title="Скачать заказ"><FileDoneOutlined style={{ fontSize: '18px', marginTop: '2px' }} /></Button>
                        <Button onClick={() => downloadTXT(p)} type="default" size="small" title="Скачать подпись"><EditOutlined style={{ fontSize: '18px', marginTop: '2px' }} /></Button>
                    </>
                }
            })
            .filter(row => {
                if (!searchString || searchString.length < 1) {
                    return true
                } else {
                    return (row.ticker && row.ticker.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                        (row.subAccount && row.subAccount.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                        (row.isin && row.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
                }
            }).map(obj => {
                const matchedObject = deals.find(obj2 => obj2.orderId === obj.clientOrderId);
                if (matchedObject) {
                    return { ...obj, dealsCount: matchedObject.dealsCount };
                }
                return obj
            })
    }

    console.log(dataSourceForOrder());


    return <>
        <Search onSearch={onSearch}
            onChange={e => onSearchChange(e.target.value)}
            allowClear={true}
            placeholder={"Поиск"}
            style={{ width: 250, marginBottom: 10, zIndex: 2 }} />

        <Button style={{ marginRight: 8 }} onClick={() => dispatch(getOrders())} type="default">
            <ReloadOutlined />
        </Button>

        <TableEditor updateColumns={updateMyColumns} columns={columns} keyForLS={'Orders'} />

        <Table
            size="small"
            showSorterTooltip={false}
            rowClassName={rowClassCompact}
            rowKey={record => record.id}
            dataSource={dataSourceForOrder()}
            columns={columnsForOrder}
            scroll={{
                // x: "max-content",
                y: getHalfPageTableHeight()
            }}
            expandable={{
                expandedRowRender: nestedTableRender,
                expandIcon: ({ expanded, record }) => {
                    if (record.dealsCount > 1) {
                        return (
                            <Button type="text" onClick={() => onExpand(record)}
                                style={{ color: expanded ? 'green' : 'blue', fontWeight: 'bold', }}>
                                {expanded ? '-' : '+'}
                            </Button>
                        );
                    } else {
                        return null;
                    }
                },
                expandedRowKeys,
                defaultExpandedRowKeys: [0],
            }}
            loading={ordersFetching || loading}
            pagination={false}
        />
    </>
}

