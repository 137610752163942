import { io, Socket } from 'socket.io-client';
import { dispatch, store } from '../store';
import { getOrderTemplates } from '../store/orders.slice';
import { apiConnector } from './api.connector';
import { EOrderType, TNotification } from '../types/notifications';
import { api } from '../constants';

export class NotificationsConnector {
    public static SocketIO: Socket;
    public static Queue: string[] = [];
    public static onNotification: (notification: TNotification) => void;
    private static socketIoHost: string = api.host;

    public static initSocketIO = (clientId: number) => {
        NotificationsConnector.SocketIO = io(NotificationsConnector.socketIoHost, {
            path: "/api/notification-service/socket.io/",
            transports: ["websocket"],
            auth: {
                token: apiConnector.token,
            },
            query: {
                clientId: clientId
            }
        });
        NotificationsConnector.SocketIO.on("connect", () => {
        })
        NotificationsConnector.SocketIO.on("notification", data => NotificationsConnector._processNotif(data))
    };

    public static subscribe = () => {
        const storeState = store.getState();
        let clientId = 0;
        if (storeState.auth.clientData) {
            clientId = storeState.auth.clientData.clientId;
        }
        const orders = storeState.orders.orderIdsWatchList;
        const ntoIds = storeState.ntOrders.idsWatchListNTO;
        const orderIds = orders.concat(ntoIds);
        apiConnector.notificationSubscribe(clientId, orderIds);
    }

    public static unsubscribe = () => {
        const storeState = store.getState();
        const orders = storeState.orders.orderIdsUnwatchList;
        const ntoIds = storeState.ntOrders.idsUnwatchListNTO;
        const orderIds = orders.concat(ntoIds);
        apiConnector.notificationUnsubscribe(orderIds);
    }
    public static notify = () => {
        const storeState = store.getState();
        let clientId = 0;
        if (storeState.auth.clientData) {
            clientId = storeState.auth.clientData.clientId;
        }
        const message: TNotification = { orderType: EOrderType.template, orderId: 0, statusId: 0, clientId: clientId };
        apiConnector.notificationNotify(message);
        dispatch(getOrderTemplates());
    }

    public static close = () => {
        NotificationsConnector.SocketIO.close();
    }

    private static _processNotif = (incomingMessage: TNotification): void => {
        NotificationsConnector.onNotification(incomingMessage);
    }
}