import ExcelExport from "./excelExport";
export type TExcelWorkSheet = {
    sheetName: string,
    startingRowNumber: 1,
    gapBetweenTwoTables: 2,
    tableSettings: {[key: string]:TExcelTableSettings}
}
export type TExcelTableSettings = {
    tableTitle: string,
    headerDefinition: {key: string, name: string}[],
}
export type TExcelSettings = {
    fileName: string,
    workSheets: TExcelWorkSheet[]
}
const getSettings = (fileName: string, sheetName: string, title: string):TExcelSettings => ({
    // Table settings
    fileName: fileName,
    workSheets: [
        {
            sheetName: sheetName,
            startingRowNumber: 1,
            gapBetweenTwoTables: 2,
            tableSettings: {
                table1: {
                    tableTitle: title,
                    headerDefinition: [],
                }
            }
        },
    ],
});
export const exportToExcel = async (rows: any[], {columns, fileName, sheetName, title}: {columns: any, fileName: string, sheetName: string, title: string}) => {
    const settings = getSettings(fileName, sheetName, title);
    const headers = [];
    for (const column of columns){
        headers.push({
            key: column.dataIndex,
            name: column.title
        })
    }
    settings.workSheets[0].tableSettings.table1.headerDefinition = headers;
    const data = [
        {
            table1: rows
        }
    ]
    const ee = new ExcelExport();
    return await ee.downloadExcel(settings, data);
}

export const exportData = async (data: any[], columns: any, reportName: string, reportDates: Date | Date[]): Promise<void> => {
    const dateString = [reportDates].flat().map(date => date.toLocaleDateString("ru-RU")).join("-")
    const title = `${reportName}`;
    const fileName = title + '-' + dateString;
    await exportToExcel(data, {
        columns,
        fileName,
        sheetName: dateString,
        title
    })
}