import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";
import Search from "antd/lib/input/Search";
import { nanoid } from "nanoid";
import { SellBtn } from "../market/sell.btn";
import { TState } from "../../store";
import { TMarketSecurity, TSecurityQuotes } from "../../types/api";
import { columns } from "./smallTable.data";
import { rowClassCompact } from "../../services/helpers";

export const SellSecWidget = () => {
    const { equitiesLib, bondsLib, equitiesFetching, bondsFetching, portfolio } = useSelector((state: TState) => state.securities)
    const securities = [...equitiesLib, ...bondsLib];
    const ownedSecs: (TMarketSecurity | undefined)[] = portfolio.map(p => {
        // const localBool = p.holdPlace === "KASD" ? 1 : 0;
        return securities.find(l => (l.securityId === p.securityId && l.intBool === p.intBool));
    });
    const validList: TMarketSecurity[] = [];
    ownedSecs.forEach(oSec => {
        if (oSec) {
            validList.push(oSec)
        }
    })
    const timerRef = useRef<number | undefined>();
    const [searchString, setSearchString] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const onSearch = (value: string) => {
        setSearchString(value);
    }
    const onSearchChange = (value: string) => {
        if (!loading) {
            setLoading(true);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            setSearchString(value);
            setLoading(false);
        }, 500)
    }
    const dataSource = () => {
        let testQuote: TSecurityQuotes = {
            open: "-",
            last: "-",
            bid: "-",
            ask: "-"
        }
        let resultList: Array<TMarketSecurity & { action: any } & TSecurityQuotes> = [];
        let ownList: TMarketSecurity[];
        if (searchString && searchString.length > 0) {
            ownList = validList.filter(row => {
                return ((row.ticker && row.ticker.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
                    // (row.subAccount && row.subAccount.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                    || (row.isin && row.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1))
            })
        } else {
            ownList = validList;
        }
        if (ownList.length > 0) {
            resultList = ownList.map(sec => {
                return {
                    ...sec,
                    ...testQuote,
                    action: <SellBtn securityId={sec.securityId} currency={sec.tradeCurrency} intBool={sec.intBool} />
                    // pin: <PinBox marketSecurity={eq} pinned={false}/>
                }
            });
        }
        return resultList;
    }
    return (
        <>
            <Search
                onSearch={onSearch}
                onChange={e => onSearchChange(e.target.value)}
                allowClear={true}
                placeholder={"Тикер или ISIN"}
                style={{
                    width: "100%",
                    marginBottom: 10,
                    zIndex: 2
                }}
            />
            <Table
                size="small"
                rowClassName={rowClassCompact}
                rowKey={() => nanoid(8)}
                columns={columns}
                dataSource={dataSource()}
                scroll={{
                    x: "max-content",
                    y: 200
                }}
                loading={(equitiesFetching || bondsFetching) || loading}
                pagination={false}
            // onChange={onChange}
            />
        </>
    )
}