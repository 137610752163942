import React, { FC, useEffect } from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { dispatch } from '../../store';
import { TMarketSecurity } from '../../types/api';
import { DepthOfMarketPage } from '../market/DepthOfMarketPage';
import { sideComponentWidth } from "../../constants";
import "./SecuritySide.css";

export const SecuritySide: FC<{ securityInfo: TMarketSecurity | null, displayCondition: boolean | null, closeFunction: Function, depthType: string }> = ({ securityInfo, displayCondition, closeFunction, depthType }) => {
    const closeHandler = () => {
        dispatch(closeFunction());
    };

    useEffect(() => {
        const comp = document.getElementById("comp");
        const content = document.getElementById("content");
        if (comp !== null && content !== null) {
            if (displayCondition) {
                comp.style.width = `${sideComponentWidth}%`;
                content.style.visibility = "visible";
            }
            else {
                comp.style.width = "0%";
                content.style.visibility = "hidden";
            }
        }
    }, [displayCondition]);

    return (
        <div id="comp" className="side-comp" style={{ width: displayCondition ? `${sideComponentWidth}%` : "0%" }}>
            <div id="content" className="side-comp-content" style={{ visibility: displayCondition ? "visible" : "hidden" }}>
                <Button size="small" className="side-close-btn" onClick={closeHandler} ><CloseOutlined /></Button>
                <DepthOfMarketPage depthType={depthType} />
            </div>
        </div>
    )
};