import {ECurrency, EDealType, ISIN, RepoBasket, Ticker, UTCDate, UUID} from "./common";

export enum OrderMarketCurrent {
    equities,
    bonds,
    repo
}

export enum OrderMarket {
    equities = "EQ",
    bonds = "BO",
    repo = "RE"
}
export enum OrderType {
    limited = 1,
    market = 2
}
export enum DealKind {
    buy = 1,
    sell = 2
}

export enum DealDirection {
    buy = "BUY",
    sell = "SELL",
    repoBuy = "REPO BUY",
    repoSell = "REPO SELL"
}

export enum PriceQuotation {
    net = 1,
    gross = 2,
    profit = 9
}

export interface EquitiesOrderParams {
    ticker: string,
    ISIN: string,
    currency: ECurrency,
    type: EDealType,
    quantity: number,
    price?: number,
}

export interface BondsOrderParams {

}

export interface RepoOrderParams {

}

export interface ClientOrder {
    orderId: number,
    direction: DealDirection,
    orderStartDate: Date
    orderEndDate: Date
    subAccount: string
    //.....
    params: EquitiesOrderParams | BondsOrderParams | RepoOrderParams
}

export type TOrderStatusFull = {
    allOrderStatus: string,
    clientOrderId: number,
    currencyCode: ECurrency,
    dealsCount: number,
    dealDirection: DealDirection,
    dealKindId: DealKind,
    dealPrice: number,
    dealQuantity: number,
    durationDate: UTCDate,
    file_path?: string,
    id: UUID,
    isin: ISIN,
    kaseOrderId: string | null,
    onum: string | null,
    orderAmount: number,
    orderDate: UTCDate,
    orderId: number | null,
    orderNumber: string,
    orderPrice: number,
    orderQuantity: number,
    orderStatus: string,
    orderStatusDate: UTCDate,
    orderStatusId: EOrderStatus,
    orderType: string,
    orderTypeId: OrderType,
    priceQuotation: PriceQuotation,
    primaryClientOrderId: number | null,
    rejectOrderId: number | null,
    rejONum: string | null,
    repoBasket: RepoBasket | null,
    signature: string | null
    subAccount: string,
    ticker: Ticker,
    tradeModeCode: string | null,
    isPaperOrder?: boolean
}

export enum EOrderStatus {
    unknown,
    confirmed,
    processing,
    onCalculation, //move out and ask for deals
    completed, //move out and ask for deals
    notCompleted,
    awaitingConfirmation,
    canceledByClient,
    canceledBySystem,
    rejected,
    partlyCompleted,//keep! and ask for deals
    partlyOnCalculationAndProcessing = 32,
    partlyOnCalculationAndNotCompleted = 35,
    partlyOnCalculationAndCanceledByClient = 37,
    partlyOnCalculationAndCanceledBySystem = 38,
    partlyOnCalculationAndRejected = 39,
    partlyCompletedAndProcessing = 42,
    partlyCompletedAndOnCalculation = 43,
    partlyCompletedAndNotCompleted = 45,
    partlyCompletedAndCanceledByClient = 47,
    partlyCompletedAndCanceledBySystem = 48,
    partlyCompletedAndRejected = 49,
}
export type TDecisionData = {
    decisionId: number,
    decisionDate: string,
    decisionNumber: string,
    protocolNumber: string,
    decisionEndDate: string,
    decisionTypeId: number,
    decisionType: string,
    decisionFunction: string,
    shortAddInfo: string
}
export type TPutOrderEquitiesRequest = {
    amount: number
    currency: ECurrency
    dataForSign: string
    dealKindId: DealKind
    imContractId: number
    intBool: number,
    localBool: number,
    marketCode: string,
    orderTypeId: OrderType
    price: number
    quantity: number
    securityId: number,
    tradeDay: string
    tradeMode: "Т+"
    signature: string,
    ticker?: string | undefined
}
export type TPutOrderEquities = {
    orderData: TPutOrderEquitiesRequest,
    orderType: EPutOrderType
}
export type TPutOrderBondsRequest = {
    amount: number
    currency: ECurrency
    dataForSign: string
    dealKindId: DealKind
    imContractId: number
    intBool: number,
    localBool: number,
    marketCode: string,
    orderTypeId: OrderType
    netPercentPrice: number
    quantity: number
    securityId: number,
    tradeDay: string
    tradeMode: "Т+"
    signature: string
    profit: number
}
export type TPutOrderBonds = {
    orderData: TPutOrderBondsRequest,
    orderType: EPutOrderType
}
export type TPutOrderRepoRequest = {
    signature: string
    begDate?: string | undefined
    endDate?: string | undefined
    decisionID?: number | undefined
    dealKindId: DealKind
    secBoard: string
    repoBasketId: number | string
    imContractId: number
    orderTypeId: OrderType
    amount: number
    price: number
    repoCloseAmount: number
    repoCloseDate: string
    currency: ECurrency
    dataForSign: string,
    securityId?: string | number
}

export type TPutOrderRepo = {
    orderData: TPutOrderRepoRequest,
    orderType: EPutOrderType
}
export type TCancelOrderRequest = {
    addReject: number,
    dataForSign: string | null,
    orderId: number,
    signature: string | null,
    errorMessage: string | null,
    kaseOrderId?: string,
    par? :string
}
export enum EPDFOrderType {
    equities = 'STOCK',
    bonds = 'BOND',
    repo = 'REPO',
    cancel = 'REJECT'
}
export type TPDFRequest = {
    id?: string,
    pdfUrl?: string,
    xmlData: string,
    orderId: string,
    orderNumber: string,
    orderDate: string,
    orderType: EPDFOrderType | ENonTradeOrderType | ETradeOrderType,
    traceId?: number,
    rejOrderNumber?: string
}

export type TPDFReportRequest = {
    processId: string,
    clientId: number,
    id: string
}
export enum EMoneyTransferType {
    internalKACD = 1,
    internal = 2,
    external = 4,
    conversion = 6
}
export type TMoneyTransferRequest = {
    transferTypeID: EMoneyTransferType,
    amount: number,
    ftOutID?: number,
    ftInID?: number,
    date?: string,
    convertationIn?: number,
    exchangeRate?: number,
    notCheckRem: 0,
    KNP?: 690 | 213 | 223,
    outBankID?: number,
    bankName?: string,
    bankSWIFT?: string,
    IIC?: string,
    corrBankName?: string,
    corrBankSWIFT?: string,
    corrBankAccount?: string,
    recipientName?: string,
    recipientBIIN?: string,
    KBE?: number
}
export type TMoneyTransferRecipientData = {
    name?: string,
    transferTypeID: EMoneyTransferType,
    notCheckRem: 0,
    KNP?: 690 | 213 | 223,
    outBankID?: number,
    bankName?: string,
    bankSWIFT?: string,
    IIC?: string,
    // corrBankName?: string,
    // corrBankSWIFT?: string,
    // corrBankAccount?: string,
    recipientName?: string,
    recipientBIIN?: string,
    KBE?: number,
    id: string,
    type: string,
    currency: string
}
export type TMoneyTransferBankCorrData = {
    name?: string
    corrBankName?: string,
    corrBankSWIFT?: string,
    corrBankAccount?: string,
    id: string,
    type: string,
    currency: string
}

export enum ESecuritiesTransferType {
    internalTransfer = 1,
    internalConversion = 2,
    externalTransferSelfOut,
    externalTransferSelfIn,
    externalTransferOtherOut,
    externalTransferOtherIn,
    externalTransferSell,
    externalTransferBuy
}
export enum ESecuritiesTransferDirection {
    incoming = "incoming",
    outgoing = "outgoing"
}
export enum ESecuritiesTransferKind {
    self,
    other,
    trade
}
export type TSecuritiesTransferRequest = {
    transferTypeID: ESecuritiesTransferType,
    outContrID: number | null,
    inContrID: number | null,
    securityID: number,
    qty: number,
    inSecurityID?: number,
    inQty?: number,
    date?: string,
    notCheckRem: 0 | 1,
    additionalData?: string,
    additionalID?: number,
    outContraName?: string,
    outHolderID?: number,
    outHolderCDS?: 0 | 1,
    outAccount?: string,
    contraBrokerID?: number,
    contraDocType?: string,
    contraDocNumber?: string,
    contraDocSerie?: string,
    contraDocIssuer?: string,
    contraDocDate?: string,
    currency?: ECurrency,
    amount?: number
}

export enum ENonTradeOrderType {
    moneyTransferInternal = 'MONEY_TRANSFER_INTERNAL',
    moneyTransferExternal = 'MONEY_TRANSFER_EXTERNAL',
    moneyConversion = 'MONEY_CONVERSION',
    securityTransfer = "SECURITY_TRANSFER",
    securityTransferInternal = "SECURITY_TRANSFER_INTERNAL",
    securityTransferExternal = "SECURITY_TRANSFER_EXTERNAL",
    securityConversion = "SECURITY_CONVERSION",
    cancel = "NON_TRADE_Cancel",
    unknown = "unknown"
}

export enum ETradeOrderType {
    trade = "TRADE",
    repo = "REPO",
    cancel = "TRADE_CANCEL"
}

export enum EPutOrderType {
    bonds = "BONDS",
    equities = "EQUITIES",
    repo = "REPO"
}

export enum EOrderKind {
    trade = 'TRADE',
    nonTrade = 'NON_TRADE'
}

export enum EOrderState {
    created = 'CREATED',
    invalid = 'INVALID',
    accepted = 'ACCEPTED',
    rejected = 'REJECTED'
}

export enum EOrderTemplateStatus {
    pending = 'PENDING',
    confirmed = 'CONFIRMED',
    rejected = 'REJECTED',
    error = "ERROR",
    invalid = "INVALID",
    expired = 'EXPIRED'
}

export type TNonTradeOrdersRequest = {
    startDate?: string,
    endDate?: string
}

export type TOrderTemplate = TSetOrderTemplate & {id: string, orderDate: string, status: EOrderTemplateStatus, updatedAt: string}

export type TSetOrderTemplate = {
    orderKind: EOrderKind,
    orderType: ETradeOrderType | ENonTradeOrderType
    orderData: any,
    extraData?: any,
    orderXml: string,
    author: string,
    authorIIN: string,
    signer?: string,
    signerIIN?: string
}

export type TOrderTemplateUpdate = {
    id: string,
    status: EOrderTemplateStatus,
    signer?: string,
    signerIIN?: string
}

export type TOraNonTradeOrder = {
    dataDate: string,
    errorText: string | null,
    orderId: number,
    rejectOrderId: number | null,
    orderNumber: string,
    orderDate: string,
    orderKind: string,
    durationDate: string | null,
    clientName: string,
    orderStatusDate: string,
    orderStatus: string,
    orderStatusId: EOrderStatus,
    allOrderStatus: string | null
}

export type TDigitalOrderRecord = {
    id: string;
    userId: number;
    orderId?: number;
    orderNumber?: string;
    orderDate: string;
    orderKind: EOrderKind;
    orderType: ETradeOrderType | ENonTradeOrderType;
    orderData: {[key: string]: string | number | null | undefined};
    orderXml: string
    orderState: EOrderState;
    errorMessage?: string
    errorDetails?: string
    orderSysData: TOraNonTradeOrder
};

export type TDealStatusRecord = {
    clientOrderId: number,
    primaryClientOrderId: number | null,
    rejectOrderId: number | null,
    orderNumber: string,
    orderDate: string,
    durationDate: string,
    kaseOrderId: string | null,
    subAccount: string,
    dealKindId: DealKind,
    dealDirection: DealDirection,
    ticker: string | null,
    isin: string | null,
    repoBasket: string | null,
    orderTypeId: OrderType,
    orderStatusDate: string,
    orderStatus: string,
    allOrderStatus: string | null,
    priceQuotation: PriceQuotation,
    orderPrice: number,
    dealPrice: number,
    orderQuantity: number,
    dealQuantity: number,
    orderAmount: number,
    dealAmount: number,
    orderStatusId: EOrderStatus,
    currencyCode: ECurrency,
    tradeModeCode: string | null
}

export type TOrderDeals = {
    orderId: number,
    orderAmount: number,
    dealsAmount: number,
    orderQuantity: number,
    dealsQuantity: number,
    dealsCount: number,
    deals: TDealStatusRecord[]
}
export type TCancelOrderData = {
    rejectedOrderId: number
}
export type TNonTradeOrderRequest = {
    orderData: TCancelOrderData,
    signedXml: string,
}
export type TRejectOrderData = {
    rejectedOrderId: number,
    addReject: number
}
export type TRejectOrderRequest = {
    orderData: TRejectOrderData,
    signedXml: string,
}
export type TCancelNonTradeOrderResponse = {
    rejOrderId: number,
    orderId: number,
    rejONum: string,
    oNum: string,
}

export enum ECheckReqType {
    eqReq = "EQ_REQUEST",
    bondsReq = "BONDS_REQUEST",
    repoReq = "REPO_REQUEST",
    moneyTransferReq = "MONEY_TRANSF_REQUEST",
    secTransferReq = "SEC_TRANSF_REQUEST",
    tradeCancelReq = "TRADE_CANCEL_REQUEST",
    nonTradeCancelReq = "NON_TRADE_CANCEL_REQUEST"
}