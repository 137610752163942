import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, Modal, notification, Row } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { dispatch, TState } from '../../store';
import { closeCancelOrder, getOrders } from '../../store/orders.slice';
import { apiConnector } from '../../integrations/api.connector';
import { NcaLayerConnector } from '../../integrations/ncaLayer.connector';
import { TPDFHeading } from "../../types/api";
import { NcaMessage } from '../../types/nca';
import { ECheckReqType, EOrderKind, EPDFOrderType, ETradeOrderType, TCancelOrderRequest, TSetOrderTemplate } from '../../types/orders';
import { ModalDS } from '../shared/modals/ModalDS';
import { isCompany, isHeadOfCompany } from "../../services/helpers";
import { buildXMLv2 } from "../../services/xmlBuilder";
import { timeForErrorNotification, timeForSuccessNotification } from '../../constants';

export const CancelForm = () => {
    const {
        auth: {
            userName,
            iin,
            cProfile,
            clientData
        },
        orders: {
            showCancelOrderModal,
            cancelTradeOrder
        }
    } = useSelector((state: TState) => state);
    const [step, setStep] = useState<"form" | "sign" | "error">('form');
    const [error, setError] = useState<string | null>(null);
    const [cancelData, setCancelData] = useState<TCancelOrderRequest>();
    const [orderForm, setOrderForm] = useState<string>();
    const [sending, setSending] = useState<boolean>(false)
    const getTitle = () => {
        return (
            <>
                {/*<CloseCircleOutlined style={{fontSize: '18px', marginTop: '2px'}} />*/}
                <span className="deal-direction">Отмена заказа {cancelTradeOrder?.orderNumber}</span>
            </>
        )
    }
    const handleOk = () => {
        setStep("form")
        dispatch(closeCancelOrder())
    }
    const handleCancel = () => {
        setStep("form")
        dispatch(closeCancelOrder())
    }
    const formOrder = async () => {
        if (cancelTradeOrder) {
            const { clientOrderId, orderNumber, orderDate } = cancelTradeOrder;
            let newOrder: TCancelOrderRequest = {
                addReject: 0,
                dataForSign: null,
                orderId: clientOrderId,
                signature: null,
                errorMessage: null
            };
            setCancelData(newOrder)
            const { dataForSign, errorMessage } = await apiConnector.getCancelDataForSign(newOrder);
            console.log({ dataForSign, errorMessage });
            if (dataForSign && !errorMessage && clientData) {
                const heading: TPDFHeading = {
                    orderDate: new Date().toISOString(),
                    docIssueDate: cProfile?.docIssueDate || "",
                    docIssuer: cProfile?.docIssueAuthor || "",
                    docNumber: cProfile?.docNumber || "",
                    docSeries: "",
                    docType: cProfile?.docType || "",
                    subAccount: clientData.subAccount,
                    accountNumber: clientData.imcAccount,
                    clientName: clientData.cliName,
                    contractNumber: clientData.contractNumber,
                    iinbin: clientData.biin
                }
                const orderData = {
                    rejectedOrderTypeName: "Клиентский заказ",
                    rejectedOrderId: clientOrderId,
                    rejectedOrderNumber: orderNumber,
                    rejectedOrderDate: orderDate,
                    addReject: 0
                }
                const xml = buildXMLv2("RejectOrder", {
                    heading,
                    orderData,
                })
                setOrderForm(xml);
                newOrder.dataForSign = xml;
                setCancelData(newOrder);
                setStep("sign");
            } else {
                console.log(errorMessage)
                setError(errorMessage)
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: errorMessage
                })
            }
        }
    }
    const getDoc = async () => {
        const p = await apiConnector.getPDF({
            id: "",
            xmlData: orderForm!,
            orderId: "_____",
            orderDate: new Date().toISOString(),
            orderNumber: "_____",
            orderType: EPDFOrderType.cancel
        });
        downloadPDF(p, 0);
    }
    const downloadPDF = (p: ArrayBuffer, clientOrderId: number) => {
        const file = new File([p], `${clientOrderId}.pdf`, { type: 'application/pdf' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${clientOrderId}.pdf`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
    }
    const signOrder = async () => {
        // dispatch(closeOrder())
        setSending(true);
        let method: string;
        let args: {};
        if (NcaLayerConnector.getBundleVersion()) {
            method = "signXmlV2"
            args = { xml: cancelData?.dataForSign, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password }
        } else {
            method = "signXml"
            args = { xml: cancelData?.dataForSign }
        }
        const message = new NcaMessage(method, args);

        if (certificate !== '' || password !== '') {
            try {
                // const signature: string = "test_signature"; //await NcaLayerConnector.callNcaMethod(message);
                const signature: string = await NcaLayerConnector.callNcaMethod(message);
                if (signature && cancelData && cancelData.dataForSign) {
                    // const signedParams = {...params};
                    const data = { ...cancelData, dataForSign: signature };
                    const dataToSend = { ...cancelData };
                    const rejectData = { rejectedOrderId: dataToSend.orderId, addReject: dataToSend.addReject }
                    setCancelData(data);

                    const reqType = ECheckReqType.tradeCancelReq;
                    const { success, details } = await apiConnector.checkRequest({ orderData: rejectData, signedXml: signature }, reqType);
                    // const { success, details } = await apiConnector.putCancelOrder({ orderData: rejectData, signedXml: signature });
                    setSending(false);
                    if (success === false) {
                        setError(details);
                        notification.error({
                            duration: timeForErrorNotification,
                            message: "Ошибка!",
                            description: details
                        })
                    } else {
                        dispatch(getOrders())
                        dispatch(closeCancelOrder())
                        notification.success({
                            duration: timeForSuccessNotification,
                            message: "Приказ отправлен!",
                            // description: errorMessage
                        })
                    }
                } else {
                    setError('Не удалось подписать приказ');
                    notification.error({
                        duration: timeForErrorNotification,
                        message: "Ошибка!",
                        description: "Не удалось подписать приказ"
                    });
                }
            } catch (e) {
                console.log(e);
                setSignError('error');
                setError('Не удалось подписать приказ');
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: "Не удалось подписать приказ"
                });
            } finally {
                setSending(false);
            }
        } else {
            notification.error({
                duration: timeForErrorNotification,
                message: "Ошибка!",
                description: "Нужно указать путь до ключа и ввести пароль"
            });
        }
    }
    const pendOrder = async () => {
        if (cancelData && orderForm) {
            setSending(true);
            const orderData: Partial<TCancelOrderRequest> = cancelData;
            const orderTemplate: TSetOrderTemplate = {
                orderKind: EOrderKind.trade,
                orderType: ETradeOrderType.cancel,
                orderData,
                orderXml: orderForm,
                author: userName || "н/д",
                authorIIN: iin || "н/д",
                extraData: {}
            };
            try {
                await apiConnector.checkTemplate(orderTemplate);
                dispatch(closeCancelOrder());
            } catch (e) {
                console.log(e);
                setError('Не удалось отправить Приказ');
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: "Не удалось отправить Приказ"
                });
            } finally {
                setSending(false);
            }
        }
    }

    const [password, setPassword] = useState<string>('');
    const [certificate, setCertificate] = useState<string>("");
    const [signError, setSignError] = useState<string | null>(null);

    return (
        <Modal
            title={getTitle()}
            visible={showCancelOrderModal}
            onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={420}
            bodyStyle={{ paddingTop: 12 }}
            zIndex={5}
        >
            {
                cancelData?.dataForSign && cancelData.dataForSign.length > 0 &&
                <Row>
                    <Button target="_blank" onClick={getDoc} block type="link"
                        icon={<FilePdfFilled color="var(--fhs-red)" />}>Открыть приказ</Button>
                </Row>
            }
            <Divider />
            <Row>
                {step === "form" && <Button onClick={formOrder} type="primary" block>Оформить приказ</Button>}
                {step === "sign" &&
                    <>
                        {isCompany() && !isHeadOfCompany() && <Button loading={sending} onClick={pendOrder} type="primary" block>Отправить на
                            согласование</Button>}
                    </>}
            </Row>
            <ModalDS
                signError={signError}
                step={step}
                password={password}
                certificate={certificate}
                setCertificate={setCertificate}
                setPassword={setPassword}
                signOrder={signOrder}
                isDisable={false}
                sending={sending}
                OT={false}
                isActual={undefined}
                loading={false}
                onConfirm={undefined}
                template={undefined}
                isSigners={false}
            />
        </Modal>
    )
}
