import { ColumnsType } from "antd/lib/table";
import { ReportMetaRecord } from "../../../types/reports";
import { getReportType } from "../../../services/statuses";

export const columns: ColumnsType<ReportMetaRecord> = [
    {
        title: 'Дата формирование',
        dataIndex: 'updatedAt',
        align: 'center',
        width: 250,
        render: text => new Date(text).toLocaleDateString("ru-RU"),
        sorter: (a: ReportMetaRecord, b: ReportMetaRecord) => a.updatedAt.localeCompare(b.updatedAt),
    },
    {
        title: 'Название',
        dataIndex: 'reportType',
        align: 'center',
        width: 250,
        render: getReportType,
        sorter: (a: ReportMetaRecord, b: ReportMetaRecord) => a.reportType.localeCompare(b.reportType),
    },
    {
        title: 'Дата/период отчета',
        dataIndex: 'endDate',
        align: 'center',
        width: 250,
        render: (text, record) =>
            record.startDate ? `${new Date(record.startDate).toLocaleDateString("ru-RU")} - ${new Date(record.endDate).toLocaleDateString("ru-RU")}`
                : `${new Date(record.endDate).toLocaleDateString("ru-RU")}`,
        sorter: (a: ReportMetaRecord, b: ReportMetaRecord) => {
            // if (a.startDate) {
            //     return a.startDate.localeCompare(b.endDate)
            // }
            // else {
            return a.endDate.localeCompare(b.endDate)
            // }
        }
    },
    {
        title: 'Действие',
        dataIndex: 'action',
        align: 'center',
        width: 250,
    }
];