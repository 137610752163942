import React, { useEffect } from 'react';
import { Login } from "./components/auth/Login";
import { useSelector } from 'react-redux';
import { dispatch, TState } from './store';
import { BrowserRouter } from 'react-router-dom';
import { useIdleTimer } from "react-idle-timer";
import { AppWrapper } from './components/WorkSpace';
import { MainHeader } from './components/MainHeader';
import { getProfile, getPerson } from "./store/auth.slice";
import { Layout, Modal } from 'antd';
import { logOut } from "./store/auth.slice";
import { inactivityTime } from './constants';
import { QuotesConnector } from "./integrations/quotes.connector";
import { NotificationsConnector } from './integrations/notifications.connector';
import './styles/App.scss';
const { Content } = Layout

const App = () => {
    const { loggedIn, ncaLayer, ncaError, clientData } = useSelector((state: TState) => state.auth);
    // Logout on user's inactivity
    const onIdle = () => {
        dispatch(logOut());
    };

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        timeout: inactivityTime,
        throttle: 500
    });

    useEffect(() => {
        getRemainingTime();
    }, []);

    useEffect(() => {
        if (loggedIn) {
            dispatch(getProfile());
            dispatch(getPerson());
            QuotesConnector.initSocketIO();
        }
    }, [loggedIn]);

    useEffect(() => {
        if (loggedIn && clientData) {
            NotificationsConnector.initSocketIO(clientData.clientId);
        }
    }, [loggedIn, clientData]);

    return (
        <BrowserRouter>
            <Layout className="app-layout">
                {loggedIn && <MainHeader />}
                <Content>
                    {loggedIn ? <AppWrapper /> : <Login />}
                </Content>
                <a className="user-manual-link" href="https://fhs.kz/wp-content/uploads/2022/09/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F-%D0%BB%D0%B8%D1%87%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%D0%B0-%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%B0.pdf">Инструкция пользователя</a>
            </Layout>
            <Modal
                title={"Ошибка NCALayer"}
                footer={false}
                visible={!ncaLayer || !!ncaError}
            >
                {ncaError || "NCALayer не запущен"}
            </Modal>
        </BrowserRouter>
    );
}

export default App;
