import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiConnector } from "../integrations/api.connector";
import { TAnalyticsData } from "../types/analytics";
import { TAnalyticsDataExtended } from "../types/analyticsExtended";

export const getAllAnalytics = createAsyncThunk(
    'analytics/getAll',
    async (query?: string): Promise<TAnalyticsData[]> => {
        try {
            return await apiConnector.getAllAnalytics(query);
        } catch (e: any) {
            console.log(e);
            throw new Error(e)
        }
    }
)
export const getAllAnalyticsExtended = createAsyncThunk(
    'analytics/getAllExtended',
    async (query?: string): Promise<TAnalyticsDataExtended[]> => {
        try {
            return await apiConnector.getAllAnalyticsExtended(query);
        } catch (e: any) {
            console.log(e);
            throw new Error(e)
        }
    }
)

export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState: {
        analyticsData: [] as TAnalyticsData[],
        analyticsDataExtended: [] as TAnalyticsDataExtended[],
        analyticsFetching: false,
        analyticsError: false
    },
    reducers: {
        showAnalytics: (state, action) => {
            state.analyticsData = action.payload
        },
        showAnalyticsExtended: (state, action) => {
            state.analyticsDataExtended = action.payload
        },
        logOutAnalytics: (state) => {
            return {
                ...state,
                analyticsFetching: false,
                analyticsError: false,
                analyticsData: [],
                analyticsDataExtended: []
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(
                getAllAnalytics.fulfilled, (state, action) => {
                    if (Array.isArray(action.payload)) {
                        state.analyticsData = action.payload
                    } else {
                        state.analyticsData = [];
                    }
                    state.analyticsFetching = false
                }
            )
            .addCase(
                getAllAnalytics.pending, state => {
                    state.analyticsFetching = true
                }
            )
            .addCase(
                getAllAnalytics.rejected, state => {
                    state.analyticsError = true
                    state.analyticsFetching = false
                }
            )
            .addCase(
                getAllAnalyticsExtended.fulfilled, (state, action) => {
                    if (Array.isArray(action.payload)) {
                        state.analyticsDataExtended = action.payload
                    } else {
                        state.analyticsDataExtended = [];
                    }
                    state.analyticsFetching = false
                }
            )
            .addCase(
                getAllAnalyticsExtended.pending, (state, action) => {
                    state.analyticsFetching = true
                }
            )
            .addCase(
                getAllAnalyticsExtended.rejected, state => {
                    state.analyticsFetching = false
                }
            )
    }
})


export const {
    showAnalytics,
    showAnalyticsExtended,
    logOutAnalytics
} = analyticsSlice.actions
