import {TMarketSecurity} from "./api";

export interface IQuote {
    "SECBOARD": string,
    "SECCODE": string,
    "ISIN": string
    "OPEN": number | null,
    "LAST": number | null,
    "BID": number | null,
    "LASTBID": number | null,
    "OFFER": number | null,
    "LASTOFFER": number | null,
    "MARKETPRICE": number | null,
    [key: string]: string | number | null
}
export enum EQuoteType {
    last = 'last',
    open = 'open',
    bid = 'bid',
    ask = 'ask'
}
export type RepoList = {
    currency: "USD" | "KZT",
    tradeModeCode: string,
    list: Partial<IQuote>[],
    count: number
}
export type RepoSecList = {
    currency: "USD" | "KZT",
    tradeModeCode: string,
    days: number,
    list: TMarketSecurity[]
}