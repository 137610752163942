import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, MenuProps, Layout, Button, Tooltip, Space, Switch } from 'antd';
import { dispatch, TState } from "../store";
import { logOut, toogleSwitch } from "../store/auth.slice";
import { logOutAnalytics } from '../store/analytics.slice';
import { logOutInfo } from '../store/info.slice';
import { logOutMoney } from '../store/money.slice';
import { logOutNonTrade } from '../store/nonTrade.orders.slice';
import { logOutRepayment } from '../store/repayments.slice';
import { logOutOrders } from '../store/orders.slice';
import { logOutSecurities } from '../store/securities.slice';
import { pathLogo } from '../constants';

const { Header } = Layout;

export const MainHeader = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const [current, setCurrent] = useState("main");
    const { userName } = useSelector((state: TState) => state.auth);
    const switchValue = useSelector((state: TState) => state.auth.switch);
    const items: MenuProps['items'] = [
        {
            label: "Главная",
            key: "main"
        },
        {
            label: "Торговля",
            key: "trade"
        },
        {
            label: "РЕПО",
            key: "repo"
        },
        {
            label: "Деньги",
            key: "money"
        },
        {
            label: "Информационные данные",
            key: "reports",
        },
        {
            label: "Отчеты",
            key: "reports-test"
        },
        {
            label: "Заказы и приказы",
            key: "orders",
        },
        {
            label: "Аналитика",
            key: "analytics",
        }
    ];
    useEffect(() => {
        let key = location.pathname.replace("/", "");
        if (key === "") key = "main";
        if (key !== current) {
            setCurrent(key);
        }
    }, [location])
    const onClick: MenuProps['onClick'] = e => {
        let key = e.key;
        setCurrent(key);
        if (key === "main") key = "";

        navigate(`/${key}`, { replace: true })
    }
    const onLogOut = async () => {
        await dispatch(logOut());
        await dispatch(logOutAnalytics());
        await dispatch(logOutInfo());
        await dispatch(logOutMoney());
        await dispatch(logOutNonTrade());
        await dispatch(logOutRepayment());
        await dispatch(logOutOrders());
        await dispatch(logOutSecurities());
        navigate("/")
    }

    const handleSwitchChange = () => {
        dispatch(toogleSwitch());
    };
    const profile = (
        <div>
            <div>_position_</div>
            <div>_org_name_</div>
        </div>
    )
    return (
        <Header className="main-header">
            <div className="brand"><img src={pathLogo} alt="fhs" /></div>
            <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
            <div className="profile">
                <Space style={{ marginRight: '20px' }} direction="vertical">
                    <Switch checked={switchValue} onChange={handleSwitchChange} checkedChildren="CORE" unCheckedChildren="AIS" />
                </Space>
                <Tooltip className="user" title={profile}>
                    <div>{userName}</div>
                </Tooltip>
                <Button onClick={onLogOut}>Выйти</Button>
            </div>
        </Header>
    )
}
