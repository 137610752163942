/**
 * @const IN - Format Integer to the thousand groups
 */
const IN = new Intl.NumberFormat('ru-RU', {minimumFractionDigits: 0});
/**
 * @const INF - Format Float to the thousand groups and 2 decimals
 */
const INF = new Intl.NumberFormat('ru-RU', {minimumFractionDigits: 2, maximumFractionDigits: 2});
const INF4 = new Intl.NumberFormat('ru-RU', {minimumFractionDigits: 4, maximumFractionDigits: 4});
const splitThousands = (num: string): string => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
export const parsePrice = (num: string): number => {
    return parseFloat(num.replace(/\$\s?|( *)/g, '').replace(',','.'))
}
export const formatPrice = (num: number | string, d: number = 2): string => {
    if(!num) return "";
    let numStr: string = typeof num === 'number' ? num.toString(10) : num;
        const [integer,fraction] = numStr.split(".");
        let fractionPart: string;
        if(!fraction){
            fractionPart = "";
        } else if(fraction && fraction.length <= d) {
            fractionPart = `,${fraction}`
        } else {
            fractionPart = `,${parseFloat(numStr).toFixed(d).split(".")[1]}`
        }
        return splitThousands(integer) + fractionPart;

}
export const finNum = (num: number): string => {
    return INF.format(num)
}
export const finNum4 = (num: number): string => {
    return INF4.format(num);
}
export const countNum = (num: number): string => {
    return IN.format(num)
}
export const parseNum = (num: string, d:number = 0): number => {
    const cleanNum = num.replace(/\s/igm,"").replace(",",".");
    if(d === 0){
        return parseInt(cleanNum, 10);
    } else {
        return parseFloat(parseFloat(cleanNum).toFixed(d));
    }
}
//
const setZeroPrefix = (numString: string): string => {
    if(numString.length < 2){
        return "0" + numString;
    } else {
        return numString;
    }
}
export const parseDate = (dateString: string): Date => {
    const [date,time] = dateString.split(" ");
    let [dd,mm,yyyy] = date.split(".");
    const datePart = `${yyyy}-${setZeroPrefix(mm)}-${setZeroPrefix(dd)}`;
    const timePart = !time ? "" : `T${time}+06:00`;
    return new Date(`${datePart}${timePart}`)
}