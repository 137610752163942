import React from 'react';
import { useSelector } from 'react-redux';
import { TState } from '../../store';
import { closePortfolioSide } from '../../store/securities.slice';
import { SecuritySide } from './SecutirySide';

export const PortfolioSide = () => {
    const {
        securities: {
            portfolioSideInfo,
            portfolioSideDisplayed
        }
    } = useSelector((state: TState) => state);

    const depthType: string = "Portfolio";

    return (
        <SecuritySide
            securityInfo={portfolioSideInfo}
            displayCondition={portfolioSideDisplayed}
            closeFunction={closePortfolioSide}
            depthType={depthType}
        />
    )
};