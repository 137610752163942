import React, { FC } from "react";
import { Card, Col, Row, Tag } from "antd";
import { TMarketSecurity } from "../../../types/api";
import {
    EMoneyTransferType,
    ENonTradeOrderType,
    EOrderTemplateStatus,
    ESecuritiesTransferType,
    ETradeOrderType,
    TCancelOrderRequest,
    TMoneyTransferRequest,
    TOrderStatusFull,
    TOrderTemplate,
    TPutOrderBondsRequest,
    TPutOrderEquitiesRequest,
    TPutOrderRepoRequest,
    TSecuritiesTransferRequest
} from "../../../types/orders";
import { countNum, finNum, formatPrice } from "../../../services/formatter";

export const TemplateListItem: FC<{ template: TOrderTemplate, lib: TMarketSecurity[], orders: TOrderStatusFull[], selected: boolean }> = ({
    template,
    lib,
    orders,
    selected = false
}) => {
    const { author, signer, orderDate, orderData, orderType, status, updatedAt, extraData } = template;
    const getTradeTitle = () => {
        const {
            dealKindId,
            securityId,
            quantity,
            localBool
        } = orderData as TPutOrderEquitiesRequest | TPutOrderBondsRequest;
        const security = lib.find(sec => sec.securityId === securityId && sec.localBool === localBool);
        return `ЦБ ${dealKindId === 1 ? 'Покупка' : 'Продажа'} ${security?.ticker} ${countNum(quantity)}`
    }
    const getRepoTitle = () => {
        const { dealKindId, currency, amount } = orderData as TPutOrderRepoRequest;
        return `РЕПО ${dealKindId === 1 ? 'Размещение' : 'Привлечение'} ${currency} ${formatPrice(amount)}`
    }
    const getTradeCancelTitle = () => {
        const { orderId } = orderData as TCancelOrderRequest;
        const orderToCancel = orders.find(o => o.clientOrderId === orderId)
        return `Отмена заказа/приказа ${orderToCancel?.orderNumber || "ID " + orderToCancel?.clientOrderId}`
    }
    const getMoneyTransferTitle = () => {
        const { transferTypeID, amount } = orderData as TMoneyTransferRequest;
        const { currencyCode } = extraData;
        switch (transferTypeID) {
            case EMoneyTransferType.conversion: return `Конвертация ДС ${currencyCode} ${finNum(amount)}`;
            case EMoneyTransferType.internal: return `Перевод ДС (внутренний) ${currencyCode} ${finNum(amount)}`;
            case EMoneyTransferType.internalKACD: return `Перевод ДС (внутренний) ${currencyCode} ${finNum(amount)}`;
            case EMoneyTransferType.external: return `Перевод ДС (внешний) ${currencyCode} ${finNum(amount)}`;
        }
    }
    const getSecurityTransferTitle = () => {
        const { transferTypeID, qty } = orderData as TSecuritiesTransferRequest;
        const { security } = extraData;
        switch (transferTypeID) {
            case ESecuritiesTransferType.internalTransfer:
                return `Внутренний перевод ЦБ ${security.tradeCode} ${countNum(qty)}`;
            case ESecuritiesTransferType.externalTransferSelfOut:
            case ESecuritiesTransferType.externalTransferSelfIn:
            case ESecuritiesTransferType.externalTransferOtherOut:
            case ESecuritiesTransferType.externalTransferOtherIn:
            case ESecuritiesTransferType.externalTransferSell:
            case ESecuritiesTransferType.externalTransferBuy:
                return `Внешний перевод ЦБ ${security.tradeCode} ${countNum(qty)}`;
        }
    }
    const getTitle = () => {
        switch (orderType) {
            case ETradeOrderType.trade:
                return getTradeTitle();
            case ETradeOrderType.repo:
                return getRepoTitle();
            case ETradeOrderType.cancel:
                return getTradeCancelTitle();
            case ENonTradeOrderType.moneyTransferInternal:
                return getMoneyTransferTitle();
            case ENonTradeOrderType.moneyTransferExternal:
                return getMoneyTransferTitle();
            case ENonTradeOrderType.securityTransferInternal:
                return getSecurityTransferTitle();
            case ENonTradeOrderType.securityTransferExternal:
                return getSecurityTransferTitle();
        }
    }
    const getStatus = () => {
        switch (status) {
            case EOrderTemplateStatus.pending:
                return <Tag color="var(--fhs-blue)">На подписании</Tag>;
            case EOrderTemplateStatus.confirmed:
                return <Tag color="var(--fhs-green)">Подписан</Tag>;
            case EOrderTemplateStatus.rejected:
                return <Tag color="var(--fhs-blue-light)">Отклонен</Tag>;
            case EOrderTemplateStatus.error:
                return <Tag color="var(--fhs-red)">Ошибка</Tag>;
        }
    }

    return (
        <Card
            size="small"
            hoverable
            title={getTitle()}
            bodyStyle={{ fontSize: 13 }}
            style={{ width: '100%', outline: selected ? "#1890ff solid 1px" : "none" }}
        >
            <Row>
                <Col span={24}><p>{getStatus()}</p></Col>
            </Row>
            <Row>
                <Col span={12}>
                    <p>Создан: {new Date(orderDate).toLocaleTimeString()}</p>
                    <p>Автор: {author}</p>
                </Col>
                <Col span={12}>
                    {signer && <>
                        <p>Подписан: {new Date(updatedAt).toLocaleTimeString()}</p>
                        <p>{signer}</p>
                    </>}
                </Col>
            </Row>

        </Card>
    )
}