import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Divider, InputNumber, Modal, notification, Radio, Row, Select } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { dispatch, TState } from '../../store';
import { closeRepoCorpOrder, closeRepoOrder, getOrders } from '../../store/orders.slice';
import { apiConnector } from '../../integrations/api.connector';
import { NcaLayerConnector } from '../../integrations/ncaLayer.connector';
import { QuotesConnector } from "../../integrations/quotes.connector";
import { TMarketSecurity, TSubAccount } from '../../types/api';
import { ECurrency } from '../../types/common';
import { NcaMessage } from '../../types/nca';
import {
    DealDirection,
    DealKind, ECheckReqType, EOrderKind,
    EPDFOrderType, EPutOrderType, ETradeOrderType,
    OrderType,
    TPutOrderRepoRequest,
    TSetOrderTemplate
} from '../../types/orders';
import { EQuoteType } from "../../types/quotes";
import { BuyIcon } from '../shared/icons/buy.icon';
import { SellIcon } from '../shared/icons/sell.icon';
import { Quote } from "../market/Quote";
import { ModalDS } from '../shared/modals/ModalDS'
import { countNum, finNum, formatPrice, parseNum, parsePrice } from '../../services/formatter';
import { getRepoMode, isCompany, isHeadOfCompany, repoODGU } from "../../services/helpers";
import { timeForErrorNotification, timeForSuccessNotification, timeForWarningNotification } from '../../constants';
import "./editedForm.css";

export const RepoCorpPostForm = () => {
    const {
        auth: {
            userName,
            iin
        },
        orders: {
            showRepoCorpModal,
            repoTypes,
            repoDays
        },
        money: {
            subAccountsLocal
        },
        securities: {
            repoList
        }
    } = useSelector((state: TState) => state);
    const switchValue = useSelector((state: TState) => state.auth.switch);
    const accounts: TSubAccount[] = [...subAccountsLocal];
    // REPOS
    repoTypes.filter(rt => (rt.tradeModeCode.indexOf("EB") === 0));
    repoTypes.filter(rt => (rt.tradeModeCode.indexOf("EL") === 0));
    const [orderDirection, setOrderDirection] = useState<DealDirection>(DealDirection.buy);
    const [orderType, setOrderType] = useState<OrderType>(OrderType.limited);
    const [estimate, setEstimate] = useState<number>(0);
    const [amount_, setAmount] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const [account, setAccount] = useState<TSubAccount>(accounts[0]);
    const [step, setStep] = useState<"form" | "sign" | "error">('form');
    // const [tradeDay, setTradeDay] = useState(moment())
    const [params, setParams] = useState<TPutOrderRepoRequest | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [orderForm, setOrderForm] = useState<string>();
    const [currency, setCurrency] = useState<ECurrency>(ECurrency.KZT);
    const [CK, setCK] = useState(true);
    const [daysOptions, setDaysOptions] = useState<number[]>([]);
    const [days, setDays] = useState<number>(repoDays);
    const [basketId, setBasketId] = useState<number>(CK ? 3 : 1);
    const [securityId, setSecurityId] = useState<number | null>(null);
    const [basketOptions, setBasketOptions] = useState<Partial<TMarketSecurity>[]>([])
    const [secBoard, setSecBoard] = useState<string>();
    const [extraData, setExtraData] = useState<{ repoCloseDate: string, repoCloseAmount: number }>();
    const [quotKey, setQuotKey] = useState<string | null>(null)
    const [sending, setSending] = useState<boolean>(false)
    const getTitle = () => {
        return (
            <>
                {
                    orderDirection === DealDirection.sell ?
                        <BuyIcon /> : <SellIcon />

                }
                <span className="deal-direction">
                    {
                        orderDirection === DealDirection.sell ?
                            "Привлечь" : "Разместить"
                    }
                </span>
            </>
        )
    }
    const handleOk = () => {
        setStep("form")
        dispatch(closeRepoCorpOrder())
    }
    const handleCancel = () => {
        setStep("form")
        dispatch(closeRepoCorpOrder())
    }

    const options = [
        { label: 'Разместить', value: DealDirection.buy },
        { label: 'Привлечь', value: DealDirection.sell, disabled: true },
    ]
    const currencies = [
        { label: "KZT", value: ECurrency.KZT },
        { label: "USD", value: ECurrency.USD }
    ]
    useEffect(() => {
        !account && setAccount(subAccountsLocal[0])
    }, [account])
    const getDoc = async () => {
        const p = await apiConnector.getPDF({
            id: "",
            xmlData: orderForm!,
            orderId: "_____",
            orderDate: new Date().toISOString(),
            orderNumber: "_____",
            orderType: EPDFOrderType.repo
        });
        downloadPDF(p, 0);
    }
    const downloadPDF = (p: ArrayBuffer, clientOrderId: number) => {
        const file = new File([p], `${clientOrderId}.pdf`, { type: 'application/pdf' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${clientOrderId}.pdf`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
        console.log({ file, link });
    }
    const formOrder = async () => {
        console.log({ account })
        if (securityId === null) {
            notification.warn({
                duration: timeForWarningNotification,
                message: "Выберите инструмент!"
            })
        }
        if (account && securityId !== null) {
            let dealType: DealKind;
            switch (orderDirection) {
                case DealDirection.repoBuy:
                    dealType = DealKind.buy;
                    break;
                case DealDirection.buy:
                    dealType = DealKind.buy;
                    break;
                case DealDirection.repoSell:
                    dealType = DealKind.sell;
                    break;
                case DealDirection.sell:
                    dealType = DealKind.sell;
                    break;
                default:
                    dealType = DealKind.buy;
            }
            new URLSearchParams();
            let newOrder: TPutOrderRepoRequest = {
                signature: '',
                dealKindId: dealType,
                secBoard: secBoard || "",
                repoBasketId: securityId === 0 ? 2 : "",
                imContractId: account.imContractId,
                orderTypeId: orderType,
                price: price,
                amount: amount_,
                repoCloseAmount: 0,
                repoCloseDate: '',
                currency: currency,
                dataForSign: '',
                securityId: securityId !== 0 ? securityId : ""
            };
            setParams(newOrder);
            const { dataForSign, errorMessage, repoCloseDate, repoCloseAmount } = await apiConnector.getDataForSignRepo(newOrder);
            console.log({ dataForSign, errorMessage });
            if (dataForSign && !errorMessage) {
                setOrderForm(dataForSign);
                setExtraData({
                    repoCloseAmount,
                    repoCloseDate
                })
                setStep("sign");
            } else {
                console.log(errorMessage)
                setError(errorMessage)
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: errorMessage
                })
            }
        }
    }
    const signOrder = async () => {
        setSending(true);
        let method: string;
        let args: {};
        if (NcaLayerConnector.getBundleVersion()) {
            method = "signXmlV2"
            args = { xml: orderForm, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password }
        } else {
            method = "signXml"
            args = { xml: orderForm }
        }
        const message = new NcaMessage(method, args);

        if (certificate !== '' || password !== '') {
            try {
                const signature: string = await NcaLayerConnector.callNcaMethod(message);
                if (signature && params && orderForm) {
                    const { repoCloseDate, repoCloseAmount } = extraData!;
                    const paramsUpdated = { ...params, dataForSign: signature, repoCloseAmount, repoCloseDate };
                    setParams(paramsUpdated);
                    const orderType = EPutOrderType.repo;
                    const repoOrderData = { orderData: paramsUpdated, orderType: orderType };
                    const reqType = ECheckReqType.repoReq;
                    const ot = undefined;
                    const { success, details } = await apiConnector.checkRequest(repoOrderData, reqType, ot, switchValue);
                    // const { success, details } = await apiConnector.putOrderRepos(repoOrderData, switchValue);
                    if (success === false) {
                        setError(details);
                        notification.error({
                            duration: timeForErrorNotification,
                            message: "Ошибка!",
                            description: details
                        })
                    } else {
                        dispatch(getOrders())
                        dispatch(closeRepoOrder())
                        notification.success({
                            duration: timeForSuccessNotification,
                            message: "Заказ отправлен!",
                            // description: errorMessage
                        })
                    }
                } else {
                    setError('Не удалось отправить заказ');
                    notification.error({
                        duration: timeForErrorNotification,
                        message: "Ошибка!",
                        description: "Не удалось отправить заказ"
                    })
                }
            } catch (e) {
                console.log(e);
                setSignError('error');
                setError('Не удалось подписать заказ');
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: "Не удалось подписать заказ"
                })
            } finally {
                setSending(false);
            }
        } else {
            notification.error({
                duration: timeForErrorNotification,
                message: "Ошибка!",
                description: "Нужно указать путь до ключа и ввести пароль"
            });
        }
    }
    const pendOrder = async () => {
        if (params && orderForm) {
            const { repoCloseDate, repoCloseAmount } = extraData!;
            const orderData: Partial<TPutOrderRepoRequest> = { ...params, repoCloseAmount, repoCloseDate };
            const orderTemplate: TSetOrderTemplate = {
                orderKind: EOrderKind.trade,
                orderType: ETradeOrderType.repo,
                orderData,
                orderXml: orderForm,
                author: userName || "н/д",
                authorIIN: iin || "н/д",
                extraData: { repoType: "CORP", repoDays: days }
            };
            try {
                const result = await apiConnector.checkTemplate(orderTemplate);
                console.log(result);
                dispatch(closeRepoOrder());
            } catch (e) {
                console.log(e);
                setError('Не удалось отправить заказ');
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: "Не удалось отправить заказ"
                });
            } finally {
                setSending(false);
            }
        }
    }
    const handleAccount = (val: string) => {
        const acc = getAccList().find(a => a.imContractId === parseInt(val))
        console.log({ acc })
        acc && setAccount(acc);
    }
    const getAccList = (): TSubAccount[] => {
        return subAccountsLocal || [];
    }
    // set day
    useEffect(() => {
        setSecurityId(null)
        if (currency === ECurrency.USD) {
            setDaysOptions([1, 7, 14, 30]);
            // if(days > 7){
            //     setDays(7)
            // } else
            if (days > 1 && days < 7) {
                setDays(1)
            }
        } else if (currency === ECurrency.KZT) {
            setDaysOptions([1, 2, 3, 7, 14, 30])
        }
    }, [currency])
    useEffect(() => {
        const tradeModeCode = getRepoMode(currency, days);
        setSecBoard(tradeModeCode);
        const record = repoList.find(rl => rl.tradeModeCode === tradeModeCode);
        if (record) {
            if (currency === ECurrency.KZT) {
                setBasketOptions([{ securityId: 0, ticker: "Корзина ОДГУ" }, ...record.list])
            } else {
                setBasketOptions(record.list)
            }
        }
        if (securityId === 0) {
            setSecBoard(repoODGU.get(days))
        }
    }, [currency, daysOptions, days, securityId])
    useEffect(() => {
        const est = ((price / 36500 * days) + 1) * amount_;
        setEstimate(parseNum(est.toFixed(2), 2));
    }, [days, CK, amount_, price])
    useEffect(() => {
        quotKey && QuotesConnector.watch(quotKey);
    }, [quotKey])
    useEffect(() => {
        if (securityId === 0) {
            setQuotKey(`${secBoard}-BSP`)
        } else {
            const tCode = basketOptions.find(bo => bo.securityId === securityId);
            tCode && tCode.ticker && setQuotKey(`${secBoard}-${tCode!.ticker}`)
        }
    }, [secBoard, securityId]);
    // reset on data change
    useEffect(() => {
        if (step === "sign") {
            setStep("form");
            setOrderForm(undefined);
        }
    }, [orderDirection, days, price, amount_, securityId])

    const [password, setPassword] = useState<string>('');
    const [certificate, setCertificate] = useState<string>("");
    const [signError, setSignError] = useState<string | null>(null);

    return <>
        <Modal
            title={getTitle()}
            visible={showRepoCorpModal}
            onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={420}
            bodyStyle={{ paddingTop: 12 }}
            className="edited-form"
            zIndex={5}
        >
            <Row className="of-row">
                <Col span={8}>
                    Направление:
                </Col>
                <Col span={16}>
                    <Radio.Group
                        className="radio-wide deal-type"
                        options={options}
                        value={orderDirection}
                        optionType="button"
                        buttonStyle="solid"
                        size="small"
                        onChange={e => setOrderDirection(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Валюта:
                </Col>
                <Col span={16}>
                    <Radio.Group
                        className="radio-wide deal-type"
                        options={currencies}
                        value={currency}
                        optionType="button"
                        buttonStyle="solid"
                        size="small"
                        onChange={e => setCurrency(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>Кол-во дней:</Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={days}
                        defaultValue={1}
                        onChange={v => setDays(v)}>
                        {
                            daysOptions.map((d, i) => (
                                <Select.Option className="of-option" key={`${i}`}
                                    value={d}>{d}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>Инструмент:</Col>
                <Col span={16}>
                    <Select className="of-select repo-basket" size="small"
                        value={securityId}
                        onChange={v => setSecurityId(v)}>
                        {
                            basketOptions && basketOptions.map((b, i) => (
                                <Select.Option className="of-option" key={`${i}`}
                                    value={b.securityId}>{b.ticker}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="bordered-row quotes-row">
                <div className="quotes-row-col">
                    <label>last</label>
                    <span><Quote secKey={quotKey} type={EQuoteType.last} /></span>
                </div>
                <div className="quotes-row-col">
                    <label>bid</label>
                    <span><Quote secKey={quotKey} type={EQuoteType.bid} /></span>
                </div>
                <div className="quotes-row-col">
                    <label>ask</label>
                    <span><Quote secKey={quotKey} type={EQuoteType.ask} /></span>
                </div>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Доходность:
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={price}
                        onChange={p => setPrice(p || 0)}
                        size="small" className="of-input deal-yield"
                        formatter={v => formatPrice(v!, 4)}
                        step=".01"
                        parser={v => parsePrice(v!)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Сумма открытия:
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={amount_}
                        onChange={p => setAmount(p || 0)}
                        size="small" className="of-input repo-amount-open"
                        formatter={v => countNum(v as number)}
                        parser={v => parseNum(v as string, 2)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Сумма закрытия:
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={estimate}
                        size="small" className="of-input repo-amount-close"
                        formatter={v => finNum(v as number)}
                        parser={v => parseNum(v as string, 2)}
                        readOnly
                    />
                </Col>
            </Row>
            <Divider />
            {
                orderForm &&
                <Row>
                    <Button target="_blank" onClick={getDoc} block type="link"
                        icon={<FilePdfFilled color="var(--fhs-red)" />}>Открыть заказ</Button>
                </Row>
            }
            <Divider />
            <Row>
                {step === "form" && <Button disabled={false} onClick={formOrder} type="primary" block>Оформить заказ</Button>}
                {step === "sign" &&
                    <>
                        {isCompany() && !isHeadOfCompany() && <Button loading={sending} onClick={pendOrder} type="primary" block>Отправить на
                            согласование</Button>}
                    </>}
            </Row>
            <ModalDS
                signError={signError}
                step={step}
                password={password}
                certificate={certificate}
                setCertificate={setCertificate}
                setPassword={setPassword}
                signOrder={signOrder}
                isDisable={false}
                sending={sending}
                OT={false}
                isActual={undefined}
                loading={false}
                onConfirm={undefined}
                template={undefined}
                isSigners={false}
            />
        </Modal>
    </>
}



export const RepoCorpAttractForm = () => {
    const {
        auth: {
            userName,
            iin
        },
        orders: {
            showRepoCorpModal,
            repoTypes,
            repoDays
        },
        money: {
            subAccountsLocal
        },
        securities: {
            repoList
        }
    } = useSelector((state: TState) => state);
    const accounts: TSubAccount[] = [...subAccountsLocal];
    // REPOS
    repoTypes.filter(rt => (rt.tradeModeCode.indexOf("EB") === 0));
    repoTypes.filter(rt => (rt.tradeModeCode.indexOf("EL") === 0));
    const [orderDirection, setOrderDirection] = useState<DealDirection>(DealDirection.sell);
    const [orderType, setOrderType] = useState<OrderType>(OrderType.limited);
    const [estimate, setEstimate] = useState<number>(0);
    const [amount_, setAmount] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const [account, setAccount] = useState<TSubAccount>(accounts[0]);
    const [step, setStep] = useState<"form" | "sign" | "error">('form');
    // const [tradeDay, setTradeDay] = useState(moment())
    const [params, setParams] = useState<TPutOrderRepoRequest | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [orderForm, setOrderForm] = useState<string>();
    const [currency, setCurrency] = useState<ECurrency>(ECurrency.KZT);
    const [CK, setCK] = useState(true);
    const [daysOptions, setDaysOptions] = useState<number[]>([]);
    const [days, setDays] = useState<number>(repoDays);
    const [basketId, setBasketId] = useState<number>(CK ? 3 : 1);
    const [securityId, setSecurityId] = useState<number | null>(null);
    const [basketOptions, setBasketOptions] = useState<Partial<TMarketSecurity>[]>([])
    const [secBoard, setSecBoard] = useState<string>();
    const [extraData, setExtraData] = useState<{ repoCloseDate: string, repoCloseAmount: number }>();
    const [quotKey, setQuotKey] = useState<string | null>(null)
    const [sending, setSending] = useState<boolean>(false)
    const getTitle = () => {
        return (
            <>
                {
                    orderDirection === DealDirection.sell ?
                        <BuyIcon /> : <SellIcon />

                }
                <span className="deal-direction">
                    {
                        orderDirection === DealDirection.sell ?
                            "Привлечь" : "Разместить"
                    }
                </span>
            </>
        )
    }
    const handleOk = () => {
        setStep("form")
        dispatch(closeRepoCorpOrder())
    }
    const handleCancel = () => {
        setStep("form")
        dispatch(closeRepoCorpOrder())
    }

    const options = [
        { label: 'Разместить', value: DealDirection.buy, disabled: true },
        { label: 'Привлечь', value: DealDirection.sell },
    ]
    const currencies = [
        { label: "KZT", value: ECurrency.KZT },
        { label: "USD", value: ECurrency.USD }
    ]
    useEffect(() => {
        !account && setAccount(subAccountsLocal[0])
    }, [account])
    const getDoc = async () => {
        const p = await apiConnector.getPDF({
            id: "",
            xmlData: orderForm!,
            orderId: "_____",
            orderDate: new Date().toISOString(),
            orderNumber: "_____",
            orderType: EPDFOrderType.repo
        });
        downloadPDF(p, 0);
    }
    const downloadPDF = (p: ArrayBuffer, clientOrderId: number) => {
        const file = new File([p], `${clientOrderId}.pdf`, { type: 'application/pdf' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${clientOrderId}.pdf`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
        console.log({ file, link });
    }
    const formOrder = async () => {
        console.log({ account })
        if (securityId === null) {
            notification.warn({
                duration: timeForWarningNotification,
                message: "Выберите инструмент!"
            })
        }
        if (account && securityId !== null) {
            let dealType: DealKind;
            switch (orderDirection) {
                case DealDirection.repoBuy:
                    dealType = DealKind.buy;
                    break;
                case DealDirection.buy:
                    dealType = DealKind.buy;
                    break;
                case DealDirection.repoSell:
                    dealType = DealKind.sell;
                    break;
                case DealDirection.sell:
                    dealType = DealKind.sell;
                    break;
                default:
                    dealType = DealKind.buy;
            }
            new URLSearchParams();
            let newOrder: TPutOrderRepoRequest = {
                signature: '',
                dealKindId: dealType,
                secBoard: secBoard || "",
                repoBasketId: securityId === 0 ? 2 : "",
                imContractId: account.imContractId,
                orderTypeId: orderType,
                price: price,
                amount: amount_,
                repoCloseAmount: 0,
                repoCloseDate: '',
                currency: currency,
                dataForSign: '',
                securityId: securityId !== 0 ? securityId : ""
            };
            setParams(newOrder);
            const { dataForSign, errorMessage, repoCloseDate, repoCloseAmount } = await apiConnector.getDataForSignRepo(newOrder);
            console.log({ dataForSign, errorMessage });
            if (dataForSign && !errorMessage) {
                setOrderForm(dataForSign);
                setExtraData({
                    repoCloseAmount,
                    repoCloseDate
                })
                setStep("sign");
            } else {
                console.log(errorMessage)
                setError(errorMessage)
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: errorMessage
                })
            }
        }
    }
    const signOrder = async () => {
        setSending(true);
        let method: string;
        let args: {};
        if (NcaLayerConnector.getBundleVersion()) {
            method = "signXmlV2"
            args = { xml: orderForm, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password }
        } else {
            method = "signXml"
            args = { xml: orderForm }
        }
        const message = new NcaMessage(method, args);

        if (certificate !== '' || password !== '') {
            try {
                const signature: string = await NcaLayerConnector.callNcaMethod(message);
                if (signature && params && orderForm) {
                    const { repoCloseDate, repoCloseAmount } = extraData!;
                    const paramsUpdated = { ...params, dataForSign: signature, repoCloseAmount, repoCloseDate };
                    setParams(paramsUpdated);
                    const orderType = EPutOrderType.repo;
                    const repoOrderData = { orderData: paramsUpdated, orderType: orderType };
                    const reqType = ECheckReqType.repoReq;
                    const { success, details } = await apiConnector.checkRequest(repoOrderData, reqType);
                    // const { success, details } = await apiConnector.putOrderRepos(repoOrderData);

                    if (success === false) {
                        setError(details);
                        notification.error({
                            duration: timeForErrorNotification,
                            message: "Ошибка!",
                            description: details
                        })
                    } else {
                        dispatch(getOrders());
                        dispatch(closeRepoOrder());
                        notification.success({
                            duration: timeForSuccessNotification,
                            message: "Заказ отправлен!",
                            // description: errorMessage
                        });
                    }
                } else {
                    setError('Не удалось отправить заказ');
                    notification.error({
                        duration: timeForErrorNotification,
                        message: "Ошибка!",
                        description: "Не удалось отправить заказ"
                    })
                }
            } catch (e) {
                console.log(e);
                setSignError('error');
                setError('Не удалось подписать заказ');
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: "Не удалось подписать заказ"
                })
            } finally {
                setSending(false);
            }
        } else {
            notification.error({
                duration: timeForErrorNotification,
                message: "Ошибка!",
                description: "Нужно указать путь до ключа и ввести пароль"
            });
        }
    }
    const pendOrder = async () => {
        if (params && orderForm) {
            const { repoCloseDate, repoCloseAmount } = extraData!;
            const orderData: Partial<TPutOrderRepoRequest> = { ...params, repoCloseAmount, repoCloseDate };
            const orderTemplate: TSetOrderTemplate = {
                orderKind: EOrderKind.trade,
                orderType: ETradeOrderType.repo,
                orderData,
                orderXml: orderForm,
                author: userName || "н/д",
                authorIIN: iin || "н/д",
                extraData: { repoType: "CORP", repoDays: days }
            };
            try {
                const result = await apiConnector.checkTemplate(orderTemplate);
                console.log(result);
                dispatch(closeRepoOrder());
            } catch (e) {
                console.log(e);
                setError('Не удалось отправить заказ');
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: "Не удалось отправить заказ"
                });
            } finally {
                setSending(false);
            }
        }
    }
    const handleAccount = (val: string) => {
        const acc = getAccList().find(a => a.imContractId === parseInt(val))
        console.log({ acc })
        acc && setAccount(acc);
    }
    const getAccList = (): TSubAccount[] => {
        return subAccountsLocal || [];
    }
    // set day
    useEffect(() => {
        setSecurityId(null)
        if (currency === ECurrency.USD) {
            setDaysOptions([1, 7, 14, 30]);
            // if(days > 7){
            //     setDays(7)
            // } else
            if (days > 1 && days < 7) {
                setDays(1)
            }
        } else if (currency === ECurrency.KZT) {
            setDaysOptions([1, 2, 3, 7, 14, 30])
        }
    }, [currency])
    useEffect(() => {
        const tradeModeCode = getRepoMode(currency, days);
        setSecBoard(tradeModeCode);
        const record = repoList.find(rl => rl.tradeModeCode === tradeModeCode);
        if (record) {
            if (currency === ECurrency.KZT) {
                setBasketOptions([{ securityId: 0, ticker: "Корзина ОДГУ" }, ...record.list])
            } else {
                setBasketOptions(record.list)
            }
        }
        if (securityId === 0) {
            setSecBoard(repoODGU.get(days))
        }
    }, [currency, daysOptions, days, securityId])
    useEffect(() => {
        const est = ((price / 36500 * days) + 1) * amount_;
        setEstimate(parseNum(est.toFixed(2), 2));
    }, [days, CK, amount_, price])
    useEffect(() => {
        quotKey && QuotesConnector.watch(quotKey);
    }, [quotKey])
    useEffect(() => {
        if (securityId === 0) {
            setQuotKey(`${secBoard}-BSP`)
        } else {
            const tCode = basketOptions.find(bo => bo.securityId === securityId);
            tCode && tCode.ticker && setQuotKey(`${secBoard}-${tCode!.ticker}`)
        }
    }, [secBoard, securityId]);
    // reset on data change
    useEffect(() => {
        if (step === "sign") {
            setStep("form");
            setOrderForm(undefined);
        }
    }, [orderDirection, days, price, amount_, securityId])

    const [password, setPassword] = useState<string>('');
    const [certificate, setCertificate] = useState<string>("");
    const [signError, setSignError] = useState<string | null>(null);

    return <>
        <Modal
            title={getTitle()}
            visible={showRepoCorpModal}
            onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={420}
            bodyStyle={{ paddingTop: 12 }}
            className="edited-form"
            zIndex={5}
        >
            <Row className="of-row">
                <Col span={8}>
                    Направление:
                </Col>
                <Col span={16}>
                    <Radio.Group
                        className="radio-wide deal-type"
                        options={options}
                        value={orderDirection}
                        optionType="button"
                        buttonStyle="solid"
                        size="small"
                        onChange={e => setOrderDirection(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Валюта:
                </Col>
                <Col span={16}>
                    <Radio.Group
                        className="radio-wide deal-type"
                        options={currencies}
                        value={currency}
                        optionType="button"
                        buttonStyle="solid"
                        size="small"
                        onChange={e => setCurrency(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>Кол-во дней:</Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={days}
                        defaultValue={1}
                        onChange={v => setDays(v)}>
                        {
                            daysOptions.map((d, i) => (
                                <Select.Option className="of-option" key={`${i}`}
                                    value={d}>{d}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>Инструмент:</Col>
                <Col span={16}>
                    <Select className="of-select repo-basket" size="small"
                        value={securityId}
                        onChange={v => setSecurityId(v)}>
                        {
                            basketOptions && basketOptions.map((b, i) => (
                                <Select.Option className="of-option" key={`${i}`}
                                    value={b.securityId}>{b.ticker}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="bordered-row quotes-row">
                <div className="quotes-row-col">
                    <label>last</label>
                    <span><Quote secKey={quotKey} type={EQuoteType.last} /></span>
                </div>
                <div className="quotes-row-col">
                    <label>bid</label>
                    <span><Quote secKey={quotKey} type={EQuoteType.bid} /></span>
                </div>
                <div className="quotes-row-col">
                    <label>ask</label>
                    <span><Quote secKey={quotKey} type={EQuoteType.ask} /></span>
                </div>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Доходность:
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={price}
                        onChange={p => setPrice(p || 0)}
                        size="small" className="of-input deal-yield"
                        formatter={v => formatPrice(v!, 4)}
                        step=".01"
                        parser={v => parsePrice(v!)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Сумма открытия:
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={amount_}
                        onChange={p => setAmount(p || 0)}
                        size="small" className="of-input repo-amount-open"
                        formatter={v => countNum(v as number)}
                        parser={v => parseNum(v as string, 2)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Сумма закрытия:
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={estimate}
                        size="small" className="of-input repo-amount-close"
                        formatter={v => finNum(v as number)}
                        parser={v => parseNum(v as string, 2)}
                        readOnly
                    />
                </Col>
            </Row>
            <Divider />
            {
                orderForm &&
                <Row>
                    <Button target="_blank" onClick={getDoc} block type="link"
                        icon={<FilePdfFilled color="var(--fhs-red)" />}>Открыть заказ</Button>
                </Row>
            }
            <Divider />
            <Row>
                {step === "form" && <Button disabled={false} onClick={formOrder} type="primary" block>Оформить заказ</Button>}
                {step === "sign" &&
                    <>
                        {isCompany() && !isHeadOfCompany() && <Button loading={sending} onClick={pendOrder} type="primary" block>Отправить на
                            согласование</Button>}
                    </>}
            </Row>
            <ModalDS
                signError={signError}
                step={step}
                password={password}
                certificate={certificate}
                setCertificate={setCertificate}
                setPassword={setPassword}
                signOrder={signOrder}
                isDisable={false}
                sending={sending}
                OT={false}
                isActual={undefined}
                loading={false}
                onConfirm={undefined}
                template={undefined}
                isSigners={false}
            />
        </Modal>
    </>
}


