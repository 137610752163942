import React, { FC } from 'react';
import { Table } from 'antd';
import { ColumnsType } from "antd/lib/table";
import { getFullPageTableHeight, rowClassCompact } from '../../../services/helpers';
import { TFTAccount } from '../../../types/api';

export const TableMoneyAll: FC<{ accounts: TFTAccount[], columns: ColumnsType<any>, loading: boolean }> = ({ accounts, columns, loading }) => (<Table
    size="small"
    showSorterTooltip={false}
    rowClassName={rowClassCompact}
    scroll={{ y: getFullPageTableHeight() - 100 }}
    dataSource={accounts}
    columns={columns}
    pagination={false}
    loading={loading} />)
