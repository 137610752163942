import { apiConnector } from "../../../integrations/api.connector";
import { NcaLayerConnector } from "../../../integrations/ncaLayer.connector";
import { ECheckReqType, ENonTradeOrderType, EPutOrderType, ETradeOrderType, TOrderTemplate } from "../../../types/orders";
import { NcaMessage } from "../../../types/nca";

export const signPendingOrder = (ot: TOrderTemplate, { password, certificate }: { password: string, certificate: string }) => {
    switch (ot.orderType) {
        case ETradeOrderType.trade: return signTradeOrder(ot, { password, certificate });
        case ETradeOrderType.repo: return signRepoOrder(ot, { password, certificate });
        case ETradeOrderType.cancel: return signTradeCancelOrder(ot, { password, certificate });
        case ENonTradeOrderType.moneyTransferInternal: return signMoneyTransferOrder(ot, { password, certificate });
        case ENonTradeOrderType.moneyTransferExternal: return signMoneyTransferOrder(ot, { password, certificate });
        case ENonTradeOrderType.securityTransferInternal: return signSecurityTransferOrder(ot, { password, certificate });
        case ENonTradeOrderType.securityTransferExternal: return signSecurityTransferOrder(ot, { password, certificate });
        case ENonTradeOrderType.cancel: return signNonTradeCancelOrder(ot, { password, certificate });
    }
}

export const signTradeOrder = async (ot: TOrderTemplate, { password, certificate }: { password: string, certificate: string }) => {
    const { orderXml, orderData, extraData } = ot;
    const method = NcaLayerConnector.getBundleVersion() ? "signXmlV2" : "signXml";
    const args = NcaLayerConnector.getBundleVersion() ? { xml: orderXml, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password } : { xml: orderXml }
    const message = new NcaMessage(method, args);
    const orderType = extraData.isBond ? EPutOrderType.bonds : EPutOrderType.equities;
    const signature: string = await NcaLayerConnector.callNcaMethod(message);
    const params = { orderData: { ...orderData, dataForSign: signature }, orderType: orderType };

    const reqType = extraData.isBond ? ECheckReqType.bondsReq : ECheckReqType.eqReq;
    return await apiConnector.checkRequest(params, reqType, ot);
    // return extraData.isBond ?
    //     await apiConnector.putOrderBonds(params) :
    //     await apiConnector.putOrderEquities(params)
        
}
export const signRepoOrder = async (ot: TOrderTemplate, { password, certificate }: { password: string, certificate: string }) => {
    const { orderXml, orderData } = ot;
    const method = NcaLayerConnector.getBundleVersion() ? "signXmlV2" : "signXml";
    const args = NcaLayerConnector.getBundleVersion() ? { xml: orderXml, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password } : { xml: orderXml }
    const message = new NcaMessage(method, args);
    const signature: string = await NcaLayerConnector.callNcaMethod(message);
    const params = { orderData: { ...orderData, dataForSign: signature }, orderType: EPutOrderType.repo };
    const reqType = ECheckReqType.repoReq;
    return await apiConnector.checkRequest(params, reqType, ot);
    // return await apiConnector.putOrderRepos(params)
}
export const signTradeCancelOrder = async (ot: TOrderTemplate, { password, certificate }: { password: string, certificate: string }) => {
    const { orderXml, orderData } = ot;
    const method = NcaLayerConnector.getBundleVersion() ? "signXmlV2" : "signXml";
    const args = NcaLayerConnector.getBundleVersion() ? { xml: orderXml, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password } : { xml: orderXml }
    const message = new NcaMessage(method, args);
    const signature: string = await NcaLayerConnector.callNcaMethod(message);
    const params = { ...orderData, dataForSign: signature };
    const reqType = ECheckReqType.tradeCancelReq;
    return await apiConnector.checkRequest(params, reqType, ot);
    // return await apiConnector.putCancelOrder(params);
}
export const signMoneyTransferOrder = async (ot: TOrderTemplate, { password, certificate }: { password: string, certificate: string }) => {
    const { orderXml, orderData } = ot;
    const method = NcaLayerConnector.getBundleVersion() ? "signXmlV2" : "signXml";
    const args = NcaLayerConnector.getBundleVersion() ? { xml: orderXml, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password } : { xml: orderXml }
    const message = new NcaMessage(method, args);
    const signedXml: string = await NcaLayerConnector.callNcaMethod(message);
    const params = { orderData, signedXml };
    const reqType = ECheckReqType.moneyTransferReq;
    return await apiConnector.checkRequest(params, reqType, ot);
    // return await apiConnector.putMoneyTransferOrder(params);
}
export const signSecurityTransferOrder = async (ot: TOrderTemplate, { password, certificate }: { password: string, certificate: string }) => {
    const { orderXml, orderData } = ot;
    const method = NcaLayerConnector.getBundleVersion() ? "signXmlV2" : "signXml";
    const args = NcaLayerConnector.getBundleVersion() ? { xml: orderXml, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password } : { xml: orderXml }
    const message = new NcaMessage(method, args);
    const signedXml: string = await NcaLayerConnector.callNcaMethod(message);
    const params = { orderData, signedXml };
    const reqType = ECheckReqType.secTransferReq;
    return await apiConnector.checkRequest(params, reqType, ot);
    // return await apiConnector.putSecurityTransferOrder(params);
}

export const signNonTradeCancelOrder = async (ot: TOrderTemplate, { password, certificate }: { password: string, certificate: string }) => {
    const { orderXml, orderData } = ot;
    const method = NcaLayerConnector.getBundleVersion() ? "signXmlV2" : "signXml";
    const args = NcaLayerConnector.getBundleVersion() ? { xml: orderXml, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password } : { xml: orderXml }
    const message = new NcaMessage(method, args);
    const signature: string = await NcaLayerConnector.callNcaMethod(message);
    const params = { ...orderData, dataForSign: signature };
    const reqType = ECheckReqType.nonTradeCancelReq;
    return await apiConnector.checkRequest(params, reqType, ot);
}