import { notification } from "antd";
import { dispatch } from '../store';
import { ncaLayerConnected, ncaLayerNotConnected, ncaLayerSetError } from '../store/auth.slice';
import { apiConnector } from './api.connector';
import { NcaMessage } from '../types/nca';
import { timeForErrorNotification } from '../constants';

export class NcaLayerConnector {

    public static socketInstance: WebSocket;
    public static onMessage: (message: any) => void = (message) => { };
    private static socketUrl: string = "wss://127.0.0.1:13579/";
    private static error: boolean = false;
    private static readonly _bundleVersion: { value: string | null } = { value: null };

    // private static credentials: { userEmail: string, conversationId: string, userName: string };

    public static initSocket = () => {

        const socket = NcaLayerConnector.socketInstance;
        const needToReInit = socket == null || (socket.readyState === 2 || socket.readyState === 3); // closing or closed connection

        if (!needToReInit) return;

        NcaLayerConnector.socketInstance = new WebSocket(this.socketUrl, "protocolOne");
        NcaLayerConnector.socketInstance.onopen = async (event) => {
            await this.checkVersion().catch(() => console.log('bundle version <2.0.0'))
            dispatch(ncaLayerConnected())
            NcaLayerConnector.error = false;
            console.log('socket connection opened!', event, Date.now());
        }
        NcaLayerConnector.socketInstance.onclose = event => {
            dispatch(ncaLayerNotConnected())
            console.log('socket connection closed!', event, Date.now());
            NcaLayerConnector.initSocket()
        }
        NcaLayerConnector.socketInstance.onerror = event => {
            dispatch(ncaLayerNotConnected())
            console.log('socket connection error!', event, Date.now());
            NcaLayerConnector.error = true;
            NcaLayerConnector.initSocket()
        }
        NcaLayerConnector.socketInstance.onmessage = event => {
            if (event.data) {
                this.processMessage(event.data)
            }
        }
    }
    /**
     * @param socketMessage message object
     */
    public static sendMessage = async (socketMessage: any): Promise<void> => {
        console.log({ socketMessage });
        if (NcaLayerConnector.socketInstance && NcaLayerConnector.socketInstance.readyState === 1) {
            NcaLayerConnector.socketInstance.send(JSON.stringify(socketMessage));
        } else {
            // Message is queued. no error.
            // return Promise.reject('Socket not ready');
        }
        return Promise.resolve()
    }
    public static disconnect = (isLogOut = true) => {
        if (NcaLayerConnector.socketInstance) {
            NcaLayerConnector.socketInstance.close()
        }
    }
    public static callNcaMethod = (message: NcaMessage): Promise<string> => {
        return new Promise((resolve, reject) => {
            NcaLayerConnector.onMessage = (message) => {
                if (message.status && message.body.code === "200" && message.body.message) {
                    resolve(message.body.message)
                } else if (message.status && message.body.code === "500") {
                    reject()
                } else if (!message.success) {
                    dispatch(ncaLayerSetError("Установите модуль FHSCabinet!"));
                    reject()
                }
                // Remove listener
                NcaLayerConnector.onMessage = (message) => { }
            };
            NcaLayerConnector.sendMessage(message).then().catch(reason => {
                reject()
            });
        })
    }

    public static getCertificateMethod = (message: NcaMessage): Promise<any> => {
        return new Promise((resolve, reject) => {
            NcaLayerConnector.onMessage = (message) => {
                if (message.status && (message.body.path || message.body.certificateInfo)) {
                    resolve(message)
                } else if (message.status && message.body.code === "500") {
                    reject()
                } else if (!message.success) {
                    reject()
                }

                NcaLayerConnector.onMessage = (message) => { }
            }
            NcaLayerConnector.sendMessage(message).then().catch(reason => {
                console.log(reason);
                reject()
            });
        })
    }

    public static getBundleVersion(): boolean {
        return !!this._bundleVersion.value && (parseInt(this._bundleVersion.value[0]) >= 2);
    }

    private static set bundleVersion(version: string | null) {
        this._bundleVersion.value = version;
    }


    public static checkVersion = async (): Promise<string> => {
        let count = 0;
        const { textb64 } = await apiConnector.getSecretForEDS({ iin: "", bin: "" });
        const messageForVersion = new NcaMessage(
            "getTradeCabBundleVersion",
            { base64data: textb64, requestId: count + 1 }
        );
        return new Promise((resolve, reject) => {
            NcaLayerConnector.onMessage = (message) => {
                if (message.status && message.body.version) {
                    if (message.body.version) {
                        this.bundleVersion = message.body.version;
                    } else {
                        this.bundleVersion = null;
                    }
                    resolve(message.body.version)
                } else if (message.status && message.body.code === "500") {
                    reject()
                } else if (!message.success) {
                    reject()
                }

                NcaLayerConnector.onMessage = (message) => { }
            }
            NcaLayerConnector.sendMessage(messageForVersion).then().catch(reason => {
                console.log(reason);
                reject()
            });
        })
    }

    private static processMessage = (incomingMessage: string): void => {
        try {
            const parsedMessage: any = JSON.parse(incomingMessage);
            console.log({ parsedMessage });

            if (parsedMessage.result) {
                let version = parseFloat(parsedMessage.result.version);
                console.log({ version });
                if (version < 1.3) {
                    console.log("Old version")
                    notification.error({
                        message: "Обнаружена устаревшая версия",
                        description: "Обновите NCALayer",
                        duration: timeForErrorNotification
                    })
                } else {
                    console.log("Version OK!")
                    notification.success({
                        message: "NCALayer подключен",
                    })
                }
            }
            NcaLayerConnector.onMessage(parsedMessage)
        } catch (e) {
            console.log(e);
            console.log('Wrong message format');
            return;
        }
        return
    }
}
