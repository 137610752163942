import { ColumnsType } from 'antd/lib/table';
import { TMarketSecurity, TSecurityQuotes } from '../../types/api';

export const columns: ColumnsType<TMarketSecurity & { action: any, pin: any } & TSecurityQuotes> = [
    {
        title: "",
        dataIndex: 'pin',
        width: 33,
        fixed: 'left'
    },
    {
        title: "Тикер",
        dataIndex: 'ticker',
        width: 180,
        fixed: "left",
        sorter: (a, b) => a.ticker.localeCompare(b.ticker)
    },
    {
        title: "Валюта",
        fixed: "left",
        width: 100,
        dataIndex: "tradeCurrency",
        sorter: (a, b) => a.tradeCurrency.localeCompare(b.tradeCurrency)
    },
    {
        title: "Тип",
        width: 200,
        fixed: "left",
        dataIndex: "securityType",
        sorter: (a, b) => a.securityType.localeCompare(b.securityType)
    },
    {
        title: "",
        fixed: "left",
        dataIndex: "action",
        className: "action-column"
    },
    {
        title: "open",
        width: 160,
        align: "right",
        dataIndex: "open"
    },
    {
        title: "last",
        width: 160,
        align: "right",
        dataIndex: "last",
    },
    {
        title: "bid",
        width: 160,
        align: "right",
        dataIndex: "bid",
    },
    {
        title: "ask",
        width: 160,
        align: "right",
        dataIndex: "ask",
    },
    {
        title: "Рынок",
        align: "right",
        dataIndex: "depository",
        sorter: (a, b) => {
            if (a.marketCode && b.marketCode) {
                return a.marketCode.localeCompare(b.marketCode)
            } else {
                return -1
            }
        }
    },
    // {
    //     title: "Название",
    //     dataIndex: "securityName",
    //     className: 'ellipsis',
    //     sorter: (a, b) => a.securityName.localeCompare(b.securityName)
    // },
    {
        title: "ISIN",
        align: "right",
        dataIndex: "isin",
        fixed: "right",
        sorter: (a, b) => a.isin.localeCompare(b.isin)
    }
]
