import { EOrderStatus } from '../types/orders';
import { EReportType } from '../types/reports';

export const getOrderStatusName = (statusId: EOrderStatus): string => {
    let statusName = "";
    switch (statusId) {
        case EOrderStatus.confirmed:
            statusName = "подтвержден"; break;
        case EOrderStatus.processing:
            statusName = "в работе"; break;
        case EOrderStatus.onCalculation:
            statusName = "на расчетах"; break;
        case EOrderStatus.completed:
            statusName = "исполнен"; break;
        case EOrderStatus.notCompleted:
            statusName = "не исполнен"; break;
        case EOrderStatus.awaitingConfirmation:
            statusName = "ожидает подтверждения"; break;
        case EOrderStatus.canceledByClient:
            statusName = "отклонен клиентом"; break;
        case EOrderStatus.canceledBySystem:
            statusName = "отклонен системой"; break;
        case EOrderStatus.rejected:
            statusName = "отклонен"; break;
        case EOrderStatus.partlyCompleted:
            statusName = "исполнен частично"; break;
        case EOrderStatus.partlyOnCalculationAndProcessing:
            statusName = "частично на расчетах/частично в работе"; break;
        case EOrderStatus.partlyOnCalculationAndNotCompleted:
            statusName = "частично на расчетах/частично не исполнен"; break;
        case EOrderStatus.partlyOnCalculationAndCanceledByClient:
            statusName = "частично на расчетах/частично отклонен клиентом"; break;
        case EOrderStatus.partlyOnCalculationAndCanceledBySystem:
            statusName = "частично на расчетах/частично отклонено системой"; break;
        case EOrderStatus.partlyOnCalculationAndRejected:
            statusName = "частично на расчетах/частично отклонено"; break;
        case EOrderStatus.partlyCompletedAndProcessing:
            statusName = "исполнен частично/частично в работе"; break;
        case EOrderStatus.partlyCompletedAndOnCalculation:
            statusName = "исполнен частично/частично на расчетах"; break;
        case EOrderStatus.partlyCompletedAndNotCompleted:
            statusName = "исполнен частично/частично не исполнен"; break;
        case EOrderStatus.partlyCompletedAndCanceledByClient:
            statusName = "исполнен частично/частично отклонен клиентом"; break;
        case EOrderStatus.partlyCompletedAndCanceledBySystem:
            statusName = "исполнен частично/частичено отклонено системой"; break;
        case EOrderStatus.partlyCompletedAndRejected:
            statusName = "исполнен частично/частично отклонено"; break;
        default:
            statusName = "неизвестный статус"
    }
    return statusName;
}

export const getOrderStatusId = (statusName: string): EOrderStatus | string => {
    switch (statusName) {
        case "подтвержден":
            return EOrderStatus.confirmed;
        case "в работе":
            return EOrderStatus.processing;
        case "на расчетах":
            return EOrderStatus.onCalculation;
        case "исполнен":
            return EOrderStatus.completed;
        case "не исполнен":
            return EOrderStatus.notCompleted;
        case "ожидает подтверждения":
            return EOrderStatus.awaitingConfirmation;
        case "отклонен клиентом":
            return EOrderStatus.canceledByClient;
        case "отклонен системой":
            return EOrderStatus.canceledBySystem;
        case "отклонен":
            return EOrderStatus.rejected;
        case "исполнен частично":
            return EOrderStatus.partlyCompleted;
        case "частично на расчетах/частично в работе":
            return EOrderStatus.partlyOnCalculationAndProcessing;
        case "частично на расчетах/частично не исполнен":
            return EOrderStatus.partlyOnCalculationAndNotCompleted;
        case "частично на расчетах/частично отклонен клиентом":
            return EOrderStatus.partlyOnCalculationAndCanceledByClient;
        case "частично на расчетах/частично отклонено системой":
            return EOrderStatus.partlyOnCalculationAndCanceledBySystem;
        case "частично на расчетах/частично отклонено":
            return EOrderStatus.partlyOnCalculationAndRejected;
        case "исполнен частично/частично в работе":
            return EOrderStatus.partlyCompletedAndProcessing;
        case "исполнен частично/частично на расчетах":
            return EOrderStatus.partlyCompletedAndOnCalculation;
        case "исполнен частично/частично не исполнен":
            return EOrderStatus.partlyCompletedAndNotCompleted;
        case "исполнен частично/частично отклонен клиентом":
            return EOrderStatus.partlyCompletedAndCanceledByClient;
        case "исполнен частично/частичено отклонено системой":
            return EOrderStatus.partlyCompletedAndCanceledBySystem;
        case "исполнен частично/частично отклонено":
            return EOrderStatus.partlyCompletedAndRejected;
        case "неизвестный статус":
            return EOrderStatus.unknown;
        default:
            return "";
    }
}

export const getReportType = (reportType: EReportType): string => {
    let type = '';
    switch (reportType) {
        case EReportType.ALL_REPORTS:
            type = "Все отчеты"; break
        case EReportType.ACCOUNT_STATEMENT:
            type = "Выписка лицевого счета"; break
        case EReportType.CUSTOMER_REPORT:
            type = "Отчет об исполнении клиентского заказа"; break
        case EReportType.DAILY_REPORT:
            type = "Отчет о сделках и операциях за день"; break
        case EReportType.MOVES_AND_CASH_FLOW_REPORT:
            type = "Отчет о движении ЦБ и ДС"; break
        case EReportType.EXECUTION_DAILY_REPORT:
            type = "Отчет о ежедневном выполнении"; break
        default:
            type = "Неизвестный тип отчета"
    }
    return type;
}
