import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, DatePicker, Divider, Input, InputNumber, Modal, notification, Radio, Row, Select } from "antd";
import moment from "moment/moment";
import { Moment } from "moment";
import { dispatch, TState } from "../../../store";
import { closeSecuritiesTransferOrder, getOrders } from "../../../store/orders.slice";
import { apiConnector } from "../../../integrations/api.connector";
import { NcaLayerConnector } from "../../../integrations/ncaLayer.connector";
import { EIntOrderType, getIntOrderTypeName } from "../InternalOrderDetails";
import { ModalDS } from "../../shared/modals/ModalDS";
import { shortName, shortNameforHolders, isCompany, isHeadOfCompany } from "../../../services/helpers";
import { countNum, finNum, parseNum } from "../../../services/formatter";
import { buildXMLv2 } from "../../../services/xmlBuilder";
import { TMarketSecurity, TOwnSecurity, TPDFHeading } from "../../../types/api";
import { ECurrency } from "../../../types/common";
import {
    ECheckReqType,
    ENonTradeOrderType,
    EOrderKind,
    ESecuritiesTransferDirection,
    ESecuritiesTransferKind,
    ESecuritiesTransferType,
    TSecuritiesTransferRequest,
    TSetOrderTemplate
} from "../../../types/orders";
import { NcaMessage } from "../../../types/nca";
import { optionStyles, timeForErrorNotification, timeForSuccessNotification, timeForWarningNotification } from "../../../constants";
import "./../editedForm.css";

export const ExternalSecuritiesTransferForm = () => {
    const {
        auth: {
            cProfile,
            clientData,
            userName,
            iin
        },
        orders: {
            showSecuritiesTransferOrderModal,
        },
        money: {
            subAccountsLocal,
            subAccountsInt
        },
        securities: {
            portfolio,
            bondsLib, equitiesLib
        },
        info: {
            brokers,
            holders
        }
    } = useSelector((state: TState) => state);
    const switchValue = useSelector((state: TState) => state.auth.switch);
    const marketSecs = [...bondsLib, ...equitiesLib];
    const [step, setStep] = useState<"form" | "sign" | "error">('form');
    const [sending, setSending] = useState<boolean>(false);
    const [direction, setDirection] = useState<ESecuritiesTransferDirection>(ESecuritiesTransferDirection.incoming);
    const [kind, setKind] = useState<ESecuritiesTransferKind>(ESecuritiesTransferKind.self);
    const [amount, setAmount] = useState<number>();
    // const [currency, setCurrency] = useState<ECurrency | undefined | string>(ECurrency.KZT)
    const options = [
        { label: 'Зачисление', value: ESecuritiesTransferDirection.incoming },
        { label: 'Списание', value: ESecuritiesTransferDirection.outgoing }
    ]
    const searchOwnSec = (searchString: string): TOwnSecurity[] => {
        return portfolio
            .filter(sec => {
                return (sec.tradeCode && sec.tradeCode.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                    (sec.isin && sec.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
            });
    }
    const searchOtherSec = (searchString: string): TMarketSecurity[] => {
        return marketSecs
            .filter(sec => {
                return (sec.tradeCode && sec.tradeCode.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                    (sec.isin && sec.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
            });
    }

    // From security
    const [search, setSearch] = useState<string>();
    const [foundFromOwnSecs, setFromOwnSecs] = useState<TOwnSecurity[]>();
    const [foundFromOtherSecs, setFromOtherSecs] = useState<TMarketSecurity[]>();
    const [fromSec, setFromSec] = useState<TOwnSecurity["isin"] | TMarketSecurity["isin"]>();
    const [fromAmount, setFromAmount] = useState<number>();
    const [fromAcc, setFromAcc] = useState<number>();
    const [brokerId, setBrokerId] = useState<number>();
    const [holderId, setHolderId] = useState<number | null>(null);
    const isOutgoing = direction === ESecuritiesTransferDirection.outgoing
    const onFromSec = (isin: string) => {
        if (isOutgoing) {
            const founds = searchOwnSec(isin);
            setFromSec(founds[0].isin);
            setFromAcc(founds[0].imContractId);
        } else {
            const founds = searchOtherSec(isin);
            setFromSec(founds[0].isin);
        }

        // setFromSec(searchSec(isin).find(s => s.imContractId === parseInt(imContractIdStr)))
    }
    useEffect(() => {
        if (search) {
            const uniques = new Map<string, TOwnSecurity | TMarketSecurity>();
            const founds = isOutgoing ? searchOwnSec(search) : searchOtherSec(search);
            founds.forEach(f => uniques.set(f.isin, f));
            const list = Array.from(uniques.values())
            isOutgoing ? setFromOwnSecs(list as TOwnSecurity[]) : setFromOtherSecs(list as TMarketSecurity[]);
            // setFromSec(list[0]?.isin)
        }
    }, [search]);
    // To account
    const [toAcc, setToAcc] = useState<number>();
    const [outContraName, setOutContraName] = useState<string>();
    const [outAccount, setOutAccount] = useState<string>();
    const [contraDocType, setContraDocType] = useState<string>();
    const [contraDocNumber, setContraDocNumber] = useState<string>();
    const [contraDocSerie, setContraDocSerie] = useState<string>();
    const [contraDocIssuer, setContraDocIssuer] = useState<string>();
    const [contraDocDate, setContraDocDate] = useState<Moment>(moment());
    const [additionalData, setAdditionalData] = useState<string>();

    const [orderData, setOrderData] = useState<TSecuritiesTransferRequest>();
    const [xml, setXml] = useState<string>()

    const handleOk = () => {
        // setStep("form")
        dispatch(closeSecuritiesTransferOrder())
    }
    const handleCancel = () => {
        // setStep("form")
        dispatch(closeSecuritiesTransferOrder())
    }
    const getTransferTypeId = () => {
        let transferTypeId: ESecuritiesTransferType;
        switch (kind) {
            case ESecuritiesTransferKind.self:
                isOutgoing ?
                    transferTypeId = ESecuritiesTransferType.externalTransferSelfOut :
                    transferTypeId = ESecuritiesTransferType.externalTransferSelfIn; break
            case ESecuritiesTransferKind.other:
                isOutgoing ?
                    transferTypeId = ESecuritiesTransferType.externalTransferOtherOut :
                    transferTypeId = ESecuritiesTransferType.externalTransferOtherIn; break
            case ESecuritiesTransferKind.trade:
                isOutgoing ?
                    transferTypeId = ESecuritiesTransferType.externalTransferSell :
                    transferTypeId = ESecuritiesTransferType.externalTransferBuy; break
        }
        return transferTypeId;
    }

    const findCurrency = marketSecs.find(marketSec => fromSec === marketSec.isin)?.tradeCurrency
    const formOrder = () => {
        const security = fromSec && (isOutgoing ? searchOwnSec(fromSec)[0] : searchOtherSec(fromSec)[0]);
        const securityInfo = fromSec && searchOtherSec(fromSec)[0];
        const holder = holders.find(h => h.holderId === holderId);
        const broker = brokers.find(h => h.brokerId === brokerId);
        const FHS = brokers.find(b => b.ourBrokerBool === 1);
        // console.log({ fromSec, isOutgoing,
        //         security, fromAcc, toAcc, fromAmount, brokerId, holderId, clientData,
        //     outContraName, outAccount, holder,
        //     contraDocType, contraDocNumber, contraDocIssuer, contraDocDate
        // })
        if (
            security && (fromAcc || toAcc) && fromAmount && brokerId /* && holderId */ && clientData &&
            outContraName && outAccount /* && holder */ && broker && securityInfo &&
            (kind === ESecuritiesTransferKind.trade ? amount && amount > 0 : true) &&
            contraDocType && contraDocNumber && contraDocIssuer && contraDocDate && FHS
        ) {
            const newOrder: TSecuritiesTransferRequest = {
                transferTypeID: getTransferTypeId(),
                notCheckRem: 0,
                outContrID: isOutgoing ? fromAcc || null : null,
                inContrID: isOutgoing ? null : toAcc || null,
                securityID: security.securityId,
                qty: fromAmount,
                date: new Date().toISOString(),
                additionalData,
                outContraName,
                outHolderID: holderId || undefined,
                outHolderCDS: holder?.cds || undefined,
                outAccount,
                contraBrokerID: brokerId,
                contraDocType,
                contraDocNumber,
                contraDocSerie,
                contraDocIssuer,
                contraDocDate: contraDocDate.format("DD.MM.yyyy"),
            }
            if (kind === ESecuritiesTransferKind.trade) {
                newOrder.amount = amount;
                newOrder.currency = findCurrency;
            }

            else {
                newOrder.amount = amount;
                newOrder.currency = findCurrency;
            }
            setOrderData(newOrder);
            let heading: TPDFHeading
            if (Array.isArray(clientData)) {
                heading = {
                    orderDate: new Date().toISOString(),
                    docIssueDate: cProfile?.docIssueDate || "",
                    docIssuer: cProfile?.docIssueAuthor || "",
                    docNumber: cProfile?.docNumber || "",
                    docSeries: "",
                    docType: cProfile?.docType || "",
                    subAccount: clientData[0]?.subAccount,
                    accountNumber: clientData[0]?.imcAccount,
                    clientName: clientData[0]?.cliName,
                    contractNumber: clientData[0]?.contractNumber,
                    iinbin: clientData[0]?.biin
                };
            }

            else {
                heading = {
                    orderDate: new Date().toISOString(),
                    docIssueDate: cProfile?.docIssueDate || "",
                    docIssuer: cProfile?.docIssueAuthor || "",
                    docNumber: cProfile?.docNumber || "",
                    docSeries: "",
                    docType: cProfile?.docType || "",
                    subAccount: clientData?.subAccount,
                    accountNumber: clientData?.imcAccount,
                    clientName: clientData?.cliName,
                    contractNumber: clientData?.contractNumber,
                    iinbin: clientData?.biin
                };
            }
            let orderKind: string;
            switch (kind) {
                case ESecuritiesTransferKind.self: orderKind = "owner_unchanged"; break;
                case ESecuritiesTransferKind.other: orderKind = "owner_change"; break;
                case ESecuritiesTransferKind.trade: orderKind = isOutgoing ? "sell" : "buy"; break;
            }
            const extraData = {
                orderKind,
                direction
            }
            const counterpartyInfo = {
                clientName: outContraName,
                broker: broker.brokerName,
                subAccount: outAccount,
                docType: contraDocType,
                docNumber: contraDocNumber,
                docSeries: contraDocSerie,
                docIssueDate: contraDocDate.format("DD.MM.yyyy"),
                docIssuer: contraDocIssuer,
            };
            const ownerInfo = {
                clientName: clientData.cliName,
                broker: FHS.brokerName,
                subAccount: [...subAccountsInt, ...subAccountsLocal].find(acc => acc.imContractId === (isOutgoing ? fromAcc : toAcc))!.subAccount,
                docType: cProfile?.docType || "",
                docNumber: cProfile?.docNumber || "",
                docSeries: "",
                docIssueDate: cProfile?.docIssueDate || "",
                docIssuer: cProfile?.docIssueAuthor || "",
            }
            const { issuer, globalType, tradeCode, isin, tradeCurrency } = securityInfo
            const currency = tradeCurrency;
            const printData = {
                securityInfo: { issuer, globalType, tradeCode, isin, currency },
                senderInfo: isOutgoing ? ownerInfo : counterpartyInfo,
                receiverInfo: isOutgoing ? counterpartyInfo : ownerInfo,
                tradeInfo: amount && kind === ESecuritiesTransferKind.trade ? {
                    price: (amount / fromAmount).toFixed(2),
                    amount
                } : {}
            }
            // const xml = buildXML({...extraData, ...newOrder}, 'MoneyTransferOrder', heading);
            const xml = buildXMLv2("SecuritiesTransferExternal", {
                heading,
                orderData: { ...extraData, ...newOrder },
                printData
            })
            setXml(xml);
            setStep("sign");
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "Заполните все поля",
                placement: "top"
                // description: errorMessage
            })
            return
        }
    }
    const signOrder = async () => {
        if (!orderData) return;
        let method: string;
        let args: {};
        if (NcaLayerConnector.getBundleVersion()) {
            method = "signXmlV2"
            args = { xml: xml, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password }
        } else {
            method = "signXml"
            args = { xml: xml }
        }
        const message = new NcaMessage(method, args);

        if (certificate !== '' || password !== '') {
            try {
                const signedXml: string = await NcaLayerConnector.callNcaMethod(message);
                const reqType = ECheckReqType.secTransferReq;
                const response = await apiConnector.checkRequest({ orderData, signedXml, switchValue }, reqType);
                // const response = await apiConnector.putSecurityTransferOrder({
                //     orderData,
                //     signedXml,
                //     switchValue
                // })
                if (response && response.success) {
                    notification.success({
                        duration: timeForSuccessNotification,
                        message: "Приказ отправлен",
                    })
                    dispatch(closeSecuritiesTransferOrder());
                    dispatch(getOrders());
                } else {
                    notification.error({
                        duration: timeForErrorNotification,
                        message: `Приказ отклонен`,
                        description: <><p>{response.message}</p><p>{response.details}</p></>
                    })
                }
            } catch (e) {
                console.log(e);
                setSignError('error');
            } finally {
                setSending(false);
            }
        } else {
            notification.error({
                duration: timeForErrorNotification,
                message: "Ошибка!",
                description: "Нужно указать путь до ключа и ввести пароль"
            });
        }
    }

    const pendOrder = async () => {
        setSending(true);
        if (orderData && xml) {
            const security = isOutgoing ? searchOwnSec(fromSec!)[0] : searchOtherSec(fromSec!)[0];
            const orderTemplate: TSetOrderTemplate = {
                orderKind: EOrderKind.nonTrade,
                orderType: ENonTradeOrderType.securityTransferExternal,
                orderData,
                orderXml: xml,
                author: userName || "н/д",
                authorIIN: iin || "н/д",
                extraData: {
                    security,
                    toAccount: isOutgoing ? orderData.outAccount : [...subAccountsInt, ...subAccountsLocal].find(acc => acc.imContractId === toAcc)!.holdPlace,
                    fromAccount: isOutgoing ? [...subAccountsInt, ...subAccountsLocal].find(acc => acc.imContractId === fromAcc)!.holdPlace : orderData.outAccount,
                    direction,
                    kind
                }
            };
            try {
                await apiConnector.checkTemplate(orderTemplate);
                dispatch(closeSecuritiesTransferOrder());
            } catch (e) {
                console.log(e);
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: "Не удалось отправить приказ"
                });
            } finally {
                setSending(false);
            }
        }
    }

    const [password, setPassword] = useState<string>('');
    const [certificate, setCertificate] = useState<string>("");
    const [signError, setSignError] = useState<string | null>(null);

    return (
        <Modal
            title={getIntOrderTypeName(EIntOrderType.securitiesTransfer)}
            visible={showSecuritiesTransferOrderModal}
            onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={680}
            bodyStyle={{ paddingTop: 12 }}
            style={{ overflow: "hidden", minHeight: 500 }}
            centered
            className="edited-form"
            zIndex={5}
        >
            <Row className="of-row">
                <Col span={10}>
                    Направление:
                </Col>
                <Col span={14}>
                    <Radio.Group
                        className="radio-wide deal-type"
                        options={options}
                        value={direction}
                        optionType="button"
                        buttonStyle="solid"
                        size="small"
                        onChange={e => setDirection(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>
                    Тип:
                </Col>
                <Col span={14}>
                    <Select className="of-select repo-days" size="small"
                        value={kind}
                        // defaultValue={foundFromSecs && foundFromSecs[0].isin}
                        onChange={v => setKind(v)}>
                        <Select.Option key={`self`} value={ESecuritiesTransferKind.self}>Без смены собственности</Select.Option>
                        <Select.Option key={`other`} value={ESecuritiesTransferKind.other}>Со сменой собственности</Select.Option>
                        <Select.Option key={`trade`} value={ESecuritiesTransferKind.trade}>{isOutgoing ? "Продажа" : "Покупка"}</Select.Option>
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>Введите торговый код или ISIN ЦБ:</Col>
                <Col span={14}>
                    <Input
                        // status={valid.investDoc ? undefined : "error"}
                        value={search}
                        maxLength={20}
                        // showCount
                        size="small" className="of-input"
                        // placeholder="№__ от __.__.____"
                        onChange={e => setSearch(e.target.value.toUpperCase())}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>
                    ЦБ:
                </Col>
                <Col span={14}>
                    <Select className="of-select repo-days" size="small"
                        value={fromSec}
                        // defaultValue={foundFromSecs && foundFromSecs[0].isin}
                        onChange={v => onFromSec(v)}>
                        {
                            isOutgoing ? foundFromOwnSecs && foundFromOwnSecs.map((sec, i) => (
                                <Select.Option key={`${i}`} value={sec.isin}>{sec.isin} {sec.tradeCode}</Select.Option>
                            )) : foundFromOtherSecs && foundFromOtherSecs.map((sec, i) => (
                                <Select.Option key={`${i}`} value={sec.isin}>{sec.isin} {sec.tradeCode}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            {isOutgoing && <Row className="of-row">
                <Col span={10}>
                    Субсчёт списания:
                </Col>
                <Col span={14}>
                    <Select className="of-select repo-days" size="small"
                        value={fromAcc}
                        // disabled={!(fromSec && searchSec(fromSec).length > 1)}
                        onChange={v => setFromAcc(v)}>
                        {
                            (fromSec ? searchOwnSec(fromSec) : []).map((sec, i) => (
                                <Select.Option key={`${i}`}
                                    value={sec.imContractId}>{sec.holdPlace} - {sec.subAccount}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>}
            {!isOutgoing && <Row className="of-row">
                <Col span={10}>
                    Субсчёт зачисления:
                </Col>
                <Col span={14}>
                    <Select className="of-select repo-days" size="small"
                        value={toAcc}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setToAcc(v)}>
                        {
                            [...subAccountsInt, ...subAccountsLocal].filter(acc => acc.imContractId !== fromAcc).map((acc, i) => (
                                <Select.Option style={optionStyles} key={`${i}`} value={acc.imContractId}>
                                    {shortName(acc)}
                                    {/* {acc.holdPlace.replace(`АО "Центральный депозитарий ценных бумаг"`, "KACD")} - {acc.subAccount} */}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>}
            <Row className="of-row">
                <Col span={10}>
                    Количество:
                </Col>
                <Col span={14}>
                    <InputNumber
                        value={fromAmount}
                        // max={fromSec ? isOutgoing ? searchSec(fromSec)[0]?.remQuantity || 0 : 0}
                        onChange={p => setFromAmount(p || 1)}
                        size="small" className="of-input repo-amount-open"
                        formatter={v => countNum(v as number)}
                        parser={v => parseNum(v as string, 0)}
                    />
                </Col>
            </Row>
            {kind === ESecuritiesTransferKind.trade && <Row className="of-row">
                <Col span={10}>
                    Сумма:
                </Col>
                <Col span={14}>
                    <InputNumber
                        // prefix={currency}
                        value={amount}
                        onChange={p => setAmount(p || 0)}
                        size="small" className="of-input repo-amount-open"
                        formatter={v => finNum(v as number)}
                        parser={v => parseNum(v as string, 2)}
                    />
                </Col>
            </Row>}
            {kind === ESecuritiesTransferKind.trade && <Row className="of-row">
                <Col span={10}>
                    Валюта:
                </Col>
                <Col span={14}>
                    <Select className="of-select repo-days" size="small" value={findCurrency} disabled>
                        {
                            Object.values(ECurrency).map((cur, i) => (
                                <Select.Option key={`${i}`} value={cur}>{cur}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>}
            <Divider style={{ borderColor: "var(--fhs-gray-tr)" }} plain>Реквизиты {isOutgoing ? "получателя" : "отправителя"}</Divider>
            <Row className="of-row">
                <Col span={10}>
                    Брокер:
                </Col>
                <Col span={14}>
                    <Select className="of-select repo-days" size="small"
                        value={brokerId}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setBrokerId(v)}>
                        {
                            brokers.map((bro, i) => (
                                <Select.Option key={`${i}`} value={bro.brokerId}>{bro.brokerName}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>
                    Место хранения:
                </Col>
                <Col span={14}>
                    <Select className="of-select repo-days" size="small"
                        value={holderId}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setHolderId(v)}>
                        <Select.Option key={`${999}`} value={null}>---</Select.Option>
                        {
                            holders.map((hol, i) => (
                                <Select.Option style={optionStyles} key={`${i}`} value={hol.holderId}>
                                    {shortNameforHolders(hol.holderName)}
                                    {/* {hol.holderName} */}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Divider style={{ borderColor: "var(--fhs-gray-tr)" }} plain>Внешний контрагент</Divider>
            <Row className="of-row">
                <Col span={10}>Наименование:</Col>
                <Col span={14}>
                    <Input
                        value={outContraName}
                        size="small" className="of-input"
                        onChange={e => setOutContraName(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>Номер счета в месте хранения:</Col>
                <Col span={14}>
                    <Input
                        value={outAccount}
                        size="small" className="of-input"
                        onChange={e => setOutAccount(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>Тип документа:</Col>
                <Col span={14}>
                    <Input
                        value={contraDocType}
                        size="small" className="of-input"
                        onChange={e => setContraDocType(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>Номер документа:</Col>
                <Col span={14}>
                    <Input
                        value={contraDocNumber}
                        size="small" className="of-input"
                        onChange={e => setContraDocNumber(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>Серия документа:</Col>
                <Col span={14}>
                    <Input
                        value={contraDocSerie}
                        size="small" className="of-input"
                        onChange={e => setContraDocSerie(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>Орган выпустивший документ:</Col>
                <Col span={14}>
                    <Input
                        value={contraDocIssuer}
                        size="small" className="of-input"
                        onChange={e => setContraDocIssuer(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>Дата выдачи документа:</Col>
                <Col span={14}>
                    <DatePicker
                        format={"DD.MM.yyyy"}
                        size="small" className="of-input deal-day"
                        disabledDate={current => current && current > moment()}
                        defaultValue={moment()}
                        onChange={val => val && setContraDocDate(val)}
                    />
                </Col>
            </Row>
            <Divider style={{ borderColor: "var(--fhs-gray-tr)" }} plain>Дополнительные данные по переводу</Divider>
            <Row className="of-row">
                <Col span={24}>
                    <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={additionalData}
                        onChange={e => setAdditionalData(e.target.value)}
                    />
                </Col>
            </Row>
            <Divider />
            <Row>
                {step === "form" && <Button onClick={formOrder} type="primary" block>Создать приказ</Button>}
                {step === "sign" &&
                    <>
                        {isCompany() && !isHeadOfCompany() && <Button loading={sending} disabled={sending} onClick={pendOrder} type="primary" block>Отправить на
                            согласование</Button>}
                    </>
                }
            </Row>
            <ModalDS
                signError={signError}
                step={step}
                password={password}
                certificate={certificate}
                setCertificate={setCertificate}
                setPassword={setPassword}
                signOrder={signOrder}
                isDisable={false}
                sending={sending}
                OT={false}
                isActual={undefined}
                loading={false}
                onConfirm={undefined}
                template={undefined}
                isSigners={false}
            />
        </Modal>
    )
}