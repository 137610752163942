import {apiConnector} from '../integrations/api.connector';
import {NcaMessage} from '../types/nca';
import {NcaLayerConnector} from '../integrations/ncaLayer.connector';
import {TAuthResponse} from "../types/api";
import { nanoid } from 'nanoid';


export const loginWithEDS = async (): Promise<TAuthResponse> => {
    const {textb64} = await apiConnector.getSecretForEDS({iin: "", bin: ""});
    const message = new NcaMessage(
        "signText",
        {base64data: textb64}
    );
    const signature = await NcaLayerConnector.callNcaMethod(message);

    if(signature){
        try {
            const data = await apiConnector.validateEDS(textb64!, signature);
            return data;
        } catch (e) {
            throw new Error()
        }
    } else {
        throw new Error()
    }
}

export const getCertificate = async (): Promise<string> => {
    
    const messageForCert = new NcaMessage(
        "getCertificatePath",
        {base64data: 'textb64', requestId: 5}
    );
    
    return await NcaLayerConnector.getCertificateMethod(messageForCert);
}

export const getCertificateInfo = async (path: string, password: string): Promise<any> => {

    const messageForCert = new NcaMessage(
        "getCertificateInfo",
        {path: path, password: password, requestId: 5}
    );   

    const data = await NcaLayerConnector.getCertificateMethod(messageForCert);
    return {...data.body, path}
}

export const loginWithEDSV2 = async (certificatePath: string, password: string): Promise<TAuthResponse> => {
    const {textb64} = await apiConnector.getSecretForEDS({iin: "", bin: ""});

    const message = new NcaMessage(
        'signTextV2',
        {path: certificatePath, password: password, base64data: textb64}
    );

    const signature = await NcaLayerConnector.callNcaMethod(message)
    if(signature){
        try {
            const data = await apiConnector.validateEDSV2(textb64!, signature);
            console.log({data});
            return data;
        } catch (e) {        
            throw e;
        }
    } else {
        throw new Error()
    }
}
