import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Divider, InputNumber, Modal, notification, Row, Select, Tooltip } from "antd";
import { dispatch, TState } from "../../../store";
import { closeMoneyConversionOrder, closeMoneyTransferOrder, getOrders } from "../../../store/orders.slice";
import { apiConnector } from "../../../integrations/api.connector";
import { NcaLayerConnector } from "../../../integrations/ncaLayer.connector";
import { countNum, formatPrice, parseNum } from "../../../services/formatter";
import { buildXML } from "../../../services/xmlBuilder";
import { getAccTitle, getCurrencies } from "./helpers";
import { TPDFHeading } from "../../../types/api";
import { ECurrency } from "../../../types/common";
import { EMoneyTransferType, TMoneyTransferRequest } from "../../../types/orders";
import { NcaMessage } from "../../../types/nca";
import { timeForErrorNotification, timeForSuccessNotification, timeForWarningNotification } from "../../../constants";
import { ModalDS } from "../../shared/modals/ModalDS";
import "./../editedForm.css";

export const MoneyConversionForm = () => {
    const {
        auth: {
            clientData,
            cProfile
        },
        orders: {
            showMoneyConversionOrderModal,
        },
        money: {
            moneyRem,
        }
    } = useSelector((state: TState) => state);
    const [step, setStep] = useState<"form" | "sign" | "error">('form');
    const [sending, setSending] = useState<boolean>(false)
    const [amount, setAmount] = useState<number>()
    const [currency, setCurrency] = useState<ECurrency | string>();
    const [currency2, setCurrency2] = useState<ECurrency | string>();
    const [rate, setRate] = useState<number>();
    const [convertedValue, setConvertedValue] = useState<string>();
    const [fromAcc, setFromAcc] = useState<number>();
    const [toAcc, setToAcc] = useState<number>();
    const [orderData, setOrderData] = useState<TMoneyTransferRequest>();
    const [xml, setXml] = useState<string>()
    const handleOk = () => {
        // setStep("form")
        dispatch(closeMoneyConversionOrder())
    }
    const handleCancel = () => {
        // setStep("form")
        dispatch(closeMoneyConversionOrder())
    }
    const formOrder = () => {
        if (!!fromAcc && !!toAcc && !!amount && !!rate && !!convertedValue && clientData) {
            const transferTypeID = EMoneyTransferType.conversion;
            let newOrder: TMoneyTransferRequest = {
                notCheckRem: 0,
                amount,
                transferTypeID,
                ftOutID: fromAcc,
                ftInID: toAcc,
                convertationIn: parseNum(convertedValue, 2),
                exchangeRate: rate,
                KNP: currency === ECurrency.KZT ? 213 : 223
            }
            setOrderData(newOrder);
            const heading: TPDFHeading = {
                orderDate: new Date().toISOString(),
                docIssueDate: cProfile?.docIssueDate || "",
                docIssuer: cProfile?.docIssueAuthor || "",
                docNumber: cProfile?.docNumber || "",
                docSeries: "",
                docType: cProfile?.docType || "",
                subAccount: clientData.subAccount,
                accountNumber: clientData.imcAccount,
                clientName: clientData.cliName,
                contractNumber: clientData.contractNumber,
                iinbin: clientData.biin

            }
            const isSell = currency !== ECurrency.KZT;
            const extraData = {
                conversionType: isSell ? "SELL" : "BUY",
                currencyCode: (isSell ? currency : currency2) as string,
                fromAccount: moneyRem.find(acc => acc.ftId === fromAcc)!.accountNumber,
                toAccount: moneyRem.find(acc => acc.ftId === toAcc)!.accountNumber,
            }
            const xml = buildXML({ ...extraData, ...newOrder }, 'MoneyTransferOrder', heading);
            setXml(xml);
            setStep("sign");
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "Заполните все поля",
                placement: "top"
                // description: errorMessage
            })
            return
        }
    }
    const signOrder = async () => {
        if (!orderData) return;
        setSending(true);
        let method: string;
        let args: {};
        if (NcaLayerConnector.getBundleVersion()) {
            method = "signXmlV2"
            args = { xml: xml, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password }
        } else {
            method = "signXml"
            args = { xml: xml }
        }
        const message = new NcaMessage(method, args);

        if (certificate !== '' || password !== '') {
            try {
                const signedXml: string = await NcaLayerConnector.callNcaMethod(message);
                const response = await apiConnector.putMoneyTransferOrder({
                    orderData,
                    signedXml
                })
                if (response && response.success) {
                    notification.success({
                        duration: timeForSuccessNotification,
                        message: "Приказ отправлен",
                    })
                    dispatch(getOrders());
                } else {
                    notification.error({
                        duration: timeForErrorNotification,
                        message: `Приказ отклонен`,
                        description: <><p>{response.message}</p><p>{response.details}</p></>
                    })
                }
                dispatch(closeMoneyTransferOrder());
            } catch (e) {
                setSignError('error');
                console.log(e);
            } finally {
                setSending(false);
            }
        } else {
            notification.error({
                duration: timeForErrorNotification,
                message: "Ошибка!",
                description: "Нужно указать путь до ключа и ввести пароль"
            });
        }

    }
    useEffect(() => {
        !!toAcc && setToAcc(undefined);
        !!currency2 && setCurrency2(undefined);
        !!convertedValue && setConvertedValue(undefined);
        if (!!currency && currency !== ECurrency.KZT) {
            setCurrency2(ECurrency.KZT);
        }
    }, [currency])
    useEffect(() => {
        !!toAcc && setToAcc(undefined);
        !!convertedValue && setConvertedValue(undefined);
    }, [currency, fromAcc])
    useEffect(() => {
        if (rate && amount && rate !== 0) {
            const value = currency === ECurrency.KZT ? amount / rate : amount * rate;
            setConvertedValue(formatPrice(value))
        }
    }, [rate, amount, currency2])

    const [password, setPassword] = useState<string>('');
    const [certificate, setCertificate] = useState<string>("");
    const [signError, setSignError] = useState<string | null>(null);

    return (
        <Modal
            title={"Конвертация денежных средств"}
            visible={showMoneyConversionOrderModal}
            onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={620}
            bodyStyle={{ paddingTop: 12 }}
            style={{ overflow: "hidden", minHeight: 500 }}
            centered
            className="edited-form"
            zIndex={5}
        >
            <Row className="of-row">
                <Col span={8}>
                    Валюта списания:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={currency}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setCurrency(v)}>
                        {
                            getCurrencies(moneyRem).filter(cur => (
                                cur === ECurrency.KZT ||
                                cur === ECurrency.USD ||
                                cur === ECurrency.EUR ||
                                cur === ECurrency.RUB
                            )).map((cur, i) => (
                                <Select.Option key={`${i}`} value={cur}>{cur}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Счёт списания:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={fromAcc}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setFromAcc(v)}>
                        {
                            moneyRem.filter(acc => acc.currencyCode === currency && acc.freeAmount > 0).map((acc, i) => (
                                <Select.Option key={`${i}`} value={acc.ftId}>
                                    <Tooltip placement="left" title={getAccTitle(acc.ftId, moneyRem)}>
                                        {acc.iType ? `(${acc.iType}) ` : ""}{acc.holdPlace} - {acc.subAccount}
                                    </Tooltip>
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Валюта зачисления:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={currency2}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setCurrency2(v)}>
                        {
                            getCurrencies(moneyRem).filter(cur => (
                                cur === ECurrency.KZT ||
                                cur === ECurrency.USD ||
                                cur === ECurrency.EUR ||
                                cur === ECurrency.RUB
                            )).filter(cur => {
                                if (currency === ECurrency.KZT) {
                                    return cur !== currency
                                } else {
                                    return cur === ECurrency.KZT
                                }
                            }).map((cur, i) => (
                                <Select.Option key={`${i}`} value={cur}>{cur}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Счёт зачисления:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={toAcc}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setToAcc(v)}>
                        {
                            moneyRem.filter(acc => acc.currencyCode === currency2).map((acc, i) => (
                                <Select.Option key={`${i}`} value={acc.ftId}>
                                    <Tooltip placement="left" title={getAccTitle(acc.ftId, moneyRem)}>
                                        {acc.iType ? `(${acc.iType}) ` : ""}{acc.holdPlace} - {acc.subAccount}
                                    </Tooltip>
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Сумма списания ({currency}):
                </Col>
                <Col span={16}>
                    <InputNumber
                        // prefix={currency}
                        value={amount}
                        onChange={p => setAmount(p || 0)}
                        size="small" className="of-input repo-amount-open"
                        formatter={v => countNum(v as number)}
                        parser={v => parseNum(v as string, 2)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Курс:
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={rate}
                        onChange={p => setRate(p || 1)}
                        size="small" className="of-input repo-amount-open"
                        formatter={v => countNum(v as number)}
                        parser={v => parseNum(v as string, 2)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Сумма зачисления ({currency2}):
                </Col>
                <Col span={16}>
                    <InputNumber
                        // prefix={currency2}
                        value={convertedValue}
                        readOnly
                        size="small" className="of-input repo-amount-open"
                    // formatter={v => finNum(v as number)}
                    // parser={v => parseNum(v as string, 2)}
                    />
                </Col>
            </Row>
            <Divider />
            <Row>
                {step === "form" && <Button onClick={formOrder} type="primary" block>Создать распоряжение</Button>}
            </Row>
            <ModalDS
                signError={signError}
                step={step}
                password={password}
                certificate={certificate}
                setCertificate={setCertificate}
                setPassword={setPassword}
                signOrder={signOrder}
                isDisable={false}
                sending={sending}
                OT={false}
                isActual={undefined}
                loading={false}
                onConfirm={undefined}
                template={undefined}
                isSigners={false}
            />
        </Modal>
    )
}
