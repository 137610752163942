import { ColumnsType } from 'antd/lib/table';
import { TAnalyticsData } from "../../types/analytics";
import { countNum, finNum } from "../../services/formatter";

export const columns: ColumnsType<TAnalyticsData & { action: any }> = [
    {
        title: "Наименование инструмента",
        dataIndex: "Ft_Name",
        fixed: "left",
        align: "left",
        width: 310,
        sorter: (a, b) => a.Ft_Name.localeCompare(b.Ft_Name)
    },
    {
        title: "Тип инструмента",
        dataIndex: "Ft_Type",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Ft_Type.localeCompare(b.Ft_Type)
    },
    {
        title: "Тикер ЦБ",
        dataIndex: "Sec_Name",
        align: "left",
        width: 120,
        sorter: (a, b) => a.Sec_Name && b.Sec_Name ? a.Sec_Name.localeCompare(b.Sec_Name) : 1
    },
    {
        title: "ISIN",
        dataIndex: "isin",
        align: "left",
        width: 120,
        sorter: (a, b) => a.Isin && b.Isin ? a.Isin.localeCompare(b.Isin) : 1
    },
    {
        title: "Тип цб",
        dataIndex: "Sec_Type",
        align: "left",
        width: 120,
        sorter: (a, b) => a.Sec_Type && b.Sec_Type ? a.Sec_Type.localeCompare(b.Sec_Type) : 1
    },
    {
        title: "Эмитент",
        dataIndex: "Issuer",
        align: "left",
        width: 220,
        sorter: (a, b) => a.Issuer.localeCompare(b.Issuer)

    },
    {
        title: "Рейтинг Moody's",
        dataIndex: "Rating_M",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Rating_M && b.Rating_M ? a.Rating_M.localeCompare(b.Rating_M) : 1

    },
    {
        title: "Рейтинг Fitch",
        dataIndex: "Rating_F",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Rating_F && b.Rating_F ? a.Rating_F.localeCompare(b.Rating_F) : 1

    },
    {
        title: "Рейтинг S&P",
        dataIndex: "Rating_S",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Rating_S && b.Rating_S ? a.Rating_S.localeCompare(b.Rating_S) : 1
    },
    {
        title: "Место хранения",
        dataIndex: "Hold_Place",
        align: "left",
        width: 220,
        sorter: (a, b) => a.Hold_Place.localeCompare(b.Hold_Place)
    },
    {
        title: "Номинал",
        dataIndex: "Face_Value",
        align: "left",
        width: 120,
        sorter: (a, b) => a.Face_Value && b.Face_Value ? a.Face_Value.localeCompare(b.Face_Value) : 1
    },
    {
        title: "Валюта инструмента",
        dataIndex: "Currency",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Currency.localeCompare(b.Currency)
    },
    {
        title: "Дата сделки",
        dataIndex: "Deal_Date",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Deal_Date && b.Deal_Date ? new Date(a.Deal_Date).valueOf() - new Date(b.Deal_Date).valueOf() : 1,
        render: record => record ? new Date(record).toLocaleDateString("ru-RU") : "-"
    },

    {
        title: "Дата погашения",
        dataIndex: "Maturity_Date",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Maturity_Date && b.Maturity_Date ? new Date(a.Maturity_Date).valueOf() - new Date(b.Maturity_Date).valueOf() : 1,
        render: record => record ? new Date(record).toLocaleDateString("ru-RU") : "-"
    },
    {
        title: "Сумма по номиналу",
        dataIndex: "Face_Value_Amount",
        render: finNum,
        align: "right",
        width: 160,
        sorter: (a, b) => a.Face_Value_Amount && b.Face_Value_Amount ? a.Face_Value_Amount - b.Face_Value_Amount : 1
    },
    {
        title: "Цена приобретения",
        dataIndex: "Deal_Price",
        render: finNum,
        align: "right",
        width: 160,
        sorter: (a, b) => a.Deal_Price && b.Deal_Price ? a.Deal_Price - b.Deal_Price : 1
    },
    {
        title: "Количество сделки",
        dataIndex: "Deal_Quantity",
        render: countNum,
        align: "right",
        width: 160,
        sorter: (a, b) => a.Deal_Quantity && b.Deal_Quantity ? a.Deal_Quantity - b.Deal_Quantity : 1
    },
    {
        title: "YTM сделки",
        dataIndex: "Deal_YTM",
        align: "right",
        width: 160,
        sorter: (a, b) => a.Deal_YTM && b.Deal_YTM ? a.Deal_YTM - b.Deal_YTM : 1
    },
    {
        title: "Доходность по РЕПО",
        dataIndex: "Repo_Pty",
        render: finNum,
        align: "right",
        width: 160,
        sorter: (a, b) => a.Repo_Pty && b.Repo_Pty ? a.Repo_Pty - b.Repo_Pty : 1
    },
    {
        title: "Курс валюты на дату",
        dataIndex: "Exchange_Rate",
        align: "right",
        width: 160,
        sorter: (a, b) => a.Exchange_Rate - b.Exchange_Rate
    },
    {
        title: "Курс USD на дату",
        dataIndex: "Exchange_Rate_USD",
        align: "right",
        width: 160,
        sorter: (a, b) => a.Exchange_Rate_USD - b.Exchange_Rate_USD
    },
    {
        title: "Ст-ть инструмента в валюте на дату",
        dataIndex: "Amount",
        render: finNum,
        align: "right",
        width: 220,
        sorter: (a, b) => a.Amount - b.Amount
    },
    {
        title: "Ст-ть инструмента в тенге на дату",
        dataIndex: "Amount_KZT",
        render: finNum,
        align: "right",
        width: 220,
        sorter: (a, b) => a.Amount_KZT - b.Amount_KZT
    },
    {
        title: "Остаточное количество на дату",
        dataIndex: "Quantity",
        align: "right",
        render: countNum,
        width: 220,
        sorter: (a, b) => a.Quantity && b.Quantity ? a.Quantity - b.Quantity : 1
    },
]
