import React from 'react'
import { ColumnsType } from 'antd/lib/table';
import { countNum, finNum } from '../../services/formatter';
import { TOrderStatusFull } from '../../types/orders';

export const columns: ColumnsType<TOrderStatusFull & { action: any, icon: any }> = [
    {
        title: "",
        dataIndex: "icon",
        width: 10,
        fixed: "left",
        sorter: (a, b) => a.dealKindId - b.dealKindId
    },
    {
        title: <span style={{ fontSize: "12px" }}>Номер заказа</span>,
        dataIndex: "orderNumber",
        width: 80,
        align: "center",
        render: (text, record) => ({
            children: text ? `${text}` : `ID ${record.clientOrderId}`,
            props: {
                style: { fontSize: "12px" } // Установите значение, соответствующее желаемому размеру шрифта
            }
        })
    },
    {
        title: <span style={{ fontSize: "12px" }}>Тип заказа</span>,
        dataIndex: "orderTypeId",
        width: 80,
        align: "center",
        render: (text) => {
            if (text === 1) {
                return <span style={{ fontSize: "12px" }}>Лимитный</span>;
            } else if (text === 2) {
                return <span style={{ fontSize: "12px" }}>Рыночный</span>;
            }
            // Если значение не соответствует 1, 2 или 3, можно вернуть что-то другое или пустую строку.
            return "";
        },
    },
    {
        title: <span style={{ fontSize: "12px" }}>Вид сделки</span>,
        dataIndex: "dealDirection",
        width: 80,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "12px" }
            }
        })
    },
    {
        title: <span style={{ fontSize: "12px" }}>Тикер</span>,
        dataIndex: "ticker",
        width: 70,
        align: "center",
        render: (text, record) => {
            return record.orderType === 'REJECT' ? <span style={{ fontSize: "12px", color: "red" }}>!{text}</span> : <span style={{ fontSize: "12px" }}>{text}</span>
        },
        // render: text => (text || "РЕПО"),
        // sorter: (a, b) => (a && b) ? a.ticker.localeCompare(b.ticker) : 0
    },
    {
        title: <span style={{ fontSize: "12px" }}>ISIN</span>,
        dataIndex: "isin",
        width: 80,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "12px" }
            }
        }),
        sorter: (a, b) => a.isin.localeCompare(b.isin),
    },
    {
        title: <span style={{ fontSize: "12px" }}>Валюта</span>,
        dataIndex: "currencyCode",
        width: 80,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "12px" }
            }
        })
    },
    {
        title: <span style={{ fontSize: "12px" }}>Статус</span>,
        dataIndex: "orderStatus",
        width: 80,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "12px" }
            }
        })
    },
    {
        title: <span style={{ fontSize: "12px" }}>Цена</span>,
        dataIndex: "orderPrice",
        width: 80,
        align: "right",
        render: (text) => ({
            children: finNum(text),
            props: {
                style: { fontSize: "12px" }
            }
        }),
        sorter: (a, b) => a.orderQuantity - b.orderQuantity
    },
    {
        title: <span style={{ fontSize: "12px" }}>Кол-во</span>,
        dataIndex: "orderQuantity",
        width: 80,
        align: "center",
        render: (text) => ({
            children: countNum(text),
            props: {
                style: { fontSize: "12px" }
            }
        }),
        sorter: (a, b) => a.orderQuantity - b.orderQuantity
    },
    {
        title: <span style={{ fontSize: "12px" }}>Сумма</span>,
        dataIndex: "orderAmount",
        width: 80,
        align: "right",
        render: (text) => ({
            children: finNum(text),
            props: {
                style: { fontSize: "12px" }
            }
        }),
        sorter: (a, b) => a.orderQuantity - b.orderQuantity
    },


    {
        title: <span style={{ fontSize: "12px" }}>Цена по сделкам</span>,
        dataIndex: "dealPrice",
        width: 80,
        align: "right",
        render: (text) => ({
            children: finNum(text),
            props: {
                style: { fontSize: "12px" }
            }
        }),
        sorter: (a, b) => a.dealPrice - b.dealPrice
    },
    {
        title: <span style={{ fontSize: "12px" }}>Кол-во по сделкам</span>,
        dataIndex: "dealQuantity",
        width: 90,
        align: "center",
        render: (text) => ({
            children: countNum(text),
            props: {
                style: { fontSize: "12px" }
            }
        }),
        sorter: (a, b) => a.dealQuantity - b.dealQuantity
    },
    {
        title: <span style={{ fontSize: "12px" }}>Сумма по сделкам</span>,
        dataIndex: "dealAmount",
        width: 80,
        align: "left",
        render: (text, record) => ({
            children: finNum(record.dealPrice * record.dealQuantity),
            props: {
                style: { fontSize: "12px" }
            }
        }),
        sorter: (a, b) => a.dealQuantity - b.dealQuantity
    },

    {
        title: <span style={{ fontSize: "12px" }}>Ордера</span>,
        dataIndex: "orderDate",
        width: 80,
        align: "center",
        render: (text) => ({
            children: new Date(text).toLocaleString("ru-RU", { dateStyle: 'short', timeStyle: 'short' }),
            props: {
                style: { fontSize: "12px" }
            }
        })
    },

    {
        title: <span style={{ fontSize: "12px" }}>Обн статуса</span>,
        dataIndex: "orderStatusDate",
        width: 80,
        align: "center",
        render: (text) => ({
            children: new Date(text).toLocaleString("ru-RU", { dateStyle: 'short', timeStyle: 'short' }),
            props: {
                style: { fontSize: "12px" }
            }
        }),
        sorter: (a, b) => new Date(a.orderStatusDate).getTime() - new Date(b.orderStatusDate).getTime()
    },

    {
        title: <span style={{ fontSize: "12px" }}>Действует до</span>,
        dataIndex: "durationDate",
        width: 80,
        align: "center",
        render: (text) => ({
            children: new Date(text).toLocaleString("ru-RU", { dateStyle: 'short', timeStyle: 'short' }),
            props: {
                style: { fontSize: "12px" }
            }
        }),
        sorter: (a, b) => new Date(a.durationDate).getTime() - new Date(b.durationDate).getTime()
    },

    {
        title: <span style={{ fontSize: "12px" }}>Тип цены</span>,
        dataIndex: "priceQuotation",
        width: 80,
        align: "center",
        render: (text) => {
            if (text === 1) {
                return <span style={{ fontSize: "12px" }}>Чистая цена</span>;
            } else if (text === 2) {
                return <span style={{ fontSize: "12px" }}>Цена</span>;
            } else if (text === 3) {
                return <span style={{ fontSize: "12px" }}>Доходность</span>;
            }
            return "";
        },
    },

    {
        title: <span style={{ fontSize: "12px" }}>Счет</span>,
        dataIndex: "subAccount",
        width: 120,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "12px" } // Установите значение, соответствующее желаемому размеру шрифта
            }
        }),
        sorter: (a, b) => a.subAccount.localeCompare(b.subAccount),
    },

    {
        title: <span style={{ fontSize: "12px" }}>Сlient Order ID</span>,
        dataIndex: "clientOrderId",
        width: 80,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "12px" } // Установите значение, соответствующее желаемому размеру шрифта
            }
        })
        // fixed: "left",
    },

    {
        title: "",
        dataIndex: "action",
        // width: 120,
        fixed: "right",
        align: "right",
        className: "action-column multi"
    },
    // {
    //     title: "",
    //     dataIndex: "file",
    //     fixed: "right",
    //     className: "action-column"
    // }
]


// {
//     title: "Номер заказа",
//     dataIndex: "orderNumber",
//     width: 80,
//     align: "center",
//     render: (text, record) => text ? `№ ${text}` : `ID ${record.clientOrderId}`,
// },

// {
//     title: "Статус",
//     dataIndex: "orderStatusId",
//     width: 100,
//     align: "right",
//     render: getOrderStatusName,
//     sorter: (a, b) => a.orderStatus.localeCompare(b.orderStatus)
// },
