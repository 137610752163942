import React, { useState, useEffect } from "react";
import { Modal, Form, notification, Input } from "antd";
import { timeForWarningNotification } from "../../../constants";
const { Item } = Form;

interface CustomInputProps {
    value: string;
    onPressEnter: () => void;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    visible: boolean;
    onCancel: () => void;
}

export const CustomInput = ({ value, onPressEnter, setPassword, visible, onCancel }: CustomInputProps) => {
    const [error, setError] = useState<boolean>(false);
    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [notificationShown, setNotificationShown] = useState(false);

    const validateInput = (value: string) => {
        const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/;
        const russianRegex = /[а-яА-Я]/;

        if (value && !regex.test(value)) {
            setError(true);
            setPassword(value);
            setButtonDisabled(true);
        } else if (russianRegex.test(value)) {
            setError(true);
            setPassword(value);
            setButtonDisabled(true);
        } else {
            setPassword(value);
            setError(false);
            setButtonDisabled(false);
        }
    };

    useEffect(() => {
        if (isButtonDisabled && !notificationShown && value !== '') {
            notification.warn({
                duration: timeForWarningNotification,
                message: "Пароль должен содержать латинские буквы.",
                placement: 'top'
            });
            setNotificationShown(true);
        }

        if (value === '') {
            setButtonDisabled(true);
        } else {
            const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/;
            const russianRegex = /[а-яА-Я]/;

            if (value && !regex.test(value)) {
                setButtonDisabled(true);
            } else if (russianRegex.test(value)) {
                setButtonDisabled(true);
            } else {
                setButtonDisabled(false);
            }
        }
    }, [isButtonDisabled, notificationShown, value]);

    return (
        <Modal
            title='Введите пароль'
            visible={visible}
            onCancel={() => {
                onCancel()
                setError(false);
                setButtonDisabled(false);
            }}
            onOk={() => {
                onPressEnter()
                setError(false)
            }}
            okButtonProps={{ disabled: isButtonDisabled }}
            style={{ marginTop: '20vh' }}
            zIndex={2000}
        >
            <Form>
                <Item
                    validateStatus={error ? 'error' : ''}
                    help={error ? 'Пожалуйста выберите английскую раскладку.' : ''}
                >
                    <Input.Password
                        type="password"
                        style={{ marginTop: "10px" }}
                        placeholder="Введите пароль"
                        value={value}
                        onPressEnter={isButtonDisabled ? () => { } : () => {
                            onPressEnter()
                            setError(false)
                        }}
                        onChange={(e) => validateInput(e.target.value)}
                    />
                </Item>
            </Form>
        </Modal>
    );
}
