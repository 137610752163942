import TADaraLogo from './media/TADara.png';

export const appMode: string = process.env.REACT_APP_MODE || 'test';
const getHost = (mode: string): string => {
    switch (mode) {
        case 'dev': return "https://dev.fhs.kz";
        case 'test': return "https://test.fhs.kz";
        case 'prod': return "https://cabinet.fhs.kz";
        default: return "https://dev.fhs.kz";
    }
}
const host = getHost(appMode);
const baseUrl = host + "/api/";
export const api = {
    host,
    base: baseUrl,
    login: baseUrl + "auth/v1/login",
    quotes: baseUrl + "mock",
    edsSecret: baseUrl + "auth/v1/get/checktext",
    edsValidate: baseUrl + "auth/v1/validate/checktext",
    consentStartOver: baseUrl + "auth/v1/consent-startover",
    refresh: baseUrl + "auth/v1/refresh",
    logout: baseUrl + "auth/v1/logout"
}

export const timeForSuccessNotification: number | null = 180;
export const timeForWarningNotification: number | null = 180;
export const timeForErrorNotification: number | null = null;
export const timeForInfoNotification: number | null = 180;

export const optionStyles = {
    textAlign: "center"
}

export const logoName = 'T.A.Dara'

export const pathLogo = TADaraLogo
// REACT_APP_ENV=dev
// REACT_APP_ENV=test
// REACT_APP_ENV=prod

export const confirmationIsOn = true;

export const sideComponentWidth = 30;
export const sideComponentWidthRepo = 50;

// 600_000 - 10 minutes
export const inactivityTime = 600_000;

export const successMessages = {
    decreeSent: "Приказ отправлен!",
    orderSent: "Заказ отправлен!"
}

export const warningMessages = {
    notEnoughFunds: "Недостаточно средств на счете для проведения операции.",
    missingFields: "Пожалуйста, заполните все поля формы."
};

export const errorMessages = {
    
}