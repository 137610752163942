import { useSelector } from 'react-redux';
import { dispatch, TState } from '../../../store';
import React, { useEffect, useState } from 'react';
import { Button, Modal, notification, Space } from 'antd';
import { closeDuplicateRequestWindow, getOrders, getOrderTemplates, setTemporaryOt, setTempReqType, setTempRequest } from '../../../store/orders.slice';
import { apiConnector } from '../../../integrations/api.connector';
import { errorMessages, timeForErrorNotification, timeForSuccessNotification } from '../../../constants';
import { NotificationsConnector } from '../../../integrations/notifications.connector';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ECheckReqType, EOrderTemplateStatus, TOrderTemplate } from '../../../types/orders';

export const DuplicateRequestAlertModal = () => {
    const {
        orders: {
            showDuplicateRequestModal,
            temporaryRequest,
            tempReqType,
            temporaryOt
        },
        auth: {
            userName,
            iin
        }
    } = useSelector((state: TState) => state);
    const switchValue = useSelector((state: TState) => state.auth.switch);
    const [loading, setLoading] = useState<boolean>(false);

    const isActual = (): boolean => (
        tempReqType && temporaryRequest
    )

    const handleOk = async () => {
        if (tempReqType && temporaryRequest) {
            setLoading(true);
            let customMessage = "приказ";
            let result = null;
            let newStatus: EOrderTemplateStatus = EOrderTemplateStatus.pending;
            try {
                switch (tempReqType) {
                    case ECheckReqType.eqReq:
                        customMessage = "заказ";
                        result = await apiConnector.putOrderEquities(temporaryRequest, switchValue); 
                        break
                    case ECheckReqType.bondsReq:
                        customMessage = "заказ";
                        result = await apiConnector.putOrderBonds(temporaryRequest, switchValue); break
                    case ECheckReqType.repoReq:
                        customMessage = "заказ";
                        result = await apiConnector.putOrderRepos(temporaryRequest, switchValue); break
                    case ECheckReqType.moneyTransferReq:
                        result = await apiConnector.putMoneyTransferOrder(temporaryRequest); break
                    case ECheckReqType.secTransferReq:
                        result = await apiConnector.putSecurityTransferOrder(temporaryRequest); break
                    case ECheckReqType.tradeCancelReq:
                        result = await apiConnector.putCancelOrder(temporaryRequest); break
                    case ECheckReqType.nonTradeCancelReq:
                        result = await apiConnector.putCancelNonTradeOrder(temporaryRequest); break
                }
    
                const success = result.success;
                const errMessage = result.message;
                
                dispatch(setTempRequest(null));
                dispatch(setTempReqType(null));
                dispatch(closeDuplicateRequestWindow());
                if (!success) {
                    if (temporaryOt) {
                        newStatus = EOrderTemplateStatus.invalid;
                    }
                    
                    notification.error({
                        duration: timeForErrorNotification,
                        message: "Ошибка!",
                        description: errMessage
                    })
                } else {
                    dispatch(getOrderTemplates());
                    if (temporaryOt) {
                        newStatus = EOrderTemplateStatus.confirmed;
                    }
                    
                    notification.success({
                        duration: timeForSuccessNotification,
                        message: `Отправлен ${customMessage}!`,
                    })
                }
            } catch (e) {
                console.log(e);
                if (temporaryOt) {
                    newStatus = EOrderTemplateStatus.error;
                }
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: `Не удалось подписать ${customMessage}`
                })
            } finally {
                if (temporaryOt) {
                    const ot = temporaryOt;
                    await apiConnector.updateTemplate({
                        id: ot.id,
                        status: newStatus,
                        signer: userName || "н/д",
                        signerIIN: iin || "н/д"
                    });
                    dispatch(getOrderTemplates());
                }
                
                dispatch(getOrders());
                setLoading(false);
                dispatch(setTempRequest(null));
                dispatch(setTempReqType(null));
                dispatch(setTemporaryOt(null));
                dispatch(closeDuplicateRequestWindow());
            }       
        }
    };
    const handleCancel = () => {
        dispatch(setTempRequest(null));
        dispatch(setTempReqType(null));
        dispatch(closeDuplicateRequestWindow());
        notification.info({
            duration: timeForSuccessNotification,
            message: "Повторая отправка заказа/приказа отменена",
        });
    };

    useEffect(() => {
        console.log(temporaryRequest)
    }, []);

    return (
        <Modal
            visible={showDuplicateRequestModal}
            footer={<Space>
                    <Button onClick={handleOk} block loading={loading} disabled={!isActual()} >Да</Button>
                    <Button onClick={handleCancel} type="primary" loading={loading} danger block>Нет</Button>
                </Space>}
            width={550}
            style={{ overflow: "hidden" }}
            zIndex={50}
            closable={false}
            centered
        >
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: 'column' }}>
                <p style={{fontWeight: "bold", fontSize: "18px"}}><ExclamationCircleFilled style={{color: "red"}} />  Вы пытаетесь повторно отправить приказ/заказ</p>
                <p style={{fontSize: "16px"}}>Продолжить отправку?</p>
            </div>
            
        </Modal>
    )
}
