export type RepAccountStatement = {
    "additional": string,
    "baseQuantity": number,
    "blockQuantity": number,
    "clientId": number,
    "isin": string,
    "issuerCountry": string,
    "issuerName": string,
    "maturityDate": string,
    "repoQuantity": number,
    "reportDate": string,
    "reportNumber": number,
    "reportRow": number,
    "securityType": string,
    "tradeCode": string,
    "zalogQuantity": number
}
export type RepTransaction = {
    "accCoup": number,
    "additional": string,
    "amount": number,
    "clientId": number,
    "currency": string,
    "id": number,
    "isin": string,
    "issuerName": string,
    "orderDate": string,
    "orderNumber": string,
    "orderType": string,
    "percentPrice": number,
    "price": number,
    "quantity": number,
    "repoCloseAmount": number,
    "repoCloseDate": string,
    "repoClosePrice": number,
    "reportDate": string,
    "reportNumber": number,
    "reportRow": number,
    "securityType": string,
    "tradeCode": string,
    "tranType": string,
    "yield": number
}
export type RepOrder = {
    "additional": string,
    "amount": number,
    "brokerCommission": number,
    "clientId": number,
    "contraCommission": number,
    "currency": string,
    "idMarketDeal": string,
    "isin": string,
    "issuerName": string,
    "marketCommission": number,
    "orderDate": string,
    "orderNumber": string,
    "orderStatus": string,
    "orderType": string,
    "otherCommission": number,
    "percentPrice": number,
    "price": number,
    "quantity": number,
    "repoCloseAmount": number,
    "repoCloseDate": string,
    "repoClosePrice": number,
    "reportDate": string,
    "reportNumber": number,
    "reportRow": number,
    "securityType": string,
    "settleDate": string,
    "stockDate": string,
    "tradeCode": string,
    "tranType": string,
    "yield": number
}
// Securities movement
export type RepMove = {
    "additional": string,
    "begSaldo": number,
    "clientId": number,
    "credit": number,
    "currency": string,
    "debit": number,
    "endSaldo": number,
    "faceValue": number,
    "id": number,
    "isin": string,
    "issuerName": string,
    "orderData": string,
    "reportDate": string,
    "reportNumber": number,
    "reportRow": number,
    "securityType": string,
    "settleDate": string,
    "tradeCode": string,
    "tranType": string
}
// Money movemont
export type RepCashFlow = {
    "additional": string,
    "begSaldo": number,
    "bankName": string, // not present
    "bic": string, // format! АО "Центральный депозитарий ценных бумаг" , CEDUKZKA
    "cbe": string, // not present
    "cliName": string,
    "clientId": number,
    "credit": number,
    "currency": string, // not present
    "debit": number,
    "endSaldo": number,
    "iic": string, // format! KZ107660000366162001 I+ KZT MOEX
    "iin": string, // format! 080740012607, 15
    "orderData": string,
    "reportDate": string,
    "reportNumber": number,
    "reportRow": number,
    "settleDate": string,
    "tranType": string
}

export enum EReportType {
    ALL_REPORTS = "ALL-REPORTS",
    ACCOUNT_STATEMENT = "ACCOUNT_STATEMENT",
    DAILY_REPORT = "DAILY_REPORT",
    CUSTOMER_REPORT = "CUSTOMER_REPORT",
    MOVES_AND_CASH_FLOW_REPORT = "MOVES_AND_CASH_FLOW_REPORT",
    EXECUTION_DAILY_REPORT = "EXECUTION_DAILY_REPORT"
}

export type ReportMetaRecord = {
    complete: boolean, 
    clientId: number,
    id: string,
    startDate?: string, // ISO Date - Если есть, то Дата начала периода
    endDate: string, // ISO Date - Если есть startDate, то Дата конца периода, иначе - Дата отчета
    processId: string, // UUID
    reportType: EReportType, // Тип отчета
    updatedAt: string, // ISO Date - Дата формирования отчета
}

/**
 * Формирование ссылок для скачивания
 *
 * PDF (Скачать Отчет)
 * `${api.base}/report-manager/client/getPdf/${example1.processId}.pdf`
 *
 * CMS (Скачать цифровой документ)
 * `${api.base}/report-manager/client/getCms/${example1.processId}.cms`
 *
 */