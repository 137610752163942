import axios, { AxiosResponse } from "axios";
import { dispatch, store } from '../store';
import { logOut } from '../store/auth.slice';
import { setTempReqType, setTempRequest, setTempTemplate, setTemporaryOt, showDuplicateRequestWindow, showDuplicateTemplateWindow } from "../store/orders.slice";
import { NotificationsConnector } from "./notifications.connector";
import { QuotesConnector } from "./quotes.connector";
import {
    IListResponse,
    IListResponsePortfolio,
    IResponse,
    TAuthRequest,
    TAuthResponse, TBrokerInfo, TClientData, TFTAccount, TFullProfile, THolderInfo,
    TINs,
    TOrderStatusUpdate, TOutBankData,
    TOwnSecurity,
    TProfile,
    TSecretTextResponse,
    TSubAccount
} from "../types/api";
import { TAnalyticsData } from "../types/analytics";
import { TAnalyticsDataExtended } from "../types/analyticsExtended";
import { JWT } from "../types/common";
import {
    TCancelOrderRequest,
    TDealStatusRecord,
    TMoneyTransferBankCorrData,
    TMoneyTransferRecipientData,
    TMoneyTransferRequest, TNonTradeOrderRequest,
    TOrderDeals,
    TOrderStatusFull,
    TOrderTemplateUpdate,
    TPDFReportRequest,
    TPDFRequest,
    TPutOrderBonds,
    TPutOrderBondsRequest,
    TPutOrderEquities,
    TPutOrderEquitiesRequest,
    TPutOrderRepoRequest,
    TPutOrderRepo,
    TRejectOrderRequest,
    TSecuritiesTransferRequest,
    TSetOrderTemplate,
    TDecisionData,
    EOrderTemplateStatus,
    ECheckReqType,
    TOrderTemplate
} from '../types/orders';
import { TNotification } from "../types/notifications";
import { TRepoBasket, TRepoCurrent, TRepoType } from '../types/repo';
import { RepAccountStatement, RepCashFlow, RepMove, RepOrder, RepTransaction, ReportMetaRecord } from '../types/reports';
import { TRepaymentsByAssets, TRepaymentsByDate } from "../types/repayments";
import { getCertificate, getCertificateInfo, loginWithEDS, loginWithEDSV2 } from '../services/eds.auth';
import { decodeJWT } from "../services/jwtDecoder";
import { api, timeForSuccessNotification } from "../constants";
import { notification } from "antd";
import { uuid } from "../services/uuid";

class ApiConnector {
    public token: string | undefined;
    private refresh: string | undefined;
    // private requestHistory: {params: TPutOrderRepo | TPutOrderBonds | TPutOrderEquities | TRejectOrderRequest | TNonTradeOrderRequest | { signedXml: string, orderData: TMoneyTransferRequest, switchValue?: boolean } | { signedXml: string, orderData: TSecuritiesTransferRequest, switchValue?: boolean } | {signedXml: string, orderData: null}, reqType: ECheckReqType} | undefined;
    private requestHistory: {params: any, reqType: ECheckReqType} | undefined;
    private templateHistory: TSetOrderTemplate | undefined;

    public async loginText(credentials: TAuthRequest): Promise<JWT> {
        try {
            const response = await axios.post<TAuthRequest, AxiosResponse<TAuthResponse>>(api.login, credentials)
            const {
                data: {
                    access_token,
                    refresh_token
                }
            } = response;
            this.token = access_token;
            this.refresh = refresh_token;
            this.timeForLogout();
            return access_token;
        } catch (e: any) {
            throw new Error(e)
        }
    }

    public async loginEDS(): Promise<{ singleSession: boolean, token?: JWT, hash?: string }> {
        try {
            const auth = await loginWithEDS();
            const { access_token, refresh_token, sessionInvalidateConsentHash, activeSessions } = auth;
            if (activeSessions && activeSessions > 0 && sessionInvalidateConsentHash) {
                return { singleSession: false, hash: sessionInvalidateConsentHash }
            } else {
                this.token = access_token;
                this.refresh = refresh_token;
                this.timeForLogout();
                return { singleSession: true, token: access_token };
            }
        } catch (e: any) {
            throw new Error(e)
        }
    }

    public async loginEDSV2(certificatePath: string, password: string): Promise<{ singleSession: boolean, token?: JWT, hash?: string }> {
        try {
            const auth = await loginWithEDSV2(certificatePath, password);
            const { access_token, refresh_token, sessionInvalidateConsentHash, activeSessions } = auth;
            if (activeSessions && activeSessions > 0 && sessionInvalidateConsentHash) {
                return { singleSession: false, hash: sessionInvalidateConsentHash }
            } else {
                this.token = access_token;
                this.refresh = refresh_token;
                this.timeForLogout();
                return { singleSession: true, token: access_token };
            }
        } catch (e: any) {
            throw e
        }
    }

    public async getCertificate(): Promise<any> {
        try {
            return await getCertificate();
        } catch (e: any) {
            throw new Error(e)
        }
    }

    public async getCertificateInfo(path: string, password: string): Promise<any> {
        try {
            return await getCertificateInfo(path, password);
        } catch (e: any) {
            throw new Error(e)
        }
    }

    public async loginWithSICH(hash: string): Promise<JWT> {
        const body = {
            consentHash: hash
        }
        try {
            const { data: { access_token, refresh_token } } = await axios.post<{ consentHash: string }, AxiosResponse<TAuthResponse>>(api.consentStartOver, body);
            this.token = access_token;
            this.refresh = refresh_token;
            this.timeForLogout();
            return access_token;
        } catch (e: any) {
            throw new Error(e)
        }
    }

    public async getSecretForEDS(ins: TINs): Promise<Partial<TSecretTextResponse>> {
        try {
            const response = await axios.post<TINs, AxiosResponse<TSecretTextResponse>>(api.edsSecret, ins);
            const { data } = response;
            return data;
        } catch (e) {
            throw new Error()
        }
    }

    public async validateEDS(secretB64: string, signature: string): Promise<TAuthResponse> {
        const body = {
            certType: "AUTH",
            data: secretB64,
            signature,
            type: "text"
        }
        try {
            const { data } = await axios.post(api.edsValidate, body);
            return data;
        } catch (e: any) {
            throw e.response.data.message
        }
    }

    public async validateEDSV2(secretB64: string, signature: string): Promise<TAuthResponse> {
        const body = {
            certType: "AUTH",
            data: secretB64,
            signature,
            type: "text"
        }

        try {
            const { data } = await axios.post(api.edsValidate, body);
            return data;
        } catch (e: any) {
            throw e;
        }
    }

    public async logout(): Promise<void> {
        // await this.call({path: `auth/v1/logout`, method: "post"});
        try {
            QuotesConnector.close();
            NotificationsConnector.close();
            await axios.post(`${api.base}auth/v1/logout`, {}, {
                headers: {
                    "Authorization": `Bearer ${this.token}`,
                    "Refresh-Token": this.refresh!
                }
            })
        } catch (e) {
            console.log(e)
        } finally {
            this.token = undefined;
            this.refresh = undefined;
        }
    }

    public async getProfile(): Promise<TProfile> {
        const path = `auth/v1/profile`;
        return await this.call({ path });
    }

    public async getMoneyRem(): Promise<{ success: boolean, payload: TFTAccount[] }> {
        const path = `money/moneyRem`;
        return await this.call({ path })
    }

    public async getOutBanks(currency: string): Promise<{ success: boolean, payload: TOutBankData[] }> {
        const path = `money/outBanks/${currency}`;
        return await this.call({ path })
    }

    public async getClientData(): Promise<{ success: boolean, payload: TClientData }> {
        const path = `money/clientData`;
        return await this.call({ path });
    }

    public async getClientReportList(): Promise<{ success: boolean, payload: ReportMetaRecord[] }> {
        const path = `report-manager/reports/client/getList`;
        return await this.call({ path });
    }

    public async getCompanyInfo(): Promise<TFullProfile> {
        const path = `profile/getCompany`;
        return await this.call({ path });
    }
    public async getPersonInfo(): Promise<TFullProfile> {
        const path = `profile/getPerson`;
        return await this.call({ path });
    }

    public async getAccounts(local: boolean): Promise<TSubAccount[]> {
        const path = `security/accounts/get-accounts?localBool=${local ? 1 : 0}&intBool=${local ? 0 : 1}`;
        return await this.call<null, TSubAccount[]>({ path })
    }

    public async getHoldersInfo(): Promise<THolderInfo[]> {
        const path = `profile/holderInfo`;
        return await this.call<null, THolderInfo[]>({ path })
    }

    public async getBrokersInfo(): Promise<TBrokerInfo[]> {
        const path = `profile/brokerInfo`;
        return await this.call<null, TBrokerInfo[]>({ path })
    }

    public async getPortfolio(): Promise<IListResponsePortfolio<TOwnSecurity>> {
        const path = `trade-orders/getPortfolio`;
        return await this.call({ path })
    }

    public async getDecisions(): Promise<IResponse<TDecisionData[]>> {
        const path = `internals/fhs-decisions`;
        return await this.call({ path, method: 'GET' });
    }

    public async getOrders(params: URLSearchParams | undefined): Promise<IListResponsePortfolio<TOrderStatusFull>> {
        const path = params !== undefined ? `trade-orders/getOrders?${params}` : `trade-orders/getOrders`;
        return await this.call({ path });
    }

    public async getAllOrders(): Promise<TOrderStatusFull[]> {
        const path = `orders-status/get-statuses`;
        return await this.call({ path })
    }

    public async getDeals(): Promise<{ success: boolean, payload: TOrderDeals[] }> {
        const path = `orders-status-v2/getDeals`;
        return await this.call({ path });
    }

    public async getPDFForReport(params: TPDFReportRequest): Promise<any> {
        // const path = `${api.base}report-manager/client/getPdf/${report.processId}.pdf`;
        const path = `report-manager/reports/client/getPdf`;

        if (!this.token) {
            throw new Error('No Token');
        }
        try {
            // if (!this.isValid()) {
            //     await this.refreshToken();
            // }
            const { data } = await axios(api.base + path, {
                method: 'post',
                data: params,
                headers: {
                    "Authorization": `Bearer ${this.token}`
                },
                responseType: "arraybuffer"
            });
            return data;
        } catch (e: any) {
            throw new Error(e)
        }
    }

    public async getCMSForReport(params: TPDFReportRequest): Promise<any> {
        const path = `report-manager/reports/client/getCms`;
        if (!this.token) {
            throw new Error('No Token');
        }

        try {
            const { data } = await axios(api.base + path, {
                method: 'post',
                data: params,
                headers: {
                    "Authorization": `Bearer ${this.token}`
                },
                responseType: "arraybuffer"
            });
            return data;
        } catch (e: any) {
            throw new Error(e)
        }
    }

    public async getDealsDetails(): Promise<{ success: boolean, payload: TDealStatusRecord[] }> {
        const path = `orders-status-v2/getDeals`;
        const response: { success: boolean, payload: TOrderDeals[] } = await this.call({ path });
        const result = response.payload.map((deals: TOrderDeals) => {
            return deals.deals
        }).flat(1)
        if (result.length) {
            return { success: true, payload: result }
        } else {
            return { success: false, payload: [] }
        }
    }

    public async getAllAnalytics(query?: string): Promise<TAnalyticsData[]> {
        const path = `analytics/getAll${query || ''}`;
        return await this.call({ path })
    }

    public async getAllAnalyticsExtended(query?: string): Promise<TAnalyticsDataExtended[]> {
        const path = `analytics/getExtAll${query || ''}`;
        return await this.call({ path })
    }

    public async getAllRepaymentsByAsset(query?: string): Promise<TRepaymentsByAssets[]> {
        const path = `analytics/getDataMaturityByAsset${query || ''}`;
        return await this.call({ path })
    }

    public async getAllRepaymentsByDate(query?: string): Promise<TRepaymentsByDate[]> {
        const path = `analytics/getDataMaturityByDate${query || ''}`;
        return await this.call({ path })
    }

    public async getOrdersUpdates(orderIds: string[]): Promise<TOrderStatusUpdate[]> {
        const path = `porders/get-orders-status-updates`;
        return await this.call({ path, method: "post", body: orderIds });
    }

    public async getEquities(page: number = 0, size?: number, search?: string): Promise<IListResponse<any>> {
        const path = `stocks/get_stocks?page=${page}${size ? '&pageSize=' + size : ""}${search ? '&tradeCode=' + search : ""}`
        return await this.call({ path })
    }
    public async getBonds(page: number = 0, size?: number, search?: string): Promise<IListResponse<any>> {
        const path = `security/security/get_bonds?page=${page}${size ? '&pageSize=' + size : ""}${search ? '&tradeCode=' + search : ""}`
        return await this.call({ path })
    }
    public async getRepoCurrent(page: number = 0, size?: number): Promise<IListResponse<TRepoCurrent>> {
        const path = `order-repos/get-repo-rem?page=${page}${size ? '&pageSize=' + size : ""}`;
        return await this.call({ path })
    }
    public async getRepoTypes(): Promise<TRepoType[]> {
        const path = `security/repos/trade-types`;
        return await this.call({ path })
    }
    public async getRepoBaskets(tradeModeCode: string): Promise<TRepoBasket[]> {
        const path = `security/repos/repo-basket?tradeModeCode=${tradeModeCode}`;
        return await this.call({ path })
    }
    // Reports
    public async getAccountStatement(date: string, page: number = 0, size?: number): Promise<IListResponse<RepAccountStatement>> {
        const path = `reports/v1/get-account-statement?page=${page}${size ? '&pageSize=' + size : ""}&reportDate=${date}`;
        return await this.call({ path })
    }
    public async getRepTransactions(date: string, page: number = 0, size?: number): Promise<IListResponse<RepTransaction>> {
        const path = `reports/v1/get-rep-002-transaction?page=${page}${size ? '&pageSize=' + size : ""}&reportDate=${date}`;
        return await this.call({ path })
    }
    public async getRepOrders(date: string, page: number = 0, size?: number): Promise<IListResponse<RepOrder>> {
        const path = `reports/v1/get-rep-003-order?page=${page}${size ? '&pageSize=' + size : ""}&reportDate=${date}`;
        return await this.call({ path })
    }
    public async getRepMoves(from: string, to: string, page: number = 0, size?: number): Promise<IListResponse<RepMove>> {
        const path = `reports/v1/get-rep-004-move?page=${page}${size ? '&pageSize=' + size : ""}&periodBegin=${from}&periodEnd=${to}`;
        return await this.call({ path })
    }
    public async getRepCashFlow(from: string, to: string, page: number = 0, size?: number): Promise<IListResponse<RepCashFlow>> {
        const path = `reports/v1/get-rep-004-cashflow?page=${page}${size ? '&pageSize=' + size : ""}&periodBegin=${from}&periodEnd=${to}`;
        return await this.call({ path })
    }
    // Orders
    public async getDataForSignEquities(params: TPutOrderEquitiesRequest): Promise<any> {
        const path = `stocks/v2/getDataForSign`;
        return await this.call({ path, method: 'post', body: params })
    }
    public async getDataForSignBonds(params: TPutOrderBondsRequest): Promise<any> {
        const path = `security/bond/v2/getDataForSign`;
        return await this.call({ path, method: 'post', body: params })
    }
    // public async getDataForSign(params: URLSearchParams, type: EGlobalSecurityType): Promise<any> {
    //     const path = `${type === EGlobalSecurityType.equity ? 'stocks' : 'security/orders'}/getDataForSign`;
    //     return await this.postUrlEncoded({path,params})
    // }
    public async getDataForSignRepo(params: TPutOrderRepoRequest): Promise<any> {
        const path = `security/repos/v2/getDataForSign`;
        return await this.call({ path, method: 'post', body: params })
    }
    // public async getDataForRepoSign(params: URLSearchParams): Promise<any> {
    //     const path = `security/repos/getDataForSign`;
    //     return await this.postUrlEncoded({path,params})
    // }
    public async putOrderEquities(params: TPutOrderEquities, switchValue?: boolean): Promise<any> {
        const path = switchValue ? `core-operations/stocks/v2/putOrder` : `trade-orders/putOrder`;
        this.requestHistory = {params, reqType: ECheckReqType.eqReq};
        return await this.call({ path, method: 'post', body: params })
    }
    public async putOrderBonds(params: TPutOrderBonds, switchValue?: boolean): Promise<any> {
        const path = switchValue ? `core-operations/security/bond/v2/putOrder` : `trade-orders/putOrder`;
        this.requestHistory = {params, reqType: ECheckReqType.bondsReq};
        return await this.call({ path, method: 'post', body: params })
    }
    public async putOrderRepos(params: TPutOrderRepo, switchValue?: boolean): Promise<any> {
        const path = switchValue ? `core-operations/security/repos/v2/putOrder` : `trade-orders/putOrder`;
        this.requestHistory = {params, reqType: ECheckReqType.repoReq};
        console.log("Req hist now", this.requestHistory)
        return await this.call({ path, method: 'post', body: params })
    }
    // public async putOrder(params: URLSearchParams, type: EGlobalSecurityType): Promise<any> {
    //     const path = `${type === EGlobalSecurityType.equity ? 'stocks' : 'security/orders'}/putOrder`;
    //     return await this.postUrlEncoded({path, params})
    // }
    public async putRepoOrder(params: URLSearchParams): Promise<any> {
        const path = `security/repos/putOrder`;
        return await this.postUrlEncoded({ path, params })
    }
    // Cancel Order
    // Deprecated
    public async getCancelDataForSign(params: TCancelOrderRequest): Promise<TCancelOrderRequest> {
        const path = `security/reject/v2/getDataForSign`;
        return await this.call({ path, method: "post", body: params })
    }
    public async putCancelOrder(params: TRejectOrderRequest): Promise<any> {
        this.requestHistory = {params, reqType: ECheckReqType.tradeCancelReq};
        const path = `trade-orders/rejectTradeOrder`;
        return await this.call({ path, method: "post", body: params })
    }
    public async putCancelNonTradeOrder(params: TNonTradeOrderRequest): Promise<any> {
        const path = `trade-orders/cancelNonTradeOrder`;
        this.requestHistory = {params, reqType: ECheckReqType.nonTradeCancelReq};
        return await this.call({ path, method: "post", body: params })
    }
    // public async putCancelNonTradeOrder(params: TNonTradeOrderRequest): Promise<any> {
    //     const path = `http://localhost:3003/nonTrade/cancel`;
    //     return await this.call({ path, method: "post", body: params, absPath: true })
    // }
    public async putMoneyTransferOrder(params: { signedXml: string, orderData: TMoneyTransferRequest, switchValue?: boolean }): Promise<any> {
        const path = params.switchValue ? `core-operations/money/transfer` : `money/transfer`;
        this.requestHistory = {params, reqType: ECheckReqType.moneyTransferReq};
        return await this.call({ path, method: "post", body: params })
        // const path = params.switchValue ? `http://localhost:3000/core-operations/money/transfer` : `money/transfer`;
        // return await this.call({ path, method: "post", body: params, absPath: true })
    }
    public async putMoneyTransferRecipientData(recipientData: TMoneyTransferRecipientData): Promise<any> {
        const path = `money/transferDetails`;
        return await this.call({ path, method: "post", body: recipientData })
    }

    public async putMoneyTransferBankCoreData(bankCorrData: TMoneyTransferBankCorrData): Promise<any> {
        const path = `money/transferDetails`;
        return await this.call({ path, method: "post", body: bankCorrData })
    }

    public async getMoneyTransferRecipientData(currency: string): Promise<any> {
        /// Delete when back-end is ready
        const ls = JSON.parse(localStorage.getItem('recipientData') || '[]')
        return ls
        ///
        const path = `money/transferDetails`;
        return await this.call({ path })
    }
    public async deleteMoneyTransferRecipientData(id: string): Promise<any> {
        /// Delete when back-end is ready
        const ls = JSON.parse(localStorage.getItem('recipientData') || '[]')
        const index = ls.findIndex((el: TMoneyTransferRecipientData) => el.id === id)
        ls.splice(index, 1)
        localStorage.setItem('recipientData', JSON.stringify(ls))
        return
        ///
        const path = `money/transferDetails`;
        return await this.call({ path })
    }

    public async getMoneyTransferBankCorrData(): Promise<any> {
        /// Delete when back-end is ready
        const ls = JSON.parse(localStorage.getItem('bankCorrData') || '[]')
        return ls
        ///
        const path = `money/transferDetails`;
        return await this.call({ path })
    }

    public async deleteMoneyTransferBankCorrData(id: string): Promise<any> {
        /// Delete when back-end is ready
        const ls = JSON.parse(localStorage.getItem('bankCorrData') || '[]')
        const index = ls.findIndex((el: TMoneyTransferBankCorrData) => el.id === id)
        ls.splice(index, 1)
        localStorage.setItem('bankCorrData', JSON.stringify(ls))
        return
        ///
        const path = `money/transferDetails`;
        return await this.call({ path })
    }
    public async putSecurityTransferOrder(params: { signedXml: string, orderData: TSecuritiesTransferRequest, switchValue?: boolean }): Promise<any> {
        const path = params.switchValue ? `core-operations/pledge/security/transfer` : `pledge/security/transfer`;
        this.requestHistory = {params, reqType: ECheckReqType.secTransferReq};
        return await this.call({ path, method: "post", body: params })
        // const path = params.switchValue ? `http://localhost:3000/core-operations/pledge/security/transfer` : `pledge/security/transfer`;
        // return await this.call({ path, method: "post", body: params, absPath: true })
    }
    // public async putSecurityTransferOrderTest(params: {signedXml: string, orderData: TSecuritiesTransferRequest}): Promise<any> {
    //     const path = `http://10.25.0.249:8000/security/transfer`;
    //     return await this.call({path, method: "post", body: params, absPath: true})
    // }
    // NonTrade Orders
    public async getNonTradeOrders(params: URLSearchParams | undefined): Promise<any> {
        const path = params !== undefined ? `orders-status-v2/getOrders?${params}` : `orders-status-v2/getOrders`;
        return await this.call({ path })
    }

    // Notification service 
    public async notificationSubscribe(clientId: number, orderIds: number[]): Promise<any> {
        const path = `notification-service/order`;
        const params = { clientId, orderIds };
        return await this.call({ path, method: "put", body: params })
    }

    public async notificationUnsubscribe(orderIds: number[]): Promise<any> {
        const path = `notification-service/order`;
        const params = { orderIds };
        return await this.call({ path, method: "delete", body: params })
    }

    public async notificationNotify(notification: TNotification): Promise<any> {
        const path = `notification-service/notify`;
        return await this.call({ path, method: "post", body: notification })
    }

    // Templates
    public async getTemplates(): Promise<any> {
        const path = `orders-status-v2/templates`;
        return await this.call({ path })
    }
    public async setTemplate(params: TSetOrderTemplate): Promise<any> {
        const path = `orders-status-v2/template`;
        this.templateHistory = params;
        return await this.call({ path, method: "post", body: params })
    }

    
    public async updateTemplate(params: TOrderTemplateUpdate): Promise<any> {
        const path = `orders-status-v2/template`;
        return await this.call({ path, method: "put", body: params })
    }
    // Generate PDF
    public async getPDF(params: TPDFRequest): Promise<any> {
        !params.traceId && (params.traceId = 0);
        const path = `pdf-gen/v1/generate-order`;
        if (!this.token) {
            throw new Error('No Token');
        }
        try {
            if (!this.isValid()) {
                await this.refreshToken();
            }
            const { data } = await axios(api.base + path, {
                method: 'post',
                data: params,
                headers: {
                    "Authorization": `Bearer ${this.token}`
                },
                responseType: "arraybuffer"
            });
            return data;
        } catch (e: any) {
            throw new Error(e)
        }
        return await this.call({ path, method: "post", body: params })
    }
    // Requests
    private async call<Request, Response>(
        { path, method = 'get', body, absPath }: { path: string, method?: string, body?: Request, absPath?: boolean }
    ): Promise<Response> {
        if (!this.token) {
            throw new Error('No Token');
        }
        try {
            if (!this.isValid()) {
                await this.refreshToken();
            }
            const { data } = await axios((absPath ? "" : api.base) + path, {
                method, data: body,
                headers: {
                    "X-Request-Id": `${uuid()}`,
                    "Authorization": `Bearer ${this.token}`
                },
                validateStatus: status => (status >= 200 && status < 404)
            });
            return data;
        } catch (e: any) {
            throw new Error(e)
        }
    }

    private async callV2<Request, Response>(
        { path, method = 'get', body, absPath }: { path: string, method?: string, body?: Request, absPath?: boolean }
    ): Promise<Response> {
        if (!this.token) {
            throw new Error('No Token');
        }
        try {
            if (!this.isValid()) {
                await this.refreshToken();
            }
            const { data } = await axios(('http://localhost:3001') + path, {
                method, data: body,
                headers: {
                    "Authorization": `Bearer ${this.token}`
                },
                validateStatus: status => (status >= 200 && status < 404)
            });
            return data;
        } catch (e: any) {
            throw new Error(e)
        }
    }

    private async postUrlEncoded<Response>(
        { path, params }: { path: string, params: URLSearchParams }
    ): Promise<Response> {
        if (!this.token) {
            throw new Error('No Token');
        }
        try {
            if (!this.isValid()) {
                await this.refreshToken();
            }
            const { data } = await axios(api.base + path, {
                method: "post",
                data: params,
                headers: {
                    "Authorization": `Bearer ${this.token}`,
                    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
                },
                validateStatus: status => (status >= 200 && status < 404)
            })
            return data;
        } catch (e: any) {
            throw new Error(e)
        }
    }

    private isValid(): boolean {
        if (!this.token) {
            return false;
        } else {
            const { exp } = decodeJWT(this.token);
            return !!exp && (exp * 1000) > Date.now() + 2000 // 2 secs for intermediate operations if any
        }
    }

    private async refreshToken(): Promise<void> {
        if (!this.refresh) {
            throw new Error('No Refresh Token');
        }
        try {
            const { data: { access_token, refresh_token } } = await axios.post<null, AxiosResponse<TAuthResponse>>(api.refresh, null, {
                headers: {
                    "Refresh-Token": this.refresh
                }
            });
            this.refresh = refresh_token;
            this.token = access_token;
        } catch (e: any) {
            console.log(e)
            throw new Error('Refresh Token Expired! Logging out');
        }
    }

    private timeForLogout() {
        if (!this.refresh) {
            throw new Error('No Refresh Token');
        }
        try {
            const data = { ...decodeJWT(this.refresh) };
            const expirationTime = data.exp * 1000; // Конвертация на миллисекунды
            const untilTime = expirationTime - Date.now() // Разница по времени
            setTimeout(() => {
                dispatch(logOut())
            }, untilTime) // Срабатывание после 1 800 000 миллисекунд, это 30 минут
        } catch (e: any) {
            console.log(e)
            throw new Error('Refresh Token Expired! Logging out');
        }
    }
    public async checkTemplate(params: TSetOrderTemplate): Promise<any> {
        const isSame = this.isSameTemplate(params);
        if (isSame) {
            dispatch(setTempTemplate(params));
            dispatch(showDuplicateTemplateWindow());
        } else {
            this.setTemplate(params);
            notification.success({
                duration: timeForSuccessNotification,
                message: "Заказ отправлен!",
            });
            NotificationsConnector.notify();
        }
    }

    public async checkRequest(params: any, reqType: ECheckReqType, ot?: TOrderTemplate, switchValue?: boolean): Promise<any> {
        const req = {params, reqType};
        const isSame = this.isSameRequest(req);
        if (isSame) {
            dispatch(setTempReqType(reqType));
            dispatch(setTempRequest(params));
            dispatch(setTemporaryOt(ot));
            dispatch(showDuplicateRequestWindow());
        } else {
            switch (reqType) {
                case ECheckReqType.eqReq:
                    return await this.putOrderEquities(params, switchValue);
                case ECheckReqType.bondsReq:
                    return await this.putOrderBonds(params, switchValue);
                case ECheckReqType.repoReq:
                    return await this.putOrderRepos(params, switchValue);
                case ECheckReqType.moneyTransferReq:
                    return await this.putMoneyTransferOrder(params);
                case ECheckReqType.secTransferReq:
                    return await this.putSecurityTransferOrder(params);
                case ECheckReqType.tradeCancelReq:
                    return await this.putCancelOrder(params);
                case ECheckReqType.nonTradeCancelReq:
                    return await this.putCancelNonTradeOrder(params);
            }
        }
    }

    private isSameRequest(request: {params: any, reqType: ECheckReqType}): boolean {
        const requestCopy = structuredClone(request);
        const reqHistoryCopy = structuredClone(this.requestHistory);

        const reqCopyParams = requestCopy.params;
        const orderDataReqCopy = this.ignoreDates(reqCopyParams.orderData);
        if (reqCopyParams.signedXml) {
            reqCopyParams["signedXml"] = "";
        }
        delete reqCopyParams.switchValue;
        reqCopyParams["orderData"] = orderDataReqCopy;
        requestCopy["params"] = reqCopyParams;
        if (reqHistoryCopy) {
            const reqHistoryCopyParams = reqHistoryCopy.params ? reqHistoryCopy.params : {signedXml: "", orderData: null};
            if (reqHistoryCopyParams.signedXml) {
                reqHistoryCopyParams["signedXml"] = "";
            }
            delete reqHistoryCopyParams.switchValue;
            const orderDataTempHistoryCopy = this.ignoreDates(reqHistoryCopyParams.orderData);
            reqHistoryCopyParams["orderData"] = orderDataTempHistoryCopy;
            reqHistoryCopy["params"] = reqHistoryCopyParams;
        }

        const currentRequest = JSON.stringify(requestCopy);
        const previousRequest = JSON.stringify(reqHistoryCopy);

        if (previousRequest === currentRequest) {
            return true;
        } else {
            return false;
        }
    }

    private isSameTemplate(params: TSetOrderTemplate): boolean {
        const paramsCopy = {...params};
        const templateHistoryCopy = {...this.templateHistory};
        const orderDataParCopy = this.ignoreDates(paramsCopy.orderData);
        const orderDataTempHistoryCopy = this.ignoreDates(templateHistoryCopy.orderData);
        paramsCopy["orderXml"] = "";
        paramsCopy["orderData"] = orderDataParCopy;
        templateHistoryCopy["orderXml"] = "";
        templateHistoryCopy["orderData"] = orderDataTempHistoryCopy;

        const currentTemplate = JSON.stringify(paramsCopy);
        const previousTemplate = JSON.stringify(templateHistoryCopy);

        if (previousTemplate === currentTemplate) {
            return true;
        } else {
            return false;
        }
    }

    private ignoreDates(orderData: any): any {
        const orderDataCopy = {...orderData};
        if (orderDataCopy.repoCloseDate) {
            orderDataCopy["repoCloseDate"] = null;
        }
        if (orderDataCopy.begDate) {
            orderDataCopy["begDate"] = null;
        }
        if (orderDataCopy.endDate) {
            orderDataCopy["endDate"] = null;
        }
        if (orderDataCopy.tradeDate) {
            orderDataCopy["tradeDate"] = null;
        }
        if (orderDataCopy.orderDate) {
            orderDataCopy["orderDate"] = null;
        }
        if (orderDataCopy.date) {
            orderDataCopy["date"] = null;
        }
        if (orderDataCopy.dataForSign) {
            orderDataCopy["dataForSign"] = null;
        }
        return orderDataCopy;
    }
}

export const apiConnector = new ApiConnector()
