import React, { FC } from 'react';
import { Table } from 'antd';
import { ColumnsType } from "antd/lib/table";
import { TFTAccount } from '../../../types/api';
import { ECurrency } from '../../../types/common';
import { getFullPageTableHeight, rowClassCompact } from '../../../services/helpers';
import { columnsByCurrency } from '../money.data';

export const TableMoneyByCurrency: FC<{ accounts: TFTAccount[], columns: ColumnsType<any>, loading: boolean }> = ({ accounts, columns, loading }) => {
    // const accountsByHolder = (holder)
    const nestedTableRender: FC<{ currencyCode: ECurrency, key: number }> = ({ currencyCode, key }) => {
        // .map(a => {a.key = key; return a})
        return <Table
            rowClassName={rowClassCompact}
            showSorterTooltip={false}
            columns={columns}
            dataSource={accounts.filter(a => a.currencyCode === currencyCode)}
            pagination={false} />
    }
    const currencies = (): { currencyCode: ECurrency, total: number, key: number }[] => {
        const currencies = new Set<ECurrency>();
        accounts.map((a) => {
            currencies.add(a.currencyCode)
        });
        let data: { currencyCode: ECurrency, key: number, total: number }[] = [];
        let i = 0;
        currencies.forEach(v => {
            data.push({ currencyCode: v, key: i, total: 0 });
            i++;
        });
        for (let cur of data) {
            cur.total = accounts.reduce((pV, cV) => {
                if (cur.currencyCode === cV.currencyCode) {
                    return pV + cV.totalAmount;
                } else {
                    return pV
                }
            }, 0);
        }
        return data;
    }
    return (
        <Table
            size="small"
            showSorterTooltip={false}
            rowClassName={"group-head"}
            scroll={{ y: getFullPageTableHeight() - 100 }}
            dataSource={currencies()}
            columns={columnsByCurrency}
            expandable={{
                expandedRowRender: nestedTableRender,
                defaultExpandedRowKeys: [0]
            }}
            pagination={false}
            loading={loading} />
    )
}
