import React, { FC } from "react";
import { Col, Row } from "antd";
import { TMarketSecurity, TSubAccount } from "../../../types/api";
import {
    OrderType,
    TOrderTemplate,
    TPutOrderBondsRequest,
    TPutOrderEquitiesRequest
} from "../../../types/orders";
import { countNum, finNum, formatPrice } from "../../../services/formatter";

export const TradeForm: FC<{ t: TOrderTemplate, lib: TMarketSecurity[], accounts: TSubAccount[] }> = ({ t, lib, accounts }) => {
    const { securityId, quantity, localBool, orderTypeId, amount, currency } = t.orderData as TPutOrderEquitiesRequest | TPutOrderBondsRequest;
    const isBond = t.extraData?.isBond;
    const targetSecurity = lib.find(sec => sec.securityId === securityId && sec.localBool === localBool);
    return (
        <>
            <Row className="bordered-row ticker-currency">
                <Col span={12}>{targetSecurity && targetSecurity.ticker}</Col>
                <Col span={12}>{targetSecurity && currency}</Col>
            </Row>
            <Row className="bordered-row">
                <Col span={24}>
                    <span>{targetSecurity && `${targetSecurity.isin} - ${targetSecurity.globalType}`}</span><br />
                    <small>{targetSecurity && targetSecurity.securityName}</small>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Тип:
                </Col>
                <Col span={16}>
                    {orderTypeId === 1 ? "Лимитный" : "Раночный"}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Количество:
                </Col>
                <Col span={16}>
                    {countNum(quantity)}
                </Col>
            </Row>
            {
                orderTypeId === OrderType.limited &&
                <Row className="of-row">
                    <Col span={8}>
                        Цена:
                    </Col>
                    <Col span={16}>
                        {isBond ? formatPrice(t.orderData.netPercentPrice, 4) : formatPrice(t.orderData.price, 2)}
                    </Col>
                </Row>
            }
            {
                isBond &&
                <Row>
                    <Col span={8}>
                        Доходность:
                    </Col>
                    <Col span={16}>
                        {t.orderData.profit}
                    </Col>
                </Row>
            }
            <Row className="of-row">
                <Col span={8}>
                    Сумма сделки:
                </Col>
                <Col span={16}>
                    {finNum(amount)}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Срок действия:
                </Col>
                <Col span={16}>
                    {new Date(t.orderData.tradeDay).toLocaleDateString()}
                </Col>
            </Row>
            {/*<Row className="of-row">*/}
            {/*    <Col span={8}>Выберите счёт:</Col>*/}
            {/*    <Col span={16}>*/}
            {/*        <Select className="of-select deal-account" size="small"*/}
            {/*                value={account?.imContractId.toString(10) || ""}*/}
            {/*                defaultValue={account?.imContractId.toString(10) || ""}*/}
            {/*                disabled={orderDirection === DealDirection.sell}*/}
            {/*                onChange={handleAccount}>*/}
            {/*            {*/}
            {/*                accounts.map((acc, i) => (*/}
            {/*                    <Select.Option className="of-option" key={`${i}`}*/}
            {/*                                   value={acc.imContractId.toString(10)}>{acc.holdPlace}</Select.Option>*/}
            {/*                ))*/}
            {/*            }*/}
            {/*        </Select>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </>
    )
}