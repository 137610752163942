import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, notification } from 'antd';
import { dispatch, TState } from '../../store';
import { cancelInvalidation, loginByEDS, loginByInvalidationHash } from '../../store/auth.slice';
import { pathLogo } from '../../constants';
import { timeForWarningNotification } from '../../constants';

export const DigitalSignature = () => {
    const {
        authError,
        authFetching,
        ncaLayer,
        multipleSessions,
        invalidatingSessions,
        invalidateHash
    } = useSelector((state: TState) => state.auth);
    const login = () => {
        if (ncaLayer) {
            dispatch(loginByEDS())
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "NCALayer не обнаружен или не запущен"
            })
        }
    }
    const handleOk = () => {
        invalidateHash && dispatch(loginByInvalidationHash(invalidateHash))
    }
    const handleCancel = () => {
        dispatch(cancelInvalidation())
    }
    return (
        <>
            <Button type="primary" block onClick={login}>Выберите ЭЦП</Button>
            {authError && <p className="err-message">Ошибка авторизации: Неверный пароль или отказ в доступе</p>}
            <img className="login-logo" src={pathLogo} alt="" />
            <Modal footer={false} closable={false}
                title="Ожидайте..."
                visible={authFetching}
            />
            <Modal
                onCancel={handleCancel}
                onOk={handleOk}
                visible={multipleSessions}
                confirmLoading={invalidatingSessions}
                title="Внимание!"
            >
                <p>Обнаружены открытые сессии на других устройствах.</p>
                <p>Закрыть другие сесии и продолжить работу в этом окне?</p>
            </Modal>
        </>
    )
}
