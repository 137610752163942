import { useSelector } from 'react-redux';
import { dispatch, TState } from '../../store';
import {
    DealDirection,
    DealKind, ECheckReqType, EOrderKind,
    EPDFOrderType, EPutOrderType, ETradeOrderType,
    OrderType,
    TPutOrderRepoRequest,
    TSetOrderTemplate
} from '../../types/orders';
import { ECurrency } from '../../types/common';
import { TMarketSecurity, TSubAccount } from '../../types/api';
import React, { useEffect, useState } from 'react';
import { BuyIcon } from '../shared/icons/buy.icon';
import { SellIcon } from '../shared/icons/sell.icon';
import { closeRepoOrder, getDecisions, getOrders } from '../../store/orders.slice';
import { Button, Col, Divider, InputNumber, Modal, notification, Radio, Row, Select, Spin } from 'antd';
import { countNum, finNum, formatPrice, parseNum, parsePrice } from '../../services/formatter';
import { FilePdfFilled } from '@ant-design/icons';
import { TRepoBasket } from '../../types/repo';
import { apiConnector } from '../../integrations/api.connector';
import { NcaMessage } from '../../types/nca';
import { NcaLayerConnector } from '../../integrations/ncaLayer.connector';
import { QuotesConnector } from "../../integrations/quotes.connector";
import { Quote } from "../market/Quote";
import { EQuoteType } from "../../types/quotes";
import { isCompany, isHeadOfCompany, repoGCBCC, repoGCBNoCC } from "../../services/helpers";
import { timeForErrorNotification, timeForSuccessNotification } from '../../constants';
import "./editedForm.css";
import { ModalDS } from '../shared/modals/ModalDS';
import { NotificationsConnector } from '../../integrations/notifications.connector';
import { TIntOrderDescription } from './InternalOrderDetails';

export const RepoOrderFormGCB = () => {
    const {
        auth: {
            userName,
            iin
        },
        orders: {
            showRepoOrderModal,
            repoTypes,
        },
        money: {
            subAccountsLocal
        },
        securities: {
            repoList,
            portfolio
        }
    } = useSelector((state: TState) => state);
    const switchValue = useSelector((state: TState) => state.auth.switch);
    const accounts: TSubAccount[] = [...subAccountsLocal];
    // REPOS
    const repoTypesCK = repoTypes.filter(rt => (rt.tradeModeCode.indexOf("EB") === 0));
    const repoTypesNoCK = repoTypes.filter(rt => (rt.tradeModeCode.indexOf("EL") === 0));
    const [orderDirection, setOrderDirection] = useState<DealDirection>(DealDirection.buy);
    const [orderType, setOrderType] = useState<OrderType>(OrderType.limited);
    const [estimate, setEstimate] = useState<number>(0);
    const [amount_, setAmount] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const [account, setAccount] = useState<TSubAccount>(accounts[0]);
    const [step, setStep] = useState<"form" | "sign" | "error">('form');
    const [params, setParams] = useState<TPutOrderRepoRequest | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [orderForm, setOrderForm] = useState<string>();
    const [currency, setCurrency] = useState<ECurrency>(ECurrency.KZT);
    const [CK, setCK] = useState(true);
    const [daysOptions, setDaysOptions] = useState<number[]>([]);
    const [days, setDays] = useState<number>(1);
    const [basketId, setBasketId] = useState<number>(3);
    const [basketOptions, setBasketOptions] = useState<TRepoBasket[]>([])
    const [secBoard, setSecBoard] = useState<string>();
    const [extraData, setExtraData] = useState<{ repoCloseDate: string, repoCloseAmount: number }>();
    const [quotKey, setQuotKey] = useState<string | null>(null)
    const [sending, setSending] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('');
    const [certificate, setCertificate] = useState<string>("");
    const [signError, setSignError] = useState<string | null>(null)
    const [securityId, setSecurityId] = useState<number | null>(null)
    const [securitiesOptions, setSecuritiesOptions] = useState<Partial<TMarketSecurity>[]>([])
    const [intOrderDescription, setIntOrderDescription] = useState<TIntOrderDescription>()

    const [decisionID, setDecisionID] = useState<number>()

    const getTitle = () => {
        return (
            <>
                {
                    orderDirection === DealDirection.sell ?
                        <BuyIcon /> : <SellIcon />

                }
                <span className="deal-direction">
                    {
                        orderDirection === DealDirection.sell ?
                            "Привлечь" : "Разместить"
                    }
                </span>
            </>
        )
    }
    const handleOk = () => {
        setStep("form")
        dispatch(closeRepoOrder())
    }
    const handleCancel = () => {
        setStep("form")
        dispatch(closeRepoOrder())
    }
    const options = [
        { label: 'Разместить', value: DealDirection.buy },
        { label: 'Привлечь', value: DealDirection.sell }
    ]
    useEffect(() => {
        !account && setAccount(subAccountsLocal[0])
    }, [account])
    const getDoc = async () => {
        const p = await apiConnector.getPDF({
            id: "",
            xmlData: orderForm!,
            orderId: "_____",
            orderDate: new Date().toISOString(),
            orderNumber: "_____",
            orderType: EPDFOrderType.repo
        });
        downloadPDF(p, 0);
    }
    const downloadPDF = (p: ArrayBuffer, clientOrderId: number) => {
        const file = new File([p], `${clientOrderId}.pdf`, { type: 'application/pdf' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${clientOrderId}.pdf`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
    }
    const formOrder = async () => {
        if (account) {
            let dealType: DealKind;
            switch (orderDirection) {
                case DealDirection.repoBuy:
                    dealType = DealKind.buy;
                    break;
                case DealDirection.buy:
                    dealType = DealKind.buy;
                    break;
                case DealDirection.repoSell:
                    dealType = DealKind.sell;
                    break;
                case DealDirection.sell:
                    dealType = DealKind.sell;
                    break;
                default:
                    dealType = DealKind.buy;
            }
            new URLSearchParams();
            let newOrder: TPutOrderRepoRequest = {
                signature: '',
                begDate: intOrderDescription?.start,
                endDate: intOrderDescription?.end,
                decisionID: decisionID,
                dealKindId: dealType,
                secBoard: secBoard || "",
                repoBasketId: basketId!,
                imContractId: account.imContractId,
                orderTypeId: orderType,
                price: price,
                amount: amount_,
                repoCloseAmount: 0,
                repoCloseDate: '',
                currency: currency,
                dataForSign: '',
                securityId: dealType === DealKind.sell ? securityId! : undefined
            };
            setParams(newOrder);
            const { dataForSign, errorMessage, repoCloseDate, repoCloseAmount } = await apiConnector.getDataForSignRepo(newOrder);
            if (dataForSign && !errorMessage) {
                setOrderForm(dataForSign);
                setExtraData({
                    repoCloseAmount,
                    repoCloseDate
                })
                setStep("sign");
            } else {
                console.log(errorMessage)
                setError(errorMessage)
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: errorMessage
                })
            }
        }
    }
    const signOrder = async () => {
        setSending(true);
        let method: string;
        let args: {};
        if (NcaLayerConnector.getBundleVersion()) {
            method = "signXmlV2"
            args = { xml: orderForm, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password }
        } else {
            method = "signXml"
            args = { xml: orderForm }
        }
        const message = new NcaMessage(method, args);

        if (certificate !== '' || password !== '') {
            try {
                const signature: string = await NcaLayerConnector.callNcaMethod(message);
                if (signature && params && orderForm) {
                    const { repoCloseDate, repoCloseAmount } = extraData!;
                    const paramsUpdated = { ...params, dataForSign: signature, repoCloseAmount, repoCloseDate };
                    setParams(paramsUpdated);
                    const orderType = EPutOrderType.repo;
                    const repoOrderData = { orderData: paramsUpdated, orderType: orderType };
                    const reqType = ECheckReqType.repoReq;
                    const ot = undefined;
                    const { success, details } = await apiConnector.checkRequest(repoOrderData, reqType, ot, switchValue);
                    // const { success, details } = await apiConnector.putOrderRepos(repoOrderData, switchValue);
                    if (success === false) {
                        setError(details);
                        notification.error({
                            duration: timeForErrorNotification,
                            message: "Ошибка!",
                            description: details
                        })
                    } else {
                        dispatch(getOrders());
                        dispatch(closeRepoOrder());
                        notification.success({
                            duration: timeForSuccessNotification,
                            message: "Заказ отправлен!",
                        });
                    }
                } else {
                    setError('Не удалось подписать заказ');
                    notification.error({
                        duration: timeForErrorNotification,
                        message: "Ошибка!",
                        description: "Не удалось подписать заказ"
                    })
                }
            } catch (e) {
                console.log(e);
                setSignError('error');
                setError('Не удалось отправить заказ');
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: "Не удалось отправить заказ"
                })
            } finally {
                setSending(false);
            }
        } else {
            notification.error({
                duration: timeForErrorNotification,
                message: "Ошибка!",
                description: "Нужно указать путь до ключа и ввести пароль"
            });
        }
    }
    const pendOrder = async () => {
        setSending(true);
        if (params && orderForm) {
            const { repoCloseDate, repoCloseAmount } = extraData!;
            const orderData: Partial<TPutOrderRepoRequest> = { ...params, repoCloseAmount, repoCloseDate };
            const orderTemplate: TSetOrderTemplate = {
                orderKind: EOrderKind.trade,
                orderType: ETradeOrderType.repo,
                orderData,
                orderXml: orderForm,
                author: userName || "н/д",
                authorIIN: iin || "н/д",
                extraData: { repoType: "GCB", repoDays: days }
            };
            try {
                await apiConnector.checkTemplate(orderTemplate);
                dispatch(closeRepoOrder());
            } catch (e) {
                console.log(e);
                setError('Не удалось отправить заказ');
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: "Не удалось отправить заказ"
                });
            } finally {
                setSending(false);
            }
        }
    }

    useEffect(() => {
        const target = CK ? repoTypesCK : repoTypesNoCK;
        const daysOptions = target.map(t => t.repoDay);
        setDays(1);
        setBasketId(CK ? 3 : 1);
        setDaysOptions(daysOptions);
    }, [CK])
    useEffect(() => {
        const target = CK ? repoTypesCK : repoTypesNoCK;
        const repo = target.find(t => t.repoDay === days);
        if (repo) {
            setSecBoard(repo.tradeModeCode);
            setBasketOptions(repo.baskets);
            let qKey = `${repo.tradeModeCode}-${CK ? "GCBRK" : "GCBRK-GR"}`;
            setQuotKey(qKey);
        }
    }, [days, CK])
    useEffect(() => {
        const est = ((price / 36500 * days) + 1) * amount_;
        setEstimate(parseNum(est.toFixed(2), 2));
    }, [days, CK, amount_, price])
    useEffect(() => {
        quotKey && QuotesConnector.watch(quotKey);
    }, [quotKey])
    // reset on data change
    useEffect(() => {
        if (step === "sign") {
            setStep("form");
            setOrderForm(undefined);
        }
        const tradeModeCode = CK ? repoGCBCC.get(days) : repoGCBNoCC.get(days);
        if (tradeModeCode) {
            setSecBoard(tradeModeCode);
            let qKey = `${tradeModeCode}-${CK ? "GCBRK" : "GCBRK-GR"}`;
            setQuotKey(qKey);
            if (orderDirection === DealDirection.sell) {
                const securities = repoList.find(rl => rl.tradeModeCode === tradeModeCode);
                securities ? setSecuritiesOptions(securities?.list.filter(rep => portfolio.some(por => por?.securityId === rep?.securityId))) : setSecuritiesOptions([]);
            }
        }
    }, [orderDirection, days, CK])

    useEffect(() => {
        dispatch(getDecisions());
    }, [])


    const CKValues = [
        { label: "ЦК", value: true },
        { label: "БЕЗ ЦК", value: false }
    ]

    return (
        <Modal
            title={getTitle()}
            visible={showRepoOrderModal}
            onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={420}
            bodyStyle={{ paddingTop: 2 }}
            style={{ overflow: "hidden" }}
            zIndex={50}
            centered
        >
            <Spin tip="Loading" spinning={sending} size="large">
                <Row className="of-row">
                    <Col span={9}>
                        Направление:
                    </Col>
                    <Col span={15}>
                        <Radio.Group
                            className="radio-wide deal-type"
                            options={options}
                            value={orderDirection}
                            optionType="button"
                            buttonStyle="solid"
                            size="small"
                            onChange={e => setOrderDirection(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="of-row">
                    <Col span={9}>Кол-во дней:</Col>
                    <Col span={15}>
                        <Select className="of-select repo-days" size="small"
                            value={days}
                            defaultValue={1}
                            onChange={v => setDays(v)}>
                            {
                                daysOptions.map((d, i) => (
                                    <Select.Option className="of-option" key={`${i}`}
                                        value={d}>{d}</Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                </Row>
                {orderDirection === DealDirection.sell && <Row className="of-row">
                    <Col span={9}>Инструмент:</Col>
                    <Col span={15}>
                        <Select className="of-select repo-basket" size="small"
                            value={securityId}
                            onChange={v => setSecurityId(v)}>
                            {
                                securitiesOptions && securitiesOptions.map((b, i) => (
                                    <Select.Option className="of-option" key={`${i}`}
                                        value={b.securityId}>{b.ticker}</Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                </Row>}
                <Row className="bordered-row quotes-row">
                    <div className="quotes-row-col">
                        <label>last</label>
                        <span><Quote secKey={quotKey} type={EQuoteType.last} /></span>
                    </div>
                    <div className="quotes-row-col">
                        <label>bid</label>
                        <span><Quote secKey={quotKey} type={EQuoteType.bid} /></span>
                    </div>
                    <div className="quotes-row-col">
                        <label>ask</label>
                        <span><Quote secKey={quotKey} type={EQuoteType.ask} /></span>
                    </div>
                </Row>
                <Row className="of-row">
                    <Col span={9}>ЦК:</Col>
                    <Col span={15}>
                        <Radio.Group
                            className="radio-wide deal-type"
                            options={CKValues}
                            value={CK}
                            optionType="button"
                            buttonStyle="solid"
                            size="small"
                            onChange={e => setCK(e.target.value)} />
                    </Col>
                </Row>
                <Row className="of-row">
                    <Col span={9}>Доходность:</Col>
                    <Col span={15}>
                        <InputNumber
                            value={price}
                            onChange={p => setPrice(p || 0)}
                            size="small" className="of-input deal-yield"
                            formatter={v => formatPrice(v!, 4)}
                            step=".01"
                            parser={v => parsePrice(v!)} />
                    </Col>
                </Row>
                <Row className="of-row">
                    <Col span={9}>Сумма открытия:</Col>
                    <Col span={15}>
                        <InputNumber
                            value={amount_}
                            onChange={p => setAmount(p || 0)}
                            size="small" className="of-input repo-amount-open"
                            formatter={v => countNum(v as number)}
                            parser={v => parseNum(v as string, 2)} />
                    </Col>
                </Row>
                <Row className="of-row">
                    <Col span={9}>Сумма закрытия:</Col>
                    <Col span={15}>
                        <InputNumber
                            value={estimate}
                            size="small" className="of-input repo-amount-close"
                            formatter={v => finNum(v as number)}
                            parser={v => parseNum(v as string, 2)}
                            readOnly />
                    </Col>
                </Row>
                <Divider />
                {
                    orderForm &&
                    <Row>
                        <Button target="_blank" onClick={getDoc} block type="link"
                            icon={<FilePdfFilled color="var(--fhs-red)" />}>Открыть заказ</Button>
                    </Row>
                }
                <Divider />
                <Row>
                    {step === "form" && <Button onClick={formOrder} disabled={sending} type="primary" block>Оформить заказ</Button>}
                    {step === "sign" &&
                        <>
                            {isCompany() && !isHeadOfCompany() && <Button loading={sending} disabled={sending} onClick={pendOrder} type="primary" block>Отправить на
                                согласование</Button>}
                        </>}
                </Row>
                <ModalDS
                    signError={signError}
                    step={step}
                    password={password}
                    certificate={certificate}
                    setCertificate={setCertificate}
                    setPassword={setPassword}
                    signOrder={signOrder}
                    isDisable={false}
                    sending={sending}
                    OT={false}
                    isActual={undefined}
                    loading={false}
                    onConfirm={undefined}
                    template={undefined}
                    isSigners={false}
                />
            </Spin>
        </Modal>
    )
}
