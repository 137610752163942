import React from "react";
import { notification } from "antd";
import { TFTAccount } from "../../../types/api";
import { formatPrice } from "../../../services/formatter";
import { timeForErrorNotification, timeForWarningNotification, warningMessages } from "../../../constants";

export const getCurrencies = (moneyRem: TFTAccount[]): string[] => {
    let curList: Set<string> = new Set<string>();
    for (const acc of moneyRem) {
        const { currencyCode, freeAmount } = acc;
        freeAmount > 0 && curList.add(currencyCode)
    }
    return Array.from(curList);
}
export const getAccTitle = (ftId: number | undefined, moneyRem: TFTAccount[]) => {
    if (ftId) {
        const acc = moneyRem.find(acc => acc.ftId === ftId);
        return !!acc ? <div className="acc-tooltip"><div>{acc.accountNumber}</div><div>{acc.currencyCode} {acc.freeAmount > 0 ? formatPrice(acc.freeAmount, 2) : '0,00'}</div></div> : ""
    } else {
        return ""
    }
}

export const getAccBalance = (ftId: number | undefined, moneyRem: TFTAccount[]) => {
    if (ftId) {
        const acc = moneyRem.find(acc => acc.ftId === ftId);
        return acc?.freeAmount
    } else {
        return 0
    }
}

export const checkAmountAndBalance = (accBalance: number | undefined, amount: number | undefined) => {
    if (accBalance !== undefined && amount !== undefined && accBalance < amount) {
        notification.warn({
            duration: timeForWarningNotification,
            message: warningMessages.notEnoughFunds // Если недостаточно средств, выводим предупреждение
        });
        return false;
    }
    return true;
};

export const checkFromToAccounts = (fromAccount: string | undefined, toAccount: string | undefined) => {
    if (!fromAccount || !toAccount) {
        notification.error({
            duration: timeForErrorNotification,
            message: "Ошибка!",
            description: "Не удалось найти номера счетов для отправки заказа."
        });
        return false;
    }
    return true;
};