import { ColumnsType } from 'antd/lib/table';
import { countNum, finNum } from "../../../services/formatter";
import { TRepaymentsByAssets } from "../../../types/repayments";

export const columns: ColumnsType<TRepaymentsByAssets & { action: any }> = [
    {
        title: "Тикер ЦБ",
        dataIndex: "secName",
        align: "left",
        width: 120,
        sorter: (a, b) => a.secName && b.secName ? a.secName.localeCompare(b.secName) : 1
    },
    {
        title: "ISIN",
        dataIndex: "isin",
        align: "left",
        width: 120,
        sorter: (a, b) => a.isin && b.isin ? a.isin.localeCompare(b.isin) : 1
    },
    {
        title: "Тип цб",
        dataIndex: "secType",
        align: "left",
        width: 120,
        sorter: (a, b) => a.secType && b.secType ? a.secType.localeCompare(b.secType) : 1
    },
    {
        title: "Валюта",
        dataIndex: "currency",
        align: "left",
        width: 160,
        sorter: (a, b) => a.currency.localeCompare(b.currency)
    },
    {
        title: "Дата погашения",
        dataIndex: "maturityDate",
        align: "left",
        width: 160,
        sorter: (a, b) => a.maturityDate && b.maturityDate ? new Date(a.maturityDate).valueOf() - new Date(b.maturityDate).valueOf() : 1
    },
    {
        title: "Количество",
        dataIndex: "quantity",
        render: countNum,
        align: "right",
        width: 160,
        sorter: (a, b) => a.quantity && b.quantity ? a.quantity - b.quantity : 1
    },
    {
        title: "Сумма",
        dataIndex: "amount",
        render: finNum,
        align: "right",
        width: 160,
        sorter: (a, b) => a.amount && b.amount ? a.amount - b.amount : 1
    },
    {
        title: "Сумма в тенге",
        dataIndex: "amountKzt",
        render: finNum,
        align: "right",
        width: 160,
        sorter: (a, b) => a.amountKzt && b.amountKzt ? a.amountKzt - b.amountKzt : 1
    },
]
