import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Button, Table } from 'antd';
import { ReloadOutlined } from "@ant-design/icons";
import { dispatch, TState } from '../../store';
import { getOpenRepos, showRepoOrder } from '../../store/orders.slice';
import { QuotesConnector } from "../../integrations/quotes.connector";
import { DealDirection } from "../../types/orders";
import { SellIcon } from '../shared/icons/sell.icon';
import { BuyIcon } from '../shared/icons/buy.icon';
import { TableEditor } from "../tableEditor/TableEditor";
import { getFullPageTableHeight, rowClassCompact, updateTableView } from "../../services/helpers";
import { columns } from "./current.data";

export const Current = () => {
    // const timerRef = useRef<number | undefined>();
    // const [searchString, setSearchString] = useState<string>()
    // const [loading, setLoading] = useState<boolean>(false)
    const { openRepos, openReposFetching } = useSelector((state: TState) => state.orders)
    // const onSearch = (value: string) => {
    //     console.log('search:', value);
    //     setSearchString(value);
    // }
    // const onSearchChange = (value: string) => {
    //     if (!loading) {
    //         setLoading(true);
    //     }
    //     if (timerRef.current) {
    //         clearTimeout(timerRef.current);
    //         console.log('reset timer')
    //     }
    //     timerRef.current = window.setTimeout(() => {
    //         console.log('timer out');
    //         setSearchString(value);
    //         setLoading(false);
    //     }, 300)
    // }
    const dataSource = () => {
        return openRepos.map(r => {
            return { ...r, icon: r.dealKind === DealDirection.buy ? <BuyIcon /> : <SellIcon /> }
        });
    }
    const onGCB = () => {
        dispatch(showRepoOrder(true))
    }
    const onCorp = () => {
        QuotesConnector.getInfo("repo-usd-sec-list");
        dispatch(showRepoOrder(false))
    }
    const [myColumns, setMyColumns] = useState(updateTableView(columns, 'Current'))
    const updateMyColumns = () => {
        setMyColumns(updateTableView(columns, 'Current'))
    }
    return (
        <>
            {/*<Search onSearch={onSearch}*/}
            {/*        onChange={e => onSearchChange(e.target.value)}*/}
            {/*    // value={searchString}*/}
            {/*        allowClear={true}*/}
            {/*        className="table-search"*/}
            {/*        placeholder={"Поиск"}*/}
            {/*        style={{*/}
            {/*            width: 250*/}
            {/*        }}*/}
            {/*/>*/}
            <Button onClick={() => dispatch(getOpenRepos())} type="default">
                <ReloadOutlined />
            </Button>
            <Button style={{ marginLeft: 10 }} type="primary" onClick={onGCB}>
                {/*<BuyIcon />*/}
                <span style={{ marginLeft: 0 }}>РЕПО ГЦБ</span>
            </Button>
            <Button style={{ marginLeft: 10, marginRight: 8 }} type="primary" onClick={onCorp}>
                {/*<SellIcon />*/}
                <span style={{ marginLeft: 0 }}>РЕПО Корп.ЦБ</span>
            </Button>
            <TableEditor updateColumns={updateMyColumns} columns={columns} keyForLS={'Current'} />
            <Table
                size="small"
                showSorterTooltip={false}
                rowClassName={rowClassCompact}
                dataSource={dataSource()}
                columns={myColumns}
                scroll={{
                    x: "max-content",
                    y: getFullPageTableHeight() - 70
                }}
                loading={openReposFetching}
                pagination={false}
            />
        </>
    )
}
