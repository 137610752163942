import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Divider, InputNumber, Modal, notification, Row, Select, Tooltip } from "antd";
import { ModalDS } from "../../shared/modals/ModalDS";
import { dispatch, TState } from "../../../store";
import { getMoneyRem } from "../../../store/money.slice";
import { closeMoneyTransferOrder, getOrders } from "../../../store/orders.slice";
import { filteredAccounts, isCompany, isHeadOfCompany } from "../../../services/helpers";
import { countNum, parseNum } from "../../../services/formatter";
import { buildXML } from "../../../services/xmlBuilder";
import { apiConnector } from "../../../integrations/api.connector";
import { NcaLayerConnector } from "../../../integrations/ncaLayer.connector";
import { TPDFHeading } from "../../../types/api";
import { ECurrency } from "../../../types/common";
import { NcaMessage } from "../../../types/nca";
import { ECheckReqType, EMoneyTransferType, ENonTradeOrderType, EOrderKind, TMoneyTransferRequest, TSetOrderTemplate } from "../../../types/orders";
import { checkAmountAndBalance, checkFromToAccounts, getAccBalance, getAccTitle, getCurrencies } from "./helpers";
import { successMessages, timeForErrorNotification, timeForSuccessNotification, timeForWarningNotification, warningMessages } from "../../../constants";
import "./../editedForm.css";

export const InternalMoneyTransferForm = () => {
    const {
        auth: {
            clientData,
            cProfile,
            userName,
            iin
        },
        orders: {
            showMoneyTransferOrderModal,
        },
        money: {
            moneyRem,
        }
    } = useSelector((state: TState) => state);
    const switchValue = useSelector((state: TState) => state.auth.switch);
    const [step, setStep] = useState<"form" | "sign" | "error">('form');
    const [sending, setSending] = useState<boolean>(false)
    const [amount, setAmount] = useState<number>()
    const [currency, setCurrency] = useState<ECurrency | string>();
    const [orderData, setOrderData] = useState<TMoneyTransferRequest>();
    const [xml, setXml] = useState<string>();
    const KACDfts: number[] = moneyRem.filter(acc => !!acc.iType).map(acc => acc.ftId);
    const [fromAcc, setFromAcc] = useState<number>();
    const [toAcc, setToAcc] = useState<number>();
    const handleOk = () => {
        // setStep("form")
        dispatch(closeMoneyTransferOrder())
    }
    const handleCancel = () => {
        // setStep("form")
        dispatch(closeMoneyTransferOrder())
    }
    const formOrder = async () => {
        if (!fromAcc || !toAcc || !amount || !clientData) {
            notification.warn({
                duration: timeForWarningNotification,
                message: warningMessages.missingFields,
            });
            return;
        }
        const accBalance = getAccBalance(fromAcc, moneyRem);
        if (!checkAmountAndBalance(accBalance, amount)) return;
        const date = new Date().toISOString();
        const transferTypeID = (KACDfts.includes(fromAcc) && KACDfts.includes(toAcc)) ? EMoneyTransferType.internalKACD : EMoneyTransferType.internal;
        const newOrder: TMoneyTransferRequest = {
            notCheckRem: 0,
            amount,
            date,
            transferTypeID,
            ftOutID: fromAcc,
            ftInID: toAcc,
            KNP: 690
        };
        setOrderData(newOrder);
        let heading: TPDFHeading
        if (Array.isArray(clientData)) {
            heading = {
                orderDate: date,
                docIssueDate: cProfile?.docIssueDate || "",
                docIssuer: cProfile?.docIssueAuthor || "",
                docNumber: cProfile?.docNumber || "",
                docSeries: "",
                docType: cProfile?.docType || "",
                subAccount: clientData[0]?.subAccount,
                accountNumber: clientData[0]?.imcAccount,
                clientName: clientData[0]?.cliName,
                contractNumber: clientData[0]?.contractNumber,
                iinbin: clientData[0]?.biin
            };
        }

        else {
            heading = {
                orderDate: date,
                docIssueDate: cProfile?.docIssueDate || "",
                docIssuer: cProfile?.docIssueAuthor || "",
                docNumber: cProfile?.docNumber || "",
                docSeries: "",
                docType: cProfile?.docType || "",
                subAccount: clientData?.subAccount,
                accountNumber: clientData?.imcAccount,
                clientName: clientData?.cliName,
                contractNumber: clientData?.contractNumber,
                iinbin: clientData?.biin
            };
        }
        const fromAccount = moneyRem.find(acc => acc.ftId === fromAcc)?.accountNumber;
        const toAccount = moneyRem.find(acc => acc.ftId === toAcc)?.accountNumber;
        if (!fromAccount || !toAccount) {
            return // Если не найдены аккаунты, прерываем функцию
        }
        const extraData = {
            currencyCode: currency as string,
            fromAccount,
            toAccount,
        };
        const xml = buildXML({ ...extraData, ...newOrder }, 'MoneyTransferInternal', heading);
        setXml(xml);
        setStep("sign");
    }

    const signOrder = async () => {
        if (!orderData) return;
        const accBalance = getAccBalance(fromAcc, moneyRem);
        if (!checkAmountAndBalance(accBalance, amount)) { return }
        setSending(true);
        const isBundleVersion = NcaLayerConnector.getBundleVersion();
        // const isBundleVersion = NcaLayerConnector.getBundleVersion() === true;
        const method = isBundleVersion ? "signXmlV2" : "signXml";
        const args = isBundleVersion ?
            { xml: xml, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password } : { xml: xml };
        const message = new NcaMessage(method, args);

        if (certificate === '' || password === '') {
            notification.error({
                duration: timeForErrorNotification,
                message: "Ошибка!",
                description: "Нужно указать путь до ключа и ввести пароль"
            });
            return;
        }

        try {
            const signedXml: string = await NcaLayerConnector.callNcaMethod(message);
            const reqType = ECheckReqType.moneyTransferReq;
            const response = await apiConnector.checkRequest({ orderData, signedXml, switchValue }, reqType);
            // const response = await apiConnector.putMoneyTransferOrder({ orderData, signedXml, switchValue });

            if (response && response.success) {
                notification.success({ duration: timeForSuccessNotification, message: successMessages.decreeSent });
                dispatch(getOrders());
            } else {
                notification.error({
                    duration: timeForErrorNotification, message: "Приказ отклонен",
                    description: <><p>{response.message}</p><p>{response.details}</p></>
                });
            }
        } catch (e) {
            setSignError('error');
            console.log(e);
        } finally {
            dispatch(closeMoneyTransferOrder());
            dispatch(getMoneyRem());
            setSending(false);
        }
    }
    const pendOrder = async () => {
        setSending(true);
        if (!orderData || !xml || !amount) return;
        const fromAccount = moneyRem.find(acc => acc.ftId === fromAcc)!.accountNumber;
        const toAccount = moneyRem.find(acc => acc.ftId === toAcc)!.accountNumber;
        const accBalance = getAccBalance(fromAcc, moneyRem);

        if (!checkAmountAndBalance(accBalance, amount) || !checkFromToAccounts(fromAccount, toAccount)) {
            return;
        }

        orderData.amount = amount;

        const orderTemplate: TSetOrderTemplate = {
            orderKind: EOrderKind.nonTrade,
            orderType: ENonTradeOrderType.moneyTransferInternal,
            orderData,
            orderXml: xml,
            author: userName || "н/д",
            authorIIN: iin || "н/д",
            extraData: {
                currencyCode: currency as string,
                fromAccount,
                toAccount,
            }
        };

        try {
            await apiConnector.checkTemplate(orderTemplate);
            dispatch(closeMoneyTransferOrder());
            dispatch(getOrders());
        } catch (error) {
            console.log(error);
            notification.error({
                duration: timeForErrorNotification,
                message: "Ошибка!",
                description: "Не удалось отправить заказ."
            });
        } finally {
            setSending(false);
        }
    }
    useEffect(() => {
        setFromAcc(undefined);
        !!toAcc && setToAcc(undefined);
    }, [currency])
    useEffect(() => {
        !!toAcc && setToAcc(undefined);
    }, [currency, fromAcc])

    const [password, setPassword] = useState<string>('');
    const [certificate, setCertificate] = useState<string>("");
    const [signError, setSignError] = useState<string | null>(null);

    return (
        <Modal
            title={"Перевод денежных средств (внутренний)"}
            visible={showMoneyTransferOrderModal}
            onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={620}
            bodyStyle={{ paddingTop: 12 }}
            style={{ overflow: "hidden", minHeight: 500 }}
            centered
            className="edited-form"
            zIndex={5}
        >
            <Row className="of-row">
                <Col span={8}>
                    Валюта:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={currency}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setCurrency(v)}>
                        {
                            getCurrencies(moneyRem).map((cur, i) => (
                                <Select.Option key={`${i}`} value={cur}>{cur}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Счёт списания:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={fromAcc}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setFromAcc(v)}>
                        {
                            moneyRem.filter(acc => acc.currencyCode === currency && acc.freeAmount > 0).map((acc, i) => (
                                <Select.Option key={`${i}`} value={acc.ftId}>
                                    <Tooltip placement="left" title={getAccTitle(acc.ftId, moneyRem)}>
                                        {acc.iType ? `(${acc.iType}) ` : ""}{acc.holdPlace} - {acc.subAccount}
                                    </Tooltip>
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Сумма:
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={amount}
                        onChange={p => setAmount(p || 0)}
                        size="small" className="of-input repo-amount-open"
                        formatter={v => countNum(v as number)}
                        parser={v => parseNum(v as string, 2)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Счёт зачисления:
                </Col>
                <Col span={16}>
                    <Select className="of-select repo-days" size="small"
                        value={toAcc}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setToAcc(v)}>
                        {
                            filteredAccounts().filter(acc => acc.currencyCode === currency && acc.ftId !== fromAcc).map((acc, i) => (
                                <Select.Option key={`${i}`} value={acc.ftId}>
                                    <Tooltip placement="left" title={getAccTitle(acc.ftId, moneyRem)}>
                                        {acc.iType ? `(${acc.iType}) ` : ""}{acc.holdPlace} - {acc.subAccount}
                                    </Tooltip>
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Divider />
            <Row>
                {step === "form" && <Button onClick={formOrder} type="primary" block>Создать приказ</Button>}
                {step === "sign" &&
                    <>
                        {isCompany() && !isHeadOfCompany() && <Button loading={sending} disabled={sending} onClick={pendOrder} type="primary" block>Отправить на
                            согласование</Button>}
                    </>}
            </Row>
            <ModalDS
                signError={signError}
                step={step}
                password={password}
                certificate={certificate}
                setCertificate={setCertificate}
                setPassword={setPassword}
                signOrder={signOrder}
                isDisable={false}
                sending={sending}
                OT={false}
                isActual={undefined}
                loading={false}
                onConfirm={undefined}
                template={undefined}
                isSigners={false}
            />
        </Modal>
    )
}


// if (!orderData) return;
// setSending(true);
// let method: string = '';
// let args: {} = {};
// if (NcaLayerConnector.getBundleVersion() === true) {
//     method = "signXmlV2"
//     args = { xml: xml, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password }
// } else {
//     method = "signXml"
//     args = { xml: xml }
// }
// const message = new NcaMessage(method, args);

// if (certificate !== '' || password !== '') {
//     try {
//         const signedXml: string = await NcaLayerConnector.callNcaMethod(message);
//         const response = await apiConnector.putMoneyTransferOrder({
//             orderData,
//             signedXml,
//             switchValue
//         })
//         if (response && response.success) {
//             notification.success({
//                 duration: timeForSuccessNotification,
//                 message: successMessages.decreeSent,
//             });
//             dispatch(getOrders());
//         } else {
//             notification.error({
//                 duration: timeForErrorNotification,
//                 message: `Приказ отклонен`,
//                 description: <><p>{response.message}</p><p>{response.details}</p></>
//             })
//         }
//     } catch (e) {
//         setSignError('error');
//         console.log(e);
//     } finally {
//         dispatch(closeMoneyTransferOrder());
//         dispatch(getMoneyRem());
//         setSending(false);
//     }
// } else {
//     notification.error({
//         duration: timeForErrorNotification,
//         message: "Ошибка!",
//         description: "Нужно указать путь до ключа и ввести пароль"
//     });
// }

// if (orderData && xml) {
//     const orderTemplate: TSetOrderTemplate = {
//         orderKind: EOrderKind.nonTrade,
//         orderType: ENonTradeOrderType.moneyTransferInternal,
//         orderData,
//         orderXml: xml,
//         author: userName || "н/д",
//         authorIIN: iin || "н/д",
//         extraData: {
//             currencyCode: currency as string,
//             fromAccount: moneyRem.find(acc => acc.ftId === fromAcc)!.accountNumber,
//             toAccount: moneyRem.find(acc => acc.ftId === toAcc)!.accountNumber,
//         }
//     };
//     try {
//         const result = await apiConnector.checkTemplate(orderTemplate);
//         dispatch(closeMoneyTransferOrder());
//         dispatch(getOrders());
//     } catch (e) {
//         console.log(e);
//         notification.error({
//             duration: timeForErrorNotification,
//             message: "Ошибка!",
//             description: "Не удалось отправить заказ"
//         });
//     } finally {
//         setSending(false);
//     }
// }