import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {TAuthRequest, TClientData, TFullProfile, TToken, TProfile, TAuthCertificate, TSignCertificate, TLoginValidateError} from '../types/api';
import {apiConnector} from '../integrations/api.connector';
import {decodeJWT} from '../services/jwtDecoder';
import {TState} from "./index";

export const loginByText = createAsyncThunk(
    'auth/loginByText',
    async (data: TAuthRequest, {dispatch}): Promise<TToken> => {
        dispatch(unsetError());
        try {
            const token = await apiConnector.loginText(data);
            return {...decodeJWT(token),token}
        } catch (e: any) {
            throw new Error(e)
        }
    }
)
export const loginByEDS = createAsyncThunk(
    'auth/loginByEDS',
    async (data, {dispatch}): Promise<TToken & {singleSession: true} | {singleSession: false, hash: string}> => {
        dispatch(unsetError());
        try {
            const {singleSession, token, hash} = await apiConnector.loginEDS();
            if(singleSession && token){
                return {...decodeJWT(token),token, singleSession}
            } else if(!singleSession && hash){
                return {singleSession, hash}
            } else {
                throw new Error();
            }
        } catch (e:any) {
            throw new Error(e)
        }
    }
)

export const loginByInvalidationHash = createAsyncThunk(
    'auth/loginByInvalidationHash',
    async (data: string): Promise<TToken> => {
        try {
            const token = await apiConnector.loginWithSICH(data);
            return {...decodeJWT(token),token}
        } catch (e: any) {
            throw new Error()
        }
    }
)

export const getPerson = createAsyncThunk(
    'auth/getPerson',
    async (): Promise<TProfile> => {
        try {
            return await apiConnector.getProfile()
        } catch (e: any) {
            throw new Error(e)
        }
    }
)
export const getProfile = createAsyncThunk(
    'auth/getProfile',
    async (data, {dispatch, getState}): Promise<any> => {
        try {
            const {auth:{bin}} = getState() as TState;
            const isCompany = !!bin;
            return isCompany ? await apiConnector.getCompanyInfo() : await apiConnector.getPersonInfo();
        } catch (e: any) {
            throw new Error(e)
        }
    }
)
export const getClientData = createAsyncThunk(
    'auth/clientData',
    async (): Promise<TClientData> => {
        try {
            const {success, payload} = await apiConnector.getClientData();
            return payload;
        } catch (e: any) {
            throw new Error(e)
        }
    }
)

export const loginByEDSV2 = createAsyncThunk(
    'auth/loginByEDSV2',
    async ({certificatePath, password}: {certificatePath: string, password: string}, {dispatch}): Promise<TToken & {singleSession: true} | {singleSession: false, hash: string}> => {
        dispatch(unsetError());
        try {
            const {singleSession, token, hash} = await apiConnector.loginEDSV2(certificatePath, password);
            if(singleSession && token){
                return {...decodeJWT(token),token, singleSession}
            } else if(!singleSession && hash){
                return {singleSession, hash}
            } else {
                throw new Error();
            }
        } catch (e: any) {   
            throw e.response?.data;
        }
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isPerson: false,
        cProfile: null as null | TFullProfile,
        token: null as string | null,
        bin: null as string | null,
        iin: null as string | null,
        sid: null as string | null,
        userName: null as string | null,
        roles: [] as string[],
        loggedIn: false,
        authFetching: false,
        authError: false,
        ncaLayer: true,
        ncaError: null as string | null,
        multipleSessions: false,
        invalidateHash: null as string | null,
        invalidatingSessions: false,
        clientData: null as TClientData | null,
        personInfo: null as null | TProfile,
        loginError: false,
        signError: false,
        authCertificates: [] as TAuthCertificate[],
        signCertificates: [] as TSignCertificate[],
        switch: false,
        loginValidateError: null as string | null
    },
    reducers: {
        setCert: (state, action) => {
            if(action.payload.certType === 'auth') {
                if(action.payload.type === 'add') {
                    console.log(action.payload.cert);
                    
                    state.authCertificates.push(action.payload.cert);
                } else if (action.payload.type === 'removeOne') {
                    const index = state.authCertificates.findIndex(cert => cert.path === action.payload.path);
                    state.authCertificates.splice(index, 1);
                } else if (action.payload.type === 'removeAll') {
                    state.authCertificates = [];
                };
            } else {
                if(action.payload.type === 'add') {
                    state.signCertificates.push(action.payload.cert);
                } else if (action.payload.type === 'removeOne') {
                    const index = state.signCertificates.findIndex((cert: any) => cert.path === action.payload.path);
                    state.signCertificates.splice(index, 1);
                } else if (action.payload.type === 'removeAll') {
                    state.signCertificates = [];
                };
            };
            
        },
        setProfile: (state, action) => {
            state.cProfile = action.payload;
        },
        unsetError: state => {
            state.authError = false;
        },
        ncaLayerConnected: state => {
            state.ncaLayer = true;
        },
        ncaLayerNotConnected: state => {
            state.ncaLayer = false;
        },
        ncaLayerSetError: (state, action) => {
            state.ncaError = action.payload;
        },
        logOut: state => {
            apiConnector.logout().then();
            return {
                ...state,
                loggedIn: false,
                token: null,
                iin: null,
                bin: null,
                sid: null,
                userName: null,
                roles: [],
                multipleSessions: false,
                invalidatingSessions: false,
            }
        },
        cancelInvalidation: state => {
            state.invalidateHash = null;
            state.multipleSessions = false;
        },
        toogleSwitch: (state) => {
            state.switch = !state.switch;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(
                loginByEDSV2.pending, state => {
                    state.authFetching = true;
                    state.loginError = false;
                    state.loginValidateError = null;
                }
            )
            .addCase(
                loginByEDSV2.rejected, (state, action) => {
                    state.authFetching = false;
                    state.loginError = true;
                    state.loginValidateError = action.error.message !== undefined ? action.error.message : null;     
                }
            )
            .addCase(
                loginByEDSV2.fulfilled, (state, action) => {
                    const {singleSession} = action.payload;
                    state.loginError = false;
                    state.loginValidateError = null;
                    if(singleSession){
                        const {token, iin, bin, sid, name, realm_access: {roles}} = action.payload
                        if(token){
                            return {...state, loggedIn: true, authFetching: false, token, iin, bin, sid, userName: name, roles};
                        } else {
                            return {...state, loggedIn: false, authFetching: false};
                        }
                    } else {
                        const {hash} = action.payload;
                        return {...state, invalidateHash: hash, multipleSessions: true, authFetching: false};
                    }

                }
            )
            .addCase(
                loginByText.pending, state => {
                    state.authFetching = true
                }
            )
            .addCase(
                loginByText.rejected, state => {
                    state.authError = true
                    state.authFetching = false
                }
            )
            .addCase(
                loginByText.fulfilled, (state, action) => {
                    const {token, iin, bin, sid, name, realm_access: {roles}} = action.payload
                    if(token){
                        return {...state, loggedIn: true, authFetching: false, token, iin, bin, sid, userName: name, roles}
                    } else {
                        state.authError = true
                        return {...state, loggedIn: false, authFetching: false}
                    }
                }
            )
            .addCase(
                loginByEDS.pending, state => {
                    state.authFetching = true
                }
            )
            .addCase(
                loginByEDS.rejected, state => {
                    state.authError = true
                    state.authFetching = false
                }
            )
            .addCase(
                loginByEDS.fulfilled, (state, action) => {
                    const {singleSession} = action.payload;
                    if(singleSession){
                        const {token, iin, bin, sid, name, realm_access: {roles}} = action.payload
                        if(token){
                            return {...state, loggedIn: true, authFetching: false, token, iin, bin, sid, userName: name, roles}
                        } else {
                            state.authError = true
                            return {...state, loggedIn: false, authFetching: false}
                        }
                    } else {
                        const {hash} = action.payload;
                        return {...state, invalidateHash: hash, multipleSessions: true, authFetching: false}
                    }

                }
            )
            .addCase(
                loginByInvalidationHash.pending, state => {
                    state.invalidatingSessions = true;
                }
            )
            .addCase(
                loginByInvalidationHash.rejected, state => {
                    state.authError = true;
                    state.invalidatingSessions = false;
                }
            )
            .addCase(
                loginByInvalidationHash.fulfilled, (state, action) => {
                    const {token, iin, bin, sid, name, realm_access: {roles}} = action.payload;
                    if(token){
                        return {...state, loggedIn: true, invalidatingSessions: false, token, iin, bin, sid, userName: name, roles};
                    } else {
                        state.authError = true
                        return {...state, loggedIn: false, invalidatingSessions: false}
                    }
                }
            )
            .addCase(
                getProfile.pending, (state, action) => {

                }
            )
            .addCase(
                getProfile.fulfilled, (state, action) => {
                    state.cProfile = action.payload;
                }
            )
            .addCase(
                getProfile.rejected, (state, action) => {

                }
            )
            .addCase(
                getPerson.pending, (state, action) => {

                }
            )
            .addCase(
                getPerson.fulfilled, (state, action) => {
                    state.personInfo = action.payload;
                }
            )
            .addCase(
                getPerson.rejected, (state, action) => {

                }
            )
            .addCase(
                getClientData.fulfilled, (state, action) => {
                    state.clientData = action.payload
                }
            )
            .addCase(
                getClientData.rejected, (state, action) => {

                }
            )
            .addCase(
                getClientData.pending, (state, action) => {

                }
            )
    }
})

export const {
    setProfile,
    unsetError,
    logOut,
    ncaLayerConnected,
    ncaLayerNotConnected,
    ncaLayerSetError,
    cancelInvalidation,
    setCert,
    toogleSwitch
} = authSlice.actions
