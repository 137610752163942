import React, { ReactNode, useState } from "react";
import { Card } from "antd";
import { NameAndPass } from "./NameAndPass";
import { DigitalSignature } from './DigitalSignature';
import { TestDS } from "./TestDS";
import { logoName } from "../../constants";
import "../../styles/login.scss";

export const Login = () => {
    const [activeTab, setActiveTab] = useState<string>("testDS")
    const onTabChange = (key: string) => {
        setActiveTab(key);
    }
    const tabList = [
        {
            key: "testDS",
            tab: "Тестовый вход"
        },
        {
            key: "signatureLogin",
            tab: "Вход по ЭЦП"
        },
        {
            key: "textLogin",
            tab: "Логин/пароль"
        },
    ]
    const contentList: Record<string, ReactNode> = {
        testDS: <TestDS />,
        signatureLogin: <DigitalSignature />,
        textLogin: <NameAndPass />,
    }
    return (
        <div className="login-wrapper">
            <Card
                className="login-card"
                title={<h2 style={{ margin: 0 }}>{logoName}</h2>}
                tabList={tabList}
                activeTabKey={activeTab}
                onTabChange={key => onTabChange(key)}
                tabProps={{
                    type: "card"
                }}
            >
                {contentList[activeTab]}
            </Card>
        </div>
    )
}
