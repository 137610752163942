import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Space, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { nanoid } from 'nanoid';
import { dispatch, TState } from '../../store';
import { showSecuritiesTransferOrder } from "../../store/orders.slice";
import { getPortfolio } from '../../store/securities.slice';
import { QuotesConnector } from "../../integrations/quotes.connector";
import { TOwnSecurity } from '../../types/api';
import { IQuote } from "../../types/quotes";
import { PortfolioInfoBtn } from './portfolioInfo.btn';
import { SellBtn } from '../market/sell.btn';
import { TableEditor } from "../tableEditor/TableEditor";
import { PortfolioSide } from '../tradeSideComponents/PortfolioSide';
import { getHalfPageTableHeight, rowClassCompact, shortNamesForPortfolio, updateTableView } from '../../services/helpers';
import { sideComponentWidth } from '../../constants';
import { columns } from './portfolio.data';

export const Portfolio = () => {
    const timerRef = useRef<number | undefined>();
    const [searchString, setSearchString] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const { secFetching, portfolio, bondsLib, equitiesLib, quotes, portfolioSideDisplayed } = useSelector((state: TState) => state.securities);
    let lib = [...bondsLib, ...equitiesLib];
    // const handleChildClick = (id: number) => {
    //     dispatch(findDepthSecurityID(id))
    // };
    const dataSource = (): (TOwnSecurity & { action: any, bid: number | null, market: number | null })[] => {
        return portfolio.map(p => {
            // const localBool = p.holdPlace === "KASD" ? 1 : 0;
            let marketSecurity = lib.find(l => (l.securityId === p.securityId && l.intBool === p.intBool));
            let quote: IQuote | undefined = undefined;
            let _ticker: string | undefined = undefined;
            let btn: boolean = false;
            if (marketSecurity && marketSecurity.tradeModeCode) {
                const { tradeModeCode, ticker } = marketSecurity;
                let key = `${tradeModeCode}-${ticker}`;
                quote = quotes[key];
                _ticker = ticker
                if (p.intBool === 0) {
                    btn = true
                }
                else {
                    btn = false;
                }
            }
            return {
                ...p,
                tradeCode: _ticker || p.tradeCode,
                bid: quote && quote.BID ? quote.BID : null,
                market: quote && quote.MARKETPRICE ? quote.MARKETPRICE : null,
                key: nanoid(6),
                action: <Space size="small">
                    <SellBtn securityId={p.securityId} currency={p.currency} intBool={p.intBool} />
                    <PortfolioInfoBtn disabledBtn={btn} securityId={p.securityId} currency={p.currency} intBool={p.intBool} />
                </Space>
            }
        }).filter(row => {
            if (!searchString || searchString.length < 1) {
                return true
            } else {
                return row.tradeCode.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    row.securityName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    row.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
            }
        }).map(data => ({ ...data, holdPlace: shortNamesForPortfolio(data)! }))
    }
    const onSearch = (value: string) => {
        setSearchString(value);
    }
    useEffect(() => {
        let keys: string[] = [];
        for (let sec of portfolio) {
            let marketSecurity = lib.find(l => l.securityId === sec.securityId);
            if (marketSecurity && marketSecurity.tradeModeCode) {
                const { tradeModeCode, ticker } = marketSecurity;
                let key = `${tradeModeCode}-${ticker}`;
                keys.push(key);
            }
        }
        QuotesConnector.watch(keys);
    }, [portfolio, bondsLib, equitiesLib]);
    const onSearchChange = (value: string) => {
        if (!loading) {
            setLoading(true);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            setSearchString(value);
            setLoading(false);
        }, 300)
    }
    const [myColumns, setMyColumns] = useState(updateTableView(columns, 'Portfolio'))
    const updateMyColumns = () => {
        setMyColumns(updateTableView(columns, 'Portfolio'))
    }
    return (
        <>

            <Search onSearch={onSearch}
                onChange={e => onSearchChange(e.target.value)}
                // value={searchString}
                allowClear={true}
                className="table-search"
                placeholder={"Поиск"}
                style={{
                    width: 250
                }}
            />
            <Button onClick={() => dispatch(getPortfolio())} type="default">
                <ReloadOutlined />
            </Button>
            {/*<Button style={{marginLeft: 8}} onClick={()=>dispatch(showSecurityConversionOrder())} type="primary">Конвертация ЦБ</Button>*/}
            <Button style={{ marginLeft: 8 }} onClick={() => dispatch(showSecuritiesTransferOrder(false))} type="primary">Внутренний перевод ЦБ</Button>
            <Button style={{ marginLeft: 8, marginRight: 8 }} onClick={() => dispatch(showSecuritiesTransferOrder(true))} type="primary">Внешний перевод ЦБ</Button>
            {/*<Button onClick={()=>dispatch(showPledgeOrder())} style={{marginLeft: 8}} type="primary">Залоги и обременения</Button>*/}
            <TableEditor updateColumns={updateMyColumns} columns={columns} keyForLS={'Portfolio'} />
            <div style={{ display: "flex", flexDirection: "row", width: '100%', height: '100%' }}>
                <Table
                    size="small"
                    showSorterTooltip={false}
                    // style={dataSource().length < 1 ? {marginTop: 56} : {}}
                    rowClassName={rowClassCompact}
                    dataSource={dataSource()}
                    columns={myColumns}
                    scroll={{
                        x: "max-content",
                        y: getHalfPageTableHeight()
                    }}
                    loading={secFetching || loading}
                    pagination={false}
                    style={{ width: portfolioSideDisplayed ? `${100 - sideComponentWidth}%` : "100%", transition: "width .2s linear" }}
                />
                {portfolioSideDisplayed ? <PortfolioSide /> : <></>}
            </div>
        </>
    )
}
