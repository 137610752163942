import { io, Socket } from 'socket.io-client';
import { apiConnector } from "./api.connector";
import { TOrderBook } from '../types/market';
import { IQuote, RepoList } from '../types/quotes';
import { api } from '../constants';

export class QuotesConnector {
    public static SocketIO: Socket;
    public static Queue: string[] = [];
    public static Depth: string[] = [];
    public static repoDepth: string[] = [];
    public static onQuote: (quote: IQuote | IQuote[]) => void;
    public static onDepth: (orderBook: TOrderBook) => void;
    public static onRepoDepth: (orderbook: TOrderBook) => void;
    public static onRepoUSDSecList: (data: RepoList[]) => void;
    private static socketIoHost: string = api.host;

    public static initSocketIO = () => {
        QuotesConnector.SocketIO = io(QuotesConnector.socketIoHost, {
            path: "/api/mock/socket.io/",
            transports: ["websocket"],
            auth: {
                token: apiConnector.token
            }
        });
        QuotesConnector.SocketIO.on("connect", () => {
            QuotesConnector.watch(QuotesConnector.Queue);
            QuotesConnector.getInfo("repo-usd-sec-list");
            // QuotesConnector.Queue = [];
        })
        QuotesConnector.SocketIO.on("quotes", data => QuotesConnector._processQuotes(data))
        QuotesConnector.SocketIO.on("update", data => QuotesConnector._processUpdate(data))
        QuotesConnector.SocketIO.on("mdUpdate", data => QuotesConnector._mdHandler(data))
        QuotesConnector.SocketIO.on("mdUpdate", data => QuotesConnector._mdHandlerRepo(data))
        QuotesConnector.SocketIO.on("repo-usd-sec-list", data => QuotesConnector.onRepoUSDSecList(data))
    }

    public static getInfo = (infoName: string): void => {
        if (QuotesConnector.SocketIO.connected) {
            QuotesConnector.SocketIO.emit("info", infoName);
        }
    }

    public static subscribeMD = (key: string | string[]): void => {
        if (QuotesConnector.SocketIO && QuotesConnector.SocketIO.connected) {
            QuotesConnector.SocketIO.emit("mdSub", key)
        }
        else {
            QuotesConnector.Depth = [...QuotesConnector.Depth, ...[key].flat()]
        }
    }

    public static unsubscribeMD = (key: string | string[]): void => {
        QuotesConnector.SocketIO.emit("mdUnsub", key)
    }

    public static subscribeRepo = (key: string | string[]): void => {
        if (QuotesConnector.SocketIO && QuotesConnector.SocketIO.connected) {
            QuotesConnector.SocketIO.emit("mdSub", key);
        }
        else {
            QuotesConnector.repoDepth = [...QuotesConnector.repoDepth, ...[key].flat()]
        }
    }

    public static unsubscribeRepo = (key: string | string[]): void => {
        QuotesConnector.SocketIO.emit("mdUnsub", key);
    }

    public static watch = (keys: string | string[]): void => {
        if (QuotesConnector.SocketIO && QuotesConnector.SocketIO.connected) {
            QuotesConnector.SocketIO.emit("sub", [keys].flat())
        } else {
            QuotesConnector.Queue = [...QuotesConnector.Queue, ...[keys].flat()]
        }
    }

    public static unwatch = (keys: string | string[]): void => {
        QuotesConnector.SocketIO.emit("del", [keys].flat())
    }

    public static close = () => {
        QuotesConnector.SocketIO.close();
    }

    private static _mdHandler = (incomingMessage: TOrderBook): void => {
        QuotesConnector.onDepth(incomingMessage)
    }

    private static _mdHandlerRepo = (incomingMessage: TOrderBook): void => {
        QuotesConnector.onRepoDepth(incomingMessage)
    }

    private static _processQuotes = (incomingMessage: IQuote[]): void => {
        QuotesConnector.onQuote(incomingMessage);
    }

    private static _processUpdate = (incomingMessage: IQuote): void => {
        QuotesConnector.onQuote(incomingMessage);
    }
}
