import React, { FC } from "react";
import { Col, Row } from "antd";
import { TMarketSecurity, TSubAccount } from "../../../types/api";
import { DealKind, TOrderTemplate, TPutOrderRepoRequest } from "../../../types/orders";
import { finNum, finNum4 } from "../../../services/formatter";

export const RepoForm: FC<{ t: TOrderTemplate, lib: TMarketSecurity[], accounts: TSubAccount[] }> = ({ t, lib, accounts }) => {
    const { dealKindId, securityId, currency, orderTypeId, amount, price, repoCloseAmount, repoCloseDate } = t.orderData as TPutOrderRepoRequest;
    const { repoType, repoDays } = t.extraData;
    const targetSecurity = lib.find(sec => sec.securityId === securityId);
    return (
        <>
            <Row className="bordered-row ticker-currency">
                <Col span={12}>{dealKindId === DealKind.buy ? "Размещение" : "Привлечение"}</Col>
                <Col span={12}>{currency} {finNum(amount)}</Col>
            </Row>
            <Row className="bordered-row">
                <Col span={24}>
                    <span>{targetSecurity ? `${targetSecurity.isin} - ${targetSecurity.globalType}` : repoType === "GCB" ? "Корзина ГЦБ" : "Корзина ОДГУ"}</span><br />
                    <small>{targetSecurity && targetSecurity.securityName}</small>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Количество дней:
                </Col>
                <Col span={16}>
                    {repoDays}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Тип:
                </Col>
                <Col span={16}>
                    {orderTypeId === 1 ? "Лимитный" : "Раночный"}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Сумма открытия:
                </Col>
                <Col span={16}>
                    {finNum(amount)}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Доходность:
                </Col>
                <Col span={16}>
                    {finNum4(price)}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Сумма закрытия:
                </Col>
                <Col span={16}>
                    {finNum(repoCloseAmount)}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Дата закрытия:
                </Col>
                <Col span={16}>
                    {repoCloseDate}
                </Col>
            </Row>
            {/*<Row className="of-row">*/}
            {/*    <Col span={8}>Выберите счёт:</Col>*/}
            {/*    <Col span={16}>*/}
            {/*        <Select className="of-select deal-account" size="small"*/}
            {/*                value={account?.imContractId.toString(10) || ""}*/}
            {/*                defaultValue={account?.imContractId.toString(10) || ""}*/}
            {/*                disabled={orderDirection === DealDirection.sell}*/}
            {/*                onChange={handleAccount}>*/}
            {/*            {*/}
            {/*                accounts.map((acc, i) => (*/}
            {/*                    <Select.Option className="of-option" key={`${i}`}*/}
            {/*                                   value={acc.imContractId.toString(10)}>{acc.holdPlace}</Select.Option>*/}
            {/*                ))*/}
            {/*            }*/}
            {/*        </Select>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </>
    )
}