import { ColumnsType } from 'antd/lib/table';
import { RepOrder } from '../../../types/reports';
import { countNum, finNum, formatPrice } from "../../../services/formatter";

export const columns: ColumnsType<RepOrder> = [
    {
        title: "Номер",
        width: 70,
        fixed: "left",
        dataIndex: "orderNumber"
    },
    {
        title: "Дата",
        width: 140,
        fixed: "left",
        dataIndex: "orderDate"
    },
    {
        title: "Статус",
        width: 120,
        align: "center",
        fixed: "left",
        dataIndex: "orderStatus"
    },
    {
        title: "Вид операции",
        width: 270,
        dataIndex: "tranType"
    },
    {
        title: "Вид приказа/КЗ",
        width: 120,
        dataIndex: "orderType"
    },
    {
        title: "ID на бирже",
        width: 120,
        dataIndex: "idMarketDeal"
    },
    {
        title: "Эмитент",
        dataIndex: "issuerName"
    },
    {
        title: "Тип ЦБ",
        width: 100,
        dataIndex: "securityType"
    },
    {
        title: "Торговый код",
        width: 130,
        dataIndex: "tradeCode"
    },
    {
        title: "ISIN",
        dataIndex: "isin"
    },
    {
        title: "Количество",
        width: 160,
        render: countNum,
        align: "right",
        dataIndex: "quantity",
    },
    {
        title: "Цена в %",
        width: 80,
        dataIndex: "percentPrice",
    },
    {
        title: "Цена в валюте",
        width: 120,
        align: "right",
        render: r => formatPrice(r, 4),
        dataIndex: "price"
    },
    {
        title: "Сумма сделки/операции",
        width: 150,
        render: finNum,
        align: "right",
        dataIndex: "amount"
    },
    {
        title: "Валюта",
        width: 80,
        dataIndex: "currency"
    },
    {
        title: "Доходность",
        width: 110,
        dataIndex: "yield"
    },
    {
        title: "Дата закрытия РЕПО",
        width: 140,
        dataIndex: "repoCloseDate"
    },
    {
        title: "Цена закрытия РЕПО",
        width: 140,
        dataIndex: "repoClosePrice"
    },
    {
        title: "Сумма закрытия РЕПО",
        width: 150,
        dataIndex: "repoCloseAmount"
    }
]
