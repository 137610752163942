import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Divider, List, notification, Row } from "antd";
import { dispatch, TState } from "../../../store";
import { getOrderTemplates, getOrders } from "../../../store/orders.slice";
import { apiConnector } from "../../../integrations/api.connector";
import { TemplateListItem } from "./TemplateListItem";
import { signPendingOrder } from "./signers";
import { TradeForm } from "./TradeForm";
import { RepoForm } from "./RepoForm";
import { IMTForm } from "./IMTForm";
import { EMTForm } from "./EMTForm";
import { ISTForm } from "./ISTForm";
import { ESTForm } from "./ESTForm";
import { ModalDS } from "../../shared/modals/ModalDS";
import { canSignOrder, getFullPageTableHeight } from "../../../services/helpers";
import { TSubAccount } from "../../../types/api";
import { ENonTradeOrderType, EOrderTemplateStatus, ETradeOrderType, TOrderTemplate } from "../../../types/orders";
import { timeForErrorNotification, timeForSuccessNotification } from "../../../constants";

export const OrderTemplates = () => {
    const {
        auth: {
            userName,
            iin
        },
        orders: {
            allOrders,
            orderTemplates,
            orderTemplatesFetching
        },
        money: {
            subAccountsInt,
            subAccountsLocal, moneyRem
        },
        securities: {
            equitiesLib, bondsLib
        }
    } = useSelector((state: TState) => state);
    const lib = [...equitiesLib, ...bondsLib];
    const accounts: TSubAccount[] = [...subAccountsLocal, ...subAccountsInt];
    useEffect(() => {
        dispatch(getOrderTemplates())
    }, [])
    const [loading, setLoading] = useState<boolean>(false)
    const [template, setTemplate] = useState<TOrderTemplate | null>()
    const onReject = async (ot: TOrderTemplate) => {
        try {
            await apiConnector.updateTemplate({
                id: ot.id,
                status: EOrderTemplateStatus.rejected
            });
            // Обновляем состояние шаблонов и заказов только после успешного обновления
            dispatch(getOrderTemplates());
            dispatch(getOrders());
            setTemplate(null);
        } catch (error) {
            // Обработка ошибки, если обновление не удалось
            console.log("Ошибка при отклонении шаблона:", error);
        }
    }
    const onConfirm = async (ot: TOrderTemplate) => {
        setLoading(true)
        let newStatus: EOrderTemplateStatus = ot.status;
        
        try {
            let { success, message } = await signPendingOrder(ot, { password, certificate });
            if (!success) {
                newStatus = EOrderTemplateStatus.invalid;
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: message
                })
            } else {
                dispatch(getOrderTemplates());
                newStatus = EOrderTemplateStatus.confirmed;
                notification.success({
                    duration: timeForSuccessNotification,
                    message: "Заказ отправлен!",
                    // description: errorMessage
                })
            }
        } catch (e) {
            console.log(e);
            setSignError('error');
            newStatus = EOrderTemplateStatus.error;
            notification.error({
                duration: timeForErrorNotification,
                message: "Ошибка!",
                description: "Не удалось подписать заказ"
            })
        } finally {
            await apiConnector.updateTemplate({
                id: ot.id,
                status: newStatus,
                signer: userName || "н/д",
                signerIIN: iin || "н/д"
            });
            dispatch(getOrderTemplates());
            dispatch(getOrders());
            setLoading(false);
            setSignError(null);
        }

    }
    const getForm = () => {
        switch (template?.orderType) {
            case ETradeOrderType.trade:
                return <TradeForm t={template} lib={lib} accounts={accounts} />
            case ETradeOrderType.repo:
                return <RepoForm t={template} lib={lib} accounts={accounts} />
            case ENonTradeOrderType.moneyTransferInternal:
                return <IMTForm t={template} accounts={moneyRem} />
            case ENonTradeOrderType.moneyTransferExternal:
                return <EMTForm t={template} accounts={moneyRem} />
            case ENonTradeOrderType.securityTransferInternal:
                return <ISTForm t={template} accounts={accounts} />
            case ENonTradeOrderType.securityTransferExternal:
                return <ESTForm t={template} accounts={accounts} />
            default:
                return <>{template?.orderType}</>
        }
    }
    const isActual = (): boolean => (
        template?.status !== EOrderTemplateStatus.confirmed &&
        template?.status !== EOrderTemplateStatus.rejected &&
        template?.status !== EOrderTemplateStatus.expired &&
        canSignOrder()
    )
    const getData = () => {
        if (orderTemplates) {
            try {
                const first = [...orderTemplates].filter(ot => ot.status === EOrderTemplateStatus.pending).sort((a, b) => (new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()));
                const last = [...orderTemplates].filter(ot => ot.status !== EOrderTemplateStatus.pending).sort((a, b) => (new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()));
                return [...first, ...last];
            } catch (e) {
                console.log(e);
                return []
            }
        } else {
            return []
        }
    }

    const [password, setPassword] = useState<string>('');
    const [certificate, setCertificate] = useState<string>("");
    const [signError, setSignError] = useState<string | null>(null);

    return (
        <>
            {/*<Button onClick={refresh} type="default">*/}
            {/*    <ReloadOutlined/>*/}
            {/*</Button>*/}
            <Row>
                <Col span={7}>
                    <List
                        size="small"
                        style={{ height: `${getFullPageTableHeight()}px`, overflowY: "auto" }}
                        dataSource={getData()}
                        loading={orderTemplatesFetching}
                        renderItem={
                            ot => (
                                <List.Item onClick={() => setTemplate(ot)}>
                                    <TemplateListItem template={ot} lib={lib} selected={ot.id === template?.id}
                                        orders={allOrders} />
                                </List.Item>
                            )
                        }
                    />
                </Col>
                <Col span={16} offset={1} className={loading ? "disabled-block" : ""}>
                    {template && <>
                        <Row className="order-template-holder" style={{ marginTop: 20 }}>
                            <ModalDS
                                signError={signError}
                                step={null}
                                password={password}
                                certificate={certificate}
                                setCertificate={setCertificate}
                                setPassword={setPassword}
                                signOrder={null}
                                isDisable={false}
                                sending={false}
                                OT={true}
                                isActual={!isActual()}
                                loading={loading}
                                onConfirm={onConfirm}
                                template={template}
                                setTemplate={setTemplate}
                                isSigners={true}
                            />
                            <Button disabled={!isActual()} onClick={() => onReject({ ...template })}>Отклонить</Button>
                        </Row>
                        <Divider />
                        {/*{Object.entries({...template.orderData, ...template.extraData}).map(([key, value]) => {*/}
                        {/*    return <Row>*/}
                        {/*        <Col span={8}>{key}:</Col>*/}
                        {/*        <Col span={16}>{value as string}</Col>*/}
                        {/*    </Row>*/}
                        {/*})}*/}
                        {getForm()}
                    </>}
                </Col>
            </Row>
        </>
    )
}