import { ColumnsType } from "antd/lib/table";
import { TRepoCurrent } from "../../types/repo";
import { countNum, finNum, formatPrice } from "../../services/formatter";

export const columns: ColumnsType<TRepoCurrent & { icon: any }> = [
    {
        title: "",
        fixed: "left",
        dataIndex: "icon"
    },
    {
        title: "Режим торгов",
        width: 120,
        fixed: "left",
        dataIndex: "tradeMode"
    },
    {
        title: "Корзина",
        fixed: "left",
        dataIndex: "repoName"
    },
    {
        title: "Дата открытия",
        fixed: "left",
        width: 130,
        render: text => new Date(text).toLocaleDateString("ru-RU"),
        dataIndex: "openDate"
    },
    {
        title: "Дата закрытия",
        fixed: "left",
        width: 130,
        render: text => new Date(text).toLocaleDateString("ru-RU"),
        dataIndex: "closeDate"
    },
    {
        title: "Тикер",
        dataIndex: "securityName"
    },
    {
        title: "ISIN",
        dataIndex: "isin"
    },
    {
        title: "Доходность",
        width: 120,
        align: "right",
        render: finNum,
        dataIndex: "profitability"
    },
    {
        title: "Валюта",
        width: 80,
        dataIndex: "dealCurrency"
    },
    {
        title: "Кол-во",
        width: 120,
        render: countNum,
        align: "right",
        dataIndex: "quantity"
    },
    {
        title: "Цена открытия",
        width: 140,
        render: r => formatPrice(r, 4),
        align: "right",
        dataIndex: "price"
    },
    {
        title: "Цена закрытия",
        width: 140,
        render: r => formatPrice(r, 4),
        align: "right",
        dataIndex: "closePrice"
    },
    {
        title: "Сумма открытия",
        width: 140,
        render: finNum,
        align: "right",
        dataIndex: "openAmount"
    },
    {
        title: "Сумма закрытия",
        width: 140,
        render: finNum,
        align: "right",
        dataIndex: "closeAmount"
    },
    // {
    //     title: "Признак авторепо",
    //     dataIndex: "autoRepo"
    // },
    // {
    //     title: "Признак без ЦК",
    //     dataIndex: "noCcBool"
    // },
    {
        title: "Кол-во дней",
        width: 110,
        align: "right",
        dataIndex: "repoDaysNumber"
    },
    // {
    //     title: "Принак сделки с корзиной",
    //     dataIndex: "basketBool"
    // },
    // {
    //     title: "Признак открытие РЕПО в запрашиваемой дате",
    //     dataIndex: "openOnDate"
    // },
    // {
    //     title: "Признак закрытия РЕПО в запрашиваемой дате",
    //     dataIndex: "closeOnDate"
    // },
    {
        title: "Номер субсчета",
        dataIndex: "subAccount"
    }

]