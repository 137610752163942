import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Layout, Row, Select, Table} from 'antd';
import {RangePickerProps} from 'antd/es/date-picker';
import moment from 'moment';
import {nanoid} from 'nanoid';
import {apiConnector} from "../../integrations/api.connector";
import {EReportType, ReportMetaRecord} from '../../types/reports';
import {columns} from "./data/allReports";
import {getFullPageTableHeight, rowClassCompact} from '../../services/helpers';

export const ReportsPageTest: React.FC = () => {
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    const [dates, setDates] = useState<string[]>([moment().subtract(1, "day").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]);
    const [reportType, setReportType] = useState<EReportType>(EReportType.ALL_REPORTS);
    const [data, setData] = useState<ReportMetaRecord[]>([])

    const onRange: RangePickerProps['onChange'] = (dates, dateStrings) => {
        setDates(dateStrings);
    }

    const getPDF = async (report: ReportMetaRecord) => {
        const pdf = await apiConnector.getPDFForReport({
            processId: report.processId,
            clientId: report.clientId,
            id: report.id
        })
        downloadPDF(pdf, report.processId)
    }

    const getCMS = async (report: ReportMetaRecord) => {
        const cms = await apiConnector.getCMSForReport({
            processId: report.processId,
            clientId: report.clientId,
            id: report.id
        })
        downloadCMS(cms, report.processId)
    }

    const downloadPDF = (p: ArrayBuffer, clientProcessId: string) => {
        const file = new File([p], `${clientProcessId}.pdf`, { type: 'application/pdf' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${clientProcessId}.pdf`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
    }

    const downloadCMS = (p: ArrayBuffer, clientProcessId: string) => {
        const file = new File([p], `${clientProcessId}.cms`, { type: 'application/cms' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${clientProcessId}.cms`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
    }

    const dataSource = (): (ReportMetaRecord & { action: any })[] => {
        if (reportType === EReportType.ALL_REPORTS) {
            return data.map(report => {
                return {
                    ...report,
                    action: <>
                        <Button
                            onClick={() => getPDF(report)}
                            type="primary" size="small" style={{ width: 150, margin: 3 }}>
                            Скачать PDF
                        </Button>
                        <Button
                            onClick={() => getCMS(report)}
                            type="default" size="small" style={{ width: 150, margin: 3 }}>
                            Скачать CMS
                        </Button>
                    </>
                }
            })
        }

        else {
            return data.filter(item => { return item.reportType === reportType && item.complete })  //item.complete === true
                .filter(item => {
                    if (item.startDate) {
                        const startDate = new Date(item.startDate).toLocaleDateString("ru-RU").split(".").reverse().join("-");
                        const endDate = new Date(item.endDate).toLocaleDateString("ru-RU").split(".").reverse().join("-");
                        return startDate >= dates[0] && endDate <= dates[1];
                    }
                    else {
                        const endDate = new Date(item.endDate).toLocaleDateString("ru-RU").split(".").reverse().join("-")
                        const momentDay = moment(endDate)
                        return momentDay.isSame(date, "day")
                        // const isSameDay = momentDay.isSame(date, "day")
                        // return isSameDay
                    }
                })
                .map(report => {
                    return {
                        ...report,
                        action: <>
                            <Button
                                onClick={() => getPDF(report)}
                                type="primary" size="small" style={{ width: 150, margin: 3 }}>
                                Скачать PDF
                            </Button>
                            <Button
                                onClick={() => getCMS(report)}
                                type="default" size="small" style={{ width: 150, margin: 3 }}>
                                Скачать CMS
                            </Button>
                        </>
                    }
                })
        }

    }

    const dateOrPeriod = () => {
        if (reportType === EReportType.ALL_REPORTS) {
            return null
        }
        else if (reportType === EReportType.MOVES_AND_CASH_FLOW_REPORT) {
            return <Row className='of-row'>
                <Col span={5}>Выберите период:</Col>
                <Col span={14}>
                    <DatePicker.RangePicker
                        size="small"
                        disabledDate={current => current && current > moment()}
                        defaultValue={[moment(dates[0]), moment(dates[1])]}
                        onChange={onRange}
                    />
                </Col>
            </Row>
        }
        else {
            return <Row className='of-row'>
                <span></span>
                <Col span={5}>Выберите дату:</Col>
                <Col span={14}>
                    <DatePicker
                        size="small"
                        disabledDate={current => current && current > moment()}
                        defaultValue={moment(date)}
                        onChange={val => val && setDate(val.format("YYYY-MM-DD"))}
                    />
                </Col>
            </Row>
        }
    }

    useEffect(() => {
        apiConnector.getClientReportList().then(({ success, payload }) => {
            if (success) {
                setData(payload);
            }
        })
    }, [])
    // useEffect(() => {
    //     dataSource()
    // }, [reportType])

    return <>
        <Layout className='content-wrap'>
            <Row className="of-row">
                <Col span={8}>
                    <Row className="of-row">
                        <Col span={7}>
                            Выберите тип отчета:
                        </Col>
                        <Col span={16}>
                            <Select
                                className="of-select deal-account"
                                size="small"
                                value={reportType}
                                onChange={setReportType}>
                                <Select.Option value={EReportType.ALL_REPORTS}>
                                    Все отчеты
                                </Select.Option>
                                <Select.Option value={EReportType.ACCOUNT_STATEMENT}>
                                    Выписка с лицевого счета
                                </Select.Option>
                                <Select.Option value={EReportType.DAILY_REPORT}>
                                    Отчет о сделках и операциях за день
                                </Select.Option>
                                <Select.Option value={EReportType.CUSTOMER_REPORT}>
                                    Отчет об исполнении клиентского заказа
                                </Select.Option>
                                <Select.Option value={EReportType.MOVES_AND_CASH_FLOW_REPORT}>
                                    Отчет о движении ЦБ и ДС
                                </Select.Option>
                                <Select.Option value={EReportType.EXECUTION_DAILY_REPORT}>
                                    Отчет о ежедневном выполнении
                                </Select.Option>
                            </Select>
                        </Col>
                    </Row>
                </Col>

                <Col span={10}>
                    {dateOrPeriod()}
                </Col>
            </Row>

            <Table
                dataSource={dataSource()}
                columns={columns}
                rowClassName={rowClassCompact}
                scroll={{ y: getFullPageTableHeight() - 100 }}
                rowKey={() => nanoid(8)}
                pagination={false}
                showSorterTooltip={false} />
        </Layout>
    </>
};
