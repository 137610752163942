import React, { FC } from "react";
import { Checkbox, Col, Row } from "antd";



export const TableEditorItem: FC<any> = (props) => {


    return (
        <Row style={{ margin: 15 }} className="of-row">
            <Checkbox style={{ marginRight: '20px' }} checked={props.checked} onChange={props.change} />
            <Col>{props.title}</Col>
        </Row>
    )
}
