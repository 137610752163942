import React, { FC } from 'react';
import { Table } from 'antd';
import { ColumnsType } from "antd/lib/table";
import { getFullPageTableHeight, rowClassCompact } from '../../../services/helpers';
import { TFTAccount } from '../../../types/api';
import { columnsByPlace } from '../money.data';


export const TableMoneyByPlace: FC<{ accounts: TFTAccount[], columns: ColumnsType<any>, loading: boolean }> = ({ accounts, columns, loading }) => {
    // const accountsByHolder = (holder)
    const nestedTableRender: FC<{ holdPlace: string, key: number }> = ({ holdPlace, key }) => {
        // .map(a => {a.key = key; return a})
        return <Table
            rowClassName={rowClassCompact}
            showSorterTooltip={false}
            columns={columns}
            dataSource={accounts.filter(a => a.holdPlace === holdPlace)}
            pagination={false} />
    }
    const holders = (): { holdPlace: string, key: number }[] => {
        const holders = new Set<string>();
        accounts.map((a) => {
            holders.add(a.holdPlace)
        });
        let data: { holdPlace: string, key: number }[] = [];
        let i = 0;
        holders.forEach(v => {
            data.push({ holdPlace: v, key: i });
            i++;
        });
        return data;
    }
    return (
        <Table
            size="small"
            showSorterTooltip={false}
            rowClassName={"group-head"}
            scroll={{ y: getFullPageTableHeight() - 100 }}
            dataSource={holders()}
            columns={columnsByPlace}
            expandable={{
                expandedRowRender: nestedTableRender,
                defaultExpandedRowKeys: [0]
            }}
            pagination={false}
            loading={loading} />
    )
}
