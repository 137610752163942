export enum EOrderType {
    // Клиентский заказ: BUY/SELL, REPO
    trade = "TRADE",
    // Клиентский приказ: Money/Securities transfer/conversion
    nonTrade = "NONTRADE",
    // Распоряги
    internal = "INTERNAL",
    // Шаблоны (КЗ и КП на согласовании)
    template = "TEMPLATE"
}
export type TNotification = {
    orderType: EOrderType,
    clientId?: number,
    orderId: number,
    statusId: number,
    date?: string // dd.mm.yyyy hh:mm:ss "29.05.2023 14:55:19"
}