import React from "react";
import { Modal, Button } from "antd";

interface CustomModalProps {
    addKey: () => void;
    isModal: boolean;
    onCancel: () => void;
    onOk: () => void;
    onRemove: () => void;
    titleTxt: string;
    children: React.ReactNode;
    isButtonDisabled: boolean;
}

export const CustomModal = ({
    addKey,
    isModal,
    onCancel,
    onOk,
    onRemove,
    titleTxt,
    children,
    isButtonDisabled
}: CustomModalProps) => {
    return (
        <Modal
            title={titleTxt}
            visible={isModal}
            onCancel={onCancel}
            okButtonProps={{ disabled: isButtonDisabled }}
            onOk={onOk}
            zIndex={100}
            footer={
                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingBottom: '10px', borderBottom: '1px solid #f0f0f0' }}>
                        <Button type="default" onClick={onRemove} style={{ width: '45%', fontSize: '13px' }}>
                            Очистить сохраненные ключи
                        </Button>
                        <Button type="default" onClick={addKey} style={{ width: '45%', fontSize: '13px' }}>
                            Добавить ключ
                        </Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingTop: '10px' }}>
                        <Button onClick={onCancel} type="default" style={{ width: '45%', fontSize: '13px' }}>
                            Отмена
                        </Button>
                        <Button onClick={onOk} type="primary" style={{ width: '45%', fontSize: '13px' }}>
                            OK
                        </Button>
                    </div>
                </div>
            }
            style={{ marginTop: '20vh' }}
        >
            {children}
        </Modal>
    );
};

