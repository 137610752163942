import React from 'react'
import { ColumnsType } from 'antd/lib/table';
import { countNum, finNum } from '../../services/formatter';
import { getOrderStatusName } from '../../services/statuses';
import { TOrderStatusFull } from '../../types/orders';

export const columns: ColumnsType<TOrderStatusFull & { action: any, icon: any }> = [
    {
        title: "Операция",
        dataIndex: "dealDirection",
        width: 120,
        fixed: "left",
        render: (text, record) => (record.rejectOrderId ? <span style={{ color: 'red' }}>Отмена {record.onum || "ID" + record.clientOrderId}</span> : text)
    },
    {
        title: "Дата заказа/приказа",
        dataIndex: "orderDate",
        width: 120,
        fixed: "left",
        render: text => new Date(text).toLocaleDateString("ru-RU")
    },
    {
        title: "Номер заказа/приказа",
        dataIndex: "orderNumber",
        fixed: "left",
        width: 120,
        render: (text, record) => text ? `№ ${text}` : `ID ${record.clientOrderId || record.rejectOrderId}`,
        // sorter: (a, b) => a.clientOrderId - b.clientOrderId
    },
    {
        title: "Тикер",
        dataIndex: "ticker",
        fixed: "left",
        render: (text, record) => (text || record.repoBasket || "-"),
        // sorter: (a, b) => (a && b) ? a.ticker.localeCompare(b.ticker) : 0
    },
    // {
    //     title: "Валюта",
    //     dataIndex: "currencyCode",
    //     fixed: "left",
    //     width: 80
    // },

    {
        title: "Счет",
        dataIndex: "subAccount",
        sorter: (a, b) => a.subAccount.localeCompare(b.subAccount)
    },
    {
        title: "Количество",
        dataIndex: "orderQuantity",
        align: "right",
        render: countNum,
        width: 110,
        sorter: (a, b) => a.orderQuantity - b.orderQuantity
    },
    {
        title: "Цена / Доходность",
        dataIndex: "orderPrice",
        align: "right",
        render: finNum,
        width: 110,
        sorter: (a, b) => a.orderQuantity - b.orderQuantity
    },
    {
        title: "Сумма",
        dataIndex: "orderAmount",
        align: "right",
        render: finNum,
        sorter: (a, b) => a.orderQuantity - b.orderQuantity
    },
    {
        title: "Статус",
        dataIndex: "orderStatusId",
        align: "right",
        render: getOrderStatusName,
        sorter: (a, b) => a.orderStatus.localeCompare(b.orderStatus)
    },
    {
        title: "Дата статуса",
        align: "right",
        dataIndex: "orderStatusDate",
        render: text => new Date(text).toLocaleString("ru-RU"),
        sorter: (a, b) => new Date(a.orderStatusDate).getTime() - new Date(b.orderStatusDate).getTime()
    },
    {
        title: "ISIN",
        dataIndex: "isin",
        sorter: (a, b) => a.isin.localeCompare(b.isin)
    },
    {
        title: "",
        dataIndex: "action",
        // width: 120,
        fixed: "right",
        align: "right",
        className: "action-column multi"
    },

    // {
    //     title: "",
    //     dataIndex: "file",
    //     fixed: "right",
    //     className: "action-column"
    // }
]
