import React from 'react';
import { Layout, Tabs } from 'antd';
import { AccountStatement } from './AccountStatement';
import { Transactions } from './Transactions';
import { OrderReport } from './OrderReport';
import { Movement } from './Movement';
import { CashFlow } from './CashFlow';

export const ReportsPage = () => {
    return (
        <Layout className="content-wrap">
            <Tabs type="card">
                <Tabs.TabPane tab="Выписка с лицевого счета" tabKey="accountStatement" key="accountStatement">
                    <AccountStatement />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Отчет о сделках и операциях за день" tabKey="transactions" key="transactions">
                    <Transactions />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Отчет об исполнении клиентского заказа" tabKey="orders" key="orders">
                    <OrderReport />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Отчет о движении ЦБ" tabKey="moves" key="moves">
                    <Movement />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Отчет о движении ДС" tabKey="cashFlow" key="cashFlow">
                    <CashFlow />
                </Tabs.TabPane>
            </Tabs>
        </Layout>
    )
}
