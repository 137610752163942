import { ColumnsType } from 'antd/lib/table';
import { RepMove } from '../../../types/reports';
import { countNum, parseDate } from "../../../services/formatter";

export const columns: ColumnsType<RepMove> = [
    {
        title: "Дата движения",
        fixed: "left",
        dataIndex: "settleDate",
        sorter: (a, b) => parseDate(a.settleDate).getTime() - parseDate(b.settleDate).getTime()
    },
    {
        title: "Вид движения",
        fixed: "left",
        dataIndex: "tranType",
    },
    {
        title: "Данные заказа/приказа",
        width: 180,
        dataIndex: "orderData",
    },
    {
        title: "Эмитент",
        dataIndex: "issuerName",
    },
    {
        title: "Торговый код",
        dataIndex: "tradeCode"
    },
    {
        title: "Тип ЦБ",
        dataIndex: "securityType",
    },
    {
        title: "ISIN",
        dataIndex: "isin",
    },
    {
        title: "Номинал",
        render: countNum,
        width: 90,
        align: "right",
        dataIndex: "faceValue",
    },
    {
        title: "Валюта",
        width: 80,
        dataIndex: "currency",
    },
    {
        title: "Сальдо на начало",
        width: 150,
        align: "right",
        render: countNum,
        dataIndex: "begSaldo",
    },
    {
        title: "Дебит",
        width: 150,
        align: "right",
        render: countNum,
        dataIndex: "debit",
    },
    {
        title: "Кредит",
        width: 150,
        align: "right",
        render: countNum,
        dataIndex: "credit",
    },
    {
        title: "Сальдо на конец",
        width: 150,
        align: "right",
        render: countNum,
        dataIndex: "endSaldo",
    },
    {
        title: "Дополнения",
        dataIndex: "additional",
    },
]
