import { useSelector } from 'react-redux';
import { dispatch, TState } from '../../../store';
import React from 'react';
import { Button, Modal, notification, Space } from 'antd';
import { closeDuplicateTemplateWindow, setTempTemplate } from '../../../store/orders.slice';
import { apiConnector } from '../../../integrations/api.connector';
import { timeForSuccessNotification } from '../../../constants';
import { NotificationsConnector } from '../../../integrations/notifications.connector';
import { ExclamationCircleFilled } from '@ant-design/icons';

export const DuplicateTemplateAlertModal = () => {
    const {
        orders: {
            showDuplicateTemplateModal,
            temporaryTemplate
        },
    } = useSelector((state: TState) => state);

    const handleOk = async () => {
        if (temporaryTemplate) {
            await apiConnector.setTemplate(temporaryTemplate);
        }
        dispatch(setTempTemplate(null));
        dispatch(closeDuplicateTemplateWindow());
        notification.success({
            duration: timeForSuccessNotification,
            message: "Заказ отправлен!",
        });
        NotificationsConnector.notify();
    };
    const handleCancel = () => {
        dispatch(setTempTemplate(null));
        dispatch(closeDuplicateTemplateWindow());
        notification.info({
            duration: timeForSuccessNotification,
            message: "Повторая отправка согласования отменена",
        });
    };

    return (
        <Modal
            visible={showDuplicateTemplateModal}
            footer={<Space>
                    <Button onClick={handleOk} block>Да</Button>
                    <Button onClick={handleCancel} type="primary" danger block>Нет</Button>
                </Space>}
            width={550}
            style={{ overflow: "hidden" }}
            zIndex={50}
            closable={false}
            centered
        >
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: 'column' }}>
                <p style={{fontWeight: "bold", fontSize: "18px"}}><ExclamationCircleFilled style={{color: "red"}} />  Вы пытаетесь повторно отправить согласование</p>
                <p style={{fontSize: "16px"}}>Продолжить отправку?</p>
            </div>
            
        </Modal>
    )
}
