import { ColumnsType } from 'antd/lib/table';
import { RepAccountStatement } from '../../../types/reports';
import { countNum } from '../../../services/formatter';

export const columns: ColumnsType<RepAccountStatement> = [
    {
        title: "Торговый код",
        dataIndex: "tradeCode",
        fixed: "left",
        sorter: (a, b) => a.tradeCode.localeCompare(b.tradeCode)
    },
    {
        title: "ISIN",
        dataIndex: "isin",
        fixed: "left",
        sorter: (a, b) => a.isin.localeCompare(b.isin)
    },
    {
        title: "Дата погашения",
        width: 150,
        align: "center",
        dataIndex: "maturityDate",
        sorter: (a, b) => (a.maturityDate ? a.maturityDate : "").localeCompare((b.maturityDate ? b.maturityDate : "")),
        render: record => record ? new Date(record).toLocaleDateString("ru-RU") : "-"
    },
    {
        title: "Остаток",
        dataIndex: "baseQuantity",
        align: "right",
        render: countNum,
        sorter: (a, b) => a.baseQuantity - b.baseQuantity
    },
    {
        title: "В РЕПО",
        dataIndex: "repoQuantity",
        align: "right",
        width: 100,
        render: countNum,
        sorter: (a, b) => a.repoQuantity - b.repoQuantity
    },
    {
        title: "В блоке",
        dataIndex: "blockQuantity",
        align: "right",
        width: 100,
        render: countNum,
        sorter: (a, b) => a.blockQuantity - b.blockQuantity
    },
    {
        title: "В залоге",
        dataIndex: "zalogQuantity",
        align: "right",
        width: 100,
        render: countNum,
        sorter: (a, b) => a.zalogQuantity - b.zalogQuantity
    },
    {
        title: "Эмитент",
        dataIndex: "issuerName",
        sorter: (a, b) => a.issuerName.localeCompare(b.issuerName)
    },
    {
        title: "Страна эмитента",
        dataIndex: "issuerCountry",
        sorter: (a, b) => a.issuerCountry.localeCompare(b.issuerCountry)
    },
]
