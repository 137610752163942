// Отправляется без eDoc для формирования XML
// Отправляется с eDoc для добавления приказа в систему
export type TProfileChangeRequest = {
    clientType: "person" | "company",
    changes: TPersonProfileChanges | TCompanyProfileChanges,
    eDoc?: string // XML with signature
}

export type TProfileChangeResponse = {
    success: boolean,
    orderId?: number, // if success true
    errorMessage?: string, // if success false
}

export type TCommonProfileChanges = {
    countryCode?: string,
    BIIN?: string,
    documentType?: string,
    documentSerie?: string,
    documentNumber?: string,
    documentDate?: string, // Date ISO string
    documentExpDate?: string, // Date ISO string
    documentIssuer?: string,
    location?: string,
    street?: string,
    buildingNumber?: string,
    aptNumber?: string,
}

export type TCompanyProfileChanges = {
    companyName?: string,
    shortName?: string,
    resident?: 0 | 1,
    firstChiefName?: string,
    firstChiefPost?: string,
    chiefAccountantName?: string,
} & TCommonProfileChanges

export type TPersonProfileChanges = {
    lastName?: string,
    firstName?: string,
    middleName?: string,
} & TCommonProfileChanges

export enum ERoles {
    accountManager = 'company_account_manager',
    admin = 'company_admin',
    assetManager = 'company_asset_manager',
    chief = 'company_chief',
    manager = 'company_manager'
}