export type UUID = string;
export type Timestamp = number;
export type UTCDate = string;
export type JWT = string;
export enum ECurrency {
    USD = "USD",
    KZT = "KZT",
    GBP = "GBP",
    RUB = "RUB",
    HKD = "HKD",
    EUR = "EUR",
}
export enum EDealType {
    market = 'market',
    limit = 'limit'
}
export type ISIN = string;
export type Ticker = string;

export enum ESecType {
    unknown,
    equity,
    bond
}
export type RepoBasket = string;
export enum EGlobalSecurityType {
    equity = 1,
    bond = 2
}

export interface jsonType {
    [key: string]: string;
}