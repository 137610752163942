import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux'
import { Button, DatePicker, Table } from 'antd';
import { ReloadOutlined } from "@ant-design/icons/lib";
import Search from 'antd/lib/input/Search';
import moment, { Moment } from "moment";
import { TableEditor } from "../tableEditor/TableEditor";
import { ExportBtn } from '../reports/ExportBtn';
import { dispatch, TState } from '../../store';
import { getAllAnalyticsExtended } from "../../store/analytics.slice";
import { getFullPageTableHeight, rowClassCompact, updateTableView } from '../../services/helpers';
import { columns } from './analyticsExt.data';
import { TAnalyticsDataExtended } from "../../types/analyticsExtended";
import { exportData } from "../../services/export";


export const AllAnalyticsDataExtend: FC = () => {
    const timerRef = useRef<number | undefined>();
    const [searchString, setSearchString] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const { analyticsDataExtended, analyticsFetching } = useSelector((state: TState) => state.analytics)
    const [searchDate, setSearchDate] = useState<string>('')
    const [exporting, setExporting] = useState(false);
    const [date, setDate] = useState(moment());

    useEffect(() => {
        dispatch(getAllAnalyticsExtended(searchDate ? `?pDate=${searchDate}` : ''))
    }, [])

    const dataSource = (): (TAnalyticsDataExtended & { action: any })[] => {
        return analyticsDataExtended
            .map(p => {
                return { ...p, action: <></> }
            }).filter(row => {
                if (!searchString || searchString.length < 1) {
                    return true
                } else {
                    return (row.Sec_Name && row.Sec_Name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                        (row.Sec_Type && row.Sec_Type.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                        (row.Isin && row.Isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
                }
            })
    }
    const onSearch = (value: string) => {
        setSearchString(value);
    }
    const onSearchByDate = (dateString: string) => {
        setSearchDate(dateString)
        dispatch(getAllAnalyticsExtended(`?pDate=${dateString}`))
    }
    const onSearchChange = (value: string) => {
        if (!loading) {
            setLoading(true);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            setSearchString(value);
            setLoading(false);
        }, 100)
    }


    const onExport = async () => {
        setExporting(true);
        const rows: TAnalyticsDataExtended[] = analyticsDataExtended.map(record => {
            return {
                ...record,
                Maturity_Date: record.Maturity_Date ? new Date(record.Maturity_Date).toLocaleDateString("ru-RU") : "-",
                Deal_Date: record.Deal_Date ? new Date(record.Deal_Date).toLocaleDateString("ru-RU") : "-",
                Prev_Pay_Date: record.Prev_Pay_Date ? new Date(record.Prev_Pay_Date).toLocaleDateString("ru-RU") : "-",
                Next_Pay_Date: record.Next_Pay_Date ? new Date(record.Next_Pay_Date).toLocaleDateString("ru-RU") : "-",
                Issue_Date: record.Issue_Date ? new Date(record.Issue_Date).toLocaleDateString("ru-RU") : "-",
            }
        })
        await exportData(rows, columns, "Расширенная Аналитика", date.toDate());
        setExporting(false);
    };

    const [myColumns, setMyColumns] = useState(updateTableView(columns, 'AllAnalyticsDataExtend'))
    const updateMyColumns = () => {
        setMyColumns(updateTableView(columns, 'AllAnalyticsDataExtend'))
    }
    return (
        <>
            <Search onSearch={onSearch}
                onChange={e => onSearchChange(e.target.value)}
                allowClear={true}
                placeholder={"Поиск"}
                style={{
                    width: 250,
                    marginBottom: 10,
                    marginRight: 8,
                    zIndex: 2
                }}
            />
            <DatePicker
                defaultValue={moment()}
                onChange={(value: Moment | null, dateString: string) => onSearchByDate(dateString)}
                style={{
                    width: 250,
                    marginBottom: 10,
                    marginRight: 8,
                    zIndex: 2
                }}
            />
            {/*<Search onSearch={(date: string) => onSearchByDate(date)}*/}
            {/*        onChange={e => onSearchChange(e.target.value)}*/}
            {/*        allowClear={true}*/}
            {/*        type={'date'}*/}
            {/*        style={{*/}
            {/*            width: 250,*/}
            {/*            marginBottom: 10,*/}
            {/*            zIndex: 2*/}
            {/*        }}*/}
            {/*/>*/}
            <Button style={{ marginRight: 8 }} onClick={() => dispatch(getAllAnalyticsExtended(searchDate ? `?pDate=${searchDate}` : ''))} type="default">
                <ReloadOutlined />
            </Button>
            <TableEditor updateColumns={updateMyColumns} columns={columns} keyForLS={'AllAnalyticsDataExtend'} />
            <ExportBtn style={{ marginLeft: 8 }} onClick={onExport.bind(AllAnalyticsDataExtend)} loading={exporting} />
            <Table
                size="small"
                showSorterTooltip={false}
                rowClassName={rowClassCompact}
                rowKey={record => record.Ft_Id}
                dataSource={dataSource()}
                columns={myColumns}
                scroll={{
                    x: "max-content",
                    y: getFullPageTableHeight() - 150
                }}
                loading={analyticsFetching || loading}
                pagination={false}
            />
        </>
    )
}
