import React from 'react';
import { Layout, Tabs } from 'antd';
import { AllAnalyticsData } from "./AllAnalyticsData";
import { AllRepaymentsDataByAssets } from "./repayments/AllRepaymentsDataByAssets";
import { AllRepaymentsDataByDate } from "./repayments/AllRepaymentsDataByDate";
import { AllAnalyticsDataExtend } from './AllAnalyticsDataExtend';


export const AnalyticsPage = () => {
    return (
        <Layout className="content-wrap">
            <Tabs type="card">
                <Tabs.TabPane tab="Аналитика" tabKey="analytics" key="analytics">
                    <AllAnalyticsData />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Расширенная Аналитика" tabKey="analyticsExtend" key="analyticsExtend">
                    <AllAnalyticsDataExtend />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Погашения по активам" tabKey="repaymentsByAssets" key="repaymentsByAssets">
                    <AllRepaymentsDataByAssets />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Погашения по дате" tabKey="repaymentsByDate" key="repaymentsByDate">
                    <AllRepaymentsDataByDate />
                </Tabs.TabPane>
            </Tabs>
        </Layout>
    )
}