import React, { FC } from "react";
import { Col, Divider, Row } from "antd";
import { TFTAccount } from "../../../types/api";
import { TMoneyTransferRequest, TOrderTemplate } from "../../../types/orders";
import { finNum } from "../../../services/formatter";

export const EMTForm: FC<{ t: TOrderTemplate, accounts: TFTAccount[] }> = ({ t, accounts }) => {
    const {
        amount, ftOutID, recipientName, recipientBIIN, bankName, IIC, bankSWIFT, KBE,
        corrBankSWIFT, corrBankName, corrBankAccount
    } = t.orderData as TMoneyTransferRequest;
    let {
        currencyCode,
    } = t.extraData;
    if (!currencyCode) {
        currencyCode = accounts.find(a => a.ftId === ftOutID)?.currencyCode;
    }
    const fromAccount = accounts.find(a => a.ftId === ftOutID);

    return (
        <>
            <Row className="bordered-row ticker-currency">
                <Col span={20}>Перевод денежных средств (внешний)</Col>
                <Col span={4}>{ }</Col>
            </Row>
            <Row className="bordered-row">
                <Col span={24}>
                    {currencyCode} {finNum(amount)}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Счёт списания:
                </Col>
                <Col span={16}>
                    {fromAccount?.iType ? `(${fromAccount.iType}) ` : ""}{fromAccount?.holdPlace} ({fromAccount?.accountNumber})
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Наименование/ФИО получателя:
                </Col>
                <Col span={16}>
                    {recipientName}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    БИН/ИИН получателя:
                </Col>
                <Col span={16}>
                    {recipientBIIN}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    ИИК (банк бенефициара):
                </Col>
                <Col span={16}>
                    {IIC}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Банк бенефициара:
                </Col>
                <Col span={16}>
                    {bankName}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    БИК/SWIFT бенефициара:
                </Col>
                <Col span={16}>
                    {bankSWIFT}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    КБе бенефициара:
                </Col>
                <Col span={16}>
                    {KBE}
                </Col>
            </Row>
            {currencyCode !== "KZT" && <>
                <Divider />
                <Row className="of-row">
                    <Col span={8}>
                        Банк-корреспондент:
                    </Col>
                    <Col span={16}>
                        {corrBankName}
                    </Col>
                </Row>
                <Row className="of-row">
                    <Col span={8}>
                        Корсчет (банк-корреспондент):
                    </Col>
                    <Col span={16}>
                        {corrBankAccount}
                    </Col>
                </Row>
                <Row className="of-row">
                    <Col span={8}>
                        SWIFT (банк-корреспондент):
                    </Col>
                    <Col span={16}>
                        {corrBankSWIFT}
                    </Col>
                </Row>
            </>}
        </>
    )
}