import { ColumnsType } from "antd/lib/table";
import { TRepoData } from "../../types/repo";

export const repoColumns: ColumnsType<TRepoData & { action: any }> = [
    // {
    //     title: "SecBoard",
    //     dataIndex: "secboard",
    //     width: 100,
    //     align: "center"
    // },
    // {
    //     title: "SecCode",
    //     dataIndex: "seccode",
    //     width: 100,
    //     align: "center"
    // },
    {
        title: "Название",
        dataIndex: "name",
        width: 250,
        align: "left"
    },

    {
        title: "",
        align: "left",
        dataIndex: "action",
        className: "action-column"
    },
    {
        title: "open",
        width: 160,
        align: "right",
        dataIndex: "open",
    },
    {
        title: "last",
        width: 160,
        align: "right",
        dataIndex: "last",
    },
    {
        title: "bid",
        width: 160,
        align: "right",
        dataIndex: "bid",
    },
    {
        title: "ask",
        width: 160,
        align: "right",
        dataIndex: "ask",
    },
    {

    }
]

export const repoData: TRepoData[] = [
    {
        secboard: "EBM3",
        seccode: "GCBRK",
        name: "РЕПО с Корзиной ГЦБ ЦК 90Д",
        days: 90
    },
    {
        secboard: "EBMP",
        seccode: "GCBRK",
        name: "РЕПО с Корзиной ГЦБ ЦК 30Д",
        days: 30
    },
    {
        secboard: "EBR2",
        seccode: "GCBRK",
        name: "РЕПО с Корзиной ГЦБ ЦК 2Д",
        days: 2
    },
    {
        secboard: "EBR3",
        seccode: "GCBRK",
        name: "РЕПО с Корзиной ГЦБ ЦК 3Д",
        days: 3
    },
    {
        secboard: "EBRP",
        seccode: "GCBRK",
        name: "РЕПО с Корзиной ГЦБ ЦК 1Д",
        days: 1
    },
    {
        secboard: "EBW2",
        seccode: "GCBRK",
        name: "РЕПО с Корзиной ГЦБ ЦК 14Д",
        days: 14
    },
    {
        secboard: "EBWP",
        seccode: "GCBRK",
        name: "РЕПО с Корзиной ГЦБ ЦК 7Д",
        days: 7
    },
    {
        secboard: "ELMP",
        seccode: "GCBRK-GR",
        name: "РЕПО с Корзиной ГЦБ без ЦК 30Д",
        days: 30
    },
    {
        secboard: "ELR2",
        seccode: "GCBRK-GR",
        name: "РЕПО с Корзиной ГЦБ без ЦК 2Д",
        days: 2
    },
    {
        secboard: "ELR3",
        seccode: "GCBRK-GR",
        name: "РЕПО с Корзиной ГЦБ без ЦК 3Д",
        days: 3
    },
    {
        secboard: "ELRP",
        seccode: "GCBRK-GR",
        name: "РЕПО с Корзиной ГЦБ без ЦК 1Д",
        days: 1
    },
    {
        secboard: "ELW2",
        seccode: "GCBRK-GR",
        name: "РЕПО с Корзиной ГЦБ без ЦК 14Д",
        days: 14
    },
    {
        secboard: "ELWP",
        seccode: "GCBRK-GR",
        name: "РЕПО с Корзиной ГЦБ без ЦК 7Д",
        days: 7
    },
    {
        secboard: "EBM3",
        seccode: "BSP",
        name: "РЕПО с Корзиной НЦБ ЦК 90Д",
        days: 90
    },
    {
        secboard: "EBMP",
        seccode: "BSP",
        name: "РЕПО с Корзиной НЦБ ЦК 30Д",
        days: 30
    },
    {
        secboard: "EBR2",
        seccode: "BSP",
        name: "РЕПО с Корзиной НЦБ ЦК 2Д",
        days: 2
    },
    {
        secboard: "EBR3",
        seccode: "BSP",
        name: "РЕПО с Корзиной НЦБ ЦК 3Д",
        days: 3
    },
    {
        secboard: "EBRP",
        seccode: "BSP",
        name: "РЕПО с Корзиной НЦБ ЦК 1Д",
        days: 1
    },
    {
        secboard: "EBW2",
        seccode: "BSP",
        name: "РЕПО с Корзиной НЦБ ЦК 14Д",
        days: 14
    },
    {
        secboard: "EBWP",
        seccode: "BSP",
        name: "РЕПО с Корзиной НЦБ ЦК 7Д",
        days: 7
    }
]