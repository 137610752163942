import React, { FC } from 'react';
import { Button } from 'antd';
import { dispatch } from '../../store';
import { showBuyOrder } from '../../store/orders.slice';
import { TMarketSecurity } from '../../types/api';


export const BuyBtn: FC<{ marketSecurity: TMarketSecurity }> = ({ marketSecurity }) => {
    return (
        <Button
            size="small"
            type="default"
            className="buy-btn"
            style={{ backgroundColor: 'var(--fhs-green)', color: 'white' }}
            onClick={() => { dispatch(showBuyOrder(marketSecurity)) }}>
            BUY
        </Button>
    )
}
