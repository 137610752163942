import React, { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import { CloseCircleOutlined, EditOutlined, FileDoneOutlined, ReloadOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { dispatch, TState } from '../../store';
import { getOrders, showCancelTradeOrder } from '../../store/orders.slice';
import { apiConnector } from "../../integrations/api.connector";
import { DealKind, EOrderStatus, EPDFOrderType, TOrderStatusFull } from '../../types/orders';
import { BuyIcon } from '../shared/icons/buy.icon';
import { SellIcon } from '../shared/icons/sell.icon';
import { TableEditor } from "../tableEditor/TableEditor";
import { getFullPageTableHeight, getHalfPageTableHeight, rowClassCompact, updateTableView } from '../../services/helpers';
import { columns } from './orders.data';
import { columns as repoColumns } from './repo.orders.data';


export const Orders: FC<{ isLog?: boolean, isRepo?: boolean, fullHeight?: boolean }> = ({ isLog = false, isRepo = false, fullHeight = false }) => {
    const timerRef = useRef<number | undefined>();
    const [searchString, setSearchString] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const { ordersFetching, completedOrders, trackedOrders, completedRepoOrders, trackedRepoOrders } = useSelector((state: TState) => state.orders)
    const orders = isRepo ? isLog ? completedRepoOrders : trackedRepoOrders : isLog ? completedOrders : trackedOrders;
    const cancelableStatusIds = [
        EOrderStatus.confirmed,
        EOrderStatus.processing,
        EOrderStatus.awaitingConfirmation,
        EOrderStatus.partlyCompleted,
        EOrderStatus.partlyOnCalculationAndProcessing,
        EOrderStatus.partlyCompletedAndProcessing
    ];
    const downloadTXT = (p: TOrderStatusFull) => {
        const fileContent = `${p.signature}`;
        const file = new File([fileContent], `${p.clientOrderId}.xml`, { type: 'application/xml' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${p.clientOrderId}.xml`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
    }
    const getDoc = async (o: TOrderStatusFull) => {
        const { clientOrderId, signature, rejectOrderId, onum } = o;
        const p = await apiConnector.getPDF({
            id: o.id,
            xmlData: signature!,
            orderId: o.rejectOrderId && o.orderId ? o.orderId.toString() : clientOrderId.toString(),
            orderDate: o.orderDate,
            orderNumber: (o.rejectOrderId && o.onum) ? o.onum : o.orderNumber || "internal",
            orderType: o.rejectOrderId ? EPDFOrderType.cancel : isRepo ? EPDFOrderType.repo : EPDFOrderType.equities,
            rejOrderNumber: o.rejONum || undefined
        });
        downloadPDF(p, rejectOrderId || clientOrderId);
    }
    const downloadPDF = (p: ArrayBuffer, clientOrderId: number) => {
        const file = new File([p], `${clientOrderId}.pdf`, { type: 'application/pdf' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${clientOrderId}.pdf`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
    }
    const dataSource = (): (TOrderStatusFull & { action: any, icon: any })[] => {
        return orders
            .map(p => {
                const canBeCanceled = !isLog && cancelableStatusIds.includes(p.orderStatusId);
                const ticker: string = p.ticker || p.repoBasket || "н.д.";
                return {
                    ...p,
                    ticker: ticker,
                    icon: p.rejectOrderId ? <CloseCircleOutlined style={{ fontSize: '16px', marginTop: '2px' }} /> : p.dealKindId === DealKind.buy ? <BuyIcon color={"var(--fhs-black)"} /> : <SellIcon color={"var(--fhs-black)"} />,
                    action: <>
                        {isLog ? null : <Button title="Отменить заказ" onClick={() => dispatch(showCancelTradeOrder(p))} disabled={!canBeCanceled} size="small" type="primary" danger><CloseCircleOutlined style={{ fontSize: '18px', marginTop: '2px' }} /></Button>}
                        {/*<Button type="primary" size="small" title="Скачать заказ" href={p.file_path} target="_blank"><FileDoneOutlined style={{fontSize: '18px', marginTop: '2px'}} /></Button>*/}
                        <Button onClick={() => getDoc(p)} type="primary" size="small" title="Скачать заказ"><FileDoneOutlined style={{ fontSize: '18px', marginTop: '2px' }} /></Button>
                        <Button onClick={() => downloadTXT(p)} type="default" size="small" title="Скачать подпись"><EditOutlined style={{ fontSize: '18px', marginTop: '2px' }} /></Button>
                    </>
                }
            })
            // .filter(row => moment().diff(moment(row.orderStatusDate),'days') < 30)
            .filter(row => {
                if (!searchString || searchString.length < 1) {
                    return true
                } else {
                    return (row.ticker && row.ticker.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                        (row.subAccount && row.subAccount.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                        (row.isin && row.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
                }
            })
    }
    const onSearch = (value: string) => {
        setSearchString(value);
    }
    const onSearchChange = (value: string) => {
        if (!loading) {
            setLoading(true);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            setSearchString(value);
            setLoading(false);
        }, 100)
    }
    const [myColumns, setMyColumns] = useState(updateTableView(isRepo ? repoColumns : columns, isRepo ? 'repoOrders' : 'Orders'))
    const updateMyColumns = () => {
        setMyColumns(updateTableView(isRepo ? repoColumns : columns, isRepo ? 'repoOrders' : 'Orders'))
    }
    return (
        <>
            <Search onSearch={onSearch}
                onChange={e => onSearchChange(e.target.value)}
                // value={searchString}
                allowClear={true}
                // className="table-search"
                placeholder={"Поиск"}
                style={{
                    width: 250,
                    marginBottom: 10,
                    // top: 10,
                    // position: "absolute",
                    zIndex: 2
                }}
            />
            <Button style={{ marginRight: 8 }} onClick={() => dispatch(getOrders())} type="default">
                <ReloadOutlined />
            </Button>
            <TableEditor updateColumns={updateMyColumns} columns={isRepo ? repoColumns : columns} keyForLS={isRepo ? 'repoOrders' : 'Orders'} />
            <Table
                size="small"
                showSorterTooltip={false}
                rowClassName={rowClassCompact}
                rowKey={record => record.id}
                dataSource={dataSource()}
                columns={myColumns}
                scroll={{
                    x: "max-content",
                    y: fullHeight ? getFullPageTableHeight() : getHalfPageTableHeight()
                }}
                loading={ordersFetching || loading}
                pagination={false}
            />
        </>
    )
}
