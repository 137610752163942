import React from 'react'
import { ColumnsType } from 'antd/lib/table';
import { TDealStatusRecord } from "../../types/orders";
import { countNum, finNum } from "../../services/formatter";


export const newColumnsDealsSec: ColumnsType<TDealStatusRecord & { action: any }> = [
    // {
    //     title: "",
    //     // dataIndex: "icon",
    //     width: 40,
    //     fixed: "left",
    //     // sorter: (a, b) => a.dealKindId - b.dealKindId
    // },
    // {
    //     title: "ID",
    //     dataIndex: "clientOrderId",
    //     align: "left",
    //     width: 110,
    //     sorter: (a, b) =>  a.clientOrderId - b.clientOrderId
    // },
    // {
    //     title: "Primary ID",
    //     dataIndex: "primaryClientOrderId",
    //     align: "left",
    //     width: 160,
    //     sorter: (a, b) => a.primaryClientOrderId && b.primaryClientOrderId ? a.primaryClientOrderId - b.primaryClientOrderId : 1
    // },
    // {
    //     title: "ID отказа",
    //     dataIndex: "rejectOrderId",
    //     align: "left",
    //     width: 160,
    //     sorter: (a, b) => a.rejectOrderId && b.rejectOrderId ? a.rejectOrderId - b.rejectOrderId : 1
    // },
    // {
    //     title: null,
    //     // dataIndex: "orderNumber",
    //     width: 80,
    //     fixed: 'left',
    //     align: "center",
    //     // render: (text) => ({
    //     //     children: text,
    //     //     props: {
    //     //         style: { fontSize: "12px" } // Установите значение, соответствующее желаемому размеру шрифта
    //     //     }
    //     // })
    //     // sorter: (a, b) => a.orderNumber && b.orderNumber ? a.orderNumber.localeCompare(b.orderNumber) : 1
    // },
    {
        title: null,
        // dataIndex: "orderTypeId",
        width: 185,
        fixed: 'left',
        align: "center",
        // render: (text) => {
        //     if (text === 1) {
        //         return <span style={{ fontSize: "12px" }}>Лимитный</span>;
        //     } else if (text === 2) {
        //         return <span style={{ fontSize: "12px" }}>Рыночный</span>;
        //     }
        //     // Если значение не соответствует 1, 2 или 3, можно вернуть что-то другое или пустую строку.
        //     return "";
        // },
    },
    {
        title: null,
        // dataIndex: "dealDirection",
        width: 80,
        fixed: 'left',
        align: "center",
        // render: (text) => ({
        //     children: text,
        //     props: {
        //         style: { fontSize: "12px" }
        //     }
        // })
    },
    {
        title: null,
        // dataIndex: "ticker",
        fixed: "left",
        align: "center",
        width: 70,
        // render: (text) => ({
        //     children: text,
        //     props: {
        //         style: { fontSize: "12px" }
        //     }
        // })
        // sorter: (a, b) => a.ticker && b.ticker ? a.ticker.localeCompare(b.ticker) : 1
    },
    {
        title: null,
        // dataIndex: "isin",
        align: "center",
        width: 80,
        // render: (text) => ({
        //     children: text,
        //     props: {
        //         style: { fontSize: "12px" }
        //     }
        // })
        // sorter: (a, b) => a.isin && b.isin ? a.isin.localeCompare(b.isin) : 1
    },
    {
        title: null,
        // dataIndex: "currencyCode",
        align: "center",
        width: 80,
        // render: (text) => ({
        //     children: text,
        //     props: {
        //         style: { fontSize: "12px" }
        //     }
        // })
        // sorter: (a, b) => a.currencyCode && b.currencyCode ? a.currencyCode.localeCompare(b.currencyCode) : 1

    },
    {
        title: null,
        dataIndex: "orderStatus",
        align: "center",
        width: 80,
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "12px" }
            }
        })
        // sorter: (a, b) => a.orderStatus && b.orderStatus ? a.orderStatus.localeCompare(b.orderStatus) : 1
    },

    {
        title: null,
        dataIndex: "orderPrice",
        width: 80,
        align: "right",
        render: (text) => ({
            children: finNum(text),
            props: {
                style: { fontSize: "12px" }
            }
        })
        // sorter: (a, b) => a.orderQuantity - b.orderQuantity
    },
    {
        title: null,
        // dataIndex: "orderQuantity",
        width: 80,
        align: "center",
        // render: (text) => ({
        //     children: countNum(text),
        //     props: {
        //         style: { fontSize: "12px" }
        //     }
        // })
        // sorter: (a, b) => a.orderQuantity - b.orderQuantity
    },
    {
        title: null,
        // dataIndex: "orderAmount",
        width: 80,
        align: "right",
        // render: (text) => ({
        //     children: finNum(text),
        //     props: {
        //         style: { fontSize: "12px" }
        //     }
        // })
        // sorter: (a, b) => a.orderQuantity - b.orderQuantity
    },

    {
        title: null,
        dataIndex: "dealPrice",
        width: 80,
        align: "right",
        render: (text) => ({
            children: finNum(text),
            props: {
                style: { fontSize: "12px" }
            }
        })
        // sorter: (a, b) => a.dealPrice - b.dealPrice
    },
    {
        title: null,
        dataIndex: "dealQuantity",
        width: 90,
        align: "center",
        render: (text) => ({
            children: countNum(text),
            props: {
                style: { fontSize: "12px" }
            }
        })
        // sorter: (a, b) => a.dealQuantity - b.dealQuantity
    },
    {
        title: null,
        dataIndex: "dealAmount",
        width: 80,
        align: "left",
        render: (text, record) => ({
            children: finNum(record.dealPrice * record.dealQuantity),
            props: {
                style: { fontSize: "12px" }
            }
        })
        // sorter: (a, b) => a.dealQuantity - b.dealQuantity
    },

    {
        title: null,
        dataIndex: "orderDate",
        align: "center",
        width: 80,
        render: (text) => ({
            children: new Date(text).toLocaleString("ru-RU", { dateStyle: 'short', timeStyle: 'short' }),
            props: {
                style: { fontSize: "12px" }
            }
        })
        // sorter: (a, b) => a.orderDate && b.orderDate ? new Date(a.orderDate).valueOf() - new Date(b.orderDate).valueOf() : 1
    },
    {
        title: null,
        dataIndex: "orderStatusDate",
        width: 80,
        align: "center",
        render: (text) => ({
            children: new Date(text).toLocaleString("ru-RU", { dateStyle: 'short', timeStyle: 'short' }),
            props: {
                style: { fontSize: "12px" }
            }
        })
        // sorter: (a, b) => new Date(a.orderStatusDate).getTime() - new Date(b.orderStatusDate).getTime()
    },

    {
        title: null,
        dataIndex: "durationDate",
        width: 80,
        align: "center",
        render: (text) => ({
            children: new Date(text).toLocaleString("ru-RU", { dateStyle: 'short', timeStyle: 'short' }),
            props: {
                style: { fontSize: "12px" }
            }
        }),
        // sorter: (a, b) => new Date(a.durationDate).getTime() - new Date(b.durationDate).getTime()
    },

    {
        title: null,
        dataIndex: "priceQuotation",
        width: 80,
        align: "center",
        render: (text) => {
            if (text === 1) {
                return <span style={{ fontSize: "12px" }}>Чистая цена</span>;
            } else if (text === 2) {
                return <span style={{ fontSize: "12px" }}>Цена</span>;
            } else if (text === 3) {
                return <span style={{ fontSize: "12px" }}>Доходность</span>;
            }
            // Если значение не соответствует 1, 2 или 3, можно вернуть что-то другое или пустую строку.
            return "";
        },
    },

    {
        title: null,
        dataIndex: "subAccount",
        width: 120,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "12px" }
            }
        })
        // sorter: (a, b) => a.subAccount.localeCompare(b.subAccount),
    },

    {
        title: null,
        dataIndex: "action",
        // width: 110,
        fixed: "right",
        align: "right",
        className: "action-column multi"
    },
    // {
    //     title: "KASE ID",
    //     dataIndex: "kaseOrderId",
    //     align: "left",
    //     width: 160,
    //     sorter: (a, b) => a.kaseOrderId && b.kaseOrderId ? a.kaseOrderId.localeCompare(b.kaseOrderId) : 1
    // },
    // {
    //     title: "Второстепенный счёт",
    //     dataIndex: "subAccount",
    //     align: "left",
    //     width: 210,
    //     sorter: (a, b) => a.subAccount && b.subAccount ? a.subAccount.localeCompare(b.subAccount) : 1
    // },
    // {
    //     title: "Кол-во дней",
    //     dataIndex: "tradeModeCode",
    //     align: "left",
    //     width: 80,
    //     render: text => `${daySuffix(getRepoDaysByTradeModeCode(text))}`,
    //     sorter: (a, b) => a.tradeModeCode && b.tradeModeCode ? a.tradeModeCode.localeCompare(b.tradeModeCode) : 1
    // },
    // {
    //     title: "Направление сделки",
    //     dataIndex: "dealDirection",
    //     align: "left",
    //     width: 210,
    // },
    // {
    //     title: "Корзина",
    //     dataIndex: "repoBasket",
    //     align: "left",
    //     width: 160,
    //     sorter: (a, b) => a.repoBasket && b.repoBasket ? a.repoBasket.localeCompare(b.repoBasket) : 1
    // },
    // {
    //     title: "ID Типа Заказа",
    //     dataIndex: "orderTypeId",
    //     align: "left",
    //     width: 160,
    // },
    // {
    //     title: "ID статуса заказа",
    //     dataIndex: "orderStatusId",
    //     align: "left",
    //     width: 160,
    //     sorter: (a, b) => a.orderStatusId && b.orderStatusId ? a.orderStatusId - b.orderStatusId : 1
    // },
    // {
    //     title: "Статус всех заказов",
    //     dataIndex: "allOrderStatus",
    //     align: "left",
    //     width: 160,
    //     sorter: (a, b) => a.allOrderStatus && b.allOrderStatus ? a.allOrderStatus.localeCompare(b.allOrderStatus) : 1
    // },
    // {
    //     title: "Катировка цены",
    //     dataIndex: "priceQuotation",
    //     align: "right",
    //     width: 160,
    //     sorter: (a, b) => a.priceQuotation && b.priceQuotation ? a.priceQuotation - b.priceQuotation : 1
    // },
]

export const columnsDealsSec: ColumnsType<TDealStatusRecord & { action: any }> = [
    // {
    //     title: "ID",
    //     dataIndex: "clientOrderId",
    //     align: "left",
    //     width: 110,
    //     sorter: (a, b) =>  a.clientOrderId - b.clientOrderId
    // },
    // {
    //     title: "Primary ID",
    //     dataIndex: "primaryClientOrderId",
    //     align: "left",
    //     width: 160,
    //     sorter: (a, b) => a.primaryClientOrderId && b.primaryClientOrderId ? a.primaryClientOrderId - b.primaryClientOrderId : 1
    // },
    // {
    //     title: "ID отказа",
    //     dataIndex: "rejectOrderId",
    //     align: "left",
    //     width: 160,
    //     sorter: (a, b) => a.rejectOrderId && b.rejectOrderId ? a.rejectOrderId - b.rejectOrderId : 1
    // },
    {
        title: "Номер заказа",
        dataIndex: "orderNumber",
        align: "left",
        width: 140,
        sorter: (a, b) => a.orderNumber && b.orderNumber ? a.orderNumber.localeCompare(b.orderNumber) : 1
    },
    {
        title: "Дата заказа",
        dataIndex: "orderDate",
        align: "left",
        width: 220,
        render: text => new Date(text).toLocaleString("ru-RU"),
        sorter: (a, b) => a.orderDate && b.orderDate ? new Date(a.orderDate).valueOf() - new Date(b.orderDate).valueOf() : 1
    },
    {
        title: "Срок действия",
        dataIndex: "durationDate",
        align: "left",
        width: 120,
        render: text => new Date(text).toLocaleDateString("ru-RU"),
        sorter: (a, b) => a.durationDate && b.durationDate ? new Date(a.durationDate).valueOf() - new Date(b.durationDate).valueOf() : 1
    },
    // {
    //     title: "KASE ID",
    //     dataIndex: "kaseOrderId",
    //     align: "left",
    //     width: 160,
    //     sorter: (a, b) => a.kaseOrderId && b.kaseOrderId ? a.kaseOrderId.localeCompare(b.kaseOrderId) : 1
    // },
    // {
    //     title: "Второстепенный счёт",
    //     dataIndex: "subAccount",
    //     align: "left",
    //     width: 210,
    //     sorter: (a, b) => a.subAccount && b.subAccount ? a.subAccount.localeCompare(b.subAccount) : 1
    // },
    {
        title: "Вид сделки",
        dataIndex: "dealKindId",
        align: "left",
        width: 160,
        render: text => text === 1 ? "Покупка" : "Продажа",
    },
    {
        title: "Валюта",
        dataIndex: "currencyCode",
        align: "left",
        width: 160,
        sorter: (a, b) => a.currencyCode && b.currencyCode ? a.currencyCode.localeCompare(b.currencyCode) : 1

    },
    // {
    //     title: "Кол-во дней",
    //     dataIndex: "tradeModeCode",
    //     align: "left",
    //     width: 80,
    //     render: text => `${daySuffix(getRepoDaysByTradeModeCode(text))}`,
    //     sorter: (a, b) => a.tradeModeCode && b.tradeModeCode ? a.tradeModeCode.localeCompare(b.tradeModeCode) : 1
    // },
    // {
    //     title: "Направление сделки",
    //     dataIndex: "dealDirection",
    //     align: "left",
    //     width: 210,
    // },
    {
        title: "Тикер",
        dataIndex: "ticker",
        align: "left",
        width: 160,
        sorter: (a, b) => a.ticker && b.ticker ? a.ticker.localeCompare(b.ticker) : 1
    },
    {
        title: "ISIN",
        dataIndex: "isin",
        align: "left",
        width: 160,
        sorter: (a, b) => a.isin && b.isin ? a.isin.localeCompare(b.isin) : 1
    },
    // {
    //     title: "Корзина",
    //     dataIndex: "repoBasket",
    //     align: "left",
    //     width: 160,
    //     sorter: (a, b) => a.repoBasket && b.repoBasket ? a.repoBasket.localeCompare(b.repoBasket) : 1
    // },
    // {
    //     title: "ID Типа Заказа",
    //     dataIndex: "orderTypeId",
    //     align: "left",
    //     width: 160,
    // },
    {
        title: "Дата статуса заказа",
        dataIndex: "orderStatusDate",
        align: "left",
        width: 220,
        render: text => new Date(text).toLocaleString("ru-RU"),
        sorter: (a, b) => a.orderStatusDate && b.orderStatusDate ? new Date(a.orderStatusDate).valueOf() - new Date(b.orderStatusDate).valueOf() : 1
    },
    // {
    //     title: "ID статуса заказа",
    //     dataIndex: "orderStatusId",
    //     align: "left",
    //     width: 160,
    //     sorter: (a, b) => a.orderStatusId && b.orderStatusId ? a.orderStatusId - b.orderStatusId : 1
    // },
    {
        title: "Статус заказа",
        dataIndex: "orderStatus",
        align: "left",
        width: 160,
        sorter: (a, b) => a.orderStatus && b.orderStatus ? a.orderStatus.localeCompare(b.orderStatus) : 1
    },
    // {
    //     title: "Статус всех заказов",
    //     dataIndex: "allOrderStatus",
    //     align: "left",
    //     width: 160,
    //     sorter: (a, b) => a.allOrderStatus && b.allOrderStatus ? a.allOrderStatus.localeCompare(b.allOrderStatus) : 1
    // },
    // {
    //     title: "Катировка цены",
    //     dataIndex: "priceQuotation",
    //     align: "right",
    //     width: 160,
    //     sorter: (a, b) => a.priceQuotation && b.priceQuotation ? a.priceQuotation - b.priceQuotation : 1
    // },
    {
        title: "Цена заказа",
        dataIndex: "orderPrice",
        align: "right",
        width: 160,
        render: finNum,
        sorter: (a, b) => a.orderPrice && b.orderPrice ? a.orderPrice - b.orderPrice : 1
    },
    {
        title: "Цена сделки",
        dataIndex: "dealPrice",
        align: "right",
        width: 160,
        render: finNum,
        sorter: (a, b) => a.dealPrice && b.dealPrice ? a.dealPrice - b.dealPrice : 1
    },
    {
        title: "Кол-во по заказам",
        dataIndex: "orderQuantity",
        align: "right",
        width: 160,
        sorter: (a, b) => a.orderQuantity && b.orderQuantity ? a.orderQuantity - b.orderQuantity : 1
    },
    {
        title: "Кол-во по сделкам",
        dataIndex: "dealQuantity",
        align: "right",
        width: 160,
        sorter: (a, b) => a.dealQuantity && b.dealQuantity ? a.dealQuantity - b.dealQuantity : 1
    },
    {
        title: "Сумма заказа",
        dataIndex: "orderAmount",
        align: "right",
        width: 160,
        render: finNum,
        sorter: (a, b) => a.orderAmount && b.orderAmount ? a.orderAmount - b.orderAmount : 1
    },
    {
        title: "Сумма по сделкам",
        dataIndex: "dealAmount",
        align: "right",
        width: 160,
        render: finNum,
        sorter: (a, b) => a.dealAmount && b.dealAmount ? a.dealAmount - b.dealAmount : 1
    },
]