import React, { FC } from 'react';

export const SellIcon: FC<{ color?: string }> = ({ color = "#003D7C" }) => (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.35355 0.646466C7.15829 0.451203 6.84171 0.451203 6.64645 0.646466L3.46447 3.82845C3.2692 4.02371 3.2692 4.34029 3.46447 4.53555C3.65973 4.73082 3.97631 4.73082 4.17157 4.53555L7 1.70713L9.82843 4.53555C10.0237 4.73082 10.3403 4.73082 10.5355 4.53555C10.7308 4.34029 10.7308 4.02371 10.5355 3.82845L7.35355 0.646466ZM7.5 8.11768L7.5 4.55885L6.5 4.55885L6.5 8.11768L7.5 8.11768ZM7.5 4.55885L7.5 1.00002L6.5 1.00002L6.5 4.55885L7.5 4.55885Z" fill={color} />
        <mask id="path-2-inside-1_34_321" fill="white">
            <path d="M0 6.82349H14V9.99996C14 11.1045 13.1046 12 12 12H2C0.89543 12 0 11.1045 0 9.99996V6.82349Z" />
        </mask>
        <path d="M0 6.82349H14H0ZM15 9.99996C15 11.6568 13.6569 13 12 13H2C0.343146 13 -1 11.6568 -1 9.99996H1C1 10.5522 1.44772 11 2 11H12C12.5523 11 13 10.5522 13 9.99996H15ZM2 13C0.343146 13 -1 11.6568 -1 9.99996V6.82349H1V9.99996C1 10.5522 1.44772 11 2 11V13ZM15 6.82349V9.99996C15 11.6568 13.6569 13 12 13V11C12.5523 11 13 10.5522 13 9.99996V6.82349H15Z" fill={color} mask="url(#path-2-inside-1_34_321)" />
    </svg>
)
