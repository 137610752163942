import { ColumnsType } from 'antd/lib/table';
import { TOwnSecurity } from '../../types/api';
import { getSecTypeName } from '../../services/helpers';
import { countNum, finNum } from '../../services/formatter';

export const columns: ColumnsType<TOwnSecurity & { action: any, bid: number | null, market: number | null }> = [
    {
        title: "Тикер",
        width: 210,
        dataIndex: "tradeCode",
        fixed: "left",
        sorter: (a, b) => a.tradeCode.localeCompare(b.tradeCode)
    },
    {
        title: "Валюта",
        dataIndex: "currency",
        fixed: "left",
        width: 100,
        sorter: (a, b) => a.currency.localeCompare(b.currency)
    },
    {
        title: "Тип",
        dataIndex: "globalTypeId",
        width: 200,
        fixed: "left",
        render: value => value === null ? 'NULL' : getSecTypeName(value),
        sorter: (a: TOwnSecurity, b: TOwnSecurity) => a.globalTypeId - b.globalTypeId
    },
    {
        title: "",
        dataIndex: "action",
        fixed: "left",
        className: "action-column"
    },
    {
        title: "bid",
        dataIndex: "bid",
        align: "right",
        width: 160,
        render: value => value === null ? '-' : finNum(value)
    },
    {
        title: "Остаток",
        dataIndex: "remQuantity",
        align: "right",
        width: 160,
        render: countNum,
        sorter: (a, b) => a.remQuantity - b.remQuantity
    },
    {
        title: "В РЕПО",
        dataIndex: "repoQuantity",
        align: "right",
        width: 160,
        render: countNum,
        sorter: (a, b) => a.repoQuantity - b.repoQuantity
    },
    {
        title: "В блоке",
        dataIndex: "blockQuantity",
        align: "right",
        width: 160,
        render: countNum,
        sorter: (a, b) => a.blockQuantity - b.blockQuantity
    },
    {
        title: "Место хранения",
        align: "center",
        width: 250,
        dataIndex: "holdPlace",
        sorter: (a: TOwnSecurity, b: TOwnSecurity) => a.holdPlace.localeCompare(b.holdPlace),
    },
    {
        title: "ISIN",
        align: "right",
        fixed: "right",
        dataIndex: "isin",
        sorter: (a: TOwnSecurity, b: TOwnSecurity) => a.isin.localeCompare(b.isin)
    },
    // {
    //     title: "Счет",
    //     dataIndex: "subAccount",
    //     sorter: (a, b) => a.subAccount.localeCompare(b.subAccount)
    // },
    // {
    //     title: "Название",
    //     dataIndex: "securityName",
    //     sorter: (a: TOwnSecurity, b: TOwnSecurity) => a.securityName.localeCompare(b.securityName)
    // },
]
