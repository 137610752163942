import React from "react";
import { Button, Card, Col, Layout, Row } from "antd";
import { dispatch } from "../../store";
import { BuySecWidget } from "./BuySecWidget";
import { SellSecWidget } from "./SellSecWidget";
import {
    showMoneyTransferOrder,
    showRepoOrder,
    showSecuritiesTransferOrder
} from "../../store/orders.slice";


export const DashBoard = () => {
    // useEffect(() => {
    //     QuotesConnector.getInfo("repo-usd-sec-list");
    // }, [])

    return (
        <Layout className="content-wrap">
            <Row gutter={[10, 10]}>
                <Col span={12}>
                    <Card title={"Продажа ЦБ"} style={{ minHeight: 200 }} hoverable={true}>
                        <SellSecWidget />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={"Покупка ЦБ"} style={{ minHeight: 200 }} hoverable={true}>
                        <BuySecWidget />
                    </Card>
                </Col>

                <>
                    <Col span={8}>
                        <Card title={"Другие операции с ЦБ"} hoverable={true}>
                            <Button style={{ margin: 8 }} onClick={() => dispatch(showSecuritiesTransferOrder(false))} type="primary">Внутренний перевод ЦБ</Button>
                            <Button style={{ margin: 8 }} onClick={() => dispatch(showSecuritiesTransferOrder(true))} type="primary">Внешний перевод ЦБ</Button>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title={"Операции РЕПО"} hoverable={true}>
                            <Button style={{ margin: 8 }} type="primary" onClick={() => dispatch(showRepoOrder(true))}>РЕПО ГЦБ</Button>
                            <Button style={{ margin: 8 }} type="primary" onClick={() => dispatch(showRepoOrder(false))}>РЕПО Корп.ЦБ</Button>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title={"Операции по Денежным средствам"} hoverable={true}>
                            <Button style={{ margin: 8 }} type="primary" onClick={() => dispatch(showMoneyTransferOrder(false))}>Внутренний перевод</Button>
                            <Button style={{ margin: 8 }} type="primary" onClick={() => dispatch(showMoneyTransferOrder(true))}>Внешний перевод</Button>
                            {/* <Button style={{ margin: 8 }} type="primary" onClick={() => dispatch(showMoneyConversionOrder())}>Конвертация</Button> */}
                        </Card>
                    </Col>
                </>
            </Row>
        </Layout>
    )
}