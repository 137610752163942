import React from "react";
import { Layout, Tabs } from "antd";
import { AllTradeOrders } from "./AllTradeOrders";
import { OrderTemplates } from "./templates/OrderTemplates";
import { AllNonTradeOrders } from "./AllNonTradeOrders";
import { isCompany } from "../../services/helpers";

export const AllOrdersPage = () => {
    return (
        <Layout className="content-wrap">
            <Tabs type="card">
                {isCompany() && <Tabs.TabPane tab="Согласования" tabKey="pending" key="pending">
                    <OrderTemplates />
                </Tabs.TabPane>}
                <Tabs.TabPane tab="Торговые операции" tabKey="trading" key="trading">
                    <AllTradeOrders />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Неторговые операции" tabKey="other" key="other">
                    <AllNonTradeOrders />
                </Tabs.TabPane>
            </Tabs>
        </Layout>
    )
}
