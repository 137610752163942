import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, Radio, RadioChangeEvent, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { CustomInput } from "../shared/modals/CustomInput";
import { CustomModal } from "../shared/modals/CustomModal";
import { dispatch, TState } from "../../store";
import {
    cancelInvalidation,
    loginByEDS,
    loginByEDSV2,
    loginByInvalidationHash,
    setCert
} from "../../store/auth.slice";
import { apiConnector } from "../../integrations/api.connector";
import { NcaLayerConnector } from "../../integrations/ncaLayer.connector";
import { TCertificatePath, TAuthCertificate } from "../../types/api";
import { processCertificate } from "../../services/helpers";
import { pathLogo } from "../../constants";
import { timeForWarningNotification } from "../../constants";

export const TestDS = () => {
    const {
        ncaLayer,
        multipleSessions,
        invalidatingSessions,
        invalidateHash,
        authCertificates,
        loginValidateError
    } = useSelector((state: TState) => state.auth);


    const [certificate, setCertificate] = useState<TAuthCertificate | null>(null);
    const [openPasswordModal, setPasswordModal] = useState<boolean>(false);
    const [certificates, setCertificates] = useState<TAuthCertificate[]>([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [certLoad, setCertLoad] = useState<boolean>(false);
    const [openModal, setModal] = useState<boolean>(false);
    const [openLogin, setLogin] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [path, setPath] = useState<string>('');

    useEffect(() => {
        setCertificates(authCertificates);

        if (authCertificates.length > 0) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [authCertificates])

    const chooseKey = async () => {
        if (ncaLayer) {
            if (NcaLayerConnector.getBundleVersion()) {
                if (certificates.length === 0) {
                    try {
                        setCertLoad(true);
                        const certPath: TCertificatePath = await apiConnector.getCertificate();
                        if (certPath.body.path.includes('AUTH')) {
                            setPasswordModal(true);
                            setPath(certPath.body.path);
                        } else {
                            notification.warn({
                                duration: timeForWarningNotification,
                                message: "Выбранный ключ не подходит для авторизации. Пожалуйста выберите другой ключ.",
                                placement: 'top'
                            });
                        }
                    } catch (error) {
                        notification.warn({
                            duration: timeForWarningNotification,
                            message: 'Ключ не выбран. Пожалуйста выберите ключ повторно.',
                            placement: 'top'
                        });
                        setCertLoad(false)
                    } finally {
                        setCertLoad(false);
                    }
                } else {
                    setModal(true);
                    setPath(certificates.length > 0 ? certificates[0].path : '');
                }
            } else {
                dispatch(loginByEDS());
            }
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "NCALayer не обнаружен или не запущен",
                placement: 'top'
            });
        }
    };

    const handlePassword = async () => {
        if (path !== '' && password !== '') {
            try {
                const certInfo: TAuthCertificate = await apiConnector.getCertificateInfo(path, password);
                if (!certificates.some(item => item.path === certInfo.path)) {
                    setCertificates(prevCertificates => [...prevCertificates, certInfo]);
                    dispatch(setCert({ certType: 'auth', type: 'add', cert: certInfo }));
                }

                setCertificate(certInfo);
                setPasswordModal(false);
                setLogin(true);
            } catch (error) {
                notification.warn({
                    duration: timeForWarningNotification,
                    message: "Вы ввели неправильный пароль. Пожалуйста попробуйте еще раз.",
                    placement: 'top'
                });
                setPassword('');
            }
        }
    };

    const handleLogin = async () => {
        if (path !== '' && certificates.length > 0) {
            dispatch(loginByEDSV2({ certificatePath: path, password: password }));
            setLogin(false);
            setPassword('');
        }
    };

    useEffect(() => {
        loginValidateError && notification.warn({
            duration: timeForWarningNotification,
            message: loginValidateError,
            placement: 'top'
        });
    }, [loginValidateError]);

    const radioHandler = (e: RadioChangeEvent) => {
        setPath(e.target.value);
    };

    const addKey = async () => {
        if (ncaLayer) {
            try {
                setCertLoad(true);
                const certPath: TCertificatePath = await apiConnector.getCertificate();
                if (certPath.body.path.includes('AUTH')) {
                    setPasswordModal(true);
                    setPath(certPath.body.path);
                } else {
                    notification.warn({
                        duration: timeForWarningNotification,
                        message: "Выбранный ключ не подходит для авторизации. Пожалуйста выберите другой ключ.",
                        placement: 'top'
                    });
                }
            } catch (error) {
                notification.warn({
                    duration: timeForWarningNotification,
                    message: 'Ключ не выбран. Пожалуйста выберите ключ повторно.',
                    placement: 'top'
                });
                setCertLoad(false)
            } finally {
                setCertLoad(false);
            }
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "NCALayer не обнаружен или не запущен",
                placement: 'top'
            });
        }
    };

    const removeKeys = (allKey: boolean, key: string | null) => {
        if (allKey) {
            setPath('');
            setCertificates([]);
            setIsButtonDisabled(true);
            dispatch(setCert({ certType: 'auth', type: 'removeAll' }));
        } else {
            setCertificates(prevCertificates =>
                prevCertificates.filter(cert => cert.path !== key)
            );
            dispatch(setCert({ certType: 'auth', type: 'removeOne', path: key }));
            setPath(certificates[0].path || '');
        }
    };

    const handleOk = () => {
        invalidateHash && dispatch(loginByInvalidationHash(invalidateHash));
    };
    const handleCancel = () => {
        dispatch(cancelInvalidation());
    };

    const okHandle = () => {
        if (path !== '' && certificates.length > 0) {
            setPasswordModal(true);
        }
    };


    return (
        <>
            <Button type="primary" block onClick={chooseKey}>
                Выберите ключ ЭЦП
            </Button>
            <img className="login-logo" src={pathLogo} alt="" />
            <Modal
                footer={false}
                closable={false}
                title={
                    <div style={{ textAlign: 'center' }}>
                        Выберете ключ для авторизации. Файл должен начинаться с AUTH.
                    </div>
                }
                visible={certLoad}
                style={{ marginTop: '20vh' }}
            />
            <CustomModal
                isButtonDisabled={isButtonDisabled}
                isModal={openModal}
                onCancel={() => setModal(false)}
                onOk={okHandle}
                addKey={addKey}
                onRemove={() => { removeKeys(true, null) }}
                titleTxt={"Выберите ключ для авторизации"}
            >
                {
                    certificates.length > 0 ?
                        <Radio.Group
                            style={{ width: "100%", overflow: "hidden", display: "flex", flexDirection: "column" }}
                            defaultValue={certificates.length > 0 ? certificates[0].path : ''}
                            value={path || (certificates.length > 0 ? certificates[0].path : '')}
                            onChange={(e) => {
                                radioHandler(e);
                                setPassword('');
                            }}
                        >
                            {
                                certificates.map((cert: TAuthCertificate, index: number) => (
                                    <div key={index} style={{ display: "flex", flexDirection: "row", alignItems: 'center', marginBottom: '15px' }}>
                                        {
                                            processCertificate(cert) !== null &&
                                            <>
                                                <Radio
                                                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', fontSize: '12px', width: '90%' }}
                                                    onClick={() => {
                                                        setPasswordModal(true);
                                                        setPath(cert.path);
                                                    }}
                                                    value={cert.path}
                                                >
                                                    {processCertificate(cert)}
                                                </Radio>
                                                <Button
                                                    danger
                                                    type="default"
                                                    size="small"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => { removeKeys(false, cert.path) }}
                                                />
                                            </>
                                        }
                                    </div>
                                ))
                            }
                        </Radio.Group>
                        :
                        <p style={{ textAlign: 'center', fontSize: '20px', margin: 0 }}>Пусто</p>
                }
            </CustomModal>
            <CustomInput
                value={password}
                onPressEnter={handlePassword}
                setPassword={setPassword}
                onCancel={() => {
                    setPasswordModal(false);
                    setLogin(false);
                    setPassword('');
                }}
                visible={openPasswordModal}
            />
            <Modal
                title='Вход'
                visible={openLogin}
                onOk={handleLogin}
                onCancel={() => {
                    setLogin(false);
                    setPassword('');
                }}
                zIndex={30}
                style={{ marginTop: '20vh' }}
            >
                <p style={{ textAlign: 'center', fontSize: '18px' }}>
                    {processCertificate(certificate)}
                </p>
            </Modal>
            <Modal
                onCancel={handleCancel}
                onOk={handleOk}
                visible={multipleSessions}
                confirmLoading={invalidatingSessions}
                title="Внимание!"
                style={{ marginTop: '20vh' }}
            >
                <p>Обнаружены открытые сессии на других устройствах.</p>
                <p>Закрыть другие сесии и продолжить работу в этом окне?</p>
            </Modal>
        </>
    );
};
