import React, { FC } from "react";
import { useSelector } from "react-redux";
import { TState } from "../../store";
import { finNum, finNum4 } from "../../services/formatter";
import { EQuoteType, IQuote } from "../../types/quotes";

export const Quote: FC<{ secKey: string | null, type: EQuoteType, bonds?: boolean }> = ({ secKey, type, bonds = false }) => {
    const { quotes } = useSelector((state: TState) => state.securities);
    const target: IQuote | null = secKey ? quotes[secKey] : null;
    let num: number | null;
    if (target) {
        switch (type) {
            case EQuoteType.open: num = target.OPEN; break;
            case EQuoteType.last: num = target.LAST; break;
            case EQuoteType.ask: num = target.OFFER; break;
            case EQuoteType.bid: num = target.BID; break;
        }
    } else {
        num = null;
    }

    return (
        <>{num ? bonds ? finNum4(num) : finNum(num) : "-"}</>
    )
}