import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Table, DatePicker, Input, AutoComplete, Spin } from "antd";
import { CloseCircleOutlined, FileDoneOutlined, ReloadOutlined } from "@ant-design/icons";
import { Moment } from 'moment';
import { dispatch, TState } from "../../store";
import { showCancelNonTradeOrder } from "../../store/orders.slice";
import { getNTOrders } from "../../store/nonTrade.orders.slice";
import { apiConnector } from "../../integrations/api.connector";
import { TableEditor } from "../tableEditor/TableEditor";
import { getFullPageTableHeight, rowClassCompact, updateTableView } from "../../services/helpers";
import { getOrderStatusId } from '../../services/statuses';
import { getOrderType } from "../../services/xmlBuilder";
import { ENonTradeOrderType, EOrderStatus, TDigitalOrderRecord } from "../../types/orders";
import { columns } from "./allNonTradeOrders.data";

const { RangePicker } = DatePicker;

const calendarDropdownAlign = {
    points: ['tl', 'bl'],
    offset: [0, -4],
    overflow: {
        adjustX: true,
        adjustY: true,
    },
};

const statusNames = {
    all: "-",
    confirmed: "подтвержден",
    processing: "в работе",
    onCalculation: "на расчетах",
    completed: "исполнен",
    notCompleted: "не исполнен",
    awaitingConfirmation: "ожидает подтверждения",
    canceledByClient: "отклонен клиентом",
    canceledBySystem: "отклонен системой",
    rejected: "отклонен",
    partlyCompleted: "исполнен частично",
    partlyOnCalculationAndProcessing: "частично на расчетах/частично в работе",
    partlyOnCalculationAndNotCompleted: "частично на расчетах/частично не исполнен",
    partlyOnCalculationAndCanceledByClient: "частично на расчетах/частично отклонен клиентом",
    partlyOnCalculationAndCanceledBySystem: "частично на расчетах/частично отклонено системой",
    partlyOnCalculationAndRejected: "частично на расчетах/частично отклонено",
    partlyCompletedAndProcessing: "исполнен частично/частично в работе",
    partlyCompletedAndOnCalculation: "исполнен частично/частично на расчетах",
    partlyCompletedAndNotCompleted: "исполнен частично/частично не исполнен",
    partlyCompletedAndCanceledByClient: "исполнен частично/частично отклонен клиентом",
    partlyCompletedAndCanceledBySystem: "исполнен частично/частичено отклонено системой",
    partlyCompletedAndRejected: "исполнен частично/частично отклонено",
    unknown: "неизвестный статус"
};

const statusOptions = Object.values(statusNames).map((key) => ({ value: key }));

export const AllNonTradeOrders = () => {
    // const timerRef = useRef<number | undefined>();
    // const [searchString, setSearchString] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedDates, setSelectedDates] = useState<[Moment | null, Moment | null] | null>(null)
    const [calendarOpen, setCalendarOpen] = useState<boolean>(false)
    const [calendarError, setCalendarError] = useState<boolean>(false)
    const [autoCompleteValue, setAutoCompleteValue] = useState<string>("")
    const [orderNumberValue, setOrderNumberValue] = useState<string>("")
    const [inputValue, setInputValue] = useState<string>("")
    const [options, setOptions] = useState<{ value: string }[]>(statusOptions)
    const { digitalOrders, ntOrdersFetching } = useSelector((state: TState) => state.ntOrders)
    const orders = [...digitalOrders];
    const cancelableStatusIds = [
        EOrderStatus.confirmed,
        EOrderStatus.processing,
        EOrderStatus.awaitingConfirmation,
        EOrderStatus.partlyCompleted,
        EOrderStatus.partlyOnCalculationAndProcessing,
        EOrderStatus.partlyCompletedAndProcessing
    ];
    // const onSearch = (value: string) => {
    //     setSearchString(value);
    // }
    // const onSearchChange = (value: string) => {
    //     if (!loading) {
    //         setLoading(true);
    //     }
    //     if (timerRef.current) {
    //         clearTimeout(timerRef.current);
    //     }
    //     timerRef.current = window.setTimeout(() => {
    //         setSearchString(value);
    //         setLoading(false);
    //     }, 100)
    // }
    const refresh = () => {
        dispatch(getNTOrders())
    }
    const getDoc = async (o: TDigitalOrderRecord) => {
        const { orderId, orderXml, orderDate, orderNumber, id } = o;
        const orderTypeString = getOrderType(orderXml);
        const p = await apiConnector.getPDF({
            id,
            xmlData: orderXml!,
            orderId: orderId!.toString(),
            orderDate,
            orderNumber: orderNumber || "internal",
            orderType: orderTypeString,
            pdfUrl: `https://test.fhs.kz/api/orders-status-v2/eds/${id}`,
            rejOrderNumber: undefined,
        });
        downloadPDF(p, orderId!);
    }
    const downloadPDF = (p: ArrayBuffer, clientOrderId: number) => {
        const file = new File([p], `${clientOrderId}.pdf`, { type: 'application/pdf' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${clientOrderId}.pdf`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
    }
    const dataSource = (): (TDigitalOrderRecord & { action: any })[] => {
        return orders.sort((a, b) => new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime())
            .map(p => {
                const canBeCanceled = p.orderType !== ENonTradeOrderType.cancel && cancelableStatusIds.includes(p.orderSysData.orderStatusId);
                // const canBeCanceled = true;
                return {
                    ...p,
                    action: false ? <>Подан на бумаге</> : <>
                        {canBeCanceled ? <Button title="Отменить заказ" onClick={() => dispatch(showCancelNonTradeOrder(p))} disabled={!canBeCanceled} size="small" type="primary" danger><CloseCircleOutlined style={{ fontSize: '18px', marginTop: '2px' }} /></Button> : null}
                        {/*<Button type="primary" size="small" title="Скачать заказ" href={p.file_path} target="_blank"><FileDoneOutlined style={{fontSize: '18px', marginTop: '2px'}} /></Button>*/}
                        <Button onClick={() => getDoc(p)} type="primary" size="small" title="Скачать заказ"><FileDoneOutlined style={{ fontSize: '18px', marginTop: '2px' }} /></Button>
                        {/*<Button onClick={()=>downloadTXT(p)} type="default" size="small" title="Скачать подпись"><EditOutlined style={{fontSize: '18px', marginTop: '2px'}} /></Button>*/}
                    </>
                }
            })
        // .filter(row => moment().diff(moment(row.orderStatusDate),'days') < 30)
        // .filter(row => {
        //     if (!searchString || searchString.length < 1) {
        //         return true
        //     } else {
        //         return (row.ticker && row.ticker.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
        //             (row.subAccount && row.subAccount.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
        //             (row.isin && row.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
        //     }
        // })
    }
    const [myColumns, setMyColumns] = useState(updateTableView(columns, 'AllNonTradeOrders'))
    const updateMyColumns = () => {
        setMyColumns(updateTableView(columns, 'AllNonTradeOrders'))
    }

    const handleDateChange = (dates: [Moment | null, Moment | null] | null) => {
        if (dates) {
            const [startDate, endDate] = dates;

            if (startDate && endDate && startDate.isAfter(endDate)) {
                setSelectedDates([endDate, startDate]);
            } else {
                setSelectedDates(dates);
            }
        } else {
            setSelectedDates(null);
        }
    }

    const handleCalendarOpenChange = (open: boolean) => {
        if (!open && selectedDates?.[1] === null) {
            setSelectedDates([selectedDates[0], selectedDates[0]]);
        }
        setCalendarOpen(open);
    }

    const onSelect = (data: string) => {
        setAutoCompleteValue(data === "-" ? "" : data);
    }

    const onChangeAutoComplete = (data: string) => {
        setAutoCompleteValue(data === "-" ? "" : data);
    }

    const onChangeOrderNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    }

    useEffect(() => {
        if (selectedDates?.[0] !== null && selectedDates?.[1] !== null && selectedDates !== null) {
            setCalendarError(false);
            const queryParams = new URLSearchParams({
                statusId: getOrderStatusId(autoCompleteValue)?.toString() || "",
                orderNumber: orderNumberValue || "",
                startDate: selectedDates[0]?.toISOString() || "",
                endDate: selectedDates[1]?.toISOString() || ""
            });
            dispatch(getNTOrders(queryParams));
        } else {
            setCalendarError(true);
        }
    }, [autoCompleteValue, selectedDates, orderNumberValue])

    return (
        ntOrdersFetching ?
            <div
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999,
                }}
            >
                <Spin size="large" />
            </div>
            :
            <>
                <RangePicker
                    value={selectedDates}
                    status={calendarError ? "error" : ""}
                    onCalendarChange={handleDateChange}
                    open={calendarOpen}
                    onOpenChange={handleCalendarOpenChange}
                    dropdownAlign={calendarDropdownAlign}
                />
                <AutoComplete
                    value={autoCompleteValue}
                    options={options}
                    style={{ width: 200 }}
                    onSelect={onSelect}
                    onSearch={(text) => {
                        const filteredOptions = statusOptions.filter(option =>
                            option.value.includes(text.toLowerCase())
                        );
                        setOptions(filteredOptions);
                    }}
                    onChange={onChangeAutoComplete}
                    placeholder="Статус"
                />
                <Input
                    style={{ width: 200 }}
                    value={inputValue}
                    placeholder="Номер заказа/приказа"
                    onChange={onChangeOrderNumber}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") setOrderNumberValue(inputValue);
                    }}
                    prefix={'№'}
                />
                <Button style={{ marginRight: 8 }} onClick={refresh} type="default">
                    <ReloadOutlined />
                </Button>
                <TableEditor updateColumns={updateMyColumns} columns={columns} keyForLS={'AllNonTradeOrders'} />
                <Table
                    size="small"
                    showSorterTooltip={false}
                    rowClassName={rowClassCompact}
                    rowKey={record => record.id}
                    dataSource={dataSource()}
                    columns={myColumns}
                    scroll={{
                        x: "max-content",
                        y: getFullPageTableHeight() - 100
                    }}
                    loading={ntOrdersFetching || loading}
                    pagination={false}
                />
            </>
    )
}