import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Divider, Space, Table } from "antd";
import Search from "antd/lib/input/Search";
import { nanoid } from "nanoid";
import { TState } from "../../store";
import { QuotesConnector } from "../../integrations/quotes.connector";
import { TRepoData } from "../../types/repo";
import { EQuoteType } from "../../types/quotes";
import { AttractBtn, PostBtn, RepoInfoBtn } from "./repo.btn";
import { RepoSide } from "./RepoSide";
import { Quote } from "../market/Quote";
import { TableEditor } from "../tableEditor/TableEditor";
import { getFullPageTableHeight, rowClassCompact, updateTableView } from "../../services/helpers";
import { repoColumns, repoData } from "./repo.data";
import { sideComponentWidthRepo } from "../../constants";

export type TRepoQuotes = {
    open: ReactNode,
    last: ReactNode,
    bid: ReactNode,
    ask: ReactNode,
}

export const RepoMarket = () => {
    const timerRef = useRef<number | undefined>();
    const [searchString, setSearchString] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const { repoSideDisplayed } = useSelector((state: TState) => state.orders);
    const onSearch = (value: string) => {
        setSearchString(value);
    }

    const onSearchChange = (value: string) => {
        if (!loading) {
            setLoading(true);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            setSearchString(value);
            setLoading(false);
        }, 500)
    }


    const dataSource = (): (TRepoData & { action: any })[] => {
        return repoData.map(repo => {
            let extraComponents: TRepoQuotes;
            // const repoQuote = repoTypes.find(t => t.repoDay === repo.days);
            // let qKey = `${repoQuote?.tradeModeCode}-${repo.seccode}`;
            let qKey = `${repo?.secboard}-${repo.seccode}`;
            extraComponents = {
                open: <Quote secKey={qKey} type={EQuoteType.open} />,
                last: <Quote secKey={qKey} type={EQuoteType.last} />,
                bid: <Quote secKey={qKey} type={EQuoteType.bid} />,
                ask: <Quote secKey={qKey} type={EQuoteType.ask} />
            };
            return {
                ...repo,
                ...extraComponents,
                action:
                    <Space size="small">
                        <PostBtn repoSecurity={repo} />
                        <AttractBtn repoSecurity={repo} />
                        <RepoInfoBtn repoSecurity={repo} />
                    </Space>,
                key: nanoid(6),
            }
        }).filter(row => {
            if (!searchString || searchString.length < 1) {
                return true
            }
            else {
                return row.secboard.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                    row.seccode.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
            }
        })
    }

    useEffect(() => {
        let keys: string[] = [];
        for (let repo of repoData) {
            const { secboard, seccode } = repo;
            let key = `${secboard}-${seccode}`;
            keys.push(key);
        }
        QuotesConnector.watch(keys);
    }, []);

    const [myColumns, setMyColumns] = useState(updateTableView(repoColumns, 'Repo'));
    const updateMyColumns = () => {
        setMyColumns(updateTableView(repoColumns, 'Repo'));
    }

    return <>
        <Search
            onSearch={onSearch}
            onChange={e => onSearchChange(e.target.value)}
            allowClear={true}
            placeholder={"Поиск"}
            style={{
                width: 250,
                marginBottom: 10,
                zIndex: 2,
                marginRight: 8
            }} />

        <TableEditor updateColumns={updateMyColumns} columns={repoColumns} keyForLS={'Repo'} />
        <div style={{ display: "flex", flexDirection: "row", width: '100%', height: '100%' }}>
            <Table
                size="small"
                showSorterTooltip={false}
                rowClassName={rowClassCompact}
                rowKey={() => nanoid(8)}
                columns={myColumns}
                dataSource={dataSource()}
                scroll={{
                    x: "max-content",
                    y: getFullPageTableHeight()
                }}
                loading={loading}
                pagination={false}
                style={{ width: repoSideDisplayed ? `${100 - sideComponentWidthRepo}%` : "100%", transition: "width .2s linear" }} />
            {repoSideDisplayed ? <RepoSide /> : <></>}
        </div>
        <Divider style={{ margin: "7px 0" }} />
    </>
}