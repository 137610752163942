import { ColumnsType } from 'antd/lib/table';
import { RepTransaction } from '../../../types/reports';
import { countNum, finNum, formatPrice } from '../../../services/formatter';

export const columns: ColumnsType<RepTransaction> = [
    {
        title: "Вид операции",
        width: 250,
        fixed: "left",
        dataIndex: "tranType",
    },
    {
        title: "Тип",
        width: 100,
        fixed: "left",
        dataIndex: "orderType"
    },
    {
        title: "Номер",
        width: 100,
        dataIndex: "orderNumber",
    },
    {
        title: "Дата",
        width: 100,
        dataIndex: "orderDate",
        sorter: (a, b) => a.orderDate.localeCompare(b.orderDate)
    },
    {
        title: "Эмитент",
        dataIndex: "issuerName",
        sorter: (a, b) => a.issuerName.localeCompare(b.issuerName)
    },
    {
        title: "ISIN",
        dataIndex: "isin",
        width: 130,
        sorter: (a, b) => a.isin.localeCompare(b.isin)
    },
    {
        title: "Количество",
        align: "right",
        width: 120,
        render: countNum,
        dataIndex: "quantity"
    },
    {
        title: "Валюта",
        width: 80,
        dataIndex: "currency"
    },
    {
        title: "Цена в %",
        width: 80,
        dataIndex: "percentPrice"
    },
    {
        title: "Доходность",
        width: 110,
        align: "right",
        dataIndex: "yield"
    },
    {
        title: "Цена в валюте",
        align: "right",
        width: 150,
        render: r => formatPrice(r, 4),
        dataIndex: "price",
    },
    {
        title: "Сумма",
        align: "right",
        render: finNum,
        width: 150,
        dataIndex: "amount"
    },
    {
        title: "Купон продавца",
        width: 140,
        align: "right",
        dataIndex: "accCoup"
    },
]
