import { ColumnsType } from 'antd/lib/table';
import { RepCashFlow } from '../../../types/reports';
import { finNum, parseDate } from "../../../services/formatter";

export const columns: ColumnsType<RepCashFlow> = [
    {
        title: "Дата движения",
        fixed: "left",
        dataIndex: "settleDate",
        sorter: (a, b) => parseDate(a.settleDate).getTime() - parseDate(b.settleDate).getTime()
    },
    {
        title: "Вид движения",
        fixed: "left",
        dataIndex: "tranType",
    },
    {
        title: "Данные заказа/приказа",
        width: 220,
        dataIndex: "orderData",
    },
    {
        title: "Наименование",
        dataIndex: "cliName",
    },
    {
        title: "Банк",
        width: 340,
        dataIndex: "bankName"
    },
    {
        title: "Счет / ИИК",
        width: 160,
        dataIndex: "iic",
    },
    {
        title: "БИК",
        width: 160,
        dataIndex: "bic",
    },
    {
        title: "ИИН",
        width: 160,
        dataIndex: "iin",
    },
    {
        title: "Кбе",
        width: 60,
        dataIndex: "cbe",
    },
    {
        title: "Валюта",
        width: 80,
        dataIndex: "currency",
    },
    {
        title: "Сальдо на начало",
        width: 160,
        align: "right",
        render: finNum,
        dataIndex: "begSaldo",
    },
    {
        title: "Дебит",
        width: 160,
        align: "right",
        render: finNum,
        dataIndex: "debit",
    },
    {
        title: "Кредит",
        width: 160,
        align: "right",
        render: finNum,
        dataIndex: "credit",
    },
    {
        title: "Сальдо на конец",
        width: 160,
        align: "right",
        render: finNum,
        dataIndex: "endSaldo",
    },
    {
        title: "Дополнения",
        width: 110,
        dataIndex: "additional",
    },
]
