import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TMarketSecurity, TOwnSecurity } from "../types/api";
import { apiConnector } from "../integrations/api.connector";
import { EGlobalSecurityType } from '../types/common';
import { storage } from '../services/storage';
import { IQuote, RepoList, RepoSecList } from '../types/quotes';
import { repoModeToDays } from "../services/helpers";
import { TOrderBookRecord } from "../types/market";

export const getPortfolio = createAsyncThunk(
    'securities/getOwn',
    async (data, { dispatch }): Promise<void> => {
        dispatch(unsetSecError());
        try {
            let parts: TOwnSecurity[] = []
            const getContent = async (): Promise<void> => {
                const { success, payload } = await apiConnector.getPortfolio();
                parts = [...parts, ...payload]
                if (success) {
                    return Promise.resolve();
                } else {
                    await getContent()
                }
            }

            await getContent();
            dispatch(addToPortfolio(parts));
            return Promise.resolve();
        } catch (e: any) {
            throw new Error(e)
        }
    }
)
export const getEquities = createAsyncThunk(
    'securities/getEquities',
    async (data, { dispatch }): Promise<void> => {
        dispatch(unsetMarketError());
        try {
            let page = 0;
            let parts: TMarketSecurity[] = [];
            const getContent = async (size: number = 10): Promise<void> => {
                const { content, last } = await apiConnector.getEquities(page, size);
                parts = [...parts, ...content]
                if (last) {
                    return Promise.resolve();
                } else {
                    page++;
                    await getContent(size)
                }
            }
            await getContent(500);
            dispatch(addToEquities(parts));
            return Promise.resolve();
        } catch (e: any) {
            throw new Error(e)
        }
    }
)
export const getBonds = createAsyncThunk(
    'securities/getBonds',
    async (data, { dispatch }): Promise<void> => {
        dispatch(unsetMarketError());
        try {
            let page = 0;
            let parts: TMarketSecurity[] = [];
            const getContent = async (size: number = 10): Promise<void> => {
                const { content, last } = await apiConnector.getBonds(page, size);      
                parts = [...parts, ...content]
                if (last) {
                    return Promise.resolve();
                } else {
                    page++;
                    await getContent(size)
                }
            }
            await getContent(500);           
            dispatch(addToBonds(parts));
            return Promise.resolve();
        } catch (e: any) {
            throw new Error(e)
        }
    }
)

export const securitiesSlice = createSlice({
    name: 'securities',
    initialState: {
        portfolio: [] as TOwnSecurity[],
        secFetching: false,
        secError: false,
        repoList: [] as RepoSecList[],
        equitiesList: [] as TMarketSecurity[],
        bondsList: [] as TMarketSecurity[],
        equitiesLib: [] as TMarketSecurity[],
        bondsLib: [] as TMarketSecurity[],
        equitiesFetching: false,
        equitiesError: false,
        bondsFetching: false,
        bondsError: false,
        quotes: {} as { [key: string]: IQuote },
        marketDepth: {} as { [key: string]: TOrderBookRecord[] },
        portfolioSideDisplayed: false,
        equitiesSideDisplayed: false,
        bondsSideDisplayed: false,
        portfolioSideInfo: null as TMarketSecurity | null,
        equitiesSideInfo: null as TMarketSecurity | null,
        bondsSideInfo: null as TMarketSecurity | null,
        depthSecurityID: null
    },
    reducers: {
        logOutSecurities: (state) => {
            return {
                ...state,
                portfolio: [],
                repoList: [],
                equitiesList: [],
                bondsList: [],
                equitiesLib: [],
                bondsLib: []
            }
        },
        addQuote: (state, action: PayloadAction<{ key: string, quote: IQuote }>) => {
            const { key, quote } = action.payload;
            state.quotes[key] = quote;
        },
        addMD: (state, action: PayloadAction<{ key: string, book: TOrderBookRecord[] }>) => {
            const { key, book } = action.payload;
            state.marketDepth[key] = book;
        },
        removeQuote: (state, action: PayloadAction<string>) => {
            delete (state.quotes[action.payload])
        },
        unsetSecError: state => {
            state.secError = false;
        },
        unsetMarketError: state => {
            state.equitiesError = false;
            state.bondsError = false;
        },
        addToPortfolio: (state, action) => {
            state.portfolio = action.payload
        },
        addToEquities: (state, action) => {
            state.equitiesLib = action.payload
        },
        addToBonds: (state, action) => {
            state.bondsLib = action.payload
        },
        setEquitiesList: (state, action) => {
            state.equitiesList = action.payload;
        },
        setBondsList: (state, action) => {
            state.bondsList = action.payload;
        },
        addToList: (state, action: PayloadAction<TMarketSecurity>) => {
            const { globalTypeId, securityId, tradeModeCode } = action.payload;
            switch (globalTypeId) {
                case EGlobalSecurityType.equity:
                    !state.equitiesList.find(eq => eq.securityId === securityId && eq.tradeModeCode === tradeModeCode) && state.equitiesList.push(action.payload);
                    storage.setItem("equities-list", state.equitiesList).then(r => { });
                    break;
                case EGlobalSecurityType.bond:
                    !state.bondsList.find(eq => eq.securityId === securityId && eq.tradeModeCode === tradeModeCode) && state.bondsList.push(action.payload);
                    storage.setItem("bonds-list", state.bondsList).then(r => { });
                    break;
            }
        },
        removeFromList: (state, action: PayloadAction<TMarketSecurity>) => {
            const { globalTypeId, securityId, tradeModeCode } = action.payload;
            switch (globalTypeId) {
                case EGlobalSecurityType.equity:
                    state.equitiesList = state.equitiesList.filter(eq => (eq.securityId.toString() + eq.tradeModeCode) !== (securityId.toString() + tradeModeCode));
                    storage.setItem("equities-list", state.equitiesList).then(r => { });
                    break;
                case EGlobalSecurityType.bond:
                    state.bondsList = state.bondsList.filter(eq => (eq.securityId.toString() + eq.tradeModeCode) !== (securityId.toString() + tradeModeCode));
                    storage.setItem("bonds-list", state.bondsList).then(r => { });
                    break;
            }
        },
        setRepoSecLists: (state, action: PayloadAction<RepoList[]>) => {
            const rSecList: RepoSecList[] = []; 
            for (const rl of action.payload) {
                const { currency, tradeModeCode, list } = rl;
                const mList: TMarketSecurity[] = [];
                list.forEach(el => {
                    const m = state.bondsLib.find(b => (b.isin === el.ISIN && b.localBool === 1));
                    m && mList.push(m);
                })
                rSecList.push({
                    currency,
                    tradeModeCode,
                    days: repoModeToDays[tradeModeCode],
                    list: mList
                })
            }
            state.repoList = rSecList;
        },
        showPortfolioSide: (state, action: PayloadAction<TMarketSecurity>) => {
            state.portfolioSideDisplayed = true;
            state.portfolioSideInfo = action.payload
        },
        closePortfolioSide: (state) => {
            state.portfolioSideDisplayed = false;
        },
        showEquitiesSide: (state, action: PayloadAction<TMarketSecurity>) => {
            state.equitiesSideDisplayed = true;
            state.equitiesSideInfo = action.payload
        },
        closeEquitiesSide: (state) => {
            state.equitiesSideDisplayed = false;
        },
        showBondsSide: (state, action: PayloadAction<TMarketSecurity>) => {
            state.bondsSideDisplayed = true;
            state.bondsSideInfo = action.payload
        },
        closeBondsSide: (state) => {
            state.bondsSideDisplayed = false;
        },
        findDepthSecurityID: (state, action) => {
            state.depthSecurityID = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(
                getPortfolio.pending, state => {
                    state.secFetching = true
                }
            )
            .addCase(
                getPortfolio.rejected, state => {
                    state.secError = true
                    state.secFetching = false
                }
            )
            .addCase(
                getPortfolio.fulfilled, (state, action) => {
                    // state.portfolio = action.payload
                    state.secFetching = false
                }
            )
            .addCase(
                getEquities.pending, state => {
                    state.equitiesFetching = true
                }
            )
            .addCase(
                getEquities.rejected, state => {
                    state.equitiesError = true
                    state.equitiesFetching = false
                }
            )
            .addCase(
                getEquities.fulfilled, (state, action) => {
                    // state.portfolio = action.payload
                    state.equitiesFetching = false
                }
            )
            .addCase(
                getBonds.pending, state => {
                    state.bondsFetching = true
                }
            )
            .addCase(
                getBonds.rejected, state => {
                    state.bondsError = true
                    state.bondsFetching = false
                }
            )
            .addCase(
                getBonds.fulfilled, (state, action) => {
                    // state.portfolio = action.payload
                    state.bondsFetching = false
                }
            )
    }
})

export const {
    unsetSecError,
    unsetMarketError,
    addToPortfolio,
    addToEquities,
    addToBonds,
    addToList,
    removeFromList,
    setEquitiesList,
    setBondsList,
    addQuote,
    addMD,
    setRepoSecLists,
    removeQuote,
    showPortfolioSide,
    closePortfolioSide,
    showEquitiesSide,
    closeEquitiesSide,
    showBondsSide,
    closeBondsSide,
    findDepthSecurityID,
    logOutSecurities
} = securitiesSlice.actions
