import React from 'react';
import { Layout, Tabs } from 'antd';
import { Current } from './Current';
import { Orders } from '../orders/Orders';
import { OrderDeals } from "../orders/OrderDeals";
import { RepoMarket } from './RepoMarket';
import { RepoDeals } from './RepoDeals';

export const RepoPage = () => {
    return (
        <Layout className="content-wrap">
            <Tabs type="card">
                <Tabs.TabPane tab="Текущие" tabKey="current" key="current">
                    <Current />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Заказы" tabKey="orders" key="orders">
                    <Orders isRepo={true} fullHeight={true} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Сделки" tabKey="deals" key="deals">
                    <OrderDeals isRepo={true} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Журнал" tabKey="log" key="log">
                    <Orders isRepo={true} fullHeight={true} isLog={true} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Рынок" tabKey="market" key="market">
                    <RepoMarket />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Сделки(new)" key="new-deals" tabKey="new-deals">
                    <RepoDeals />
                </Tabs.TabPane>
            </Tabs>
        </Layout>
    )
}
