import {configureStore} from "@reduxjs/toolkit";
import {authSlice} from './auth.slice';
import {moneySlice} from "./money.slice";
import {securitiesSlice, setBondsList, setEquitiesList} from './securities.slice';
import {ordersSlice} from './orders.slice';
import {storage} from '../services/storage';
import {TMarketSecurity} from '../types/api';
import {QuotesConnector} from "../integrations/quotes.connector";
import {analyticsSlice} from "./analytics.slice";
import {nonTradeOrdersSlice} from "./nonTrade.orders.slice";
import {infoSlice} from "./info.slice";
import {repaymentsSlice} from "./repayments.slice";

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        money: moneySlice.reducer,
        securities: securitiesSlice.reducer,
        orders: ordersSlice.reducer,
        ntOrders: nonTradeOrdersSlice.reducer,
        analytics: analyticsSlice.reducer,
        repayments: repaymentsSlice.reducer,
        info: infoSlice.reducer
    }
})

export const dispatch = store.dispatch.bind(store);
export type TState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


// Restore local config
(async () => {
    const watchQuotes = (secs: TMarketSecurity[]): void => {
        const keys = secs.map(s => (`${s.tradeModeCode}-${s.ticker}`));
        QuotesConnector.watch(keys);
    }
    let equitiesList = await storage.getItem<TMarketSecurity[]>("equities-list");
    equitiesList && dispatch(setEquitiesList(equitiesList));
    equitiesList && watchQuotes(equitiesList);
    let bondsList = await storage.getItem<TMarketSecurity[]>("bonds-list");
    bondsList && dispatch(setBondsList(bondsList));
    bondsList && watchQuotes(bondsList);
})()
