import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import { dispatch, TState } from '../../store';
import { showPortfolioSide } from '../../store/securities.slice';
import { ECurrency } from '../../types/common';

export const PortfolioInfoBtn: FC<{ securityId: number, currency: ECurrency, intBool: number, disabledBtn: boolean }> = ({ securityId, currency, intBool, disabledBtn }) => {
    const { equitiesLib, bondsLib } = useSelector((state: TState) => state.securities)
    const handleInfo = () => {
        const security = [...equitiesLib, ...bondsLib].find(s => (
            s.securityId === securityId &&
            s.tradeCurrency === currency &&
            s.intBool === intBool
        ));
        if (security) {
            dispatch(showPortfolioSide(security));
        }
    }
    return (
        <Button disabled={!disabledBtn} size="small" type="primary" onClick={handleInfo} style={{ color: 'white' }}>
            <BarChartOutlined />
        </Button>
    )
};