class Storage {
    private prefix: string = "fhs-";
    public getItem = async <T>(key: string): Promise<T | null> => {
        return this.localStorageGet(key)
    }
    public setItem = async (key: string, value: any): Promise<void> => {
        return this.localStorageSet(key, value)
    }
    public removeItem = async (key: string): Promise<void> => {
        return this.localStorageRemove(key)
    }
    private localStorageGet = async <T>(key: string): Promise<T | null> => {
        try {
            const value = localStorage.getItem(this.prefix + key);
            return value ? JSON.parse(value) as T : null;
        } catch (e) {
            return Promise.reject()
        }
    }
    private localStorageSet = async (key: string, value: any): Promise<void> => {
        try {
            await localStorage.setItem(this.prefix + key, JSON.stringify(value));
            return;
        } catch (e) {
            return Promise.reject()
        }
    }
    private localStorageRemove = async (key: string) => {
        try {
            await localStorage.removeItem(this.prefix + key);
            return;
        } catch (e) {
            return Promise.reject()
        }
    }
}

export const storage = new Storage()
