import React, { useState } from 'react';
import { Button, DatePicker, Table } from 'antd';
import moment from 'moment';
import { RepAccountStatement } from '../../types/reports';
import { apiConnector } from '../../integrations/api.connector';
import { getFullPageTableHeight, rowClassCompact, updateTableView } from '../../services/helpers';
import { columns } from './data/account.statement.data';
import { ExportBtn } from "./ExportBtn";
import { exportData } from "../../services/export";
import { TableEditor } from "../tableEditor/TableEditor";

export const AccountStatement = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<RepAccountStatement[]>([])
    const [date, setDate] = useState(moment());
    const [exporting, setExporting] = useState(false);
    const getReport = async () => {
        setLoading(true);
        try {
            const { content } = await apiConnector.getAccountStatement(date.format("YYYY-MM-DD"), 0, 500);
            setData(content);
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }
    const onExport = async () => {
        setExporting(true);
        const rows: RepAccountStatement[] = data.map(record => {
            record.maturityDate = record.maturityDate ? new Date(record.maturityDate).toLocaleDateString("ru-RU") : "-";
            return record;
        })
        await exportData(rows, columns, "Выписка с лицевого счета", date.toDate());
        setExporting(false);
    }
    const [myColumns, setMyColumns] = useState(updateTableView(columns, 'AccountStatement'))
    const updateMyColumns = () => {
        setMyColumns(updateTableView(columns, 'AccountStatement'))
    }
    return (
        <>
            <span className="picker-pre">Дата отчета:</span>
            <DatePicker
                size="small"
                disabledDate={current => current && current > moment()}
                defaultValue={moment()}
                onChange={val => val && setDate(val)}
            />
            <Button style={{ marginLeft: 8 }} type="primary" onClick={getReport} size="small" loading={loading}>
                Сформировать отчет
            </Button>
            <ExportBtn style={{ marginLeft: 8, marginRight: 8 }} onClick={onExport.bind(AccountStatement)} loading={exporting} />
            <TableEditor updateColumns={updateMyColumns} columns={columns} keyForLS={'AccountStatement'} />
            <Table
                size="small"
                showSorterTooltip={false}
                rowClassName={rowClassCompact}
                dataSource={data}
                columns={myColumns}
                loading={loading}
                scroll={{
                    x: "max-content",
                    y: getFullPageTableHeight() - 70
                }}
                pagination={false}
            />
        </>
    )
}
