import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiConnector } from "../integrations/api.connector";
import {
    DealDirection,
    EOrderStatus, TDealStatusRecord, TDecisionData, TDigitalOrderRecord, TMoneyTransferBankCorrData,
    TMoneyTransferRecipientData, TOrderDeals,
    TOrderStatusFull,
    TOrderTemplate,
    TSetOrderTemplate
} from '../types/orders';
import { TState } from './index';
import { TMarketSecurity, TOrderStatusUpdate, TSecurityQuotes } from '../types/api';
import repoTypesData from '../repo.json';
import { TRepoCurrent, TRepoData, TRepoTypeAndBasket } from '../types/repo';
import { NotificationsConnector } from "../integrations/notifications.connector";
import moment from "moment";

export const getAllOrders = createAsyncThunk(
    'orders/getAll',
    async (data): Promise<TOrderStatusFull[]> => {
        try {
            return await apiConnector.getAllOrders();
        } catch (e: any) {
            console.log(e);
            throw new Error(e)
        }
    }
)

export const getAllRecipientData = createAsyncThunk(
    'money/transferDetails',
    async (currency: string): Promise<TMoneyTransferRecipientData[]> => {
        try {
            return await apiConnector.getMoneyTransferRecipientData(currency);
        } catch (e: any) {
            console.log(e);
            throw new Error(e)
        }
    }
)

export const getAllBankCorrData = createAsyncThunk(
    'money/transferDetailsBankCorr',
    async (): Promise<TMoneyTransferBankCorrData[]> => {
        try {
            return await apiConnector.getMoneyTransferBankCorrData();
        } catch (e: any) {
            console.log(e);
            throw new Error(e)
        }
    }
)

export const getOrderTemplates = createAsyncThunk(
    'orders/templates',
    async (data): Promise<TOrderTemplate[]> => {
        try {
            const { success, payload } = await apiConnector.getTemplates();
            return payload
        } catch (e: any) {
            console.log(e);
            throw new Error(e)
        }
    }
)

export const getOrders = createAsyncThunk(
    'orders/getStatus',
    async (data: URLSearchParams | undefined, { dispatch }): Promise<void> => {        
        dispatch(unsetOrderError());
        try {
            const  result = await apiConnector.getOrders(data);
            const content = result.payload;
            const rejOrders = content.filter((o: any) => !!o && o.rejectOrderId);
            dispatch(addToRejected(rejOrders));
            const eqOrders = content.filter((o: any) => !!o && o.dealDirection && o.dealDirection.toLowerCase().indexOf("repo") < 0);
            const repoOrders = content.filter((o: any) => !!o && o.dealDirection && o.dealDirection.toLowerCase().indexOf("repo") >= 0);
            const completedOrders = eqOrders.filter((o: any) =>
                o.orderStatusId === EOrderStatus.completed ||
                o.orderStatusId === EOrderStatus.rejected ||
                o.orderStatusId === EOrderStatus.notCompleted ||
                o.orderStatusId === EOrderStatus.canceledByClient ||
                o.orderStatusId === EOrderStatus.canceledBySystem ||
                o.orderStatusId > 44
            );
            dispatch(addToCompleted(completedOrders));
            const trackedOrders = eqOrders.filter((o: any) =>
                o.orderStatusId === EOrderStatus.confirmed ||
                o.orderStatusId === EOrderStatus.processing ||
                o.orderStatusId === EOrderStatus.onCalculation ||
                o.orderStatusId === EOrderStatus.awaitingConfirmation ||
                o.orderStatusId === EOrderStatus.partlyCompleted ||
                (o.orderStatusId > 30 && o.orderStatusId < 44)
            );
            dispatch(addToTracked(trackedOrders.sort((a: any, b: any) => (
                new Date(b.orderStatusDate).getTime() - new Date(a.orderStatusDate).getTime()
            ))));
            const completedRepoOrders = repoOrders.filter((o: any) =>
                o.orderStatusId === EOrderStatus.completed ||
                o.orderStatusId === EOrderStatus.rejected ||
                o.orderStatusId === EOrderStatus.notCompleted ||
                o.orderStatusId === EOrderStatus.canceledByClient ||
                o.orderStatusId === EOrderStatus.canceledBySystem ||
                o.orderStatusId > 44

            );
            dispatch(addToRepoCompleted(completedRepoOrders));
            const trackedRepoOrders = repoOrders.filter((o: any) =>
                o.orderStatusId === EOrderStatus.confirmed ||
                o.orderStatusId === EOrderStatus.processing ||
                o.orderStatusId === EOrderStatus.onCalculation ||
                o.orderStatusId === EOrderStatus.awaitingConfirmation ||
                o.orderStatusId === EOrderStatus.partlyCompleted ||
                (o.orderStatusId > 30 && o.orderStatusId < 44)
            );
            dispatch(addToRepoTracked(trackedRepoOrders.sort((a: any, b: any) => (
                new Date(b.orderStatusDate).getTime() - new Date(a.orderStatusDate).getTime()
            ))));
            dispatch(addIdsToWatch({ trackedOrders, trackedRepoOrders }));
            dispatch(addIdsToUnwatch({ completedOrders, completedRepoOrders }));
            NotificationsConnector.subscribe();
            NotificationsConnector.unsubscribe();
            return Promise.resolve();
        } catch (e: any) {
            console.log(e);
            throw new Error(e)
        }
    }
)
export const getOrderUpdates = createAsyncThunk(
    'orders/getUpdates',
    async (data, { dispatch, getState }): Promise<void> => {
        try {
            const { orders: { trackedOrders, trackedRepoOrders } } = getState() as TState;
            let allOrderIds = [...trackedRepoOrders, ...trackedOrders].map(o => o.id);
            if (!allOrderIds || allOrderIds.length < 1) return;
            const updated = await apiConnector.getOrdersUpdates(allOrderIds);
            updated.forEach(update => dispatch(updateOrderStatus(update)));
        } catch (e: any) {
            throw new Error(e)
        }
    }
)

export const getOrderDeals = createAsyncThunk(
    'orders/getDeals',
    async (data): Promise<TOrderDeals[]> => {
        try {
            const { success, payload } = await apiConnector.getDeals();
            return payload;
        } catch (e: any) {
            throw new Error(e)
        }
    }
)

export const getOrderDealsDetails = createAsyncThunk(
    'orders/getDealsDetails',
    async (data): Promise<TDealStatusRecord[]> => {
        try {
            const { success, payload } = await apiConnector.getDealsDetails();
            return payload;
        } catch (e: any) {
            throw new Error(e)
        }
    }
)

export const getOpenRepos = createAsyncThunk(
    'orders/getOpenRepos',
    async (data, { dispatch }): Promise<void> => {
        try {
            let page = 0;
            let parts: TRepoCurrent[] = []
            const getContent = async (size: number = 50): Promise<void> => {
                const { content, last } = await apiConnector.getRepoCurrent(page, size);
                parts = [...parts, ...content]
                if (last) {
                    return Promise.resolve();
                } else {
                    page++;
                    await getContent(size)
                }
            }
            await getContent(50);
            dispatch(addToOpenRepos(parts));
            return Promise.resolve();
        } catch (e: any) {
            throw new Error(e)
        }
    }
)

export const getDecisions = createAsyncThunk(
    'decisions/getAll',
    async (): Promise<TDecisionData[]> => {
        try {
            const {payload, success, message} = await apiConnector.getDecisions();
            if(success && payload){
                const filteredDecisions = payload.filter((decision) => {
                    return moment(decision.decisionEndDate).isSameOrAfter(moment());
                });
                return filteredDecisions;
            } else {
                throw new Error(message);
            }
        } catch (e:any) {
            throw new Error(e)
        }
    }
)

export const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        bankCorrData: [] as TMoneyTransferBankCorrData[],
        recipientData: [] as TMoneyTransferRecipientData[],
        showSecuritiesConversionOrderModal: false,
        showSecuritiesTransferOrderModal: false,
        stExternal: false,
        trackedOrders: [] as TOrderStatusFull[],
        trackedRepoOrders: [] as TOrderStatusFull[],
        completedOrders: [] as TOrderStatusFull[],
        completedRepoOrders: [] as TOrderStatusFull[],
        rejectOrders: [] as TOrderStatusFull[],
        allOrders: [] as TOrderStatusFull[],
        allOrdersFetching: false,
        allOrderIds: [] as string[],
        ordersFetching: false,
        ordersError: false,
        deals: [] as TOrderDeals[],
        dealsDetails: [] as TDealStatusRecord[],
        dealsFetching: false,
        showOrderModal: false,
        showRepoOrderModal: false,
        showCancelOrderModal: false,
        showNonTradeCancelModal: false,
        showPledgeOrderModal: false,
        repo: true,
        showMoneyTransferOrderModal: false,
        mtExternal: false,
        showMoneyConversionOrderModal: false,
        orderDirection: null as DealDirection | null,
        buySecurity: null as TMarketSecurity | null,
        sellSecurity: null as TMarketSecurity | null,
        cancelTradeOrder: null as TOrderStatusFull | null,
        cancelNonTradeOrder: null as TDigitalOrderRecord | null,
        repoTypes: repoTypesData as TRepoTypeAndBasket[],
        openRepos: [] as TRepoCurrent[],
        openReposFetching: false,
        orderTemplates: [] as TOrderTemplate[],
        orderTemplatesFetching: false,
        quotes: {
            open: 120.00,
            last: 123.50,
            bid: 123.60,
            ask: 121.70
        } as TSecurityQuotes,
        orderIdsWatchList: [] as number[],
        orderIdsUnwatchList: [] as number[],
        repoSideDisplayed: false,
        repoSideInfo: null as TRepoData | null,
        repoDays: null as unknown as number,
        repoInfo: null as TRepoData | null,
        decisions: [] as TDecisionData[],
        decisionsFetching: false,
        showRepoCorpModal: false,
        showRepoGCBModal: false,
        repoCorp: false,
        repoGCB: false,
        showDuplicateTemplateModal: false,
        temporaryTemplate: {} as TSetOrderTemplate | null,
        tempTemplateType: null as string | null,
        showDuplicateRequestModal: false,
        temporaryRequest: {} as any | null,
        tempReqType: null as string | null,
        temporaryOt: null as TOrderTemplate | null
    },
    reducers: {
        logOutOrders: (state) => {
            return {
                ...state,
                bankCorrData: [],
                recipientData: [],
                trackedOrders: [],
                trackedRepoOrders: [],
                completedOrders: [],
                completedRepoOrders: [],
                rejectOrders: [],
                allOrders: [],
                allOrderIds: [],
                deals: [],
                dealsDetails: [],
                openRepos: [],
                orderTemplates: [],
                orderIdsWatchList: [],
                orderIdsUnwatchList: [],
            }
        },
        showSecurityConversionOrder: state => {
            state.showSecuritiesConversionOrderModal = true;
        },
        closeSecurityConversionOrder: state => {
            state.showSecuritiesConversionOrderModal = false;
        },
        showSecuritiesTransferOrder: (state, action: PayloadAction<boolean>) => {
            state.stExternal = action.payload
            state.showSecuritiesTransferOrderModal = true;
        },
        closeSecuritiesTransferOrder: state => {
            state.showSecuritiesTransferOrderModal = false;
        },
        showBuyOrder: (state, action: PayloadAction<TMarketSecurity>) => {
            state.showOrderModal = true;
            state.orderDirection = DealDirection.buy;
            state.buySecurity = action.payload
        },
        showSellOrder: (state, action: PayloadAction<TMarketSecurity>) => {
            state.showOrderModal = true;
            state.orderDirection = DealDirection.sell;
            state.sellSecurity = action.payload
        },
        closeOrder: (state) => {
            state.showOrderModal = false;
            state.orderDirection = null;
            state.buySecurity = null;
            state.sellSecurity = null;
        },
        // showRepoBuyOrder: (state) => {
        //     state.showRepoOrderModal = true;
        //     state.orderDirection = DealDirection.buy;
        // },
        showRepoOrder: (state, action) => {
            state.showRepoOrderModal = true;
            state.repo = action.payload
            // state.orderDirection = DealDirection.sell;
        },
        closeRepoOrder: (state) => {
            state.showRepoOrderModal = false;
            state.orderDirection = null;
        },

        // Это отвечает за то что выводим только КОРП ЦБ
        showRepoCorpOrder: (state, action) => {
            state.showRepoCorpModal = true;
            state.repoCorp = action.payload
        },
        closeRepoCorpOrder: (state) => {
            state.showRepoCorpModal = false;
            state.orderDirection = null;
        },
        // Это отвечает за то что выводим только ГЦБ
        showRepoGCBOrder: (state, action) => {
            state.showRepoGCBModal = true;
            state.repoGCB = action.payload;
        },
        closeRepoGCBOrder: (state) => {
            state.showRepoGCBModal = false;
            state.orderDirection = null;
        },

        closeRepoSide: (state) => {
            state.repoSideDisplayed = false;
        },
        showRepoSide: (state, action: PayloadAction<TRepoData>) => {
            state.repoSideDisplayed = true;
            state.repoSideInfo = action.payload
        },

        showRepoInfo: (state, action: PayloadAction<TRepoData>) => {
            state.repoDays = action.payload.days
            state.repoInfo = action.payload;
        },

        showCancelTradeOrder: (state, action: PayloadAction<TOrderStatusFull>) => {
            state.cancelTradeOrder = action.payload;
            state.showCancelOrderModal = true;
        },
        showCancelNonTradeOrder: (state, action: PayloadAction<TDigitalOrderRecord>) => {
            state.cancelNonTradeOrder = action.payload;
            state.showNonTradeCancelModal = true;
        },
        closeCancelOrder: state => {
            state.cancelTradeOrder = null;
            state.cancelNonTradeOrder = null;
            state.showCancelOrderModal = false;
            state.showNonTradeCancelModal = false;
        },
        showPledgeOrder: state => {
            state.showPledgeOrderModal = true;
        },
        closePledgeOrder: state => {
            state.showPledgeOrderModal = false;
        },
        unsetOrderError: state => {
            state.ordersError = false;
        },
        showMoneyTransferOrder: (state, action: PayloadAction<boolean>) => {
            state.mtExternal = action.payload;
            state.showMoneyTransferOrderModal = true;
        },
        closeMoneyTransferOrder: state => {
            state.showMoneyTransferOrderModal = false;
        },
        showMoneyConversionOrder: state => {
            state.showMoneyConversionOrderModal = true;
        },
        closeMoneyConversionOrder: state => {
            state.showMoneyConversionOrderModal = false;
        },
        addOrderIds: (state, action: PayloadAction<TOrderStatusFull[]>) => {
            let idsSet = new Set<string>();
            const merged = [...state.allOrderIds, ...action.payload.filter(o => !!o).map(o => o.id)]
            merged.forEach(i => idsSet.add(i as string));
            const unique = Array.from(idsSet)
            state.allOrderIds = unique;
        },
        addToTracked: (state, action) => {
            state.trackedOrders = action.payload;
        },
        addToCompleted: (state, action) => {
            state.completedOrders = action.payload;
        },
        addToRejected: (state, action) => {
            state.rejectOrders = action.payload;
        },
        updateOrderStatus: (state, action: PayloadAction<TOrderStatusUpdate>) => {
            const { orderStatusId, id } = action.payload;
            let order = state.trackedOrders.find(o => o.id === id);
            let repoOrder = state.trackedRepoOrders.find(o => o.id === id);
            if (order && order.orderStatusId !== orderStatusId) {
                order.orderStatusId = orderStatusId;
            } else if (repoOrder && repoOrder.orderStatusId !== orderStatusId) {
                repoOrder.orderStatusId = orderStatusId;
            }
        },
        addToRepoTracked: (state, action) => {
            state.trackedRepoOrders = action.payload;
        },
        addToRepoCompleted: (state, action) => {
            state.completedRepoOrders = action.payload;
        },
        addToOpenRepos: (state, action: PayloadAction<TRepoCurrent[]>) => {
            state.openRepos = action.payload
        },
        addIdsToWatch: (state, action) => {
            const ids: number[] = [];
            const orders = action.payload;
            const trackedOrders = orders.trackedOrders;
            const trackedRepoOrders = orders.trackedRepoOrders;
            trackedOrders.forEach((order: any) => {
                ids.push(order.clientOrderId);
            });
            trackedRepoOrders.forEach((order: any) => {
                ids.push(order.clientOrderId);
            });
            state.orderIdsWatchList = ids;
        },
        addIdsToUnwatch: (state, action) => {
            const ids: number[] = [];
            const orders = action.payload;
            const completedOrders = orders.completedOrders;
            const completedRepoOrders = orders.completedRepoOrders;
            completedOrders.forEach((order: any) => {
                ids.push(order.clientOrderId);
            });
            completedRepoOrders.forEach((order: any) => {
                ids.push(order.clientOrderId);
            });
            state.orderIdsUnwatchList = ids;
        },
        showDuplicateTemplateWindow: (state) => {
            state.showDuplicateTemplateModal = true;
        },
        closeDuplicateTemplateWindow: (state) => {
            state.showDuplicateTemplateModal = false;
        },
        setTempTemplate: (state, action) => {
            state.temporaryTemplate = action.payload;
        },
        setTempTemplateType: (state, action) => {
            state.tempTemplateType = action.payload;
        },
        showDuplicateRequestWindow: (state) => {
            state.showDuplicateRequestModal = true;
        },
        closeDuplicateRequestWindow: (state) => {
            state.showDuplicateRequestModal = false;
        },
        setTempRequest: (state, action) => {
            state.temporaryRequest = action.payload;
        },
        setTempReqType: (state, action) => {
            state.tempReqType = action.payload;
        },
        setTemporaryOt: (state, action) => {
            console.log("State tempOt", state.temporaryOt)
            state.temporaryOt = action.payload;
        }
    },
    extraReducers: builder => {
        builder
        .addCase(
                getDecisions.fulfilled, (state, action) => {
                    state.decisions = action.payload;
                    state.decisionsFetching = false;
                }
            )
            .addCase(
                getDecisions.pending, state => {
                    state.decisionsFetching = true;
                }
            )
            .addCase(
                getDecisions.rejected, state => {
                    state.decisionsFetching = false;
                }
            )
            .addCase(
                getAllRecipientData.pending, state => {
                    // state.ordersFetching = true
                }
            )
            .addCase(
                getAllRecipientData.rejected, state => {
                    // state.ordersError = true
                    // state.ordersFetching = false
                }
            )
            .addCase(
                getAllRecipientData.fulfilled, (state, action) => {
                    state.recipientData = action.payload
                }
            )
            .addCase(
                getAllBankCorrData.pending, state => {
                    // state.ordersFetching = true
                }
            )
            .addCase(
                getAllBankCorrData.rejected, state => {
                    // state.ordersError = true
                    // state.ordersFetching = false
                }
            )
            .addCase(
                getAllBankCorrData.fulfilled, (state, action) => {
                    state.bankCorrData = action.payload
                    // state.ordersFetching = false
                }
            )
            .addCase(
                getOrders.pending, state => {
                    state.ordersFetching = true
                }
            )
            .addCase(
                getOrders.rejected, state => {
                    state.ordersError = true
                    state.ordersFetching = false
                }
            )
            .addCase(
                getOrders.fulfilled, (state, action) => {
                    // state.portfolio = action.payload
                    state.ordersFetching = false
                }
            )
            .addCase(
                getOpenRepos.pending, state => {
                    state.openReposFetching = true;
                }
            )
            .addCase(
                getOpenRepos.rejected, state => {
                    state.openReposFetching = false;
                }
            )
            .addCase(
                getOpenRepos.fulfilled, state => {
                    state.openReposFetching = false;
                }
            )
            .addCase(
                getAllOrders.fulfilled, (state, action) => {
                    state.allOrders = action.payload.sort((a, b) => {
                        return b.clientOrderId - a.clientOrderId
                    });
                    state.allOrdersFetching = false;
                }
            )
            .addCase(
                getAllOrders.pending, state => {
                    state.allOrdersFetching = true;
                }
            )
            .addCase(
                getAllOrders.rejected, state => {
                    state.allOrdersFetching = false;
                }
            )
            .addCase(
                getOrderTemplates.pending, state => {
                    state.orderTemplatesFetching = true;
                }
            )
            .addCase(
                getOrderTemplates.rejected, state => {
                    state.orderTemplatesFetching = false;
                }
            )
            .addCase(
                getOrderTemplates.fulfilled, (state, action) => {
                    state.orderTemplatesFetching = false;
                    state.orderTemplates = action.payload;
                }
            )
            .addCase(
                getOrderDeals.pending, state => {
                    state.dealsFetching = true
                }
            )
            .addCase(
                getOrderDeals.rejected, state => {
                    state.dealsFetching = false
                }
            )
            .addCase(
                getOrderDeals.fulfilled, (state, action) => {
                    state.dealsFetching = false
                    state.deals = action.payload
                }
            )
            .addCase(
                getOrderDealsDetails.pending, state => {
                    state.dealsFetching = true
                }
            )
            .addCase(
                getOrderDealsDetails.rejected, state => {
                    state.dealsFetching = false
                }
            )
            .addCase(
                getOrderDealsDetails.fulfilled, (state, action) => {
                    state.dealsFetching = false
                    state.dealsDetails = action.payload
                }
            )
    }
})

export const {
    showBuyOrder,
    showSellOrder,
    showRepoOrder,
    showCancelTradeOrder,
    showCancelNonTradeOrder,
    showMoneyTransferOrder,
    showMoneyConversionOrder,
    closeRepoOrder,
    closeOrder,
    closeCancelOrder,
    closeMoneyConversionOrder,
    closeMoneyTransferOrder,
    unsetOrderError,
    addToTracked,
    addToCompleted,
    addToRepoTracked,
    addToRepoCompleted,
    addToRejected,
    addIdsToWatch,
    addIdsToUnwatch,
    addOrderIds,
    updateOrderStatus,
    addToOpenRepos,
    showPledgeOrder,
    closePledgeOrder,
    showSecurityConversionOrder,
    closeSecurityConversionOrder,
    showSecuritiesTransferOrder,
    closeSecuritiesTransferOrder,

    showRepoInfo,
    showRepoSide,
    closeRepoSide,
    showRepoCorpOrder,
    showRepoGCBOrder,
    closeRepoCorpOrder,
    closeRepoGCBOrder,
    logOutOrders,
    showDuplicateTemplateWindow,
    closeDuplicateTemplateWindow,
    setTempTemplate,
    setTempTemplateType,
    showDuplicateRequestWindow,
    closeDuplicateRequestWindow,
    setTempRequest,
    setTempReqType,
    setTemporaryOt
} = ordersSlice.actions

