import React, { FC, useEffect } from "react"
import { useSelector } from "react-redux";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { TState, dispatch } from "../../store";
import { closeRepoSide } from "../../store/orders.slice";
import { TRepoData } from "../../types/repo";
import { DepthOfRepoPage } from "../market/DepthOfRepoPage";
import { sideComponentWidthRepo } from "../../constants";

export const RepoSide = () => {
    const { orders: { repoSideInfo, repoSideDisplayed } } = useSelector((state: TState) => state);
    const depthType: string = "Repo";
    return <>
        <RepoSideTable
            repoInfo={repoSideInfo}
            displayCondition={repoSideDisplayed}
            closeFunction={closeRepoSide}
            depthType={depthType} />
    </>
}

const RepoSideTable: FC<{ repoInfo: TRepoData | null, displayCondition: boolean | null, closeFunction: Function, depthType: string }> = ({ repoInfo, displayCondition, closeFunction, depthType }) => {
    const closeHandler = () => {
        dispatch(closeFunction());
    };

    useEffect(() => {
        const comp = document.getElementById("comp");
        const content = document.getElementById("content");
        if (comp !== null && content !== null) {
            if (displayCondition) {
                comp.style.width = `${sideComponentWidthRepo}%`;
                content.style.visibility = "visible";
            }
            else {
                comp.style.width = "0%";
                content.style.visibility = "hidden";
            }
        }
    }, [displayCondition]);

    return <>
        <div id="comp" className="side-comp" style={{ width: displayCondition ? `${sideComponentWidthRepo}%` : "0%" }}>
            <div id="content" className="side-comp-content" style={{ visibility: displayCondition ? "visible" : "hidden" }}>
                <Button size="small" className="side-close-btn" onClick={closeHandler} ><CloseOutlined /></Button>
                <DepthOfRepoPage depthType={depthType} />
            </div>
        </div>
    </>
}