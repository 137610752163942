import React, { FC } from 'react';

export const BuyIcon: FC<{ color?: string }> = ({ color = "#ED1C24" }) => (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M6.64645 7.4712C6.84171 7.66646 7.15829 7.66646 7.35355 7.4712L10.5355 4.28922C10.7308 4.09396 10.7308 3.77738 10.5355 3.58211C10.3403 3.38685 10.0237 3.38685 9.82843 3.58211L7 6.41054L4.17157 3.58211C3.97631 3.38685 3.65973 3.38685 3.46447 3.58211C3.2692 3.77738 3.2692 4.09396 3.46447 4.28922L6.64645 7.4712ZM6.5 1.41419e-08L6.5 3.55882L7.5 3.55882L7.5 -1.41419e-08L6.5 1.41419e-08ZM6.5 3.55882L6.5 7.11765L7.5 7.11765L7.5 3.55882L6.5 3.55882Z" />
        <mask id="path-2-inside-1_34_320" fill="white">
            <path d="M0 5.82349H14V8.99996C14 10.1045 13.1046 11 12 11H2C0.89543 11 0 10.1045 0 8.99996V5.82349Z" />
        </mask>
        <path fill={color} d="M0 5.82349H14H0ZM15 8.99996C15 10.6568 13.6569 12 12 12H2C0.343146 12 -1 10.6568 -1 8.99996H1C1 9.55224 1.44772 9.99996 2 9.99996H12C12.5523 9.99996 13 9.55224 13 8.99996H15ZM2 12C0.343146 12 -1 10.6568 -1 8.99996V5.82349H1V8.99996C1 9.55224 1.44772 9.99996 2 9.99996V12ZM15 5.82349V8.99996C15 10.6568 13.6569 12 12 12V9.99996C12.5523 9.99996 13 9.55224 13 8.99996V5.82349H15Z" mask="url(#path-2-inside-1_34_320)" />
    </svg>
)
