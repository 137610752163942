import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TBrokerInfo, THolderInfo } from "../types/api";
import { apiConnector } from "../integrations/api.connector";

export const getInfo = createAsyncThunk(
    'info/general',
    async (): Promise<{ brokers: TBrokerInfo[], holders: THolderInfo[] }> => {
        try {
            const brokers = await apiConnector.getBrokersInfo();
            const holders = await apiConnector.getHoldersInfo();
            return { brokers, holders }
        } catch (e: any) {
            throw new Error(e)
        }
    }
)

export const infoSlice = createSlice({
    name: 'info',
    initialState: {
        brokers: [] as TBrokerInfo[],
        holders: [] as THolderInfo[],
        infoFetching: false,
        infoError: false
    },
    reducers: {
        logOutInfo: (state) => {
            return {
                ...state,
                brokers: [],
                holders: [],
                infoFetching: false,
                infoError: false,
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(
                getInfo.pending, state => {
                    state.infoFetching = true
                }
            )
            .addCase(
                getInfo.rejected, state => {
                    state.infoFetching = false
                }
            )
            .addCase(
                getInfo.fulfilled, (state, action) => {
                    state.infoFetching = false
                    state.brokers = action.payload.brokers
                    state.holders = action.payload.holders
                }
            )
    }
})

export const { logOutInfo } = infoSlice.actions;