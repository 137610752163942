import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { dispatch, TState } from '../../store';
import { showSellOrder } from '../../store/orders.slice';
import { ECurrency } from '../../types/common';

export const SellBtn: FC<{ securityId: number, currency: ECurrency, intBool: number }> = ({ securityId, currency, intBool }) => {
    const { equitiesLib, bondsLib } = useSelector((state: TState) => state.securities)
    const handleSell = () => {
        const marketSecurity = [...equitiesLib, ...bondsLib].find(s => (
            s.securityId === securityId &&
            s.tradeCurrency === currency &&
            s.intBool === intBool
        ));
        [...equitiesLib, ...bondsLib].filter(s => (
            s.securityId === securityId));
        if (marketSecurity) {
            dispatch(showSellOrder(marketSecurity))
        }
    }
    return (
        <Button size="small" type="primary" danger onClick={handleSell} style={{ color: 'white' }}>
            SELL
        </Button>
    )
}
