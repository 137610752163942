import React, { FC, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Layout, Table } from "antd";
import { nanoid } from "nanoid";
import { TState } from "../../store";
import { QuotesConnector } from "../../integrations/quotes.connector";
import { getHalfPageTableHeight, rowClassCompact } from "../../services/helpers";
import { TOrderBookRecord } from "../../types/market"
import { columns } from "./market.data";
import "./DepthOfMarketPage.css";


export const DepthOfRepoPage: FC<{ depthType: string }> = ({ depthType }) => {
    const {
        securities: { marketDepth },
        orders: { repoSideInfo }
    } = useSelector((state: TState) => state);
    const [data, setData] = useState<TOrderBookRecord[]>();
    const [keyMD, setKeyMD] = useState<string | string[]>();
    const tableRef = useRef<HTMLTableElement>(null);
    const config = (): { ytm: boolean, repo: boolean } => {
        const repoSum = data?.reduce((previousValue, currentValue) => {
            let currNum: number;
            if (currentValue.repovalue === null) {
                currNum = 0;
            } else {
                currNum = currentValue.repovalue
            }
            return previousValue += currNum;
        }, 0);

        if (repoSum !== undefined && repoSum > 0) {
            return {
                ytm: false,
                repo: repoSum > 0
            }
        }

        else {
            return {
                ytm: false,
                repo: false
            };
        }
    };

    const dataSource = (): TOrderBookRecord[] | undefined => {
        if (data !== undefined) {
            const dataMD = [...data];
            // return dataMD.sort((a, b) => b.price - a.price);
            let dataSell = dataMD.filter(d => d.direction === "S").sort((a, b) => b.price - a.price);
            let dataBuy = dataMD.filter(d => d.direction === "B").sort((a, b) => b.price - a.price);
            return dataSell.concat(dataBuy);
        }
    };

    const titleForDepthOfMarket = () => {
        if (repoSideInfo) {
            const { secboard, seccode } = repoSideInfo;
            return () => {
                return <span style={{ fontWeight: "bold" }}>Тикер: {secboard} - {seccode}</span>;
            }
        }
    };

    useEffect(() => {
        if (depthType === "Repo") {
            const repo = repoSideInfo;
            if (repo) {
                const key = [`${repo.secboard}-${repo.seccode}`];
                QuotesConnector.subscribeRepo(key);
                setKeyMD(key);
                return () => {
                    setData(undefined);
                    QuotesConnector.unsubscribeRepo(key);
                }
            }
        }
    }, [repoSideInfo]);

    useEffect(() => {
        let market = { ...marketDepth };
        if (keyMD !== undefined) {
            const key = keyMD[0];
            if (market[key]) {
                setData(market[key]);
            }
        }
    }, [marketDepth]);

    useEffect(() => {
        if (data !== undefined) {
            const elements: NodeListOf<HTMLElement> = document.querySelectorAll('.ant-table-row.ant-table-row-level-0.compact-row');
            const heights: number[] = [];
            elements.forEach((element: HTMLElement) => {
                const height: number = element.offsetHeight;
                heights.push(height);
            });
            const totalHeight: number = heights.reduce((accumulator: number, currentValue: number) => accumulator + currentValue);
            const tableElement = tableRef.current?.querySelector('.ant-table-body');
            if (tableElement) {
                // Устанавливаем позицию скролла по центру таблицы
                tableElement.scrollTo({ top: totalHeight / 4.9 });
            }
        }
    }, [data])
    return <>
        <Layout className='content-wrap' style={{ padding: 0, width: '100%', maxHeight: getHalfPageTableHeight() - 22 }}>
            <Table
                ref={tableRef}
                title={titleForDepthOfMarket()}
                dataSource={dataSource()}
                columns={columns(config())}
                size="small"
                style={{ width: '100%' }}
                className="table-depth-of-market"
                rowClassName={rowClassCompact}
                scroll={{ y: getHalfPageTableHeight() - 22 }}
                rowKey={() => nanoid(8)}
                pagination={false}
                showSorterTooltip={false}
            />
        </Layout>
    </>
};