import React, { CSSProperties, FC } from "react";
import { Button } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";

export const ExportBtn: FC<{ onClick: () => void, loading: boolean, style?: CSSProperties }> = ({ onClick, loading, style }) => {
    return (
        <Button
            style={{
                backgroundColor: 'var(--fhs-green)',
                color: 'white',
                border: "1px solid var(--fhs-green)",
                ...style
            }}
            className="export-btn"
            onClick={onClick}
            ghost
            loading={loading}
            size="small"
            icon={<FileExcelOutlined />}
        >
            Экспорт в EXCEL
        </Button>
    )
}