import {ECurrency, ESecType} from '../types/common';
import {ERoles} from "../types/profile";
import {store} from "../store";
import {confirmationIsOn, timeForInfoNotification} from '../constants';
import {TOrderArray} from "../types/tableEditor";
import {TAuthCertificate, TFTAccount, THolderInfo, TOwnSecurity, TSubAccount} from "../types/api";
import { EOrderType, TNotification } from '../types/notifications';
import { notification } from 'antd';
import { getOrderStatusName } from './statuses';

export const rowClass = (record: any, index: number): string => {
    return (index)%2 > 0 ? `odd` : 'event'
}
export const rowClassCompact = (record: any, index: number): string => {
    return `compact-row ${(index)%2 > 0 ? `odd` : 'event'}`
}
export const getSecTypeName = (type: ESecType): string => {
    switch (type) {
        case ESecType.bond: return "Облигации";
        case ESecType.equity: return "Акции";
        default: return "н/д"
    }
}
const widgetHeaderHeight = 0;
const getContentHeight = ():number => window.innerHeight - 64 - 95;
// 10 + 40 + 16
export const getFullPageTableHeight = () => {
    return getContentHeight() - widgetHeaderHeight - 76;
}
export const getHalfPageTableHeight = () => {
    return Math.round(getContentHeight() / 2 - widgetHeaderHeight - 165);
}
export const getHalfPageContainerHeight = () => (getContentHeight() - 15) / 2;


export const repoModeToDays: {[key: string]: number} = {
    "EQRD":1,
    "EQWD":7,
    "EUSW": 14,
    "EUOM": 30,
    "EQRP":1,
    "EQR2":2,
    "EQR3":3,
    "EQWP":7,
    "EQW2":14,
    "EQMP":30,
}
export const getRepoMode = (currency: ECurrency, days: number): string => {
    const invertedUSD = new Map<number, string>()
    const invertedKZT = new Map<number, string>()
    for(const key in repoModeToDays){
        const targetMap = (key.indexOf('D') === -1 && key.indexOf('U') === -1) ? invertedKZT : invertedUSD;
        targetMap.set(repoModeToDays[key],key);
    }
    return (currency === ECurrency.KZT ? invertedKZT.get(days) : invertedUSD.get(days)) || "Unknown"
}
export const repoODGU = new Map<number, string>([
    [90,"EBM3"],
    [30,"EBMP"],
    [2,"EBR2"],
    [3,"EBR3"],
    [1,"EBRP"],
    [14,"EBW2"],
    [7,"EBWP"],
])
export const daySuffix = (num: number): string => {
    let suf = 'дней';
    if(num === 1){
        suf = "день";
    } else if(num > 1 && num < 5){
        suf = "дня"
    }
    return `${num} ${suf}`;
}
export const getRepoDaysByTradeModeCode = (tradeModeCode: string): number => {
    if(tradeModeCode?.length !== 4){
        console.log({tradeModeCode})
        // throw new Error('wrong format')
    }
    const dayPart: string = tradeModeCode?.slice(2,4);
    let days: number;
    switch (dayPart){
        case "RD": days = 1; break;
        case "RP": days = 1; break;
        case "R2": days = 2; break;
        case "R3": days = 3; break;
        case "WD": days = 7; break;
        case "WP": days = 7; break;
        case "W2": days = 14; break;
        case "MP": days = 30; break;
        case "M3": days = 90; break;
        default: days = 0;
    }
    if(days === 0){
        // throw new Error('unknown format')
    }
    return days
}

export const getRoles = () => {
    const {auth: {roles}} = store.getState();
    return roles;
}

export const isCompany = ():boolean => {
    const {auth: {iin, bin}} = store.getState();
    return confirmationIsOn && !!iin && !!bin;
}

export const isHeadOfCompany = (): boolean => {
    const roles = getRoles();
    return roles.includes(ERoles.chief)
}

export const canSignOrder = (): boolean => {
    const roles = getRoles();
    return roles.includes(ERoles.chief) || roles.includes(ERoles.assetManager)
}

export const canCreateTemplate = (): boolean => {
    return canSignOrder() || getRoles().includes(ERoles.manager)
}

export const filteredAccounts = (): TFTAccount[] => {
    const {money: {moneyRem}} = store.getState();
    const KACD = moneyRem.filter(mr => !!mr.iType);
    const EuroClear = moneyRem.filter(mr => mr.holdPlace.indexOf("Euroclear") > -1 && mr.currencyCode === "USD");
    const DBS = moneyRem.filter(mr => mr.holdPlace.indexOf("DBS") > -1);
    const Halyk = moneyRem.filter(mr => mr.holdPlace.indexOf("Народный") > -1);
    return [...KACD, ...EuroClear, ...DBS, ...Halyk]
}


export const updateTableView = (initialArray: Object[], keyForLS: string): Object[] => {
    const orderArray: TOrderArray[] = JSON.parse(localStorage.getItem(keyForLS) || '[]')
    if (!orderArray.length) {
        return initialArray
    }
    const result: Object[] = [];
    for (let i = 0; i < orderArray.length; i++) {
        if (orderArray[i].active) {
            result.push(initialArray[orderArray[i].index]);
        }
    }
    return result;
}


export const shortName = (acc: TSubAccount | undefined): string | undefined => {
    const firthSymbol = acc?.subAccount.charAt(0);
    const secondSymbol = acc?.subAccount.charAt(1);
    const combinedString = `${firthSymbol}${secondSymbol}`; 
    if(acc?.holdPlace === "АО \"Центральный депозитарий ценных бумаг\"" && combinedString === "I+" && acc.localBool === 1) {
        return "KASE"
    }
    else if(acc?.holdPlace === "АО \"Центральный депозитарий ценных бумаг\"" && acc?.subAccount !== combinedString && acc.localBool === 1) {
        return "KACD"
    }

    else if(acc?.holdPlace === "АО \"Центральный депозитарий ценных бумаг\"" && acc.intBool === 1) {
        return "KACD"
    }

    else if(acc?.holdPlace === "Euroclear Bank SA/NV" && acc.intBool === 1) {
        return "Euroclear Bank"
    } 

    else if(acc?.holdPlace === "АО \"Банк ЦентрКредит\"") {
        return "BCC"
    }

    else if(acc?.holdPlace === "АО \"Народный Банк Казахстана\"") {
        return "HalykBank"
    }

    else if(acc?.holdPlace === "АО \"BEREKE BANK\"") {
        return "BerekeBank"
    }

    else if(acc?.holdPlace === "Astana International Exchange CSD") {
        return "AIX"
    }
    
    else {
        return acc?.holdPlace
    }
}

export const shortNameforHolders = (holderName: string | undefined) : string | undefined => {
    if(holderName === "АО 'Центральный депозитарий ценных бумаг'") {
        return "KACD"
    }
    else if(holderName === "DBS") {
        return "DBS"
    }
    else if(holderName === "Euroclear Bank SA/NV ") {
        return "Euroclear Bank"
    }
    else if(holderName === "АО 'BEREKE BANK'") {
        return "BerekeBank"
    }
    else if(holderName === "АО 'Народный Банк Казахстана'") {
        return "HalykBank"
    }
}

export const shortNamesForPortfolio = (acc: TOwnSecurity) => {
    switch (true) {
        case acc.holdPlace === "АО \"Центральный депозитарий ценных бумаг\"" && acc.subAccount === "I+1033600356":
            return "KASE"
        case acc.holdPlace === "АО \"Центральный депозитарий ценных бумаг\"" && acc.subAccount === "1033600356":
            return "KACD"
        case acc.holdPlace === "Euroclear Bank SA/NV":
            return "Euroclear Bank"
        default:
            return acc.holdPlace
    }
}

export const displayNotificationMessage = (notificationMessage: TNotification) => {
    if (notificationMessage.orderType === EOrderType.template) {
        notification.info({
            duration: timeForInfoNotification,
            message: `Новый заказ/приказ ожидает согласования`,
        });
    } else {
        let orderType = "Заказ";
        if (notificationMessage.orderType === EOrderType.nonTrade) {
            orderType = "Приказ";
        }
        const storeState = store.getState();
        const trackedOrders = storeState.orders.trackedOrders;
        const trackedRepoOrders = storeState.orders.trackedRepoOrders;
        const digitalOrders = storeState.ntOrders.digitalOrders;
        let orders: any = trackedOrders.concat(trackedRepoOrders);
        
        const orderId = notificationMessage.orderId;
        let orderNumber = "";
        let currentStatus = 0;
        orders.forEach((order: any) => {
            if (order.clientOrderId === orderId) {
                currentStatus = order.orderStatusId;
                orderNumber = order.orderNumber;
            }
        });

        if (currentStatus === 0) {
            digitalOrders.forEach((digOrder: any) => {
                if (digOrder.orderSysData.orderId === orderId) {
                    currentStatus = digOrder.orderSysData.orderStatusId;
                    orderNumber = digOrder.orderNumber;
                }
            });
        }

        notification.info({
            duration: timeForInfoNotification,
            message: `${orderType} № ${orderNumber}`,
            description: `Статус изменен: ${getOrderStatusName(currentStatus)} -> ${getOrderStatusName(notificationMessage.statusId)}`
        });
    }
}

export const processCertificate = (certificate: TAuthCertificate | null) => {
    if (certificate && certificate.certificateInfo && certificate.certificateInfo.fullName) {
        const orgName = certificate.certificateInfo.orgName?.replace("Товарищество с ограниченной ответственностью", "ТОО").replace("Акционерное общество", "АО");
        return `${orgName ? orgName : 'ФИЗ. ЛИЦО'} - ${certificate.certificateInfo.fullName}`;
    } else if (certificate && certificate.certificateInfo && certificate.path) {
        return null
    } else {
        return "Неверный формат сертификата";
    }
};

export const isEmptyAccount = (acc: TFTAccount): boolean => {
    const sum = acc.totalAmount + acc.blockAmount + acc.freeAmount + acc.repoBuyAmount + acc.repoSellAmount;
    return sum <= 0
}

export const repoGCBCC = new Map<number, string>([
    [1, "EBRP"],
    [2, "EBR2"],
    [3, "EBR3"],
    [7, "EBWP"],
    [14, "EBW2"],
    [30, "EBMP"],
    [90, "EBM3"]
])
export const repoGCBNoCC = new Map<number, string>([
    [1, "ELRP"],
    [2, "ELR2"],
    [3, "ELR3"],
    [7, "ELWP"],
    [14, "ELW2"],
    [30, "ELMP"],
]);