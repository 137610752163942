import React from 'react';
import { useSelector } from 'react-redux';
import { TState } from '../../store';
import { closeEquitiesSide } from '../../store/securities.slice';
import { SecuritySide } from './SecutirySide';

export const EquitiesSide = () => {
    const {
        securities: {
            equitiesSideInfo,
            equitiesSideDisplayed
        }
    } = useSelector((state: TState) => state);

    const depthType = "Equities"

    return (
        <SecuritySide
            securityInfo={equitiesSideInfo}
            displayCondition={equitiesSideDisplayed}
            closeFunction={closeEquitiesSide}
            depthType={depthType}
        />
    )
};