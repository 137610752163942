import React, { useEffect, memo, useRef, useState, FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { dispatch } from '../store';
import { useInitDataFetch } from "./hooks/initial";
import { MoneyPage } from "./money/MoneyPage";
import { TradePage } from './trade/TradePage';
import { getOrderUpdates } from '../store/orders.slice';
import { OrderFormHolder } from './orders/OrderFormHolder';
import { RepoPage } from './repo/RepoPage';
import { ReportsPage } from './reports/ReportsPage';
import { AllOrdersPage } from "./orders/AllOrdersPage";
import { AnalyticsPage } from "./analytics/AnalyticsPage";
import { DashBoard } from './dashboard/DashBoard';
import { ReportsPageTest } from './reports/ReportsPageTest';

export const SpinnerOverlay: FC = () => {
    return <>
        <div style={{
            position: "fixed",
            top: 0, left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999
        }}>
            <div style={{
                textAlign: 'center',
                color: '#333', // Цвет текста
                padding: '20px',
                borderRadius: '5px'
            }}>
                <Spin size="large" />
                <p style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '16px' }}>
                    Загружаем данные...
                </p>
            </div>
        </div>
    </>
};

export const WorkSpace = () => {
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useInitDataFetch(setIsDataLoaded);
    return <>
        {
            isDataLoaded ?
                <SpinnerOverlay />
                :
                <Layout className="app-content">
                    <Routes>
                        <Route path={"/"} element={<DashBoard />} />
                        <Route path={"/trade"} element={<TradePage />} />
                        <Route path={"/repo"} element={<RepoPage />} />
                        <Route path={"/money"} element={<MoneyPage />} />
                        <Route path={"/reports"} element={<ReportsPage />} />
                        <Route path={"/reports-test"} element={<ReportsPageTest />} />
                        <Route path={"/orders"} element={<AllOrdersPage />} />
                        <Route path={"/analytics"} element={<AnalyticsPage />} />
                    </Routes>
                    <OrderFormHolder />
                </Layout>
        }
    </>
}

const MemoSpace = memo(WorkSpace)
export const AppWrapper = () => {
    const timer = useRef<number>();
    useEffect(() => {
        timer.current = window.setInterval(() => {
            dispatch(getOrderUpdates())
        }, 1000 * 10)
        return () => clearInterval(timer.current)
    }, [])
    return <MemoSpace />
}