import { ColumnsType } from "antd/lib/table";
import { finNum } from "../../services/formatter";
import { TFTAccount } from "../../types/api";
import { ECurrency } from '../../types/common';

export const columnsAll = (accounts?: TFTAccount[]): ColumnsType<TFTAccount> => {
    return [
        {
            title: 'Место хранения',
            dataIndex: 'holdPlace',
            key: 'holdPlace',
            sorter: (a: TFTAccount, b: TFTAccount) => a.holdPlace.localeCompare(b.holdPlace),
        },
        {
            title: 'Номер счета',
            dataIndex: 'subAccount',
            key: 'subAccount',
            align: 'right',
            sorter: (a: TFTAccount, b: TFTAccount) => a.subAccount.localeCompare(b.currencyCode),
            render: (_, record) => (record.iType ? `${record.iType}${record.subAccount}` : `${record.subAccount}`)
        },
        {
            title: 'Валюта',
            dataIndex: 'currencyCode',
            key: 'currencyCode',
            align: 'right',
            sorter: (a: TFTAccount, b: TFTAccount) => a.currencyCode.localeCompare(b.currencyCode)
        },
        {
            title: 'Остаток',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            align: 'right',
            render: finNum,
            sorter: (a: TFTAccount, b: TFTAccount) => a.totalAmount - b.totalAmount
        },
        {
            title: 'Блокировано',
            dataIndex: 'blockAmount',
            key: 'blockAmount',
            render: finNum,
            sorter: (a: TFTAccount, b: TFTAccount) => a.blockAmount - b.blockAmount,
            align: 'right'
        },
        {
            title: 'Свободный остаток',
            dataIndex: 'freeAmount',
            key: 'freeAmount',
            render: finNum,
            sorter: (a: TFTAccount, b: TFTAccount) => a.freeAmount - b.freeAmount,
            align: 'right',
        },
        {
            title: 'Размещенные средства',
            dataIndex: 'repoBuyAmount',
            key: 'repoBuyAmount',
            render: finNum,
            sorter: (a: TFTAccount, b: TFTAccount) => a.repoBuyAmount - b.repoBuyAmount,
            align: 'right'
        },
        {
            title: 'Привлеченные средства',
            dataIndex: 'repoSellAmount',
            key: 'repoSellAmount',
            sorter: (a: TFTAccount, b: TFTAccount) => a.repoSellAmount - b.repoSellAmount,
            render: finNum,
            align: 'right'
        },
    ];
}
export const columnsByCurrency: ColumnsType<{ currencyCode: ECurrency, key: number }> = [
    {
        title: 'Валюта',
        dataIndex: 'currencyCode',
        key: 'currencyCode',
        width: 100,
        className: "big"
        // sorter: (a: TFTAccount, b: TFTAccount) => a.holdPlace.localeCompare(b.holdPlace),
    },
    {
        title: 'Сумма',
        dataIndex: 'total',
        key: 'total',
        align: 'right',
        width: 250,
        render: finNum,
    },
    {
        title: "",
        key: "filler"
    }
]
export const columnsByCurrencyNested: ColumnsType<TFTAccount> = [
    {
        title: 'Место хранения',
        dataIndex: 'holdPlace',
        key: 'holdPlace',
        width: "20%",
        sorter: (a: TFTAccount, b: TFTAccount) => a.currencyCode.localeCompare(b.currencyCode)
    },
    {
        title: 'Номер счета',
        dataIndex: 'subAccount',
        key: 'subAccount',
        align: 'right',
        width: "10%",
        sorter: (a: TFTAccount, b: TFTAccount) => a.subAccount.localeCompare(b.currencyCode)
    },
    {
        title: 'Остаток',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        align: 'right',
        render: finNum,
        sorter: (a: TFTAccount, b: TFTAccount) => a.totalAmount - b.totalAmount
    },
    {
        title: 'Блокировано',
        dataIndex: 'blockAmount',
        key: 'blockAmount',
        render: finNum,
        sorter: (a: TFTAccount, b: TFTAccount) => a.blockAmount - b.blockAmount,
        align: 'right'
    },
    {
        title: 'Свободный остаток',
        dataIndex: 'freeAmount',
        key: 'freeAmount',
        render: finNum,
        sorter: (a: TFTAccount, b: TFTAccount) => a.freeAmount - b.freeAmount,
        align: 'right',
    },
    {
        title: 'Размещенные средства',
        dataIndex: 'repoBuyAmount',
        key: 'repoBuyAmount',
        sorter: (a: TFTAccount, b: TFTAccount) => a.repoBuyAmount - b.repoBuyAmount,
        render: finNum,
        width: "15%",
        align: 'right'
    },
    {
        title: 'Привлеченные средства',
        dataIndex: 'repoSellAmount',
        key: 'repoSellAmount',
        sorter: (a: TFTAccount, b: TFTAccount) => a.repoSellAmount - b.repoSellAmount,
        render: finNum,
        width: "15%",
        align: 'right'
    },
]
export const columnsByPlace: ColumnsType<{ holdPlace: string, key: number }> = [
    {
        title: 'Место хранения',
        dataIndex: 'holdPlace',
        key: 'holdPlace',
        // sorter: (a: TFTAccount, b: TFTAccount) => a.holdPlace.localeCompare(b.holdPlace),
    }
]
export const columnsByPlaceNested: ColumnsType<TFTAccount> = [
    {
        title: 'Номер счета',
        dataIndex: 'subAccount',
        key: 'subAccount',
        align: 'right',
        sorter: (a: TFTAccount, b: TFTAccount) => a.subAccount.localeCompare(b.currencyCode)
    },
    {
        title: 'Валюта',
        dataIndex: 'currencyCode',
        key: 'currencyCode',
        align: 'right',
        sorter: (a: TFTAccount, b: TFTAccount) => a.currencyCode.localeCompare(b.currencyCode)
    },
    {
        title: 'Остаток',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        align: 'right',
        render: finNum,
        sorter: (a: TFTAccount, b: TFTAccount) => a.totalAmount - b.totalAmount
    },
    {
        title: 'Блокировано',
        dataIndex: 'blockAmount',
        key: 'blockAmount',
        render: finNum,
        sorter: (a: TFTAccount, b: TFTAccount) => a.blockAmount - b.blockAmount,
        align: 'right'
    },
    {
        title: 'Свободный остаток',
        dataIndex: 'freeAmount',
        key: 'freeAmount',
        render: finNum,
        sorter: (a: TFTAccount, b: TFTAccount) => a.freeAmount - b.freeAmount,
        align: 'right',
    },
    {
        title: 'Размещенные средства',
        dataIndex: 'repoBuyAmount',
        key: 'repoBuyAmount',
        render: finNum,
        sorter: (a: TFTAccount, b: TFTAccount) => a.repoBuyAmount - b.repoBuyAmount,
        align: 'right'
    },
    {
        title: 'Привлеченные средства',
        dataIndex: 'repoSellAmount',
        key: 'repoSellAmount',
        sorter: (a: TFTAccount, b: TFTAccount) => a.repoSellAmount - b.repoSellAmount,
        render: finNum,
        align: 'right'
    },
]
