import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dispatch, TState } from '../../store';
import { getClientData } from "../../store/auth.slice";
import { OrderForm } from './OrderForm';
import { CancelForm } from "./CancelForm";
import { CancelNonTradeForm } from "./CancelNonTradeForm";
import { RepoOrderForm } from './RepoOrderForm';
import { RepoOrderFormGCB } from "./RepoOrderFormGCB";
import { RepoCorpAttractForm, RepoCorpPostForm } from './RepoCorpCB';
import { RepoGCBAttractForm, RepoGCBPostForm } from './RepoGCB';
import { PledgeOrderForm } from "./PledgeOrderForm";
import { MoneyConversionForm } from "./money/MoneyConversionForm";
import { ExternalMoneyTransferForm } from "./money/ExternalMoneyTransferForm";
import { InternalMoneyTransferForm } from "./money/InternalMoneyTransferForm";
import { InternalSecuritiesTransferForm } from "./securities/InternalSecuritiesTransferForm";
import { SecuritiesConversionForm } from "./securities/SecuritiesConversionForm";
import { ExternalSecuritiesTransferForm } from "./securities/ExternalSecuritiesTransferForm";
import { DuplicateTemplateAlertModal } from '../shared/modals/DuplicateTemplateAlertModal';
import { DuplicateRequestAlertModal } from '../shared/modals/DuplicateRequestAlertModal';

export const OrderFormHolder = () => {
    const {
        showOrderModal,
        showRepoOrderModal,
        showRepoCorpModal,
        showRepoGCBModal,
        showCancelOrderModal,
        showNonTradeCancelModal,
        repo,
        repoCorp,
        repoGCB,
        showPledgeOrderModal,
        showMoneyTransferOrderModal,
        mtExternal,
        showMoneyConversionOrderModal,
        showSecuritiesConversionOrderModal,
        showSecuritiesTransferOrderModal,
        stExternal,
        showDuplicateTemplateModal,
        showDuplicateRequestModal
    } = useSelector((state: TState) => state.orders);
    useEffect(() => {
        dispatch(getClientData())
    }, [])
    return (
        <>
            {showOrderModal ? <OrderForm /> : ""}
            {repo && showRepoOrderModal ? <RepoOrderFormGCB /> : ""}
            {!repo && showRepoOrderModal ? <RepoOrderForm /> : ""}

            {repoCorp && showRepoCorpModal ? <RepoCorpPostForm /> : ""}
            {!repoCorp && showRepoCorpModal ? <RepoCorpAttractForm /> : ""}
            {repoGCB && showRepoGCBModal ? <RepoGCBPostForm /> : ""}
            {!repoGCB && showRepoGCBModal ? <RepoGCBAttractForm /> : ""}

            {showCancelOrderModal ? <CancelForm /> : ""}
            {showNonTradeCancelModal ? <CancelNonTradeForm /> : ""}
            {showPledgeOrderModal ? <PledgeOrderForm /> : ""}
            {showMoneyTransferOrderModal && !mtExternal ? <InternalMoneyTransferForm /> : ""}
            {showMoneyTransferOrderModal && mtExternal ? <ExternalMoneyTransferForm /> : ""}
            {showMoneyConversionOrderModal ? <MoneyConversionForm /> : ""}
            {showSecuritiesConversionOrderModal ? <SecuritiesConversionForm /> : ""}
            {showSecuritiesTransferOrderModal && !stExternal ? <InternalSecuritiesTransferForm /> : ""}
            {showSecuritiesTransferOrderModal && stExternal ? <ExternalSecuritiesTransferForm /> : ""}

            {showDuplicateTemplateModal ? <DuplicateTemplateAlertModal /> : ""}
            {showDuplicateRequestModal ? <DuplicateRequestAlertModal /> : ""}
        </>
    )
}
