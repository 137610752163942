import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/antd.css'
import './styles/index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { dispatch, store } from './store';
import { ConfigProvider } from 'antd';
import "moment/locale/ru";
import ruRU from 'antd/lib/locale/ru_RU';
import { NcaLayerConnector } from './integrations/ncaLayer.connector';
import { QuotesConnector } from './integrations/quotes.connector';
import { addQuote, addMD, setRepoSecLists } from './store/securities.slice';
import { appMode } from "./constants";
import { NotificationsConnector } from './integrations/notifications.connector';
import { getOrders } from './store/orders.slice';
import { getNTOrders } from './store/nonTrade.orders.slice';
import { displayNotificationMessage } from './services/helpers';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
NcaLayerConnector.initSocket();
QuotesConnector.onQuote = (quotes) => {
    for (const quote of [quotes].flat()) {
        let key = `${quote.SECBOARD}-${quote.SECCODE}`;
        dispatch(addQuote({ key, quote }))
    }
}
QuotesConnector.onDepth = (orderBook) => {
    const key = orderBook.key;
    const book = orderBook.book;
    dispatch(addMD({ key, book }));
}

QuotesConnector.onRepoDepth = (orderBook) => {
    const key = orderBook.key;
    const book = orderBook.book;
    dispatch(addMD({ key, book }));
}

QuotesConnector.onRepoUSDSecList = (data) => {
    // TODO: Form list of securities available for use in REPO
    setTimeout(() => {
        dispatch(setRepoSecLists(data));
    }, 5000)
}

NotificationsConnector.onNotification = (notificationMessage) => {
    displayNotificationMessage(notificationMessage);
    dispatch(getOrders());
    dispatch(getNTOrders());
}

root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <ConfigProvider locale={ruRU}>
            <App />
        </ConfigProvider>
    </Provider>
    // </React.StrictMode>
);

const env = process.env.REACT_APP_MODE;
const test = process.env.REACT_APP_TEST;
console.log('dev 06.09', 'ENV:', { env, test, mode: appMode })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
