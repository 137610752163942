import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, DatePicker, Divider, InputNumber, Modal, notification, Radio, Row, Select, Typography } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import moment from 'moment';
import { BuyIcon } from '../shared/icons/buy.icon';
import { SellIcon } from '../shared/icons/sell.icon';
import { ModalDS } from '../shared/modals/ModalDS';
import { dispatch, TState } from '../../store';
import { closeOrder, getOrders } from '../../store/orders.slice';
import { apiConnector } from '../../integrations/api.connector';
import { NcaLayerConnector } from '../../integrations/ncaLayer.connector';
import { NotificationsConnector } from '../../integrations/notifications.connector';
import { TSubAccount } from '../../types/api';
import {
    DealDirection,
    DealKind,
    ECheckReqType,
    EOrderKind,
    EPDFOrderType,
    EPutOrderType,
    ETradeOrderType,
    OrderType,
    TPutOrderBondsRequest,
    TPutOrderEquitiesRequest, TSetOrderTemplate
} from '../../types/orders';
import { EGlobalSecurityType } from '../../types/common';
import { NcaMessage } from '../../types/nca';
import { EQuoteType } from "../../types/quotes";
import { countNum, finNum, formatPrice, parseNum, parsePrice } from '../../services/formatter';
import { isCompany, isHeadOfCompany, shortName } from "../../services/helpers";
import { Quote } from "../market/Quote";
import { timeForErrorNotification, timeForSuccessNotification } from '../../constants';
import "./editedForm.css";

const commissionValue: number = 0.0005;
const { Paragraph } = Typography;

export const OrderForm = () => {
    const {
        auth: {
            userName,
            iin
        },
        orders: {
            showOrderModal,
            orderDirection,
            buySecurity,
            sellSecurity,
        },
        money: {
            subAccountsInt,
            subAccountsLocal,
            accounts
        },
        securities: {
            equitiesLib,
            bondsLib,
            portfolio,
            quotes
        }
    } = useSelector((state: TState) => state);

    const switchValue = useSelector((state: TState) => state.auth.switch);
    const securities = [...equitiesLib, ...bondsLib];
    const foundSecurity = securities.find(security =>
        security.securityId === sellSecurity?.securityId &&
        security.intBool === sellSecurity?.intBool &&
        (security.tradeCurrency === "USD" && sellSecurity.tradeCurrency === "KZT")
    );

    const targetSecurity = orderDirection === DealDirection.buy ? buySecurity : sellSecurity;
    const isLocal: boolean = targetSecurity?.localBool === 1;
    const isBond: boolean = targetSecurity?.globalTypeId === EGlobalSecurityType.bond
    const subAccounts: TSubAccount[] = isLocal ? [...subAccountsLocal] : [...subAccountsInt];
    const isUsBond = (targetSecurity?.issuer === 'US Treasury' &&
        targetSecurity.globalTypeId === 2 &&
        targetSecurity.securityTypeId === 1 &&
        targetSecurity.intBool === 1); //
    const isUsEquity = (targetSecurity?.marketCode === 'US' &&
        targetSecurity.globalTypeId === 1 &&
        targetSecurity.securityTypeId === 3 &&
        targetSecurity.tradeCurrencyId === 2 &&
        targetSecurity.intBool === 1); //
    const availableSubAccounts: TSubAccount[] = subAccounts.filter(acc => {
        if (isUsBond || isUsEquity) {
            return acc.holdPlace.includes("Euroclear Bank SA/NV") || acc.holdPlace.includes("Народный")
        } else {
            return !acc.holdPlace.toLowerCase().includes("центркредит") && !acc.subAccount.toLowerCase().includes("r00");
        }
    }) // результат фильтрации subAccounts
    const ownedSecurity = portfolio.find(p => p.securityId === targetSecurity?.securityId);
    const [orderType, setOrderType] = useState<OrderType>(OrderType.limited)
    const [sum, setSum] = useState(0);
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [account, setAccount] = useState<TSubAccount>(availableSubAccounts[0]);
    const [step, setStep] = useState<"form" | "sign" | "error">('form');
    const [tradeDay, setTradeDay] = useState(moment())
    const [paramsBo, setParamsBo] = useState<TPutOrderBondsRequest | null>(null);
    const [paramsEq, setParamsEq] = useState<TPutOrderEquitiesRequest | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [orderForm, setOrderForm] = useState<string>();
    const [amount, setAmount] = useState<number>();
    const [commission, setCommission] = useState<number>(0);
    const [disclaimer, setDisclaimer] = useState<string>('');
    const [profit, setProfit] = useState<number>();
    const [sending, setSending] = useState<boolean>(false)
    const [isEuroclear, setIsEuroclear] = useState<boolean>(false);
    const [checkBalance, setCheckBalance] = useState(true);
    const [selectedCurrency, setSelectedCurrency] = useState('KZT');
    const getTitle = () => {
        return (
            <>
                {
                    orderDirection === DealDirection.buy ?
                        <BuyIcon /> : <SellIcon />

                }
                <span className="deal-direction">
                    {
                        orderDirection === DealDirection.buy ?
                            "Покупка" : "Продажа"
                    }
                </span>
            </>
        )
    }
    const handleOk = () => {
        setStep("form")
        dispatch(closeOrder())
    }
    const handleCancel = () => {
        setStep("form")
        dispatch(closeOrder())
    }
    const secKey = `${targetSecurity!.tradeModeCode}-${targetSecurity!.ticker}`;
    let quote = quotes[secKey];
    let key = orderDirection === DealDirection.buy ? "OFFER" : "BID";
    let quotPrice = quote ? quote[key] : undefined;
    const sellIsOff = orderDirection === DealDirection.buy && isBond;
    const options = [
        { label: 'Рынок', value: OrderType.market, disabled: sellIsOff || !quotPrice },
        // {label: 'Рынок', value: OrderType.market, disabled: orderDirection === DealDirection.buy},
        { label: 'Лимит', value: OrderType.limited }
    ]

    const calcCommission = () => {
        if (sum <= 0) {
            setCommission(orderDirection === 'BUY' ? 10 : -10)
        } else {
            if ((sum * commissionValue) < 10) {
                setCommission(orderDirection === 'BUY' ? 10 : -10)
            } else {
                setCommission(orderDirection === 'BUY' ? sum * commissionValue : -(sum * commissionValue))
            }
        }
    }

    const calcSum = () => {
        if (orderType === OrderType.limited) {
            setSum(price * quantity * (isBond ? 0.01 : 1))
        } else {
            if (quotPrice && typeof quotPrice === 'number') {
                setSum(price * quantity)
            }
        }

        if (targetSecurity?.globalTypeId === 1 && targetSecurity.securityTypeId === 3 && targetSecurity.tradeCurrencyId === 2 && targetSecurity.intBool === 1 && targetSecurity.marketCode === 'US') {
            calcCommission()
            setDisclaimer('Настоящим информируем, что сделка содержит комиссию контрагента и/или биржи помимо комиссии Брокера. Минимальная комиссия международного Брокера составляет 10 USD')
        } else {
            if (targetSecurity?.globalTypeId === 2 && targetSecurity.securityTypeId === 1 && targetSecurity.intBool === 1 && targetSecurity.issuer === 'US Treasury') {
                calcCommission()
                setDisclaimer('Настоящим информируем, что сделка содержит комиссию контрагента и/или биржи помимо комиссии Брокера.')
            } else {
                setCommission(0);
                setDisclaimer('');
            }
        }
    }

    const handleCurrencyChange = (value: string) => {
        setSelectedCurrency(value);
        setQuantity(0);
        setPrice(0);
        setProfit(undefined);
    }

    useEffect(() => {
        calcSum();
    }, [orderType, price, quantity, sum, commission])

    useEffect(() => {
        if (!account) {
            setAccount(isLocal ? subAccountsLocal[0] : subAccountsInt[0])
        }
        if (account && orderDirection === DealDirection.sell && ownedSecurity) {
            const { subAccount } = ownedSecurity;
            const acc = subAccounts.find(a => a.subAccount === subAccount);
            acc && setAccount(acc);
        }
    }, [account])
    // reset if data changed
    useEffect(() => {
        if (step === "sign") {
            setStep("form");
            setOrderForm(undefined);
        }
    }, [account, orderType, price, quantity, tradeDay])

    const setNewOrders = async () => {
        if (targetSecurity && account) {
            const { tradeCurrency, securityId, globalTypeId, marketCode } = targetSecurity;
            let dealType: DealKind;
            switch (orderDirection) {
                case DealDirection.repoBuy:
                    dealType = DealKind.buy;
                    break;
                case DealDirection.buy:
                    dealType = DealKind.buy;
                    break;
                case DealDirection.repoSell:
                    dealType = DealKind.sell;
                    break;
                case DealDirection.sell:
                    dealType = DealKind.sell;
                    break;
                default:
                    dealType = DealKind.buy;
            }
            let newOrder: TPutOrderEquitiesRequest | TPutOrderBondsRequest;
            new URLSearchParams();
            if (globalTypeId === EGlobalSecurityType.equity) {
                newOrder = {
                    amount: sum + commission,
                    currency: foundSecurity ? selectedCurrency : tradeCurrency,
                    dataForSign: '',
                    dealKindId: dealType,
                    imContractId: account.imContractId,
                    intBool: isLocal ? 0 : 1,
                    localBool: isLocal ? 1 : 0,
                    orderTypeId: orderType,
                    securityId: securityId,
                    price: price,
                    quantity: quantity,
                    marketCode,
                    signature: '',
                    tradeDay: tradeDay.toDate().toISOString(),
                    tradeMode: 'Т+'
                } as TPutOrderEquitiesRequest;
                setParamsEq(newOrder);
            } else if (globalTypeId === EGlobalSecurityType.bond) {
                newOrder = {
                    securityId: securityId,
                    localBool: isLocal ? 1 : 0,
                    intBool: isLocal ? 0 : 1,
                    tradeMode: 'Т+',
                    signature: '',
                    dealKindId: dealType,
                    orderTypeId: orderType,
                    imContractId: account.imContractId,
                    quantity: quantity,
                    netPercentPrice: price,
                    profit: 0,
                    amount: sum,
                    tradeDay: tradeDay.toDate().toISOString(),
                    currency: foundSecurity ? selectedCurrency : tradeCurrency,
                    dataForSign: '',
                } as TPutOrderBondsRequest;
                setParamsBo(newOrder)
            } else {
                return;
            }
            const { dataForSign, errorMessage, amount, profit } = globalTypeId === EGlobalSecurityType.equity ?
                await apiConnector.getDataForSignEquities(newOrder as TPutOrderEquitiesRequest) :
                await apiConnector.getDataForSignBonds(newOrder as TPutOrderBondsRequest);
            return { dataForSign: dataForSign, errorMessage: errorMessage, amount: amount, profit: profit }
        }
    }

    useEffect(() => {
        setNewOrders();
    }, [price, quantity, sum, amount, commission]);

    const formOrder = async () => {
        if ((isNaN(price) || price <= 0) && orderType !== 2) {
            notification.error({
                duration: timeForErrorNotification,
                message: "Цена должна быть больше 0!"
            })
        } else {
            if (targetSecurity && account) {
                const dataForSign = await setNewOrders().then(res => res?.dataForSign)
                const errorMessage = await setNewOrders().then(res => res?.errorMessage)
                const amount = await setNewOrders().then(res => res?.amount)
                const profit = await setNewOrders().then((res: any) => res?.profit)
                if (dataForSign && !errorMessage) {
                    setOrderForm(dataForSign);
                    if (targetSecurity.globalTypeId === EGlobalSecurityType.bond) {
                        setAmount(amount);
                        setSum(sum);
                        setProfit(profit);
                    }
                    setStep("sign");
                } else {
                    setError(errorMessage)
                    notification.error({
                        duration: timeForErrorNotification,
                        message: "Ошибка!",
                        description: errorMessage
                    })
                }
            }
        }
    }
    const getDoc = async () => {
        const p = await apiConnector.getPDF({
            id: "",
            xmlData: orderForm!,
            orderId: "_____",
            orderDate: tradeDay.toDate().toISOString(),
            orderNumber: "_____",
            orderType: EPDFOrderType.equities
        });
        downloadPDF(p, 0);
    }
    const downloadPDF = (p: ArrayBuffer, clientOrderId: number) => {
        const file = new File([p], `${clientOrderId}.pdf`, { type: 'application/pdf' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${clientOrderId}.pdf`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
    }
    const signOrder = async () => {
        // dispatch(closeOrder())
        setSending(true);
        let method: string;
        let args: {};

        if (NcaLayerConnector.getBundleVersion()) {
            method = "signXmlV2"
            args = { xml: orderForm, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password }
        } else {
            method = "signXml"
            args = { xml: orderForm }
        }

        const message = new NcaMessage(method, args);
        if (certificate !== '' || password !== '') {
            try {
                // const signature: string = "test_signature"; //await NcaLayerConnector.callNcaMethod(message);
                const signature: string = await NcaLayerConnector.callNcaMethod(message);
                let errorMessage: string | null = null;
                if (signature && orderForm) {
                    // const signedParams = {...params};
                    // params.set("dataForSign", orderForm);
                    // params.set("signature", signature);
                    if (isBond && paramsBo && amount && profit) {
                        const params = { ...paramsBo, amount, profit, dataForSign: signature };
                        setParamsBo(params);
                        const orderType = EPutOrderType.bonds;
                        const bondOrderData = { orderData: params, orderType: orderType };

                        const reqType = ECheckReqType.bondsReq;
                        const ot = undefined;
                        const response = await apiConnector.checkRequest(bondOrderData, reqType, ot, switchValue);
                        // const response = await apiConnector.putOrderBonds(bondOrderData, switchValue);
                        if (response.success === false) {
                            errorMessage = response.details
                        }
                    } else if (!isBond && paramsEq) {
                        const amountOrder = await setNewOrders().then(res => res?.amount)
                        const params = { ...paramsEq, amount: amountOrder, dataForSign: signature, ticker: targetSecurity?.ticker };
                        setParamsEq(params)
                        const orderType = EPutOrderType.equities;
                        const equityOrderData = { orderData: params, orderType: orderType };
                        
                        const reqType = ECheckReqType.eqReq;
                        const ot = undefined;
                        const response = await apiConnector.checkRequest(equityOrderData, reqType, ot, switchValue);
                        // const response = await apiConnector.putOrderEquities(equityOrderData, switchValue);
                        if (response.success === false) {
                            errorMessage = response.details
                        }
                    }
                    setSending(false);
                    if (errorMessage) {
                        setError(errorMessage);
                        notification.error({
                            duration: timeForErrorNotification,
                            message: "Ошибка!",
                            description: errorMessage
                        })
                    } else {
                        dispatch(getOrders());
                        dispatch(closeOrder());
                        notification.success({
                            duration: timeForSuccessNotification,
                            message: "Заказ отправлен!",
                            // description: errorMessage
                        });
                    }
                } else {
                    setError('Не удалось подписать заказ');
                    notification.error({
                        duration: timeForErrorNotification,
                        message: "Ошибка!",
                        description: "Не удалось подписать заказ"
                    });
                }
            } catch (e) {
                console.log(e);
                setSignError('error');
                setError('Не удалось отправить заказ');
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: "Не удалось отправить заказ"
                });
            } finally {
                setSending(false);
            }
        } else {
            setError('Нужно указать путь до ключа и ввести пароль');
            notification.error({
                duration: timeForErrorNotification,
                message: "Ошибка!",
                description: "Нужно указать путь до ключа и ввести пароль"
            });
        }


    }
    const pendOrder = async () => {
        setSending(true);
        if (orderForm) {
            setSending(true);
            let orderData: Partial<TPutOrderBondsRequest | TPutOrderEquitiesRequest> = isBond ? { ...paramsBo, amount, profit } : { ...paramsEq, amount: sum };
            const orderTemplate: TSetOrderTemplate = {
                orderKind: EOrderKind.trade,
                orderType: ETradeOrderType.trade,
                orderData,
                orderXml: orderForm,
                author: userName || "н/д",
                authorIIN: iin || "н/д",
                extraData: { isBond }
            };
            try {
                await apiConnector.checkTemplate(orderTemplate);
                dispatch(closeOrder());
            } catch (e) {
                console.log(e);
                setError('Не удалось отправить заказ');
                notification.error({
                    duration: timeForErrorNotification,
                    message: "Ошибка!",
                    description: "Не удалось отправить заказ"
                });
            } finally {
                setSending(false);
            }
        }
    }
    const handleAccount = (val: string) => {
        const acc = getAccList().find(a => a.imContractId === parseInt(val))
        checkingBalance(acc?.holdPlace);
        acc && setAccount(acc);
    }
    const getAccList = (): TSubAccount[] => {
        return isLocal ? subAccountsLocal || [] : subAccountsInt || [];
    }

    const checkingBalance = (holdPlace: string | undefined) => {
        const accMoney = accounts.find((a: any) => a.holdPlace === holdPlace)
        if (accMoney && amount) {
            if (accMoney?.freeAmount < amount) setCheckBalance(false)
            else setCheckBalance(true)
        }
    }

    const [password, setPassword] = useState<string>('');
    const [certificate, setCertificate] = useState<string>("");
    const [signError, setSignError] = useState<string | null>(null);

    return (
        <Modal
            title={getTitle()}
            visible={showOrderModal}
            onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={420}
            bodyStyle={{ paddingTop: 12 }}
            className="edited-form"
            zIndex={5}
        >
            <Row className="bordered-row ticker-currency">
                <Col span={12}>{targetSecurity && targetSecurity.ticker}</Col>
                <Col span={12}>{foundSecurity ? selectedCurrency : targetSecurity?.tradeCurrency}</Col>
                {/*<Col span={12}>{targetSecurity && targetSecurity.tradeCurrency}</Col>*/}
            </Row>
            <Row className="bordered-row">
                <Col span={24}>
                    <span>{targetSecurity && `${targetSecurity.isin} - ${targetSecurity.globalType}`}</span><br />
                    <small>{targetSecurity && targetSecurity.securityName}</small>
                </Col>
            </Row>
            <Row className="bordered-row quotes-row">
                <div className="quotes-row-col">
                    <label>last</label>
                    <span><Quote secKey={secKey} type={EQuoteType.last} bonds={isBond} /></span>
                </div>
                <div className="quotes-row-col">
                    <label>bid</label>
                    <span><Quote secKey={secKey} type={EQuoteType.bid} bonds={isBond} /></span>
                </div>
                <div className="quotes-row-col">
                    <label>ask</label>
                    <span><Quote secKey={secKey} type={EQuoteType.ask} bonds={isBond} /></span>
                </div>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Тип:
                </Col>
                <Col span={16}>
                    <Radio.Group
                        className="radio-wide deal-type"
                        options={options}
                        value={orderType}
                        optionType="button"
                        buttonStyle="solid"
                        size="small"
                        onChange={e => setOrderType(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Количество:
                </Col>
                <Col span={16}>
                    {targetSecurity?.globalTypeId === EGlobalSecurityType.bond &&
                        <span className="left-hint">По номиналу</span>}
                    <InputNumber
                        value={quantity}
                        min={1}
                        onChange={(q) => setQuantity(q || 0)}
                        size="small" className="of-input deal-quantity"
                        formatter={v => countNum(v as number)}
                        parser={v => parseNum(v as string, 0)}
                    />
                </Col>
            </Row>

            {
                foundSecurity ? <>
                    <Row className="of-row">
                        <Col span={8}>
                            Валюта:
                        </Col>
                        <Col span={16}>
                            <Select className="of-select deal-account" size="small" defaultValue="KZT" onChange={handleCurrencyChange}>
                                <Select.Option value="USD">USD</Select.Option>
                                <Select.Option value="KZT">KZT</Select.Option>
                            </Select>
                        </Col>
                    </Row>
                </> : null
            }

            {
                orderType === OrderType.limited &&
                <Row className="of-row">
                    <Col span={8}>
                        Цена:
                    </Col>
                    <Col span={16}>
                        <InputNumber
                            value={price}
                            onChange={p => setPrice(p || 0)}
                            size="small" className="of-input deal-price"
                            formatter={v => formatPrice(v!, isBond ? 4 : 2)}
                            parser={v => parsePrice(v!)}
                        // parser={v => parseNum(v as string, 2)}
                        />
                    </Col>
                </Row>
            }
            {
                isBond &&
                <Row className="of-row">
                    <Col span={8}>
                        Доходность:
                    </Col>
                    <Col span={16}>
                        <InputNumber
                            size="small" className="of-input deal-yield"
                            value={profit}
                            disabled
                        />
                    </Col>
                </Row>
            }
            <Row className="of-row">
                <Col span={8}>
                    Сумма сделки:
                </Col>
                <Col span={16}>
                    <InputNumber
                        size="small" className="of-input deal-amount"
                        // value={sum + commission}
                        value={sum}
                        formatter={v => finNum(v as number)}
                        parser={v => parseNum((v as string), 2)}
                        disabled
                    />
                </Col>
            </Row>
            {
                disclaimer === '' ? null :
                    <Row className="of-row">
                        <Col span={24} style={{ textAlign: 'center' }}>
                            {disclaimer}
                        </Col>
                    </Row>
            }

            <Row className="of-row">
                <Col span={8}>
                    Срок действия:
                </Col>
                <Col span={16}>
                    <DatePicker
                        size="small" className="of-input deal-day"
                        disabledDate={current => current && current < moment().subtract(1, 'day')}
                        defaultValue={moment()}
                        onChange={val => val && setTradeDay(val)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>Выберите счёт:</Col>
                <Col span={16}>
                    <Select className="of-select deal-account" size="small"
                        value={account.imContractId.toString(10) || ''}
                        defaultValue={account.imContractId.toString(10) || ''}
                        disabled={orderDirection === DealDirection.sell}
                        onChange={handleAccount}>
                        {availableSubAccounts.map((acc, i) => {
                            return <Select.Option className="of-option" key={`${i}`} value={acc.imContractId.toString(10)}>
                                {shortName(acc)}
                            </Select.Option>
                        })}
                    </Select>
                </Col>
            </Row>
            {!checkBalance && <Paragraph type="danger" style={{ textAlign: "center", marginTop: "15px" }}>
                Уважаемый клиент, у Вас недостаточно средств для заключения данной сделки. Просим пополнить расчетный счет Euroclear и попробовать снова.
            </Paragraph>}
            <Divider />
            {
                orderForm &&
                <Row>
                    <Button target="_blank" onClick={getDoc} block type="link" disabled={isEuroclear}
                        icon={<FilePdfFilled color="var(--fhs-red)" />}>Открыть заказ</Button>
                </Row>
            }
            <Divider />
            <Row>
                {step === "form" && <Button onClick={formOrder} type="primary" block disabled={isEuroclear}>Оформить заказ</Button>}
                {step === "sign" &&
                    <>
                        {isCompany() && !isHeadOfCompany() && <Button loading={sending} onClick={pendOrder} type="primary" block disabled={sending}>Отправить на
                            согласование</Button>}
                    </>}
            </Row>
            <ModalDS
                signError={signError}
                step={step}
                password={password}
                certificate={certificate}
                setCertificate={setCertificate}
                setPassword={setPassword}
                signOrder={signOrder}
                isDisable={isEuroclear}
                sending={sending}
                OT={false}
                isActual={undefined}
                loading={false}
                onConfirm={undefined}
                template={undefined}
                isSigners={false}
            />
        </Modal>
    )
}
