import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import { TState, dispatch } from '../../store';
import { showBondsSide, showEquitiesSide } from '../../store/securities.slice';
import { TMarketSecurity } from '../../types/api';

export const EqBondInfoBtn: FC<{ marketSecurity: TMarketSecurity, securityType: string, disabledBtn: boolean }> = ({ marketSecurity, securityType, disabledBtn }) => {
    const { equitiesLib, bondsLib } = useSelector((state: TState) => state.securities)

    const showHandler = () => {
        if (securityType === "equities") {
            const security = [...equitiesLib, ...bondsLib].find(s => (
                s.securityId === marketSecurity.securityId && s.intBool === marketSecurity.intBool && s.tradeCurrency === marketSecurity.tradeCurrency
            ));
            if (security) {
                dispatch(showEquitiesSide(marketSecurity));
            }
        }
        if (securityType === "bonds") {
            dispatch(showBondsSide(marketSecurity));
        }
    };
    return (
        <Button disabled={!disabledBtn} size="small" type="primary" style={{ color: 'white' }} onClick={showHandler}>
            <BarChartOutlined />
        </Button>
    )
};