import { ColumnsType } from 'antd/lib/table';
import moment from "moment";
import { TDigitalOrderRecord } from '../../types/orders';

export const columns: ColumnsType<TDigitalOrderRecord & { action: any }> = [
    {
        title: "Операция",
        dataIndex: ["orderSysData", "orderKind"],
        width: 420,
        fixed: "left",
        // render: (text, record) => (record.rejectOrderId ? <span style={{color: 'red'}}>Отмена {record.onum || "ID"+record.orderId}</span> : text)
    },
    {
        title: "Дата заказа/приказа",
        dataIndex: "orderDate",
        width: 220,
        fixed: "left",
        render: text => new Date(text).toLocaleDateString("ru-RU")
    },
    {
        title: "Номер заказа/приказа",
        dataIndex: "orderNumber",
        fixed: "left",
        width: 220,
        render: (text, record) => text ? `№ ${text}` : `ID ${record.orderId || record.orderId}`,
        // sorter: (a, b) => a.clientOrderId - b.clientOrderId
    },
    {
        title: "Статус",
        dataIndex: ["orderSysData", "orderStatus"],
        align: "right",
    },
    {
        title: "Дата статуса",
        align: "right",
        dataIndex: ["orderSysData", "orderStatusDate"],
        render: (text, record) => moment(text || record.orderSysData.orderDate).toDate().toLocaleString("ru-RU"),
        // sorter: (a, b) => new Date(a.orderSysData.orderStatusDate).getTime() - new Date(b.orderSysData.orderStatusDate).getTime()
    },
    {
        title: "",
        dataIndex: "action",
        // width: 120,
        fixed: "right",
        align: "right",
        className: "action-column multi"
    },

    // {
    //     title: "",
    //     dataIndex: "file",
    //     fixed: "right",
    //     className: "action-column"
    // }
]
