import { ColumnsType } from 'antd/lib/table';
import { TAnalyticsDataExtended } from "../../types/analyticsExtended";
import { countNum, finNum } from "../../services/formatter";

export const columns: ColumnsType<TAnalyticsDataExtended & { action: any }> = [
    {
        title: "Наименование инструмента",
        dataIndex: "Ft_Name",
        fixed: "left",
        align: "left",
        width: 310,
        sorter: (a, b) => a.Ft_Name.localeCompare(b.Ft_Name)
    },
    {
        title: "Тип инструмента",
        dataIndex: "Ft_Type",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Ft_Type.localeCompare(b.Ft_Type)
    },
    {
        title: "Тикер ЦБ",
        dataIndex: "Sec_Name",
        align: "left",
        width: 120,
        sorter: (a, b) => a.Sec_Name && b.Sec_Name ? a.Sec_Name.localeCompare(b.Sec_Name) : 1
    },
    {
        title: "ISIN",
        dataIndex: "isin",
        align: "left",
        width: 120,
        sorter: (a, b) => a.Isin && b.Isin ? a.Isin.localeCompare(b.Isin) : 1
    },
    {
        title: "Тип цб",
        dataIndex: "Sec_Type",
        align: "left",
        width: 120,
        sorter: (a, b) => a.Sec_Type && b.Sec_Type ? a.Sec_Type.localeCompare(b.Sec_Type) : 1
    },
    {
        title: "Эмитент",
        dataIndex: "Issuer",
        align: "left",
        width: 220,
        sorter: (a, b) => a.Issuer.localeCompare(b.Issuer)

    },
    {
        title: "Рейтинг Moody's",
        dataIndex: "Rating_M",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Rating_M && b.Rating_M ? a.Rating_M.localeCompare(b.Rating_M) : 1

    },
    {
        title: "Рейтинг Fitch",
        dataIndex: "Rating_F",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Rating_F && b.Rating_F ? a.Rating_F.localeCompare(b.Rating_F) : 1

    },
    {
        title: "Рейтинг S&P",
        dataIndex: "Rating_S",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Rating_S && b.Rating_S ? a.Rating_S.localeCompare(b.Rating_S) : 1
    },
    {
        title: "Место хранения",
        dataIndex: "Hold_Place",
        align: "left",
        width: 220,
        sorter: (a, b) => a.Hold_Place.localeCompare(b.Hold_Place)
    },
    {
        title: "Номинал",
        dataIndex: "Face_Value",
        align: "left",
        width: 120,
        sorter: (a, b) => a.Face_Value && b.Face_Value ? a.Face_Value.localeCompare(b.Face_Value) : 1
    },
    {
        title: "Валюта инструмента",
        dataIndex: "Currency",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Currency.localeCompare(b.Currency)
    },
    {
        title: "Дата сделки",
        dataIndex: "Deal_Date",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Deal_Date && b.Deal_Date ? new Date(a.Deal_Date).valueOf() - new Date(b.Deal_Date).valueOf() : 1,
        render: record => record ? new Date(record).toLocaleDateString("ru-RU") : "-"
    },

    {
        title: "Дата погашения",
        dataIndex: "Maturity_Date",
        align: "left",
        width: 160,
        sorter: (a, b) => a.Maturity_Date && b.Maturity_Date ? new Date(a.Maturity_Date).valueOf() - new Date(b.Maturity_Date).valueOf() : 1,
        render: record => record ? new Date(record).toLocaleDateString("ru-RU") : "-"
    },
    {
        title: "Сумма по номиналу",
        dataIndex: "Face_Value_Amount",
        render: finNum,
        align: "right",
        width: 160,
        sorter: (a, b) => a.Face_Value_Amount && b.Face_Value_Amount ? a.Face_Value_Amount - b.Face_Value_Amount : 1
    },
    {
        title: "Цена приобретения",
        dataIndex: "Deal_Price",
        render: finNum,
        align: "right",
        width: 160,
        sorter: (a, b) => a.Deal_Price && b.Deal_Price ? a.Deal_Price - b.Deal_Price : 1
    },
    {
        title: "Количество сделки",
        dataIndex: "Deal_Quantity",
        render: countNum,
        align: "right",
        width: 160,
        sorter: (a, b) => a.Deal_Quantity && b.Deal_Quantity ? a.Deal_Quantity - b.Deal_Quantity : 1
    },
    {
        title: "YTM сделки",
        dataIndex: "Deal_YTM",
        align: "right",
        width: 160,
        sorter: (a, b) => a.Deal_YTM && b.Deal_YTM ? a.Deal_YTM - b.Deal_YTM : 1
    },
    {
        title: "Доходность по РЕПО",
        dataIndex: "Repo_Pty",
        render: finNum,
        align: "right",
        width: 160,
        sorter: (a, b) => a.Repo_Pty && b.Repo_Pty ? a.Repo_Pty - b.Repo_Pty : 1
    },
    {
        title: "Курс валюты на дату",
        dataIndex: "Exchange_Rate",
        align: "right",
        width: 160,
        sorter: (a, b) => a.Exchange_Rate - b.Exchange_Rate
    },
    {
        title: "Курс USD на дату",
        dataIndex: "Exchange_Rate_USD",
        align: "right",
        width: 160,
        sorter: (a, b) => a.Exchange_Rate_USD - b.Exchange_Rate_USD
    },
    {
        title: "Ст-ть инструмента в валюте на дату",
        dataIndex: "Amount",
        render: finNum,
        align: "right",
        width: 220,
        sorter: (a, b) => a.Amount - b.Amount
    },
    {
        title: "Ст-ть инструмента в тенге на дату",
        dataIndex: "Amount_KZT",
        render: finNum,
        align: "right",
        width: 220,
        sorter: (a, b) => a.Amount_KZT - b.Amount_KZT
    },
    {
        title: "Остаточное количество на дату",
        dataIndex: "Quantity",
        align: "right",
        render: countNum,
        width: 220,
        sorter: (a, b) => a.Quantity && b.Quantity ? a.Quantity - b.Quantity : 1
    },
    {
        title: "Категория учета",
        dataIndex: "Deal_Position",
        align: "right",
        render: countNum,
        width: 160,
        sorter: (a: any, b: any) => a.Deal_Position && b.Deal_Position ? a.Deal_Position - b.Deal_Position : 1
    },
    {
        title: "Страна выпуска",
        dataIndex: "Sec_Country",
        align: "right",
        render: countNum,
        width: 160,
        sorter: (a: any, b: any) => a.Sec_Country && b.Sec_Country ? a.Sec_Country - b.Sec_Country : 1
    },
    {
        title: "Страна эмитента",
        dataIndex: "Issuer_Country",
        align: "right",
        render: countNum,
        width: 160,
        sorter: (a: any, b: any) => a.Issuer_Country && b.Issuer_Country ? a.Issuer_Country - b.Issuer_Country : 1
    },
    {
        title: "Отрасль",
        dataIndex: "Industry",
        align: "right",
        render: countNum,
        width: 150,
        sorter: (a: any, b: any) => a.Industry && b.Industry ? a.Industry - b.Industry : 1
    },
    {
        title: "Сектор",
        dataIndex: "Sector",
        align: "right",
        render: countNum,
        width: 150,
        sorter: (a: any, b: any) => a.Sector && b.Sector ? a.Sector - b.Sector : 1
    },
    {
        title: "Ставка купона",
        dataIndex: "rate",
        align: "right",
        render: countNum,
        width: 160,
        sorter: (a: any, b: any) => a.rate && b.rate ? a.rate - b.rate : 1
    },
    {
        title: "Дата последней купонной выплаты",
        dataIndex: "Prev_Pay_Date",
        align: "right",
        render: record => record ? new Date(record).toLocaleDateString("ru-RU") : "-",
        width: 220,
        sorter: (a: any, b: any) => a.Prev_Pay_Date && b.Prev_Pay_Date ? a.Prev_Pay_Date - b.Prev_Pay_Date : 1
    },
    {
        title: "Дата след. купонной выплаты",
        dataIndex: "Next_Pay_Date",
        align: "right",
        render: record => record ? new Date(record).toLocaleDateString("ru-RU") : "-",
        width: 220,
        sorter: (a: any, b: any) => a.Next_Pay_Date && b.Next_Pay_Date ? a.Next_Pay_Date - b.Next_Pay_Date : 1
    },
    {
        title: "Купонный период",
        dataIndex: "Calc_Period",
        align: "right",
        render: countNum,
        width: 220,
        sorter: (a: any, b: any) => a.Calc_Period && b.Calc_Period ? a.Calc_Period - b.Calc_Period : 1
    },
    {
        title: "Сумма ожидаемого купона в валюте номинала",
        dataIndex: "Coup_In_Period",
        align: "right",
        render: countNum,
        width: 220,
        sorter: (a: any, b: any) => a.Coup_In_Period && b.Coup_In_Period ? a.Coup_In_Period - b.Coup_In_Period : 1
    },
    {
        title: "Объем покупки",
        dataIndex: "Deal_Amount",
        align: "right",
        render: countNum,
        width: 180,
        sorter: (a: any, b: any) => a.Deal_Amount && b.Deal_Amount ? a.Deal_Amount - b.Deal_Amount : 1
    },
    {
        title: "Объем покупки в валюте бумаги",
        dataIndex: "Deal_Amount_Face",
        align: "right",
        render: countNum,
        width: 220,
        sorter: (a: any, b: any) => a.Deal_Amount_Face && b.Deal_Amount_Face ? a.Deal_Amount_Face - b.Deal_Amount_Face : 1
    },
    {
        title: "Дата выпуска",
        dataIndex: "Issue_Date",
        align: "right",
        render: record => record ? new Date(record).toLocaleDateString("ru-RU") : "-",
        width: 160,
        sorter: (a: any, b: any) => a.Issue_Date && b.Issue_Date ? a.Issue_Date - b.Issue_Date : 1
    },
    {
        title: "Курс валюты номинала на дату покупки",
        dataIndex: "Deal_Exchange_Rate",
        align: "right",
        render: countNum,
        width: 220,
        sorter: (a: any, b: any) => a.Deal_Exchange_Rate && b.Deal_Exchange_Rate ? a.Deal_Exchange_Rate - b.Deal_Exchange_Rate : 1
    },
    {
        title: "Курс USD на дату покупки",
        dataIndex: "DeaL_Exchange_Rate_USD",
        align: "right",
        render: countNum,
        width: 220,
        sorter: (a: any, b: any) => a.DeaL_Exchange_Rate_USD && b.DeaL_Exchange_Rate_USD ? a.DeaL_Exchange_Rate_USD - b.DeaL_Exchange_Rate_USD : 1
    },
    {
        title: "Накопленный купон в валюте бумаги",
        dataIndex: "Accum_Coup",
        align: "right",
        render: countNum,
        width: 220,
        sorter: (a: any, b: any) => a.Accum_Coup && b.Accum_Coup ? a.Accum_Coup - b.Accum_Coup : 1
    },
    {
        title: "Чистая рыночная цена",
        dataIndex: "Cur_Net_Price",
        align: "right",
        render: countNum,
        width: 220,
        sorter: (a: any, b: any) => a.Cur_Net_Price && b.Cur_Net_Price ? a.Cur_Net_Price - b.Cur_Net_Price : 1
    },
    {
        title: "Полученное вознаграждение с момента приобретения",
        dataIndex: "Done_Interest",
        align: "right",
        render: countNum,
        width: 260,
        sorter: (a: any, b: any) => a.Done_Interest && b.Done_Interest ? a.Done_Interest - b.Done_Interest : 1
    },
]
