import React, { FC, useEffect, useState } from "react";
import { Col, DatePicker, Row } from "antd";
import moment from "moment";


export enum EIntOrderType {
    repo,
    securitiesBuySell,
    moneyTransferInt,
    moneyTransferExt,
    moneyConversion,
    securitiesPledgeEncumbrance,
    securitiesConversion,
    cancellation,
    securitiesTransfer,
    deposit,
    derivatives

}
export type TInvestDocument = {
    id: number,
    title: string,
    type: EIntOrderType
}
export type TIntOrderDescription = {
    investDoc?: string,
    portfolioType?: EPortfolioType,
    orderNum?: string,
    orderDate?: Date,
    orderType: EIntOrderType
    start: string,
    end: string
}

export const getIntOrderTypeName = (type: EIntOrderType): string => {
    switch (type) {
        case EIntOrderType.repo: return "РЕПО"
        case EIntOrderType.securitiesBuySell: return "Сделки с ЦБ"
        case EIntOrderType.moneyTransferInt: return "Перевод денежных средств (внутренний)"
        case EIntOrderType.moneyTransferExt: return "Перевод денежных средств (внешний)"
        case EIntOrderType.moneyConversion: return "Конвертация денежных средств"
        case EIntOrderType.securitiesPledgeEncumbrance: return "Залог/Обременение ЦБ"
        case EIntOrderType.securitiesConversion: return "Конвертация ЦБ"
        case EIntOrderType.cancellation: return "Отмена"
        case EIntOrderType.securitiesTransfer: return "Перевод ЦБ"
        case EIntOrderType.deposit: return "Размещение вклада"
        case EIntOrderType.derivatives: return "Сделки с произвожными ЦБ"
    }
}
export enum EPortfolioType {
    standard = "Стандартный",
    special = "Специальный"
}

export const InternalOrderDetails: FC<{ type: EIntOrderType, onChange: (data: TIntOrderDescription) => void, onClose: () => void }> = ({ type, onChange, onClose }) => {
    const [dates, setDates] = useState<{ orderDate: Date, start: string, end: string }>({
        orderDate: new Date(),
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD')
    })
    const [valid, setValid] = useState<{ "orderNum": boolean }>({ "orderNum": true })
    useEffect(() => {
        if (dates) {
            onChange({ orderType: type, ...dates })
        }
    }, [dates]);
    const setDataAndClose = () => {
        if (dates) {
            onChange({ orderType: type, ...dates })
            onClose()
        } else {
            let statuses = { ...valid };
            setValid({ ...statuses })
        }
    }
    return (
        <>
            <Row>
                <Col span={9}>Вид распоряжения:</Col>
                <Col span={15}>{getIntOrderTypeName(type)}</Col>
            </Row>
            <Row className="of-row">
                <Col span={9}>Период действия:</Col>
                <Col span={15}>
                    <DatePicker.RangePicker
                        size="small"
                        format="DD.MM.YYYY"
                        className="of-input"
                        disabledDate={current => current && current < moment(dates.orderDate)}
                        defaultValue={[moment(), moment()]}
                        onChange={range => {
                            if (range) {
                                const [start, end] = range;
                                if (start && end) {
                                    setDates({ ...dates, start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD') })
                                }
                            }
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}