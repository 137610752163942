import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Divider, Input, InputNumber, Modal, notification, Row, Select, Tooltip, Typography } from "antd";
import { CloseOutlined } from '@ant-design/icons'
import { dispatch, TState } from "../../../store";
import { closeMoneyTransferOrder, getAllBankCorrData, getAllRecipientData, getOrders } from "../../../store/orders.slice";
import { ModalDS } from "../../shared/modals/ModalDS";
import { apiConnector } from "../../../integrations/api.connector";
import { NcaLayerConnector } from "../../../integrations/ncaLayer.connector";
import { getAccTitle, getCurrencies } from "./helpers";
import { isCompany, isHeadOfCompany } from "../../../services/helpers";
import { countNum, parseNum } from "../../../services/formatter";
import { buildXML } from "../../../services/xmlBuilder";
import { uuid } from "../../../services/uuid";
import { TClientData, TPDFHeading, TProfile } from "../../../types/api";
import { ECurrency, jsonType } from "../../../types/common";
import { NcaMessage } from "../../../types/nca";
import {
    ECheckReqType,
    EMoneyTransferType,
    ENonTradeOrderType,
    EOrderKind,
    TMoneyTransferBankCorrData,
    TMoneyTransferRecipientData,
    TMoneyTransferRequest,
    TSetOrderTemplate
} from "../../../types/orders";
import jsonCountries from "../../../isoCountryCodes.json";
import { timeForErrorNotification, timeForSuccessNotification, timeForWarningNotification } from "../../../constants";
import "./../editedForm.css";
import "./ExternalMoneyTransferForm.css"

type StateValid = boolean | undefined;
const { Paragraph } = Typography;

export const ExternalMoneyTransferForm = () => {
    const {
        auth: {
            clientData,
            cProfile,
            userName,
            iin,
            personInfo,
            isPerson
        },
        orders: {
            showMoneyTransferOrderModal,
            recipientData,
            bankCorrData,
        },
        money: {
            moneyRem,
        }
    } = useSelector((state: TState) => state);
    const switchValue = useSelector((state: TState) => state.auth.switch);
    const [step, setStep] = useState<"form" | "sign" | "error">('form');
    const [sending, setSending] = useState<boolean>(false)
    const [amount, setAmount] = useState<number>()
    const [currency, setCurrency] = useState<ECurrency | string>();
    const [fromAcc, setFromAcc] = useState<number>();
    const [bin, setBin] = useState<string>();
    const [name, setName] = useState<string>();
    const [iik, setIik] = useState<string>();
    const [bank, setBank] = useState<string>();
    const [swift, setSwift] = useState<string>();
    const [kbe, setKbe] = useState<number>(parseInt(cProfile?.cbe || '11'));
    const [iskbe, setIskbe] = useState<boolean>(false);
    const [personData, setPersonData] = useState<TProfile | null>(personInfo)
    const [clientInfo, setClientInfo] = useState<TClientData | null>(clientData);
    const [local, setLocal] = useState(true);
    const [corrAcc, setCorrAcc] = useState<string>();
    const [corrName, setCorrName] = useState<string>();
    const [corrSWIFT, setCorrSWIFT] = useState<string>();
    const [orderData, setOrderData] = useState<TMoneyTransferRequest>();
    const [recipientDataState, setRecipientDataState] = useState<TMoneyTransferRecipientData>();
    const [bankCorrDataState, setBankCorrDataState] = useState<TMoneyTransferBankCorrData>();
    const [savingRecipient, setSavingRecipient] = useState<boolean>(false)
    const [savingBankCorr, setSavingBankCorr] = useState<boolean>(false)
    const [recipientDataName, setRecipientDataName] = useState<string>('')
    const [bankCorrDataName, setBankCorrDataName] = useState<string>('')
    const [isRecipientDataChanged, setIsRecipientDataChanged] = useState<boolean>(false)
    const [isBankCorrDataChanged, setIsBankCorrDataChanged] = useState<boolean>(false)

    const [deletingRecipient, setDeletingRecipient] = useState<boolean>(false)
    const [deletingBankCorr, setDeletingBankCorr] = useState<boolean>(false)
    const [deletingRecipientId, setDeletingRecipientId] = useState<string>('')
    const [deletingBankCorrId, setDeletingBankCorrId] = useState<string>('')

    const [isValidSwift, setIsValidSwift] = useState<StateValid>(true);
    const [isValidSwiftCorr, setIsValidSwiftCorr] = useState<StateValid>(true);
    const [swiftCountry, setSwiftCountry] = useState("");
    const [swiftCorrCountry, setSwiftCorrCountry] = useState("");

    useEffect(() => {
        setName(isPerson ? `${personData?.lastName} ${personData?.firstName} ${personData?.middleName}` : clientInfo?.cliName);
        setBin(isPerson ? personData?.iin : clientInfo?.biin);
    }, [clientInfo, isPerson, personData]);

    useEffect(() => {
        kbe === 11 ? setIskbe(false) : setIskbe(true)
    }, [kbe]);

    const getCountryCode = (jsonCountries: jsonType, key: string, e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "swift") {
            if (jsonCountries[key]) {
                const countryName = jsonCountries[key];
                setSwiftCountry(countryName);
                return true;
            } else return false;
        }
        else if (e.target.name === "corrSwift") {
            if (jsonCountries[key]) {
                const countryName = jsonCountries[key];
                setSwiftCorrCountry(countryName);
                return true;
            } else return false;
        }
    };

    const validatorCountry = (swift: string, e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "swift") {
            const swiftRegex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
            const fifthCodeIn = swift?.charAt(4);
            const sixthCodeIn = swift?.charAt(5);
            const combinedString = `${fifthCodeIn}${sixthCodeIn}`;
            const isValid = swiftRegex.test(swift);
            if (isValid) {
                return getCountryCode(jsonCountries, combinedString, e);
                // const isValidCountry = getCountryCode(jsonCountries, combinedString, e);
                // return isValidCountry;
            }
            return isValid;
        }
        else if (e.target.name === "corrSwift") {
            const swiftRegex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
            const fifthCodeIn = swift?.charAt(4);
            const sixthCodeIn = swift?.charAt(5);
            const combinedString = `${fifthCodeIn}${sixthCodeIn}`;
            const isValid = swiftRegex.test(swift);
            if (isValid) {
                return getCountryCode(jsonCountries, combinedString, e);
                // const isValidCountry = getCountryCode(jsonCountries, combinedString, e);
                // return isValidCountry;
            }
            return isValid;
        }
    }

    const swiftChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "swift") {
            const value = e.target.value.toUpperCase();
            setSwift(value);
            setIsValidSwift(validatorCountry(value, e));
        }
        else if (e.target.name === "corrSwift") {
            const value = e.target.value.toUpperCase();
            setCorrSWIFT(value);
            setIsValidSwiftCorr(validatorCountry(value, e))
        }
    }

    const [xml, setXml] = useState<string>()
    const handleOk = () => {
        // setStep("form")
        dispatch(closeMoneyTransferOrder())
    }
    const handleCancel = () => {
        // setStep("form")
        dispatch(closeMoneyTransferOrder())
    }
    const handleCloseSavingRecipientData = () => {
        // setStep("form")
        setSavingRecipient(false)
    }
    const handleCloseSavingBankCorrData = () => {
        // setStep("form")
        setSavingBankCorr(false)
    }
    const formOrder = () => {
        let valid: boolean = !!amount && !!currency && !!fromAcc && !!bin && !!iik && !!swift && !!bank;
        if (!local) {
            valid = valid && !!corrAcc && !!corrName && !!corrSWIFT;
        }
        if (valid && clientData) {
            const transferTypeID = EMoneyTransferType.external;
            const date = new Date().toISOString();
            let newOrder: TMoneyTransferRequest = {
                date,
                notCheckRem: 0,
                amount: amount!,
                transferTypeID,
                ftOutID: fromAcc,
                bankName: bank,
                bankSWIFT: swift,
                IIC: iik,
                recipientName: name,
                recipientBIIN: bin,
                KNP: 690,
                KBE: kbe
            };
            if (!local) {
                newOrder = {
                    ...newOrder,
                    corrBankAccount: corrAcc,
                    corrBankName: corrName,
                    corrBankSWIFT: corrSWIFT
                }
            }
            setOrderData(newOrder);
            let heading: TPDFHeading
            if (Array.isArray(clientData)) {
                heading = {
                    orderDate: date,
                    docIssueDate: cProfile?.docIssueDate || "",
                    docIssuer: cProfile?.docIssueAuthor || "",
                    docNumber: cProfile?.docNumber || "",
                    docSeries: "",
                    docType: cProfile?.docType || "",
                    subAccount: clientData[0]?.subAccount,
                    accountNumber: clientData[0]?.imcAccount,
                    clientName: clientData[0]?.cliName,
                    contractNumber: clientData[0]?.contractNumber,
                    iinbin: clientData[0]?.biin
                };
            }

            else {
                heading = {
                    orderDate: date,
                    docIssueDate: cProfile?.docIssueDate || "",
                    docIssuer: cProfile?.docIssueAuthor || "",
                    docNumber: cProfile?.docNumber || "",
                    docSeries: "",
                    docType: cProfile?.docType || "",
                    subAccount: clientData?.subAccount,
                    accountNumber: clientData?.imcAccount,
                    clientName: clientData?.cliName,
                    contractNumber: clientData?.contractNumber,
                    iinbin: clientData?.biin
                };
            }
            const extraData = {
                currencyCode: currency as string,
                fromAccount: moneyRem.find(acc => acc.ftId === fromAcc)!.accountNumber
            }
            const xml = buildXML({ ...extraData, ...newOrder }, 'MoneyTransferExternal', heading);
            console.log(xml);
            setXml(xml);

            setStep("sign");
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "Заполните все поля",
                placement: "top"
                // description: errorMessage
            })
            return
        }

    }

    const formRecipientData = () => {
        let valid: boolean = !!currency && !!bin && !!iik && !!swift && !!bank;
        if (valid && clientData) {
            const transferTypeID = EMoneyTransferType.external;
            let recipient: TMoneyTransferRecipientData = {
                notCheckRem: 0,
                transferTypeID,
                bankName: bank,
                bankSWIFT: swift,
                IIC: iik,
                recipientName: name,
                recipientBIIN: bin,
                KNP: 690,
                KBE: kbe,
                id: uuid(),
                type: 'recipient',
                currency: currency || ''
            };
            // if(!local){
            //     recipient = {
            //         ...recipient,
            //         corrBankAccount: corrAcc,
            //         corrBankName: corrName,
            //         corrBankSWIFT: corrSWIFT,
            //     }
            // }
            setRecipientDataState(recipient);
            setSavingRecipient(true)
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "Заполните поля получателя",
                placement: "top"
            })
            return
        }
    }

    const updateRecipientData = () => {
        let valid: boolean = !!currency && !!bin && !!iik && !!swift && !!bank;
        if (valid && clientData) {
            const transferTypeID = EMoneyTransferType.external;
            let recipient: TMoneyTransferRecipientData = {
                notCheckRem: 0,
                transferTypeID,
                bankName: bank,
                bankSWIFT: swift,
                IIC: iik,
                recipientName: name,
                recipientBIIN: bin,
                KNP: 690,
                KBE: kbe,
                id: recipientDataState?.id || uuid(),
                type: 'recipient',
                currency: currency || ''
            };
            setRecipientDataState(recipient);
            const ls = JSON.parse(localStorage.getItem('recipientData') || '[]')
            const index = ls.findIndex((el: TMoneyTransferRecipientData) => el.id === recipientDataState?.id)
            ls[index].bankName = bank
            ls[index].bankSWIFT = swift
            ls[index].IIC = iik
            ls[index].recipientName = name
            ls[index].recipientBIIN = bin
            ls[index].KBE = kbe
            localStorage.setItem('recipientData', JSON.stringify(ls))
            currency && dispatch(getAllRecipientData(currency))
            alert('Update Recipient')
            // const response = await apiConnector.putMoneyTransferBankCoreData(bankCorr as TMoneyTransferBankCorrData)
            // console.log(response);
            // if(response && response.success){
            //     notification.success({
            //         message: "Запись сохранена",
            //     })
            // } else {
            //     notification.error({
            //         message: `Запись не прошла`,
            //         description: <><p>{response.message}</p><p>{response.details}</p></>
            //     })
            // }
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "Заполните поля получателя",
                placement: "top"
            })
            return
        }
    }

    const formBankCorrData = () => {
        let valid: boolean = !!currency;
        if (!local) {
            valid = valid && !!corrAcc && !!corrName && !!corrSWIFT;
        }
        if (valid && clientData) {
            let bankCorr: TMoneyTransferBankCorrData = {
                corrBankAccount: corrAcc,
                corrBankName: corrName,
                corrBankSWIFT: corrSWIFT,
                id: uuid(),
                type: 'correspondent',
                currency: currency || ''
            };
            setBankCorrDataState(bankCorr);
            setSavingBankCorr(true)
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "Заполните поля банка корреспондента",
                placement: "top"
            })
            return
        }
    }
    const updateBankCorrData = () => {
        let valid: boolean = !!currency;
        if (!local) {
            valid = valid && !!corrAcc && !!corrName && !!corrSWIFT;
        }
        if (valid && clientData) {
            let bankCorr: TMoneyTransferBankCorrData = {
                corrBankAccount: corrAcc,
                corrBankName: corrName,
                corrBankSWIFT: corrSWIFT,
                id: bankCorrDataState?.id || uuid(),
                type: 'correspondent',
                currency: currency || ''
            };
            setBankCorrDataState(bankCorr);

            const ls = JSON.parse(localStorage.getItem('bankCorrData') || '[]')
            const index = ls.findIndex((el: TMoneyTransferBankCorrData) => el.id === bankCorrDataState?.id)
            ls[index].corrBankAccount = corrAcc
            ls[index].corrBankName = corrName
            ls[index].corrBankSWIFT = corrSWIFT
            localStorage.setItem('bankCorrData', JSON.stringify(ls))

            currency && dispatch(getAllBankCorrData())
            alert('Updating Bank Corr')
            // const response = await apiConnector.putMoneyTransferBankCoreData(bankCorr as TMoneyTransferBankCorrData)
            // console.log(response);
            // if(response && response.success){
            //     notification.success({
            //         message: "Запись сохранена",
            //     })
            // } else {
            //     notification.error({
            //         message: `Запись не прошла`,
            //         description: <><p>{response.message}</p><p>{response.details}</p></>
            //     })
            // }
        } else {
            notification.warn({
                duration: timeForWarningNotification,
                message: "Заполните поля банка корреспондента",
                placement: "top"
            })
            return
        }
    }

    const saveBankCorrData = async () => {
        if (bankCorrDataName.trim() === '') return
        setSending(true)

        try {
            const bankCorr = {
                ...bankCorrDataState,
                name: bankCorrDataName
            }
            const ls = JSON.parse(localStorage.getItem('bankCorrData') || '[]')
            ls.push(bankCorr)
            localStorage.setItem('bankCorrData', JSON.stringify(ls))
            // const response = await apiConnector.putMoneyTransferBankCoreData(bankCorr as TMoneyTransferBankCorrData)
            // console.log(response);
            // if(response && response.success){
            //     notification.success({
            //         message: "Запись сохранена",
            //     })
            // } else {
            //     notification.error({
            //         message: `Запись не прошла`,
            //         description: <><p>{response.message}</p><p>{response.details}</p></>
            //     })
            // }
            setSavingBankCorr(false)
        } catch (e) {
            console.log(e);
            notification.error({
                duration: null,
                message: "Ошибка!",
                description: "Не удалось отправить заказ"
            });
        } finally {
            setSending(false);
        }
    }

    const saveRecipientData = async () => {
        if (recipientDataName.trim() === '') return
        setSending(true)

        try {
            const recipient = {
                ...recipientDataState,
                name: recipientDataName
            }
            const ls = JSON.parse(localStorage.getItem('recipientData') || '[]')
            ls.push(recipient)
            localStorage.setItem('recipientData', JSON.stringify(ls))
            // const response = await apiConnector.putMoneyTransferRecipientData(recipient as TMoneyTransferRecipientData)
            // console.log(response);
            // if(response && response.success){
            //     notification.success({
            //         message: "Запись сохранена",
            //     })
            // } else {
            //     notification.error({
            //         message: `Запись не прошла`,
            //         description: <><p>{response.message}</p><p>{response.details}</p></>
            //     })
            // }
            setSavingRecipient(false)
        } catch (e) {
            console.log(e);
            notification.error({
                duration: null,
                message: "Ошибка!",
                description: "Не удалось отправить заказ"
            });
        } finally {
            setSending(false);
        }
    }

    const signOrder = async () => {
        if (!orderData) return;
        setSending(true);
        let method: string;
        let args: {};
        if (NcaLayerConnector.getBundleVersion()) {
            method = "signXmlV2"
            args = { xml: xml, requestId: '4', path: certificate.replace(/\\/g, "/"), password: password }
        } else {
            method = "signXml"
            args = { xml: xml }
        }
        const message = new NcaMessage(method, args);

        if (certificate !== '' || password !== '') {
            try {
                const signedXml: string = await NcaLayerConnector.callNcaMethod(message);
                const reqType = ECheckReqType.moneyTransferReq;
                const response = await apiConnector.checkRequest({ orderData, signedXml, switchValue }, reqType);
                // const response = await apiConnector.putMoneyTransferOrder({
                //     orderData,
                //     signedXml,
                //     switchValue
                // })
                if (response && response.success) {
                    dispatch(getOrders());
                    notification.success({
                        duration: timeForSuccessNotification,
                        message: "Приказ отправлен",
                    });
                } else {
                    notification.error({
                        duration: null,
                        message: `Приказ отклонен`,
                        description: <><p>{response.message}</p><p>{response.details}</p></>
                    })
                }
                dispatch(closeMoneyTransferOrder());
            } catch (e) {
                setSignError('error');
                console.log(e);
            } finally {
                setSending(false);
            }
        } else {
            notification.error({
                duration: timeForErrorNotification,
                message: "Ошибка!",
                description: "Нужно указать путь до ключа и ввести пароль"
            });
        }
    }
    const pendOrder = async () => {
        setSending(true);
        if (orderData && xml) {
            const orderTemplate: TSetOrderTemplate = {
                orderKind: EOrderKind.nonTrade,
                orderType: ENonTradeOrderType.moneyTransferExternal,
                orderData,
                orderXml: xml,
                author: userName || "н/д",
                authorIIN: iin || "н/д",
                extraData: {
                    currencyCode: currency as string,
                    fromAccount: moneyRem.find(acc => acc.ftId === fromAcc)!.accountNumber
                }
            };
            try {
                await apiConnector.checkTemplate(orderTemplate);
                dispatch(closeMoneyTransferOrder());
            } catch (e) {
                console.log(e);
                notification.error({
                    duration: null,
                    message: "Ошибка!",
                    description: "Не удалось отправить заказ"
                });
            } finally {
                setSending(false);
            }
        }
    }

    const checkIfChangesInRecipient = () => {
        setIsRecipientDataChanged(recipientDataState !== undefined && (recipientDataState?.bankName !== bank || recipientDataState?.bankSWIFT !== swift || recipientDataState?.IIC !== iik || recipientDataState?.KBE !== kbe || recipientDataState.recipientName !== name || recipientDataState.recipientBIIN !== bin))
    }

    const checkIfChangesInBankCorr = () => {
        setIsBankCorrDataChanged(bankCorrDataState !== undefined && (bankCorrDataState?.corrBankAccount !== corrAcc || bankCorrDataState?.corrBankName !== corrName || bankCorrDataState?.corrBankSWIFT !== corrSWIFT))
    }
    useEffect(() => {
        checkIfChangesInBankCorr()
    }, [corrSWIFT, corrName, corrAcc])
    useEffect(() => {
        checkIfChangesInRecipient()
    }, [bank, swift, iik, kbe, name, bin])

    const selectSavedRecipientData = (value: string) => {
        const index = recipientData.findIndex(rd => rd.id === value)
        // setIsRecipientDataChanged(false)
        setRecipientDataName(recipientData[index].name || '')
        setBank(recipientData[index].bankName)
        // if (currency !== 'KZT') {
        //     setCorrSWIFT(recipientData[index].corrBankSWIFT)
        //     setCorrName(recipientData[index].corrBankName)
        //     setCorrAcc(recipientData[index].corrBankAccount)
        // }
        setSwift(recipientData[index].bankSWIFT)
        setIik(recipientData[index].IIC)
        setName(recipientData[index].recipientName)
        setBin(recipientData[index].recipientBIIN)
        setKbe(recipientData[index].KBE || 11)
        const transferTypeID = EMoneyTransferType.external;
        let recipient: TMoneyTransferRecipientData = {
            notCheckRem: 0,
            transferTypeID,
            bankName: recipientData[index].bankName,
            bankSWIFT: recipientData[index].bankSWIFT,
            IIC: recipientData[index].IIC,
            recipientName: recipientData[index].recipientName,
            recipientBIIN: recipientData[index].recipientBIIN,
            KNP: 690,
            KBE: recipientData[index].KBE || 11,
            id: recipientData[index].id,
            type: recipientData[index].type,
            currency: recipientData[index].currency
        };
        setRecipientDataState(recipient);
    }
    const selectSavedBankCorrData = (value: string) => {
        const index = bankCorrData.findIndex(rd => rd.id === value)
        // setIsRecipientDataChanged(false)
        setBankCorrDataName(bankCorrData[index].name || '')
        setCorrAcc(bankCorrData[index].corrBankAccount)
        setCorrName(bankCorrData[index].corrBankName)
        setCorrSWIFT(bankCorrData[index].corrBankSWIFT)

        let bankCorr: TMoneyTransferBankCorrData = {
            corrBankSWIFT: bankCorrData[index].corrBankSWIFT,
            corrBankName: bankCorrData[index].corrBankName,
            corrBankAccount: bankCorrData[index].corrBankAccount,
            id: bankCorrData[index].id,
            type: bankCorrData[index].type,
            currency: bankCorrData[index].currency
        };
        setBankCorrDataState(bankCorr);
    }
    const startDeleteRecipientData = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, id: string) => {
        e.stopPropagation()
        setDeletingRecipientId(id)
        setDeletingRecipient(true)
    }
    const startDeleteBankCorrData = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, id: string) => {
        e.stopPropagation()
        setDeletingBankCorrId(id)
        setDeletingBankCorr(true)
    }
    const handleCloseDeletingRecipientData = () => {
        setDeletingRecipient(false)
    }
    const handleCloseDeletingBankCorrData = () => {
        setDeletingBankCorr(false)
    }
    const deleteRecipientData = async (id: string) => {
        setSending(true)
        try {
            await apiConnector.deleteMoneyTransferRecipientData(id)
            handleCloseDeletingRecipientData()
            dispatch(getAllRecipientData(currency || ''))
        } catch (e) {
            console.log(e);
            notification.error({
                duration: null,
                message: "Ошибка!",
                description: "Не удалось удалить получателя"
            });
        } finally {
            setSending(false);
        }
    }
    const deleteBankCorrData = async (id: string) => {
        setSending(true)
        try {
            await apiConnector.deleteMoneyTransferBankCorrData(id)
            handleCloseDeletingBankCorrData()
            dispatch(getAllBankCorrData())
        } catch (e) {
            console.log(e);
            notification.error({
                duration: null,
                message: "Ошибка!",
                description: "Не удалось удалить банк корреспондент"
            });
        } finally {
            setSending(false);
        }
    }

    useEffect(() => {
        setFromAcc(undefined);
        setLocal(currency === ECurrency.KZT);
        currency && apiConnector.getOutBanks(currency).then(res => console.log(res))
        currency && dispatch(getAllRecipientData(currency))
        currency && dispatch(getAllBankCorrData())
    }, [currency])

    const [password, setPassword] = useState<string>('');
    const [certificate, setCertificate] = useState<string>("");
    const [signError, setSignError] = useState<string | null>(null);

    return (
        <Modal
            title={"Перевод денежных средств (внешний)"}
            visible={showMoneyTransferOrderModal}
            onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={620}
            bodyStyle={{ paddingTop: 12 }}
            style={{ overflow: "hidden", minHeight: 500 }}
            centered
            className="edited-form"
            zIndex={5}
        >
            <Row className="of-row">
                <Col span={10}>
                    Валюта:
                </Col>
                <Col span={14}>
                    <Select className="of-select repo-days" size="small"
                        value={currency}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setCurrency(v)}>
                        {
                            getCurrencies(moneyRem).map((cur, i) => (
                                <Select.Option key={`${i}`} value={cur}>{cur}</Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>

            <Row className="of-row">
                <Col span={10}>
                    Счёт списания:
                </Col>
                <Col span={14}>
                    <Select className="of-select repo-days" size="small"
                        value={fromAcc}
                        // defaultValue={ECurrency.KZT}
                        onChange={v => setFromAcc(v)}>
                        {
                            moneyRem.filter(acc => acc.currencyCode === currency && acc.freeAmount > 0).map((acc, i) => (
                                <Select.Option key={`${i}`} value={acc.ftId}>
                                    <Tooltip placement="left" title={getAccTitle(acc.ftId, moneyRem)}>
                                        {acc.iType ? `(${acc.iType}) ` : ""}{acc.holdPlace} - {acc.subAccount}
                                    </Tooltip>
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>
                    Сумма:
                </Col>
                <Col span={14}>
                    <InputNumber
                        value={amount}
                        onChange={p => setAmount(p || 0)}
                        size="small" className="of-input repo-amount-open"
                        formatter={v => countNum(v as number)}
                        parser={v => parseNum(v as string, 2)}
                    />
                </Col>
            </Row>
            <Divider />
            {step === 'form' &&
                <>
                    {recipientData.length && currency ?
                        <Row className="of-row">
                            <Col span={10}>
                                Мои записи:
                            </Col>
                            <Col span={14}>
                                <Select className="of-select repo-days" size="small"
                                    value={recipientDataName}
                                    // defaultValue={ECurrency.KZT}
                                    onChange={v => selectSavedRecipientData(v)}>
                                    {
                                        recipientData.map((rd, i) => (
                                            <Select.Option key={`${i}`} value={rd.id}><Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><span>{rd.name}</span> <CloseOutlined style={{ display: 'block' }} onClick={(e) => startDeleteRecipientData(e, rd.id)} /></Row></Select.Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                        </Row> : <></>
                    }
                    <Row className="of-row">
                        <Button size={'small'} block={false} onClick={formRecipientData} type="primary">Сохранить реквизиты получателя</Button>
                        <Button disabled={!isRecipientDataChanged} style={{ marginLeft: 'auto' }} size={'small'} block={false} onClick={updateRecipientData} type="primary">Сохранить изменения</Button>
                        <Modal
                            title={"Сохранение реквизитов получателя"}
                            visible={savingRecipient}
                            onOk={handleCloseSavingRecipientData}
                            onCancel={handleCloseSavingRecipientData}
                            footer={null}
                            width={620}
                            bodyStyle={{ paddingTop: 12 }}
                            style={{ overflow: "hidden", minHeight: 500 }}
                            centered
                        >
                            <Row className="of-row">
                                <Col span={10}>Название записи:</Col>
                                <Col span={14}>
                                    <Input
                                        value={recipientDataName}
                                        maxLength={20}
                                        size="small" className="of-input"
                                        onChange={e => setRecipientDataName(e.target.value)}
                                    />
                                    <Divider />
                                    <Button loading={sending} onClick={saveRecipientData} type="primary" block>Сохранить</Button>
                                </Col>
                            </Row>
                        </Modal>
                        <Modal
                            title={"Удаление реквизитов получателя"}
                            visible={deletingRecipient}
                            onOk={handleCloseDeletingRecipientData}
                            onCancel={handleCloseDeletingRecipientData}
                            footer={null}
                            width={620}
                            bodyStyle={{ paddingTop: 12 }}
                            style={{ overflow: "hidden", minHeight: 500 }}
                            centered
                        >
                            <Row className="of-row">
                                <Col span={10}>Вы уверены, что хотите удалить запись <b>"{recipientDataName}"</b>?</Col>
                                <Col span={14}>
                                    <Button loading={sending} onClick={() => deleteRecipientData(deletingRecipientId)} type="primary" block>Да</Button>
                                    <Divider />
                                    <Button loading={sending} onClick={handleCloseDeletingRecipientData} type="primary" block>Нет</Button>
                                </Col>
                            </Row>
                        </Modal>
                    </Row>
                </>
            }
            <Row className="of-row">
                <Col span={10}>Наименование/ФИО получателя:</Col>
                <Col span={14}>
                    <Input
                        // status={valid.investDoc ? undefined : "error"}
                        value={name}
                        readOnly
                        // minLength={12}
                        // maxLength={12}
                        // showCount
                        size="small" className="of-input"
                        // placeholder="№__ от __.__.____"
                        onChange={e => setName(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>БИН/ИИН получателя:</Col>
                <Col span={14}>
                    <Input
                        // status={valid.investDoc ? undefined : "error"}
                        value={bin}
                        readOnly
                        minLength={12}
                        maxLength={12}
                        // showCount
                        size="small" className="of-input"
                        // placeholder="№__ от __.__.____"
                        onChange={e => setBin(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>ИИК (банк бенефициара):</Col>
                <Col span={14}>
                    <Input
                        // status={valid.investDoc ? undefined : "error"}
                        value={iik}
                        maxLength={20}
                        // showCount
                        size="small" className="of-input"
                        // placeholder="№__ от __.__.____"
                        onChange={e => setIik(e.target.value.toUpperCase())}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>Банк бенефициара:</Col>
                <Col span={14}>
                    <Input
                        // status={valid.investDoc ? undefined : "error"}
                        value={bank}
                        size="small" className="of-input"
                        // placeholder="№__ от __.__.____"
                        onChange={e => setBank(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>БИК/SWIFT бенефициара:</Col>
                <Col span={14}>
                    <Input
                        // status={valid.investDoc ? undefined : "error"}
                        name="swift"
                        value={swift}
                        minLength={8}
                        maxLength={11}
                        // showCount
                        size="small" className="of-input"
                        // placeholder="№__ от __.__.____"
                        onChange={swiftChangeHandler}
                    // onChange={e => setSwift(e.target.value.toUpperCase())}
                    />
                    {!isValidSwift && <Paragraph type="danger">
                        Код SWIFT невалиден.
                    </Paragraph>
                    }
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={10}>
                    КБе бенефициара:
                </Col>
                <Col span={14}>
                    <InputNumber
                        value={kbe}
                        onChange={p => setKbe(p || 11)}
                        readOnly={iskbe}
                        step={1}
                        min={11}
                        max={29}
                        size="small" className="of-input repo-amount-open"
                    />
                </Col>
            </Row>
            {!local && <>
                <Divider />
                {step === 'form' &&
                    <>
                        {bankCorrData.length && currency ?
                            <Row className="of-row">
                                <Col span={10}>
                                    Мои записи:
                                </Col>
                                <Col span={14}>
                                    <Select className="of-select repo-days" size="small"
                                        value={bankCorrDataName}
                                        // defaultValue={ECurrency.KZT}
                                        onChange={v => selectSavedBankCorrData(v)}>
                                        {
                                            bankCorrData.map((bc, i) => (
                                                <Select.Option key={`${i}`} value={bc.id}><Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><span>{bc.name}</span> <CloseOutlined style={{ display: 'block' }} onClick={(e) => startDeleteBankCorrData(e, bc.id)} /></Row></Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Col>
                            </Row> : <></>
                        }
                        <Row className="of-row">
                            <Button size={'small'} block={false} onClick={formBankCorrData} type="primary">Сохранить банк-корреспондент</Button>
                            <Button disabled={!isBankCorrDataChanged} style={{ marginLeft: 'auto' }} size={'small'} block={false} onClick={updateBankCorrData} type="primary">Сохранить изменения</Button>
                            <Modal
                                title={"Сохранение реквизитов получателя"}
                                visible={savingBankCorr}
                                onOk={handleCloseSavingBankCorrData}
                                onCancel={handleCloseSavingBankCorrData}
                                footer={null}
                                width={620}
                                bodyStyle={{ paddingTop: 12 }}
                                style={{ overflow: "hidden", minHeight: 500 }}
                                centered
                            >
                                <Row className="of-row">
                                    <Col span={10}>Название записи:</Col>
                                    <Col span={14}>
                                        <Input
                                            value={bankCorrDataName}
                                            maxLength={20}
                                            size="small" className="of-input"
                                            onChange={e => setBankCorrDataName(e.target.value)}
                                        />
                                        <Divider />
                                        <Button loading={sending} onClick={saveBankCorrData} type="primary" block>Сохранить</Button>
                                    </Col>
                                </Row>
                            </Modal>
                            <Modal
                                title={"Удаление банка корреспондента"}
                                visible={deletingBankCorr}
                                onOk={handleCloseDeletingBankCorrData}
                                onCancel={handleCloseDeletingBankCorrData}
                                footer={null}
                                width={620}
                                bodyStyle={{ paddingTop: 12 }}
                                style={{ overflow: "hidden", minHeight: 500 }}
                                centered
                            >
                                <Row className="of-row">
                                    <Col span={10}>Вы уверены, что хотите удалить запись <b>"{bankCorrDataName}"</b>?</Col>
                                    <Col span={14}>
                                        <Button loading={sending} onClick={() => deleteBankCorrData(deletingBankCorrId)} type="primary" block>Да</Button>
                                        <Divider />
                                        <Button loading={sending} onClick={handleCloseDeletingBankCorrData} type="primary" block>Нет</Button>
                                    </Col>
                                </Row>
                            </Modal>
                        </Row>
                    </>
                }
                {/*<Row className="of-row">*/}
                {/*    <Col span={10}>Страна(банк бенефициара):</Col>*/}
                {/*    <Col span={14}>*/}
                {/*        <Input*/}
                {/*            // status={valid.investDoc ? undefined : "error"}*/}
                {/*            // value={swift}*/}
                {/*            minLength={8}*/}
                {/*            maxLength={11}*/}
                {/*            // showCount*/}
                {/*            size="small" className="of-input"*/}
                {/*            // placeholder="№__ от __.__.____"*/}
                {/*            // onChange={e => setSwift(e.target.value.toUpperCase())}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row className="of-row">
                    <Col span={10}>Банк-корреспондент:</Col>
                    <Col span={14}>
                        <Input
                            // status={valid.investDoc ? undefined : "error"}
                            value={corrName}
                            size="small" className="of-input"
                            onChange={e => setCorrName(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="of-row">
                    <Col span={10}>Корсчет (банк-корреспондент):</Col>
                    <Col span={14}>
                        <Input
                            value={corrAcc}
                            maxLength={20}
                            size="small" className="of-input"
                            onChange={e => setCorrAcc(e.target.value.toUpperCase())}
                        />
                    </Col>
                </Row>

                {/*<Row className="of-row">*/}
                {/*    <Col span={10}>Страна (банк-корреспондент):</Col>*/}
                {/*    <Col span={14}>*/}
                {/*        <Input*/}
                {/*            // status={valid.investDoc ? undefined : "error"}*/}
                {/*            // value={iik}*/}
                {/*            // showCount*/}
                {/*            size="small" className="of-input"*/}
                {/*            // placeholder="№__ от __.__.____"*/}
                {/*            // onChange={e => setIik(e.target.value.toUpperCase())}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row className="of-row">
                    <Col span={10}>SWIFT (банк-корреспондент):</Col>
                    <Col span={14}>
                        <Input
                            name="corrSwift"
                            value={corrSWIFT}
                            minLength={8}
                            maxLength={11}
                            size="small" className="of-input"
                            onChange={swiftChangeHandler}
                        // onChange={e => setCorrSWIFT(e.target.value.toUpperCase())}
                        />
                        {!isValidSwiftCorr && <Paragraph type="danger">
                            Код SWIFT невалиден.
                        </Paragraph>
                        }
                    </Col>
                </Row>
            </>}
            <Divider />
            <Row>
                {step === "form" &&
                    <>
                        <Button onClick={formOrder} type="primary" block>Создать распоряжение</Button>
                    </>}
                {step === "sign" &&
                    <>
                        {isCompany() && !isHeadOfCompany() && <Button loading={sending} disabled={sending} onClick={pendOrder} type="primary" block>Отправить на
                            согласование</Button>}
                    </>}
            </Row>
            <ModalDS
                signError={signError}
                step={step}
                password={password}
                certificate={certificate}
                setCertificate={setCertificate}
                setPassword={setPassword}
                signOrder={signOrder}
                isDisable={false}
                sending={sending}
                OT={false}
                isActual={undefined}
                loading={false}
                onConfirm={undefined}
                template={undefined}
                isSigners={false}
            />
        </Modal>
    )
}
