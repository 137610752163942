import React, { useState } from 'react';
import { Button, DatePicker, Table } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { apiConnector } from '../../integrations/api.connector';
import { RepCashFlow } from '../../types/reports';
import { getFullPageTableHeight, rowClassCompact, updateTableView } from '../../services/helpers';
import { columns } from './data/cashflow.data';
import { ExportBtn } from "./ExportBtn";
import { exportData } from "../../services/export";
import { TableEditor } from "../tableEditor/TableEditor";

export const CashFlow = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<RepCashFlow[]>([]);
    const [dates, setDates] = useState<string[]>([moment().subtract(1, "day").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]);
    const [exporting, setExporting] = useState(false);
    const getReport = async () => {
        setLoading(true);
        try {
            const { content } = await apiConnector.getRepCashFlow(dates[0], dates[1], 0, 500);
            setData(content);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }
    const onRange: RangePickerProps['onChange'] = (dates, dateStrings) => {
        setDates(dateStrings);
    }
    const onExport = async () => {
        setExporting(true);
        const rows: RepCashFlow[] = data.map(record => {
            // record.reportDate = record.reportDate ? new Date(record.reportDate).toLocaleDateString("ru-RU") : "-";
            return record;
        })
        await exportData(rows, columns, "Отчет о движении ДС", dates.map(d => new Date(d)));
        setExporting(false);
    }
    const [myColumns, setMyColumns] = useState(updateTableView(columns, 'CashFlow'))
    const updateMyColumns = () => {
        setMyColumns(updateTableView(columns, 'CashFlow'))
    }
    return (
        <>
            <span className="picker-pre">Даты отчета:</span>
            <DatePicker.RangePicker
                size="small"
                disabledDate={current => current && current > moment()}
                defaultValue={[moment().subtract(1, "day"), moment()]}
                onChange={onRange}
            />
            <Button style={{ marginLeft: 8 }} type="primary" onClick={getReport} size="small">
                Сформировать отчет
            </Button>
            <ExportBtn style={{ marginLeft: 8, marginRight: 8 }} onClick={onExport.bind(CashFlow)} loading={exporting} />
            <TableEditor updateColumns={updateMyColumns} columns={columns} keyForLS={'CashFlow'} />
            <Table
                size="small"
                showSorterTooltip={false}
                rowClassName={rowClassCompact}
                dataSource={data}
                columns={myColumns}
                loading={loading}
                scroll={{
                    x: "max-content",
                    y: getFullPageTableHeight() - 70
                }}
                pagination={false}
            />
        </>
    )
}
