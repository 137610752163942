import React, { FC } from "react";
import { Button } from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import { dispatch } from "../../store";
import { showRepoSide, showRepoCorpOrder, showRepoGCBOrder, showRepoInfo } from "../../store/orders.slice";
import { TRepoData } from "../../types/repo";

export const PostBtn: FC<{ repoSecurity: TRepoData }> = ({ repoSecurity }) => {

    const onClick = () => {
        if (repoSecurity.seccode === "BSP") {
            dispatch(showRepoCorpOrder(true)); //   Размещение по Корп ЦБ
            dispatch(showRepoInfo(repoSecurity));
        }

        else {
            dispatch(showRepoGCBOrder(true));  // Размещение по ГЦБ
            dispatch(showRepoInfo(repoSecurity));
        }
    }
    return <>
        <Button
            size="small" type="default"
            className="buy-btn" style={{ backgroundColor: 'var(--fhs-green)', color: 'white' }}
            onClick={onClick}>
            Разм.
        </Button>
    </>
}

export const AttractBtn: FC<{ repoSecurity: TRepoData }> = ({ repoSecurity }) => {

    const onClick = () => {
        if (repoSecurity.seccode === "BSP") {
            dispatch(showRepoCorpOrder(false)); // Привлечение по КОРП ЦБ 
            dispatch(showRepoInfo(repoSecurity));
        }

        else {
            dispatch(showRepoGCBOrder(false)); // Привлечение по ГЦБ
            dispatch(showRepoInfo(repoSecurity));
        }
    }
    return <>
        <Button
            size="small" type="primary" danger
            className="buy-btn" style={{ color: 'white' }}
            onClick={onClick}>
            Привл.
        </Button>
    </>
}

export const RepoInfoBtn: FC<{ repoSecurity: TRepoData }> = ({ repoSecurity }) => {

    const handleInfo = () => {
        if (repoSecurity) {
            dispatch(showRepoSide(repoSecurity));
        }
    }

    return <>
        <Button size="small" type="primary"
            style={{ color: 'white' }}
            onClick={handleInfo}>
            <BarChartOutlined />
        </Button>
    </>
}