import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Layout, PageHeader, Radio, Tabs } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { dispatch, TState } from "../../store";
import { getMoneyRem } from "../../store/money.slice";
import { showMoneyTransferOrder } from "../../store/orders.slice";
import { TableEditor } from "../tableEditor/TableEditor";
import { TableMoneyAll } from './groups/TableMoneyAll';
import { TableMoneyByPlace } from './groups/TableMoneyByPlace';
import { TableMoneyByCurrency } from './groups/TableMoneyByCurrency';
import { isEmptyAccount, updateTableView } from "../../services/helpers";
import { columnsAll } from "./money.data";

const { TabPane } = Tabs;
export enum MoneyGroupMode {
    all = 'all',
    byHolder = 'byHolder',
    byCurrency = 'byCurrency'
}

export const MoneyPage = () => {
    const { moneyRem, accFetching } = useSelector((state: TState) => state.money);
    const [groupMode, setGroupMode] = useState<MoneyGroupMode>(MoneyGroupMode.all);
    const accounts = moneyRem.filter(mr => !isEmptyAccount(mr));
    const refresh = () => {
        dispatch(getMoneyRem());
    }
    const [myColumns, setMyColumns] = useState(updateTableView(columnsAll(), groupMode === MoneyGroupMode.all ? 'TableMoneyAll' :
        groupMode === MoneyGroupMode.byHolder ? 'TableMoneyByPlace' :
            groupMode === MoneyGroupMode.byCurrency ? 'TableMoneyByCurrency' : ''))
    const updateMyColumns = () => {
        setMyColumns(updateTableView(columnsAll(), groupMode === MoneyGroupMode.all ? 'TableMoneyAll' :
            groupMode === MoneyGroupMode.byHolder ? 'TableMoneyByPlace' :
                groupMode === MoneyGroupMode.byCurrency ? 'TableMoneyByCurrency' : ''))
    }
    return (
        <Layout className="content-wrap">
            <Tabs type="card">
                <TabPane tabKey="money" tab={<span className="sub-tab">Денежные средства</span>} key="money">
                    <PageHeader
                        subTitle={
                            <Radio.Group value={groupMode} onChange={e => setGroupMode(e.target.value)}>
                                <Radio.Button value={MoneyGroupMode.all}>Все</Radio.Button>
                                <Radio.Button value={MoneyGroupMode.byHolder}>По месту хранения</Radio.Button>
                                <Radio.Button value={MoneyGroupMode.byCurrency}>По валюте</Radio.Button>
                                <Button onClick={() => refresh()} type="default">
                                    <ReloadOutlined />
                                </Button>
                                <Button style={{ marginLeft: 8 }} type="primary" onClick={() => dispatch(showMoneyTransferOrder(false))}>Внутренний перевод</Button>
                                <Button style={{ marginLeft: 8, marginRight: 8 }} type="primary" onClick={() => dispatch(showMoneyTransferOrder(true))}>Внешний перевод</Button>
                                {/*<Button style={{marginLeft: 8}} type="primary" onClick={()=>dispatch(showMoneyConversionOrder())}>Конвертация</Button>*/}
                                <TableEditor
                                    updateColumns={updateMyColumns}
                                    columns={columnsAll()}
                                    keyForLS={
                                        groupMode === MoneyGroupMode.all ? 'TableMoneyAll' :
                                            groupMode === MoneyGroupMode.byHolder ? 'TableMoneyByPlace' :
                                                groupMode === MoneyGroupMode.byCurrency ? 'TableMoneyByCurrency' : ''
                                    }
                                />
                            </Radio.Group>
                        }
                    />
                    {groupMode === MoneyGroupMode.all && <TableMoneyAll columns={myColumns} accounts={accounts} loading={accFetching} />}
                    {groupMode === MoneyGroupMode.byHolder && <TableMoneyByPlace columns={myColumns} accounts={accounts} loading={accFetching} />}
                    {groupMode === MoneyGroupMode.byCurrency && <TableMoneyByCurrency columns={myColumns} accounts={accounts} loading={accFetching} />}
                </TabPane>
            </Tabs>
        </Layout>
    )
}

