import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Col, Divider, Row } from 'antd';
import { TState } from "../../../store";
import { TOwnSecurity, TSubAccount } from "../../../types/api";
import { ESecuritiesTransferKind, TOrderTemplate } from "../../../types/orders";
import { ESecType } from "../../../types/common";
import { countNum } from "../../../services/formatter";

export const ESTForm: FC<{ t: TOrderTemplate, accounts: TSubAccount[] }> = ({ t, accounts }) => {
    const { security } = t.extraData;
    const { globalTypeId, tradeCode } = security as TOwnSecurity;

    const {
        info: {
            brokers,
            holders
        }
    } = useSelector((state: TState) => state);

    const getKind = () => {
        switch (t.extraData.kind) {
            case ESecuritiesTransferKind.self:
                return "Без смены собственности";
            case ESecuritiesTransferKind.other:
                return "Со сменой собственности";
            case ESecuritiesTransferKind.trade:
                if (t.extraData.direction === "incoming") {
                    return "Покупка";
                } else {
                    return "Продажа";
                }
        }
    };

    const getBroker = (id: number) => {
        const broker: any = brokers.find(e => e.brokerId === id)
        return broker.brokerName;
    };

    const getHolder = (id: number) => {
        const holder: any = holders.find(e => e.holderId === id)
        return holder.holderName;
    }

    let brokerId = t.orderData.contraBrokerID;
    let holderId = t.orderData.outHolderID;

    return (
        <div style={{ maxHeight: 600, overflowY: "auto" }}>
            <Row className="bordered-row ticker-currency">
                <Col span={20}>Внешний перевод ценных бумаг</Col>
                <Col span={4}>{tradeCode}</Col>
            </Row>
            <Row className="bordered-row">
                <Col span={24}>
                    {t.extraData.security.isin} {countNum(t.orderData.qty)} {globalTypeId === ESecType.bond ? "(по номиналу)" : "штук"}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Направление:
                </Col>
                <Col span={16}>
                    {t.extraData.direction === "incoming" ? "Зачисление" : "Списание"}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Тип:
                </Col>
                <Col span={16}>
                    {getKind()}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Наименование ЦБ:
                </Col>
                <Col span={16}>
                    {tradeCode}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    {t.extraData.direction === "incoming" ? "Субсчёт зачисления:" : "Субсчёт списания:"}
                </Col>
                <Col span={16}>
                    {t.extraData.direction === "incoming" ? t.extraData.toAccount : t.extraData.fromAccount}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Количество:
                </Col>
                <Col span={16}>
                    {t.orderData.qty}
                </Col>
            </Row>
            {t.extraData.kind === ESecuritiesTransferKind.trade && <Row className="of-row">
                <Col span={8}>
                    Сумма:
                </Col>
                <Col span={16}>
                    {t.orderData.amount}
                </Col>
            </Row>}
            {t.extraData.kind === ESecuritiesTransferKind.trade && <Row className="of-row">
                <Col span={8}>
                    Валюта:
                </Col>
                <Col span={16}>
                    {t.orderData.currency}
                </Col>
            </Row>}
            <Divider style={{ borderColor: "var(--fhs-gray-tr)" }} plain>Реквизиты отправителя</Divider>
            <Row className="of-row">
                <Col span={8}>
                    Брокер:
                </Col>
                <Col span={16}>
                    {getBroker(brokerId)}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Место хранения:
                </Col>
                <Col span={16}>
                    {getHolder(holderId)}
                </Col>
            </Row>

            <Divider style={{ borderColor: "var(--fhs-gray-tr)" }} plain>Внешний контрагент</Divider>
            <Row className="of-row">
                <Col span={8}>
                    Наименование:
                </Col>
                <Col span={16}>
                    {t.orderData.outContraName}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Номер счёта в месте хранения:
                </Col>
                <Col span={16}>
                    {t.orderData.outAccount}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Тип документа:
                </Col>
                <Col span={16}>
                    {t.orderData.contraDocType}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Номер документа:
                </Col>
                <Col span={16}>
                    {t.orderData.contraDocNumber}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Серия документа:
                </Col>
                <Col span={16}>
                    {t.orderData.contraDocSerie}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Орган выпустивший документ:
                </Col>
                <Col span={16}>
                    {t.orderData.contraDocIssuer}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Дата выдачи документа:
                </Col>
                <Col span={16}>
                    {t.orderData.contraDocDate}
                </Col>
            </Row>
            {t.orderData.additionalData && <Divider style={{ borderColor: "var(--fhs-gray-tr)" }} plain>Дополнительные данные по переводу</Divider>}
            {t.orderData.additionalData && <Row className="of-row">
                <Col span={24}>
                    {t.orderData.additionalData}
                </Col>
            </Row>}
        </div>
    )
};