import React, { CSSProperties } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { TOrderBookRecord } from '../../types/market';
import { countNum, finNum4 } from '../../services/formatter';
import { finNum } from '../../services/formatter';

const stylesForSell: CSSProperties = {
    width: '100%',
    height: '100%',
    backgroundColor: "#f5222d",
    color: "#fff",
    cursor: "pointer"
}

const stylesForBuy: CSSProperties = {
    width: '100%',
    height: '100%',
    backgroundColor: "#52c41a",
    color: "#fff",
    cursor: 'pointer',
}
export const columns = (settings: { ytm: boolean, repo: boolean }): ColumnsType<TOrderBookRecord> => {
    const isEquity = !settings.ytm && !settings.repo;
    const isBond = settings.ytm && !settings.repo;
    const isRepo = !settings.ytm && settings.repo;

    let defaultColumns = [
        {
            title: "LOTS (B)",
            dataIndex: "quantity",
            align: 'center',
            width: 100,
            render: (quantity: number, record: TOrderBookRecord) => {
                if (record.direction === "B") {
                    return <div style={stylesForBuy}>{countNum(quantity)}</div>
                }
                return null
            }
        },
        {
            title: "PRICE",
            dataIndex: "price",
            align: 'center',
            width: 100,
            sortOrder: 'descend',
            render: (price: number, record: TOrderBookRecord) => {
                if (record.direction === "S") {
                    if (isBond || isRepo) {
                        return <div style={stylesForSell}>{finNum4(price)}</div>
                    }
                    else return <div style={stylesForSell}>{finNum(price)}</div>
                }
                else {
                    if (isBond || isRepo) {
                        return <div style={stylesForBuy}>{finNum4(price)}</div>
                    }
                    else return <div style={stylesForBuy}>{finNum(price)}</div>
                }
            }
        },
        {
            title: "LOTS (S)",
            dataIndex: "quantity",
            align: 'center',
            width: 100,
            render: (quantity: number, record: TOrderBookRecord) => {
                if (record.direction === 'S') {
                    return <div style={stylesForSell}>{countNum(quantity)}</div>
                }
                return null
            },
        }
    ];
    const ytmColumns = [
        {
            title: "YTM (B)",
            dataIndex: "yield",
            align: 'center',
            width: 100,
            render: (_: number | null, record: TOrderBookRecord) => {
                if (record.direction === 'B' && record.yield !== null && record.yield !== 0.0) {
                    return <div style={stylesForBuy}>{finNum(record.yield)}</div>
                }

                else if (record.direction === 'B' && (record.yield === null || record.yield === 0.0)) {
                    return <>
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: "#52c41a", cursor: 'pointer' }}></div>
                    </>
                }
                else return null
            },
        },
        {
            title: "YTM (S)",
            dataIndex: "yield",
            align: 'center',
            width: 100,
            render: (_: number | null, record: TOrderBookRecord) => {
                if (record.direction === 'S' && record.yield !== null && record.yield !== 0.0) {
                    return <div style={stylesForSell}>{finNum(record.yield)}</div>
                }

                else if (record.direction === 'S' && (record.yield === null || record.yield === 0.0)) {
                    return <>
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: "#f5222d", cursor: 'pointer' }} />
                    </>
                }
                else return null
            },
        },

    ];
    const repoColumns = [
        {
            title: "REPOVALUE (B)",
            dataIndex: "repovalue",
            align: 'center',
            width: 180,
            render: (_: number | null, record: TOrderBookRecord) => {
                if (record.direction === 'B' && record.repovalue !== null && record.repovalue !== 0) {
                    return <div style={stylesForBuy}>{finNum(record.repovalue)}</div>
                }
                else if (record.direction === 'B' && (record.repovalue === null || record.repovalue === 0.0)) {
                    return <>
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: "#52c41a", cursor: 'pointer' }}></div>
                    </>
                }
                return null
            }
        },
        {
            title: "REPOVALUE (S)",
            dataIndex: "repovalue",
            align: 'center',
            width: 180,
            render: (_: number | null, record: TOrderBookRecord) => {
                if (record.direction === 'S' && record.repovalue !== null && record.repovalue !== 0) {
                    return <div style={stylesForSell}>{finNum(record.repovalue)}</div>
                }
                else if (record.direction === 'S' && (record.repovalue === null || record.repovalue === 0.0)) {
                    return <>
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: "#f5222d", cursor: 'pointer' }} />
                    </>
                }
                return null
            }
        }
    ];

    if (isEquity) {
        defaultColumns = [...defaultColumns];
    }
    if (isBond) {
        defaultColumns = [ytmColumns[0], ...defaultColumns, ytmColumns[1]]
    }
    if (isRepo) {
        defaultColumns = [repoColumns[0], ...defaultColumns, repoColumns[1]]
    }
    return defaultColumns as ColumnsType<TOrderBookRecord>;
}

















