import React, { useEffect } from "react";
import { dispatch } from "../../store";
import { getMoneyRem, getSubAccounts } from "../../store/money.slice";
import { getBonds, getEquities, getPortfolio } from '../../store/securities.slice';
import { getDecisions, getOpenRepos, getOrders } from '../../store/orders.slice';
import { getInfo } from "../../store/info.slice";

type SetBooleanState = React.Dispatch<React.SetStateAction<boolean>>;
export const useInitDataFetch = (setIsDataLoaded: SetBooleanState) => {
    const fetchData = async () => {
        // ... диспатчи ...
        setIsDataLoaded(true);
        const commonDispatches = [
            await dispatch(getBonds()),
            await dispatch(getMoneyRem()),
            await dispatch(getSubAccounts()),
            await dispatch(getPortfolio()),
            await dispatch(getOrders()),
            await dispatch(getEquities()),
            await dispatch(getOpenRepos()),
            await dispatch(getDecisions()),
            await dispatch(getInfo())
        ];
        await Promise.all(commonDispatches);
        setIsDataLoaded(false);
    };
    useEffect(() => {
        fetchData()
    }, [dispatch])
}
