import React, { FC } from "react";
import { Col, Row } from "antd";
import { TFTAccount } from "../../../types/api";
import { TMoneyTransferRequest, TOrderTemplate } from "../../../types/orders";
import { finNum } from "../../../services/formatter";

export const IMTForm: FC<{ t: TOrderTemplate, accounts: TFTAccount[] }> = ({ t, accounts }) => {
    const {
        // transferTypeID,
        amount, ftOutID, ftInID
    } = t.orderData as TMoneyTransferRequest;
    let {
        currencyCode
    } = t.extraData;
    if (!currencyCode) {
        currencyCode = accounts.find(a => a.ftId === ftOutID)?.currencyCode;
    }
    const fromAccount = accounts.find(a => a.ftId === ftOutID);
    const toAccount = accounts.find(a => a.ftId === ftInID);

    return (
        <>
            <Row className="bordered-row ticker-currency">
                <Col span={20}>Перевод денежных средств (внутренний)</Col>
                <Col span={4}>{ }</Col>
            </Row>
            <Row className="bordered-row">
                <Col span={24}>
                    {currencyCode} {finNum(amount)}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Счёт списания:
                </Col>
                <Col span={16}>
                    {fromAccount?.iType ? `(${fromAccount.iType}) ` : ""}{fromAccount?.holdPlace} ({fromAccount?.accountNumber})
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Счёт зачисления:
                </Col>
                <Col span={16}>
                    {toAccount?.iType ? `(${toAccount.iType}) ` : ""}{toAccount?.holdPlace} ({toAccount?.accountNumber})
                </Col>
            </Row>
        </>
    )
}