import React, { FC, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { Button, Table } from "antd";
import { CloseCircleOutlined, EditOutlined, FileDoneOutlined, ReloadOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import Search from "antd/lib/input/Search";
import { TState, dispatch } from "../../store";
import { getOrderDeals, showCancelTradeOrder } from "../../store/orders.slice";
import { apiConnector } from "../../integrations/api.connector";
import { DealKind, EOrderStatus, EPDFOrderType, TDealStatusRecord, TOrderStatusFull } from "../../types/orders";
import { TableEditor } from "../tableEditor/TableEditor";
import { BuyIcon } from "../shared/icons/buy.icon";
import { SellIcon } from "../shared/icons/sell.icon";
import { getFullPageTableHeight, rowClassCompact, updateTableView } from "../../services/helpers";
import { countNum, finNum } from "../../services/formatter";
import { newRepoColumns } from "../orders/repo.orders.data";
import "./RepoDeals.css";

export const DealsColumns: ColumnsType<TDealStatusRecord> = [
    {
        title: null,
        width: 120
    },
    {
        title: null,
        width: 120
    },
    {
        title: null,
        width: 120
    },
    {
        title: null,
        width: 100
    },
    {
        title: null,
        width: 120
    },
    {
        title: null,
        width: 80
    },
    {
        title: null,
        dataIndex: "orderStatus",
        width: 120,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "10px" }
            }
        })
    },
    {
        title: null,
        dataIndex: "orderPrice",
        width: 120,
        align: "right",
        render: (text) => ({
            children: finNum(text),
            props: {
                style: { fontSize: "10px" }
            }
        })
    },
    {
        title: null,
        width: 120
    },
    {
        title: null,
        width: 160
    },
    {
        title: null,
        dataIndex: "dealPrice",
        width: 160,
        align: "right",
        render: (text) => ({
            children: finNum(text),
            props: {
                style: { fontSize: "10px" }
            }
        }),
    },
    {
        title: null,
        dataIndex: "dealQuantity",
        width: 160,
        align: "right",
        render: (text) => ({
            children: countNum(text),
            props: {
                style: { fontSize: "10px" }
            }
        }),
    },
    {
        title: null,
        dataIndex: "dealAmount",
        width: 160,
        align: "right",
        render: (text, record) => ({
            children: finNum(record.dealPrice * record.dealQuantity),
            props: {
                style: { fontSize: "10px" }
            }
        })
    },
    {
        title: null,
        dataIndex: "orderDate",
        width: 160,
        align: "center",
        render: (text) => ({
            children: new Date(text).toLocaleString("ru-RU", { dateStyle: 'short', timeStyle: 'short' }),
            props: {
                style: { fontSize: "10px" }
            }
        })
    },

    {
        title: null,
        dataIndex: "orderStatusDate",
        width: 160,
        align: "center",
        render: (text) => ({
            children: new Date(text).toLocaleString("ru-RU", { dateStyle: 'short', timeStyle: 'short' }),
            props: {
                style: { fontSize: "10px" }
            }
        })
    },

    {
        title: null,
        dataIndex: "durationDate",
        width: 160,
        align: "center",
        render: (text) => ({
            children: new Date(text).toLocaleString("ru-RU", { dateStyle: 'short', timeStyle: 'short' }),
            props: {
                style: { fontSize: "10px" }
            }
        })
    },
    {
        title: null,
        dataIndex: "priceQuotation",
        width: 80,
        align: "center",
        render: (text) => {
            if (text === 1) {
                return <span style={{ fontSize: "10px" }}>Чистая цена</span>;
            } else if (text === 2) {
                return <span style={{ fontSize: "10px" }}>Цена</span>;
            } else if (text === 3) {
                return <span style={{ fontSize: "10px" }}>Доходность</span>;
            }
            return "";
        },
    },

    {
        title: null,
        dataIndex: "subAccount",
        width: 120,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "10px" } // Установите значение, соответствующее желаемому размеру шрифта
            }
        }),
    },

    {
        title: null,
        dataIndex: "clientOrderId",
        width: 120,
        align: "center",
        render: (text) => ({
            children: text,
            props: {
                style: { fontSize: "10px" } // Установите значение, соответствующее желаемому размеру шрифта
            }
        })
    },
    {
        title: "",
        dataIndex: "action",
        width: 95,
        // fixed: "right",
        align: "right",
        className: "action-column multi"
    },
]

export const RepoDeals: FC<{ isLog?: boolean }> = ({ isLog = false }) => {
    const timerRef = useRef<number | undefined>();
    const [searchString, setSearchString] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const { ordersFetching, trackedRepoOrders, deals, dealsFetching } = useSelector((state: TState) => state.orders)
    const orders = trackedRepoOrders;

    const cancelableStatusIds = [
        EOrderStatus.confirmed,
        EOrderStatus.processing,
        EOrderStatus.awaitingConfirmation,
        EOrderStatus.partlyCompleted,
        EOrderStatus.partlyOnCalculationAndProcessing,
        EOrderStatus.partlyCompletedAndProcessing
    ];

    const downloadTXT = (p: TOrderStatusFull) => {
        const fileContent = `${p.signature}`;
        const file = new File([fileContent], `${p.clientOrderId}.xml`, { type: 'application/xml' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${p.clientOrderId}.xml`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
    }

    const getDoc = async (o: TOrderStatusFull) => {
        const { clientOrderId, signature, rejectOrderId, onum } = o;
        const p = await apiConnector.getPDF({
            id: o.id,
            xmlData: signature!,
            orderId: o.rejectOrderId && o.orderId ? o.orderId.toString() : clientOrderId.toString(),
            orderDate: o.orderDate,
            orderNumber: (o.rejectOrderId && o.onum) ? o.onum : o.orderNumber || "internal",
            orderType: o.rejectOrderId ? EPDFOrderType.cancel : EPDFOrderType.equities,
            rejOrderNumber: o.rejONum || undefined
        });
        downloadPDF(p, rejectOrderId || clientOrderId);
    }

    const downloadPDF = (p: ArrayBuffer, clientOrderId: number) => {
        const file = new File([p], `${clientOrderId}.pdf`, { type: 'application/pdf' });
        const link = URL.createObjectURL(file);
        const linkEl = document.createElement('a');
        linkEl.setAttribute("href", link);
        linkEl.setAttribute('download', `${clientOrderId}.pdf`);
        linkEl.style.display = 'none';
        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);
    }

    const onSearch = (value: string) => {
        setSearchString(value);
    }
    const onSearchChange = (value: string) => {
        if (!loading) {
            setLoading(true);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            setSearchString(value);
            setLoading(false);
        }, 100)
    }

    const [columnsForRepoOrder, setColumnsForRepoOrder] = useState(updateTableView(newRepoColumns, 'RepoOrders'))
    const updateMyColumns = () => {
        setColumnsForRepoOrder(updateTableView(newRepoColumns, 'RepoOrders'))
    }











    useEffect(() => {
        dispatch(getOrderDeals())
    }, [])


    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const nestedTableRender = (record: TOrderStatusFull) => {
        const matchedObject = deals.find(deal => deal.orderId === record.clientOrderId);
        const matchedDeals = matchedObject ? matchedObject.deals : [];
        return <>
            <Table
                rowClassName={rowClassCompact}
                showSorterTooltip={false}
                columns={DealsColumns}
                dataSource={matchedDeals}
                scroll={{ x: "max-content", y: getFullPageTableHeight() }}
                loading={dealsFetching}
                pagination={false} />
        </>
    }


    const onExpand = (record: TOrderStatusFull) => {
        const recordKey = record.id;
        const isExpanded = expandedRowKeys.includes(recordKey);

        if (isExpanded) {
            setExpandedRowKeys(prevKeys => prevKeys.filter(key => key !== recordKey));
        } else {
            setExpandedRowKeys(prevKeys => [...prevKeys, recordKey]);
        }
    };

    const dataSourceForRepoOrder = (): (TOrderStatusFull & { action: any, icon: any })[] => {
        return orders.map(p => {
            const canBeCanceled = !isLog && cancelableStatusIds.includes(p.orderStatusId);
            const ticker: string = p.ticker || p.repoBasket || "н.д.";
            return {
                ...p,
                ticker: ticker,
                icon: p.rejectOrderId ? <CloseCircleOutlined style={{ fontSize: '16px', marginTop: '2px' }} /> : p.dealKindId === DealKind.buy ? <BuyIcon color={"var(--fhs-black)"} /> : <SellIcon color={"var(--fhs-black)"} />,
                action: <>
                    {isLog ? null : <Button title="Отменить заказ" onClick={() => dispatch(showCancelTradeOrder(p))} disabled={!canBeCanceled} size="small" type="primary" danger><CloseCircleOutlined style={{ fontSize: '18px', marginTop: '2px' }} /></Button>}
                    <Button onClick={() => getDoc(p)} type="primary" size="small" title="Скачать заказ"><FileDoneOutlined style={{ fontSize: '18px', marginTop: '2px' }} /></Button>
                    <Button onClick={() => downloadTXT(p)} type="default" size="small" title="Скачать подпись"><EditOutlined style={{ fontSize: '18px', marginTop: '2px' }} /></Button>
                </>
            }
        }).filter(row => {
            if (!searchString || searchString.length < 1) {
                return true
            } else {
                return (row.ticker && row.ticker.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                    (row.subAccount && row.subAccount.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                    (row.isin && row.isin.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
            }
        }).map(obj => {
            const matchedObject = deals.find(obj2 => obj2.orderId === obj.clientOrderId);
            if (matchedObject) {
                return { ...obj, dealsCount: matchedObject.dealsCount, deals: matchedObject.deals };
            }
            return obj
        })
    }

    return <>
        <Search onSearch={onSearch}
            onChange={e => onSearchChange(e.target.value)}
            allowClear={true}
            placeholder={"Поиск"}
            style={{ width: 250, marginBottom: 10, zIndex: 2 }} />

        <Button style={{ marginRight: 8 }} type="default">
            <ReloadOutlined />
        </Button>

        <TableEditor updateColumns={updateMyColumns} columns={newRepoColumns} keyForLS={'RepoOrders'} />

        <Table
            size="small"
            showSorterTooltip={false}
            className="parent-table"
            rowClassName={rowClassCompact}
            rowKey={record => record.id}
            dataSource={dataSourceForRepoOrder()}
            columns={columnsForRepoOrder}
            scroll={{
                x: "max-content",
                y: getFullPageTableHeight()
            }}
            expandable={{
                expandedRowRender: nestedTableRender,
                expandIcon: ({ expanded, record }) => {
                    if (record.dealsCount > 1) {
                        return (
                            <Button type="text" onClick={() => onExpand(record)}
                                style={{ color: expanded ? 'green' : 'blue', fontWeight: 'bold', }}>
                                {expanded ? '-' : '+'}
                            </Button>
                        )
                    }

                    else {
                        return null;
                    }
                },
                expandedRowKeys,
                defaultExpandedRowKeys: [0],
            }}
            loading={ordersFetching || loading}
            pagination={false}
        />
    </>
}











































// const columnsDeals = newColumnsDealsRepo;
// const lsKey = 'RepoDealsDetailsSec';

// const [columnsForOrderDeals, setColumnsForOrderDeals] = useState(updateTableView(columnsDeals, lsKey))
// const updateColumnsForOrderDeals = () => {
//     setColumnsForOrderDeals(updateTableView(columnsDeals, lsKey))
// }




// const nestedTableRender = (record: TOrderStatusFull) => {
//     const matchedObject = deals.find(deal => deal.orderId === record.clientOrderId);
//     const matchedDeals = matchedObject ? matchedObject.deals : [];

//     const nullPrimaryClientOrderId = matchedDeals.find((item) => item.primaryClientOrderId === null);
//     const notNullPrimaryClientOrderId = matchedDeals.filter((item) => item.primaryClientOrderId !== null);
//     const updatedDeals = [nullPrimaryClientOrderId, ...notNullPrimaryClientOrderId].map((item, index) => ({
//         ...item,
//         // orderNumber: item?.orderNumber.split('/')[0],
//         key: index
//     }));

//     const dataSourceForNestedTable = updatedDeals.map((item) => ({
//         orderStatus: item.orderStatus, // Replace with the actual data property names
//         orderPrice: item.orderPrice,
//         orderQuantity: item.orderQuantity,
//         orderAmount: item.orderAmount,
//         dealPrice: item.dealPrice,
//         dealQuantity: item.dealQuantity,
//         dealAmount: item.dealAmount,
//         orderDate: item.orderDate,
//         orderStatusDate: item.orderStatusDate,
//         durationDate: item.durationDate,
//         priceQuotation: item.priceQuotation,
//         subAccount: item.subAccount
//     }));
//     return <React.Fragment>
//         <Table
//             rowClassName={rowClassCompact}
//             showSorterTooltip={false}
//             columns={columnsForOrderDeals}
//             dataSource={dataSourceForNestedTable}
//             loading={dealsFetching}
//             pagination={false} />
//     </React.Fragment>
// }