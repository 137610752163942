import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiConnector } from "../integrations/api.connector";
import { TRepaymentsByAssets, TRepaymentsByDate } from "../types/repayments";

export const getAllRepaymentsByAsset = createAsyncThunk(
    'repayments/getDataMaturityByAsset',
    async (query?: string): Promise<TRepaymentsByAssets[]> => {
        try {
            return await apiConnector.getAllRepaymentsByAsset(query);
        } catch (e: any) {
            console.log(e);
            throw new Error(e)
        }
    }
)
export const getAllRepaymentsByDate = createAsyncThunk(
    'repayments/getDataMaturityByDate',
    async (query?: string): Promise<TRepaymentsByDate[]> => {
        try {
            return await apiConnector.getAllRepaymentsByDate(query);
        } catch (e: any) {
            console.log(e);
            throw new Error(e)
        }
    }
)

export const repaymentsSlice = createSlice({
    name: 'repayments',
    initialState: {
        repaymentsByAssetsData: [] as TRepaymentsByAssets[],
        repaymentsByDateData: [] as TRepaymentsByDate[],
        repaymentsFetching: false,
        repaymentsError: false
    },
    reducers: {
        showRepaymentsByAssets: (state, action) => {
            state.repaymentsByAssetsData = action.payload
        },
        showRepaymentsByDate: (state, action) => {
            state.repaymentsByDateData = action.payload
        },
        logOutRepayment: (state) => {
            return {
                ...state,
                repaymentsFetching: false,
                repaymentsError: false,
                repaymentsByAssetsData: [],
                repaymentsByDateData: []
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(
                getAllRepaymentsByAsset.fulfilled, (state, action) => {
                    if (Array.isArray(action.payload)) {
                        state.repaymentsByAssetsData = action.payload
                    } else {
                        state.repaymentsByAssetsData = [];
                    }
                    state.repaymentsFetching = false
                }
            )
            .addCase(
                getAllRepaymentsByAsset.pending, state => {
                    state.repaymentsFetching = true
                }
            )
            .addCase(
                getAllRepaymentsByAsset.rejected, state => {
                    state.repaymentsError = true
                    state.repaymentsFetching = false
                }
            )
            .addCase(
                getAllRepaymentsByDate.fulfilled, (state, action) => {
                    if (Array.isArray(action.payload)) {
                        state.repaymentsByDateData = action.payload
                    } else {
                        state.repaymentsByDateData = [];
                    }
                    state.repaymentsFetching = false
                }
            )
            .addCase(
                getAllRepaymentsByDate.pending, (state, action) => {
                    state.repaymentsFetching = true
                }
            )
            .addCase(
                getAllRepaymentsByDate.rejected, state => {
                    state.repaymentsFetching = false
                }
            )
    }
})


export const {
    showRepaymentsByAssets,
    showRepaymentsByDate,
    logOutRepayment
} = repaymentsSlice.actions
