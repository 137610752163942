import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TAccount, TFTAccount, TSubAccount } from "../types/api";
import { apiConnector } from "../integrations/api.connector";

export const getMoneyRem = createAsyncThunk(
    'money/getRem',
    async (data): Promise<any> => {
        try {
            const { payload } = await apiConnector.getMoneyRem();
            return payload;
        } catch (e: any) {
            throw new Error(e)
        }
    }
)

export const getSubAccounts = createAsyncThunk(
    'money/getSubs',
    async (data, { dispatch }): Promise<{ int: TSubAccount[], loc: TSubAccount[] }> => {
        try {
            const int = await apiConnector.getAccounts(false);
            const loc = await apiConnector.getAccounts(true);
            return { int, loc }
        } catch (e: any) {
            throw new Error(e)
        }
    }
)

export const moneySlice = createSlice({
    name: 'money',
    initialState: {
        accounts: [] as TAccount[],
        accFetching: false,
        accError: false,
        subAccountsInt: [] as TSubAccount[],
        subAccountsLocal: [] as TSubAccount[],
        moneyRem: [] as TFTAccount[],
        moneyFetching: false
    },
    reducers: {
        unsetAccError: state => {
            state.accError = false;
        },
        logOutMoney: state => {
            return {
                ...state,
                accounts: [],
                subAccountsInt: [],
                subAccountsLocal: [],
                moneyRem: [],
                accError: false,
                accFetching: false,
                moneyFetching: false,
            };
        }
    },
    extraReducers: builder => {
        builder
            .addCase(
                getSubAccounts.pending, state => {
                    state.accFetching = true
                }
            )
            .addCase(
                getSubAccounts.rejected, state => {
                    state.accError = true
                    state.accFetching = false
                }
            )
            .addCase(
                getSubAccounts.fulfilled, (state, action) => {
                    const { int, loc } = action.payload;
                    state.subAccountsInt = int;
                    state.subAccountsLocal = loc;
                    state.accFetching = false
                }
            )
            .addCase(
                getMoneyRem.fulfilled, (state, action) => {
                    state.moneyFetching = false;
                    state.moneyRem = action.payload
                }
            )
            .addCase(
                getMoneyRem.pending, state => {
                    state.moneyFetching = true;
                }
            )
            .addCase(
                getMoneyRem.rejected, state => {
                    state.moneyFetching = false;
                }
            )
    }
})

export const {
    unsetAccError,
    logOutMoney
} = moneySlice.actions
