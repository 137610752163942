import React, { FC } from "react";
import { Col, Row } from 'antd';
import { TOwnSecurity, TSubAccount } from "../../../types/api";
import { ESecType } from "../../../types/common";
import { TOrderTemplate, TSecuritiesTransferRequest } from "../../../types/orders";
import { countNum } from "../../../services/formatter";


export const ISTForm: FC<{ t: TOrderTemplate, accounts: TSubAccount[] }> = ({ t, accounts }) => {
    const {
        // transferTypeID,
        qty,
        outContrID,
        inContrID,
        // securityID
    } = t.orderData as TSecuritiesTransferRequest;
    const { security } = t.extraData;
    const { globalTypeId, tradeCode } = security as TOwnSecurity;
    const fromAccount = accounts.find(a => a.imContractId === outContrID);
    const toAccount = accounts.find(a => a.imContractId === inContrID);
    return (
        <>
            <Row className="bordered-row ticker-currency">
                <Col span={20}>Внутренний перевод ценных бумаг</Col>
                <Col span={4}>{tradeCode}</Col>
            </Row>
            <Row className="bordered-row">
                <Col span={24}>
                    {security.isin} {countNum(qty)} {globalTypeId === ESecType.bond ? "(по номиналу)" : "штук"}
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Счёт списания:
                </Col>
                <Col span={16}>
                    {fromAccount?.holdPlace}({fromAccount?.subAccount})
                </Col>
            </Row>
            <Row className="of-row">
                <Col span={8}>
                    Счёт зачисления:
                </Col>
                <Col span={16}>
                    {toAccount?.holdPlace}({toAccount?.subAccount})
                </Col>
            </Row>
            <Row className="of-tow">
                <Col span={8}>
                    Наименование ЦБ:
                </Col>
                <Col span={16}>
                    {security.securityName}
                </Col>
            </Row>
        </>
    )
}